const fileApiUrl = 'https://fleet.treesat.io:7443/fileapi/attachment';

export function sendFile(file) {
    return new Promise((resolve, reject) => {
        let formData = new FormData();
        formData.append('file', file);
        let request = new XMLHttpRequest();
        request.open('POST', fileApiUrl, true);
        request.onreadystatechange = function () {
            if (request.readyState !== XMLHttpRequest.DONE) {
                return;
            }
            if (
                this.readyState === XMLHttpRequest.DONE &&
                this.status === 200
            ) {
                let response = JSON.parse(this.responseText);
                if (response.success === 1) {
                    let url = fileApiUrl + '/' + response.file_token;
                    resolve(url);
                } else {
                    reject(response.success);
                }
            } else {
                reject(this.status);
            }
        };
        request.send(formData);
    });
}
