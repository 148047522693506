import React, {useCallback, useEffect, useMemo, useRef, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {connect} from 'react-redux';
import {toast} from 'react-toastify';
import _ from 'lodash';
import moment from 'moment';

import {MainListHeader} from '../../components/MainListsHeader/MainListHeader';
import Table from '../../components/Table';
import {Loader} from '../../components/Loader/Loader';
import {IconRoundedCircle} from '../../graphics/icons';
import {FULL_DATE_FORMAT} from '../../utils/constants';

import './MainNotificationListStyles.scss';

function MainNotificationList({
                                  notificationService,
                                  notificationList,
                                  unreadNotificationList,
                                  readNotificationList,
                                  notificationCount,
                              }) {
    const {t} = useTranslation();
    const [selection, setSelection] = useState([]);
    const [loadingMore, setLoadingMore] = useState(false);
    const [status, setStatus] = useState({
        label: t('ALL'),
        value: 'ALL',
    });
    const [noMoreData, setNoMoreData] = useState({
        unread: false,
        read: false,
        all: false,
    });
    const [searchValue, setSearchValue] = useState('');
    const [tableData, setTableData] = useState(null);
    const tableContainerRef = useRef(null);
    const statusOptions = [
        {label: t('ALL'), value: 'ALL'},
        {label: t('READ'), value: 'READ'},
        {label: t('UNREAD'), value: 'UNREAD'},
    ];
    useEffect(() => {
        let list;
        switch (status.value) {
            case 'UNREAD':
                list = unreadNotificationList;
                break;
            case 'READ':
                list = readNotificationList;
                break;
            default:
                list = notificationList;
        }

        if (list === null) {
            return;
        }

        console.debug(
            'MainNotificationList::[searchValue, list] => "%s", %O',
            searchValue,
            list,
        );
        setTableData(
            list.filter((n) =>
                n.text.toLocaleLowerCase().includes(searchValue),
            ),
        );

    }, [notificationList, unreadNotificationList, readNotificationList, searchValue, status]);

    useEffect(() => {
        notificationService.getNotifications(status.value);
        notificationService.getNotificationCount();
    }, [notificationService, status.value]);

    function markNotificationRead() {
        notificationService
            .markNotificationRead(selection.map((s) => s.id))
            .then(() => {
                toast.success(
                    t('THE_MARKED_NOTIFICATIONS_HAVE_BEEN_MARKED_AS_READ'),
                );
                setSelection([]);
            })
            .catch((reason) => {
                toast.error(
                    t('THE_MARKED_NOTIFICATIONS_HAVE_BEEN_NOT_MARKED_AS_READ'),
                );
            });
    }

    useEffect(() => console.debug(selection), [selection]);

    const columns = useMemo(
        () => [
            {
                id: 'OCCURRENCE_DATE_NOTIFICATION',
                Header: t('OCCURRENCE_DATE'),
                accessor: 'occurrence',
                Cell: ({value}) =>
                    typeof value === 'number'
                        ? moment.unix(value).format(FULL_DATE_FORMAT)
                        : '',
                width: 180,
            },
            {
                Header: t('MESSAGE'),
                accessor: 'text',
                width: 300,
            },
            {
                Header: t('DRIVER'),
                accessor: 'driver',
                width: 180,
            },
            {
                id: 'vehicle_name',
                Header: t('VEHICLE'),
                accessor: 'vehicle',
                width: 180,
            },
            {
                Header: t('READ'),
                accessor: 'read',
                className: 'notification-read-cell',
                Cell: ({value}) =>
                    value ? (
                        <IconRoundedCircle />
                    ) : (
                        <IconRoundedCircle withoutCircle />
                    ),
                width: 80,
            },
        ],
        [t],
    );

    const handleLoadMore = useCallback(() => {
        let list;
        switch (status.value) {
            case 'UNREAD':
                list = unreadNotificationList;
                break;
            case 'READ':
                list = readNotificationList;
                break;
            default:
                list = notificationList;
        }

        if (list === null || loadingMore) {
            return;
        }
        setLoadingMore(true);
        const oldest = list.reduce(
            (prev, curr) =>
                prev.original.created < curr.original.created ? prev : curr,
        );

        notificationService
            .getNotifications(status.value, oldest.original.created)
            .then((value) => {
                setLoadingMore(false);
            })
            .catch((reason) => {
                setLoadingMore(false);
                setNoMoreData({...noMoreData, [status.value]: true});
            });
    }, [notificationList, unreadNotificationList, readNotificationList, loadingMore, notificationService, status, noMoreData]);

    const scrollGuard = useCallback(() => {
        const container = tableContainerRef.current;
        if (container && !noMoreData[status.value]) {
            if (container.scrollHeight - container.scrollTop - container.clientHeight < 500) {
                handleLoadMore();
            }
        }
    }, [handleLoadMore, noMoreData, status.value]);

    const handleSearch = _.debounce(({target}) => {
        setSearchValue(target.value.toLocaleLowerCase());
    }, 200);

    const sorted = useMemo(
        () => [{id: 'OCCURRENCE_DATE_NOTIFICATION', desc: true}],
        [],
    );

    return (
        <div
            id={'main-notification-list'}
            className={'main-notification-list-container'}
        >
            <MainListHeader
                headerText={t('NOTIFICATIONS')}
                handleChangeSearchValue={(e) => {
                    e.persist();
                    handleSearch(e);
                }}
                selectedDataAction={
                    selection.length > 0 ? markNotificationRead : null
                }
                dropdownOptions={statusOptions}
                dropdownAction={(value) => {
                    setStatus(value);
                }}
                dropdownValue={status}
                selectedDataActionLabel={t('MARK_AS_READ')}
            />
            {tableData === null && <Loader />}
            {tableData !== null && (
                <>
                    <Table
                        ref={tableContainerRef}
                        onScroll={scrollGuard}
                        data={tableData}
                        columns={columns}
                        selectType="checkbox"
                        setSelectedRows={setSelection}
                        defaultSortBy={sorted}
                        footer={loadingMore && t('LOADING')}
                        getTbodyProps={() => ({id: 'notification-table-body'})}
                    />
                </>
            )}
        </div>
    );
}

const mapStateToProps = (state) => {
    const {notificationList, notificationCount} = state;
    return {
        notificationList: notificationList.notificationList,
        unreadNotificationList: notificationList.unreadNotificationList,
        readNotificationList: notificationList.readNotificationList,
        notificationCount,
    };
};

export default connect(mapStateToProps)(MainNotificationList);
