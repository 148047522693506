import React from 'react';
import {useTranslation} from 'react-i18next';
import Autosuggest from 'react-autosuggest';
import {loadOptions} from '../../utils/suggestionOptions';

/**
 *
 * @param point {RoutePoint}
 * @param setPoint {Function}
 * @param localizationSuggestions {Suggestion[]}
 * @param setLocalizationSuggestions {Function}
 * @param placeholder {string}
 * @returns {Element}
 * @constructor
 */
const AutoSuggestItem = ({
    point,
    setPoint,
    localizationSuggestions,
    setLocalizationSuggestions,
    placeholder,
}) => {
    const {t} = useTranslation('common');

    const renderInputComponent = (inputProps) => <input {...inputProps} />;

    const renderSectionTitle = (section) => (
        <span className="custom-section-title">{section.title}</span>
    );

    const onChange = (event, {newValue}) => {
        setPoint((prev) => ({
            lat: newValue && newValue.trim().length === 0 ? null : prev.lat,
            lng: newValue && newValue.trim().length === 0 ? null : prev.lng,
            localization: newValue,
        }));
    };

    const inputProps = {
        placeholder: placeholder,
        value: point.localization,
        onChange: onChange,
    };

    const renderSuggestion = (suggestion) => {
        let getSuggestionImage = () => {
            if (suggestion.type === 'place') {
                return (
                    <img
                        src={
                            require('../../graphics/iko_vehicle_marker_blue.png')
                                .default
                        }
                        alt=""
                    />
                );
            } else if (suggestion.type === 'parking') {
                return (
                    <img
                        src={
                            require('../../graphics/iko_vehicle_park.png')
                                .default
                        }
                        alt=""
                    />
                );
            } else {
                return (
                    <img
                        src={
                            require('../../graphics/iko_vehicle_poi_blue.png')
                                .default
                        }
                        alt=""
                    />
                );
            }
        };

        return (
            <>
                {getSuggestionImage()}
                <span>{suggestion.label}</span>
            </>
        );
    };

    const getSuggestionValue = (suggestion) => {
        setPoint((prev) => ({
            ...prev,
            lat: suggestion.lat,
            lng: suggestion.lng,
        }));
        return suggestion.label;
    };

    const getSectionSuggestions = (section) => {
        return section.values;
    };

    const getSuggestions = (value) => {
        let suggestions = [];
        value.length &&
            suggestions.push({
                title: t('PLACES'),
                values: value,
            });
        return suggestions;
    };

    const onSuggestionsFetchRequested = ({value}) => {
        loadOptions(value, (result) => {
            setLocalizationSuggestions(getSuggestions(result, value));
        });
    };

    const onSuggestionsClearRequested = () => {
        setLocalizationSuggestions([]);
    };

    return (
        <Autosuggest
            suggestions={localizationSuggestions}
            onSuggestionsFetchRequested={onSuggestionsFetchRequested}
            onSuggestionsClearRequested={onSuggestionsClearRequested}
            getSectionSuggestions={getSectionSuggestions}
            renderSectionTitle={renderSectionTitle}
            renderSuggestion={renderSuggestion}
            renderInputComponent={renderInputComponent}
            getSuggestionValue={getSuggestionValue}
            shouldRenderSuggestions={(v) => v.trim().length > 2}
            inputProps={inputProps}
            multiSection={true}
        />
    );
};

export default AutoSuggestItem;
