import React from 'react';
import moment from 'moment';
import {withTranslation} from 'react-i18next';
import {FULL_DATE_WITHOUT_SECONDS_FORMAT} from '../../utils/constants';

import './MapChatUserItemStyles.scss';

function MapChatUserItem({userData, selected, selectUser, t}) {
    const userName =
        userData.first_name === null || userData.last_name === null
            ? userData.login
            : userData.first_name + ' ' + userData.last_name;
    const lastMessageTime = userData.messages.length
        ? moment(
              userData.messages[userData.messages.length - 1].created * 1000,
          ).format(FULL_DATE_WITHOUT_SECONDS_FORMAT)
        : '';

    const unreadMessages = (function () {
        for (let i = userData.messages.length - 1; i > 0; i--) {
            if (userData.messages[i].id_from === userData.id) {
                return !userData.messages[i].log?.some(
                    (log) => log.type === 'read',
                );
            }
        }
    })();

    return (
        <div
            id={'user_' + userData.id}
            className={'map-chat-user-item ' + (selected ? 'selected' : '')}
            onClick={selectUser}
        >
            <div className={'user-data-container'}>
                <div className={'online-status-icon-container'}>
                    {userData.is_online ? (
                        <img
                            alt={''}
                            src={
                                require('../../graphics/chatIcons/iko_chat_user_online.png')
                                    .default
                            }
                            className={'online-status-icon'}
                        />
                    ) : null}
                </div>
                <div
                    className={'user-data ' + (unreadMessages ? 'unread' : '')}
                >
                    {userName}
                </div>
            </div>
            {userData.type && (
                <div className={'type-info'}>
                    <div>{t(`ACCOUNT_OF(${userData.type})`)}</div>
                </div>
            )}
            <div className={'other-info'}>
                {lastMessageTime && (
                    <>
                        <div className={'description'}>{t('LAST_MESSAGE')}</div>
                        <div className={'value'}>{lastMessageTime}</div>
                    </>
                )}
            </div>
        </div>
    );
}

export default withTranslation()(MapChatUserItem);
