import {store} from '../redux/store/store';
import {
    addAccessSchema,
    removeAccessSchema,
    setAccessSchemaList,
    updateAccessSchemaList,
} from '../redux/actions/accessSchemaActions';

export default class AccessSchemaService {
    constructor(connection) {
        this.connection = connection;
        this.createSchemaNotificationsHandler();
    }

    init() {
        this.getAccessSchemaList();
    }

    createSchemaNotificationsHandler() {
        this.connection.addHandler('access_schema_created', (result) => {
            store.dispatch(addAccessSchema(result.data));
        });
        this.connection.addHandler('access_schema_updated', (result) => {
            store.dispatch(updateAccessSchemaList(result.data));
        });
        this.connection.addHandler('access_schema_removed', (result) => {
            store.dispatch(removeAccessSchema(result.data.id));
        });
    }

    getAccessSchemaList() {
        return new Promise((resolve, reject) => {
            this.connection.query(
                'business.getAccessSchemas',
                {},
                (result) => {
                    store.dispatch(setAccessSchemaList(result));
                    resolve(result);
                },
                (reject) => {
                    console.log(reject);
                },
            );
        });
    }

    addSchema(accessSchema) {
        return new Promise((resolve, reject) => {
            this.connection.query(
                'business.createAccessSchema',
                accessSchema,
                resolve,
                (reject) => {
                    console.log(reject);
                },
            );
        });
    }

    updateSchema(schema) {
        return new Promise((resolve, reject) => {
            this.connection.query(
                'business.updateAccessSchema',
                schema,
                resolve,
                (reject) => {
                    console.log(reject);
                },
            );
        });
    }

    removeSchema(schemaId) {
        return new Promise((resolve, reject) => {
            this.connection.query(
                'business.deleteAccessSchema',
                {id: schemaId},
                resolve,
                (reason) => {
                    console.log(reason);
                    reject(reason);
                },
            );
        });
    }
}
