import {useEffect, useRef} from 'react';
import {Loader} from '../Loader/Loader';

import './Modal.scss';

/**
 *
 * @param children {ReactNode}
 * @param onHide {Function}
 * @param id {string}
 * @param className {string}
 * @param isLoading {boolean}
 * @returns {JSX.Element}
 * @constructor
 */
const Modal = ({children, onHide, id, className, isLoading}) => {
    const ref = useRef(null);

    useEffect(() => {
        setTimeout(() => {
            ref && ref.current && ref.current.classList.add('visible');
        }, 10);
    }, []);

    return (
        <>
            <div
                className="overlay"
                onClick={() => {
                    if (!onHide) return;
                    onHide();
                }}
            ></div>
            <div
                ref={ref}
                id={id}
                className={`modal-container ${className ? className : ''}`}
            >
                {isLoading && (
                    <div className="loading">
                        <Loader />
                    </div>
                )}
                {children}
            </div>
        </>
    );
};

export default Modal;
