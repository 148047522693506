import _ from 'lodash';

export function createTemperaturesArray(iodata) {
    let temperatures = [];
    if (
        !_.isNil(iodata.t1) ||
        !_.isNil(iodata.t2) ||
        !_.isNil(iodata.t3) ||
        !_.isNil(iodata.t4)
    ) {
        temperatures.push({
            value: getThermometerValue(iodata.t1),
            type: 'dallas',
        });
        temperatures.push({
            value: getThermometerValue(iodata.t2),
            type: 'dallas',
        });
        temperatures.push({
            value: getThermometerValue(iodata.t3),
            type: 'dallas',
        });
        temperatures.push({
            value: getThermometerValue(iodata.t4),
            type: 'dallas',
        });
    } else if (
        !_.isNil(iodata.BLE_temp_1) ||
        !_.isNil(iodata.BLE_temp_2) ||
        !_.isNil(iodata.BLE_temp_3) ||
        !_.isNil(iodata.BLE_temp_4)
    ) {
        temperatures.push({
            value: getThermometerValue(iodata.BLE_temp_1),
            type: 'bluetooth',
        });
        temperatures.push({
            value: getThermometerValue(iodata.BLE_temp_2),
            type: 'bluetooth',
        });
        temperatures.push({
            value: getThermometerValue(iodata.BLE_temp_3),
            type: 'bluetooth',
        });
        temperatures.push({
            value: getThermometerValue(iodata.BLE_temp_4),
            type: 'bluetooth',
        });
    }
    return temperatures;
}

export function getThermometerValue(value) {
    if (typeof value === 'number') {
        return Math.round(value * 10) / 10 + '°C';
    } else if (typeof value === 'string') {
        switch (value) {
            case 'failed_sensor_data_parsing':
            case 'value_read_error': {
                return 'error';
            }
            case 'sensor_not_ready':
            case 'sensor_not_found':
            case 'not_connected': {
                return 'N/A';
            }
            case 'abnormal_sensor_state':
            case 'id_failed': {
                return 'N/N';
            }
            default: {
                return '';
            }
        }
    } else {
        return false;
    }
}
