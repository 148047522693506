import React, {useEffect, useMemo, useState} from 'react';
import './App.scss';
import 'react-confirm-alert/src/react-confirm-alert.css';
import {store} from './redux/store/store';
import {BrowserRouter as Router, Navigate, Outlet, Route, Routes} from 'react-router-dom';
import moment from 'moment';
import {useSelector} from 'react-redux';
import {registerLocale} from 'react-datepicker';
import ReactTooltip from 'react-tooltip';
import {usePrevious} from 'react-use';
import Rollbar from 'rollbar';
import {useTranslation} from 'react-i18next';

import TopMenu from './containers/TopMenu/TopMenu';
import Login from './routes/Login/Login';
import MapView from './routes/MainMap/MapView';
import Poi from './routes/Poi/Poi';
import Clients from './routes/Clients/Clients';
import MainNotificationList from './routes/MainNotificationList/MainNotificationList';
import Orders from './routes/Orders/Orders';
import Dispositors from './routes/Dispositors/Dispositors';
import Drivers from './routes/Drivers/Drivers';
import Forms from './routes/Forms/Forms';
import Vehicles from './routes/Vehicles/Vehicles';
import ReportGenerator from './routes/ReportGenerator/ReportGenerator';
import VehicleSettings from './routes/VehicleSettings/VehicleSettings';
import Settings from './routes/Settings/Settings';
import Devices from './routes/Devices/Devices';
import Cart from './routes/Cart/Cart';

import Connection from './Connection';
import UserService from './services/UserService';
import POIService from './services/POIService';
import ClientService from './services/ClientService';
import VehicleService from './services/VehicleService';
import OrderService from './services/OrderService';
import NotificationService from './services/NotificationService';
import ReportService from './services/ReportService';
import ChatService from './services/ChatService';
import UsersService from './services/UsersService';
import DriversService from './services/DriversService';
import AccessSchemaService from './services/AccessSchemaService';
import DevicesService from './services/DevicesService';
import LicenseService from './services/LicenseService';
import OffersService from './services/OffersService';
import GroupService from './services/GroupService';
import AtlasService from './services/AtlasService';
import PuescService from './services/PuescService';

import pl from 'date-fns/locale/pl';
import 'moment/locale/pl';

import Account from './containers/Account/Account';
import ConnectionIssues from './containers/ConnectionIssues/ConnectionIssues';
import ChangeLog from './containers/ChangeLog/ChangeLog';
import Newsletter from './containers/Newsletter/Newsletter';
import DemoPopup from './components/DemoPopup/DemoPopup';
import {setSelectedGroupId} from './redux/actions/appActions';
import type {ServiceProvider} from './utils/service';
import {ServiceProviderContext} from './utils/service';
import {ApiContext} from './utils/api';
import useVersion from './hooks/useVersion';
import useEventListener from './hooks/useEventListener';
import type {User} from './utils/interfaces/user';
import type {AccountStore} from './utils/interfaces/account';
import type {RootState} from './redux/reducers/rootReducer';

registerLocale('pl', pl);
moment.locale('pl');


function AppHooked() {
    const version = useVersion();
    let devMode = version.includes('DEV');

    const {i18n} = useTranslation();

    const connection = useMemo(() => new Connection(), []);
    const {selectedGroupId, variant} = useSelector(
        (state: RootState) => state.app,
    );
    const groupList = useSelector((state: RootState) => state.groupList);

    const [userIsLogged: boolean, setUserIsLogged: Function<boolean>] =
        useState(false);
    const [showNewsletter: boolean, setShowNewsletter: Function<boolean>] =
        useState(false);
    const [showDemoPopup: boolean, setShowDemoPopup: Function<boolean>] =
        useState(false);

    const services: ServiceProvider = useMemo(
        () => ({
            connection: connection,
            vehicleService: new VehicleService(connection),
            userService: new UserService(connection, setUserIsLogged),
            poiService: new POIService(connection),
            orderService: new OrderService(connection),
            clientService: new ClientService(connection),
            notificationService: new NotificationService(connection),
            reportService: new ReportService(connection),
            chatService: new ChatService(connection),
            usersService: new UsersService(connection),
            driversService: new DriversService(connection),
            accessSchemaService: new AccessSchemaService(connection),
            devicesService: new DevicesService(connection),
            licenseService: new LicenseService(connection),
            offersService: new OffersService(connection),
            groupService: new GroupService(connection),
            atlasService: new AtlasService(connection),
            puescService: new PuescService(connection),
        }),
        [connection],
    );

    useEffect(() => {
        const scrollDiv = document.createElement('div');
        scrollDiv.className = 'scrollbar-measure';
        document.body.appendChild(scrollDiv);

        // Get the scrollbar width
        const scrollbarWidth = scrollDiv.offsetWidth - scrollDiv.clientWidth;
        document.documentElement.style.setProperty(
            '--scrollbar-width',
            scrollbarWidth + 'px',
        );

        // Delete the DIV
        document.body.removeChild(scrollDiv);
    }, []);

    useEffect(() => {
        const rollbar = new Rollbar({
            accessToken: process.env.REACT_APP_ROLLBAR_ACCESS_TOKEN,
        });

        rollbar.configure({
            captureUncaught: !devMode,
            captureUnhandledRejections: !devMode,
            payload: {
                environment: devMode ? 'dev' : 'production',
            },
            version: version,
        });
    }, [devMode, version]);

    // reload page on connection_closed
    window.addEventListener('connection_closed', () => {
        // if user was logged in blow everything up! WebSocket was closed and nothing can be done to recover from this
        setTimeout(() => window.location.reload(false), 2000);
    });

    useEventListener('groups_change', () => {
        const groupIndex = groupList.findIndex(
            (group) => group.id === selectedGroupId,
        );
        const _selectedGroupId =
            groupIndex !== -1 && typeof selectedGroupId === 'number'
                ? selectedGroupId
                : null;

        services.vehicleService.getVehicles({
            filter_group_id: _selectedGroupId,
        });
        services.devicesService.getDevices(_selectedGroupId);
        services.licenseService.getAllLicenses(_selectedGroupId);
        variant === 'fm' && services.orderService.getOrders(_selectedGroupId);
    });

    // System notifications
    if (window.Notification) {
        if (window.Notification.permission !== 'granted') {
            const notificationPermissionPromise =
                Notification.requestPermission();
            if (notificationPermissionPromise) {
                notificationPermissionPromise.then((status) => {
                    if (Notification.permission !== status) {
                        try {
                            Notification.permission = status;
                        } catch (error) {
                            console.warn(
                                'App => Notification.permission = status',
                                error,
                            );
                        }
                    }
                });
            }
        }
    }

    // initialize once userIsLogged
    const prevUserIsLogged = usePrevious(userIsLogged);
    useEffect(() => {
        if (prevUserIsLogged === false && userIsLogged === true) {
            services.userService.getUserData().then((userData: User) => {
                i18n.changeLanguage(userData.lang || 'pl');
                if (userData.demo_mode) {
                    setShowDemoPopup(true);
                }
                if (
                    userData.type !== 'user' &&
                    store.getState().app.variant === 'fm'
                ) {
                    services.chatService.init(userData.id);
                }
                if (userData.type === 'manager') {
                    services.offersService.init();
                }
                services.notificationService.getNotificationCount(false);
                // this.notificationService.getUnreadNotifications().then((result) => {
                //     if (result.length) {
                //         toast.info("ℹ " + this.t("YOU_HAVE_UNREAD_NOTIFICATIONS", {numberOfUnreadMessages: result.length}))
                //     }
                // });
                if (userData.type === 'user') {
                    store.dispatch(setSelectedGroupId(null));
                    services.vehicleService.initStore(null);
                    services.devicesService.initStore(null);
                    variant === 'fm' && services.orderService.initStore(null);
                } else {
                    services.accessSchemaService.init();
                    services.userService.getStore().then((r: AccountStore) => {
                        console.debug(
                            'App::[logon] => account.getStore: %O',
                            r,
                        );
                        if (
                            typeof r?.selected_group === 'number' ||
                            r?.selected_group === null
                        ) {
                            store.dispatch(
                                setSelectedGroupId(r.selected_group),
                            );
                        } else {
                            store.dispatch(setSelectedGroupId(null));
                        }
                    });
                    services.groupService.init();
                }
            });
        }
    }, [prevUserIsLogged, userIsLogged, services, variant, i18n]);

    useEffect(() => {
        if (selectedGroupId === undefined || !userIsLogged || !groupList) {
            return;
        }

        if (typeof selectedGroupId === 'number') {
            const groupIndex = groupList.findIndex(
                (group) => group.id === selectedGroupId,
            );
            if (groupIndex !== -1) {
                services.vehicleService.initStore(selectedGroupId);
                services.devicesService.initStore(selectedGroupId);
                services.licenseService.init(selectedGroupId);
                variant === 'fm' &&
                services.orderService.initStore(selectedGroupId);
                return;
            }
        }
        store.dispatch(setSelectedGroupId(null));
        services.vehicleService.initStore(null);
        services.devicesService.initStore(null);
        services.licenseService.init(null);
        variant === 'fm' && services.orderService.initStore(null);
    }, [
        selectedGroupId,
        userIsLogged,
        groupList,
        services.vehicleService,
        services.devicesService,
        services.orderService,
        services.licenseService,
        variant,
    ]);

    // check newsletter
    useEffect(() => {
        if (!userIsLogged) return;
        let isMounted = true;

        services.userService
            .getStore('fm_newsletter')
            .then((r: AccountStore) => {
                if (isMounted) {
                    if (r.fm_newsletter) {
                        setShowNewsletter(false);
                    } else {
                        setShowNewsletter(true);
                        services.userService.setStore('fm_newsletter', true);
                    }
                }
            })
            .catch((error) => {
                if (isMounted) {
                    if (error === 'err_key_not_exists') {
                        setShowNewsletter(true);
                        services.userService.setStore('fm_newsletter', true);
                    }
                }
            });

        return () => {
            isMounted = false;
        };
    }, [userIsLogged, services.userService]);

    return (
        <ApiContext.Provider value={connection}>
            <ServiceProviderContext.Provider value={services}>
                <Router>
                    <div id="app">
                        <TopMenu />
                        <Routes>
                            <Route
                                path="/"
                                element={
                                    userIsLogged ? (
                                        <Navigate to={'/map'} />
                                    ) : (
                                        <Navigate to={'/login'} />
                                    )
                                }
                            />
                            <Route
                                path={'/login'}
                                element={
                                    userIsLogged ? (
                                        <Navigate to={'/map'} />
                                    ) : (
                                        <Login
                                            userService={services.userService}
                                        />
                                    )
                                }
                            />
                            <Route
                                element={
                                    <AuthLayout userIsLogged={userIsLogged} />
                                }
                            >
                                <Route
                                    path="/map"
                                    element={
                                        <MapView
                                            vehicleService={
                                                services.vehicleService
                                            }
                                            reportService={
                                                services.reportService
                                            }
                                            clientService={
                                                services.clientService
                                            }
                                            driversService={
                                                services.driversService
                                            }
                                            userService={services.userService}
                                        />
                                    }
                                />
                                <Route path="/orders/*" element={<Orders />} />
                                <Route
                                    path="/vehicles/settings"
                                    element={<VehicleSettings />}
                                />
                                <Route
                                    path="/vehicles/*"
                                    element={<Vehicles />}
                                />
                                <Route
                                    path="/clients"
                                    element={
                                        <Clients
                                            clientService={
                                                services.clientService
                                            }
                                        />
                                    }
                                />
                                <Route
                                    path="/dispatchers"
                                    element={
                                        <Dispositors
                                            usersService={services.usersService}
                                        />
                                    }
                                />
                                <Route
                                    path="/drivers/*"
                                    element={<Drivers />}
                                />
                                <Route path="/forms/*" element={<Forms />} />
                                <Route
                                    path="/forms/tapa/active"
                                    element={
                                        <Forms
                                            type="tapa"
                                            active={true}
                                            vehicleService={
                                                services.vehicleService
                                            }
                                            driversService={
                                                services.driversService
                                            }
                                        />
                                    }
                                />
                                <Route
                                    path="/forms/tapa/archived"
                                    element={
                                        <Forms
                                            type="tapa"
                                            active={false}
                                            vehicleService={
                                                services.vehicleService
                                            }
                                            driversService={
                                                services.driversService
                                            }
                                        />
                                    }
                                />
                                <Route
                                    path="/devices"
                                    element={
                                        <Devices
                                            devicesService={
                                                services.devicesService
                                            }
                                        />
                                    }
                                />
                                <Route
                                    path="/reports/*"
                                    element={<ReportGenerator />}
                                />
                                <Route path="/poi" element={<Poi />} />
                                <Route
                                    path="/notifications"
                                    element={
                                        <MainNotificationList
                                            notificationService={
                                                services.notificationService
                                            }
                                        />
                                    }
                                />
                                <Route
                                    path="/settings/*"
                                    element={<Settings />}
                                />
                                <Route path="/cart" element={<Cart />} />
                                <Route
                                    path="*"
                                    element={
                                        <span
                                            className="not-found"
                                            role="img"
                                            aria-label="Where would you like to be?"
                                        >
                                            🤷‍
                                        </span>
                                    }
                                />
                            </Route>
                        </Routes>
                        {userIsLogged && <Account />}
                        <ChangeLog userIsLogged={userIsLogged} />
                        {showNewsletter && (
                            <Newsletter
                                onHide={() => setShowNewsletter(false)}
                            />
                        )}
                        {showDemoPopup && (
                            <DemoPopup onHide={() => setShowDemoPopup(false)} />
                        )}
                        <ReactTooltip place="top" effect="solid" />
                    </div>
                </Router>
                <ConnectionIssues />
            </ServiceProviderContext.Provider>
        </ApiContext.Provider>
    );
}

/**
 *
 * @param userIsLogged {boolean}
 * @returns {Element}
 * @constructor
 */
const AuthLayout = ({userIsLogged}) => {
    if (userIsLogged) {
        return <Outlet />;
    }
    return <Navigate to="/login" replace />;
};

export default AppHooked;
