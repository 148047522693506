import React, {useEffect, useState} from 'react';
import useEventListener from '../../hooks/useEventListener';
import {IconChevronLeft, IconChevronRight} from '../../graphics/icons';

import './Filters.scss';

/**
 *
 * @param {string[]} filters
 * @param {string[]} labels
 * @param {Function} onTabChange
 * @param {number} maxElementsVisible
 * @param {string} selectedTab
 * @returns {JSX.Element}
 * @constructor
 */
const Filters = ({
    filters,
    labels,
    onTabChange,
    maxElementsVisible,
    selectedTab,
}) => {
    const [activeTab: string, setActiveTab: Function<string>] = useState(
        filters[0],
    );
    const [currentIndex: number, setCurrentIndex: Function<number>] =
        useState(0);
    const [boundaries: {left: number, right: number}, setBoundaries] =
        useState(null);

    const elementsOnSide = maxElementsVisible
        ? Math.floor(maxElementsVisible / 2)
        : null;

    useEventListener('tab_changed', (e: CustomEvent) => {
        setActiveTab(e.detail);
    });

    useEffect(() => {
        if (!selectedTab) return;
        setActiveTab(selectedTab);
    }, [selectedTab]);

    useEffect(() => {
        if (!maxElementsVisible || !elementsOnSide) return;
        const index = filters.findIndex((f) => f === activeTab);
        setCurrentIndex(index);

        if (index === 0) {
            setBoundaries({
                left: index,
                right:
                    maxElementsVisible % 2 === 0
                        ? index + elementsOnSide * 2 - 1
                        : index + elementsOnSide * 2,
            });
        } else if (index === filters.length - 1) {
            setBoundaries({
                left:
                    maxElementsVisible % 2 === 0
                        ? index - elementsOnSide * 2 + 1
                        : index - elementsOnSide * 2,
                right: index,
            });
        } else {
            setBoundaries({
                left: index - elementsOnSide,
                right: index + elementsOnSide,
            });
        }
    }, [maxElementsVisible, activeTab, filters, elementsOnSide]);

    return (
        <ul className="filters">
            {maxElementsVisible && (
                <li
                    className={`arrow ${currentIndex === 0 ? ' disabled' : ''}`}
                    onClick={() => {
                        if (currentIndex === 0) return;
                        setActiveTab(filters[currentIndex - 1]);
                        onTabChange(filters[currentIndex - 1]);
                    }}
                >
                    <IconChevronLeft />
                </li>
            )}
            {filters &&
                onTabChange &&
                filters.map((filter, index) => {
                    let shouldBeVisible = false;
                    if (
                        !maxElementsVisible ||
                        !boundaries ||
                        (index >= boundaries.left && index <= boundaries.right)
                    ) {
                        shouldBeVisible = true;
                    }
                    if (!shouldBeVisible) return null;
                    return (
                        <li
                            key={index}
                            onClick={() => {
                                setActiveTab(filter);
                                onTabChange(filter);
                            }}
                            className={activeTab === filter ? 'active' : null}
                        >
                            {labels ? labels[index] : filters[index]}
                        </li>
                    );
                })}
            {maxElementsVisible && (
                <li
                    className={`arrow ${currentIndex === filters.length - 1 ? ' disabled' : ''}`}
                    onClick={() => {
                        if (currentIndex === filters.length - 1) return;
                        setActiveTab(filters[currentIndex + 1]);
                        onTabChange(filters[currentIndex + 1]);
                    }}
                >
                    <IconChevronRight />
                </li>
            )}
        </ul>
    );
};

export default Filters;
