import React, {useEffect, useMemo, useState} from 'react';
import {useSelector} from 'react-redux';
import {useTranslation} from 'react-i18next';
import {toast} from 'react-toastify';
import _ from 'lodash';
import {confirmAlert} from 'react-confirm-alert';

import PointsMap from '../../containers/PointsMap/PointsMap';
import PointsTable from '../../containers/PointsTable/PointsTable';
import PoiCreator from './parts/PoiCreator';
import ShowPoi from './parts/ShowPoi';
import {MainListHeader} from '../../components/MainListsHeader/MainListHeader';
import {Loader} from '../../components/Loader/Loader';
import Filters from '../../components/Filters/Filters';
import {store} from '../../redux/store/store';
import {
    setNewPoiDataFromExisted,
    setNewPoiInitialState,
} from '../../redux/actions/newPOIActions';
import {addPOI, updatePOI} from '../../redux/actions/POIActions';
import useServiceProvider from '../../utils/service';
import {poiIcons} from '../../utils/POITypes';
import type {POI, POIType} from '../../utils/interfaces/poi';
import type {RootState} from '../../redux/reducers/rootReducer';
import type {SelectOption} from '../../utils/interfaces/interfaces';

import './Poi.scss';

type tabOptions = 'list' | 'map';

const Poi = () => {
    const {t} = useTranslation('Poi');

    const {poiService} = useServiceProvider();
    const {POIList, POITypeList, newPOIData} = useSelector(
        (state: RootState) => state,
    );

    const [data: POI[], setData: Function<POI[]>] = useState(null);
    const [tableData: POI[], setTableData: Function<POI[]>] = useState(null);
    const [currentPoi: POI, setCurrentPoi: Function<POI>] = useState(null);
    const [searchValue: string, setSearchValue: Function<string>] =
        useState('');

    const [addMode: boolean, setAddMode: Function<boolean>] = useState(false);
    const [editMode: boolean, setEditMode: Function<boolean>] = useState(false);
    const [detailsMode: boolean, setDetailsMode: Function<boolean>] =
        useState(false);

    const [activeTab: tabOptions, setActiveTab: Function<tabOptions>] =
        useState('list');
    const [
        poiTypeOptions: SelectOption[],
        setPoiTypeOptions: Function<SelectOption[]>,
    ] = useState([]);
    const [
        selectedPoiType: SelectOption,
        setSelectedPoiType: Function<SelectOption>,
    ] = useState({
        value: 'all',
        label: t('ALL'),
    });

    useEffect(() => {
        poiService.initStore();
    }, [poiService]);

    useEffect(() => {
        if (!POITypeList) return;
        const typeOptions = POITypeList.filter((poiType) =>
            poiIcons.includes(poiType.icon),
        )
            .map((type) => ({
                value: type.id,
                label: t(type.name),
            }))
            .sort((a, b) => {
                if (a.label > b.label) {
                    return 1;
                }
                return -1;
            });
        typeOptions.unshift({value: 'all', label: t('ALL')});
        setPoiTypeOptions(typeOptions);
    }, [POITypeList, t]);

    const filteredTypes = useMemo(
        () =>
            selectedPoiType.value === 'all'
                ? POITypeList
                : POITypeList?.filter(
                      (type) => type.id === selectedPoiType.value,
                  ),
        [POITypeList, selectedPoiType.value],
    );

    useEffect(() => {
        if (POIList === null || POITypeList === null) {
            return;
        }
        setData(
            POIList.filter((poi) =>
                filteredTypes?.find((type) => type.id === poi.type),
            ),
        );
    }, [POIList, POITypeList, filteredTypes]);

    useEffect(() => {
        if (!POIList || !filteredTypes) {
            return;
        }
        if (!searchValue) {
            if (selectedPoiType.value === 'all') {
                setTableData(POIList);
            } else {
                setTableData(
                    POIList.filter((poi) =>
                        filteredTypes.find((type) => type.id === poi.type),
                    ),
                );
            }
            return;
        }
        const sv = searchValue.toLocaleLowerCase();
        const filteredPois = POIList.filter(
            (p: POI) =>
                (p.address?.toLocaleLowerCase().includes(sv) ||
                    p.name.toLocaleLowerCase().includes(sv) ||
                    p.extra?.description?.toLocaleLowerCase().includes(sv)) &&
                filteredTypes.find((t: POIType) => t.id === p.type),
        );
        setTableData(filteredPois);
    }, [searchValue, POIList, filteredTypes, selectedPoiType.value]);

    const switchAddMode = () => {
        if (!POITypeList) return;

        if (!addMode) {
            setActiveTab('map');
            window.dispatchEvent(
                new CustomEvent('tab_changed', {detail: 'map'}),
            );
        }
        setAddMode((prev) => !prev);
        store.dispatch(setNewPoiInitialState());
    };

    const addOrEditPOI = (data) => {
        data.extra = JSON.stringify(data.extra);
        if (data.id) {
            updatePOIHandler(data);
        } else {
            addPOIHandler(data);
        }
    };

    const updatePOIHandler = (data) => {
        const updatedPoint = {
            id: data.id,
            data: data,
        };
        const onSuccess = () => {
            toast.success(t('THE_POI_HAS_BEEN_UPDATED'));
            disableAddAndEditMode();
            store.dispatch(updatePOI(data));
            store.dispatch(setNewPoiInitialState());
        };
        const onError = (reason) => {
            toast.error(
                t('THE_POI_HAS_BEEN_NOT_UPDATED_TRY_AGAIN', {error: t(reason)}),
            );
        };
        poiService.updatePoint(updatedPoint, onSuccess, onError);
    };

    const addPOIHandler = (data) => {
        const onError = (reason) => {
            toast.error(
                t('THE_POI_HAS_BEEN_NOT_CREATED_TRY_AGAIN', {error: t(reason)}),
            );
        };
        const onSuccess = (result) => {
            toast.success(t('THE_POI_HAS_BEEN_CREATED'));
            store.dispatch(addPOI({id: result, ...data}));
            store.dispatch(setNewPoiInitialState());
            disableAddAndEditMode();
        };
        poiService.createPoint(data, onSuccess, onError);
    };

    const removePOI = () => {
        poiService.removePoint(
            currentPoi.id,
            () => {
                toast.success(t('THE_POI_HAS_BEEN_SUCCESSFULLY_REMOVED'));
                onHideDetailsMode();
                setSearchValue('');
            },
            () => {
                toast.error(t('THE_POI_HAS_NOT_BEEN_REMOVED'));
            },
        );
    };

    const removePOIConfirmAlert = () => {
        confirmAlert({
            title: t('CONFIRM_TO_REMOVE_THIS_POI'),
            message: t('ARE_YOU_SURE_YOU_WANT_TO_REMOVE_SELECTED_POI'),
            buttons: [
                {label: t('YES'), onClick: () => removePOI()},
                {label: t('NO')},
            ],
        });
    };

    const disableAddAndEditMode = () => {
        setEditMode(false);
        setAddMode(false);
        setActiveTab('list');
    };

    const handleSearch = _.debounce((e) => {
        setSearchValue(e.target.value);
    }, 300);

    const onOpenEditMode = () => {
        setDetailsMode(false);
        setEditMode(true);
        setActiveTab('map');
        store.dispatch(setNewPoiDataFromExisted(currentPoi));
    };

    const onHideDetailsMode = () => {
        setDetailsMode(false);
        setCurrentPoi(null);
        store.dispatch(setNewPoiInitialState());
    };

    return (
        <div id="main-poi-list">
            <MainListHeader
                headerText={addMode ? t('NEW_POI') : t('POI')}
                handleChangeSearchValue={
                    activeTab === 'map' ? null : handleSearch
                }
                addMode={addMode}
                editMode={editMode}
                addText={t('NEW_POI')}
                switchAddMode={switchAddMode}
                filters={
                    !addMode &&
                    !editMode && (
                        <Filters
                            filters={['list', 'map']}
                            labels={[t('LIST'), t('MAP')]}
                            onTabChange={setActiveTab}
                        />
                    )
                }
                dropdownOptions={!addMode && !editMode && poiTypeOptions}
                dropdownValue={selectedPoiType}
                dropdownAction={setSelectedPoiType}
            />
            <div className={'list-and-map'}>
                {((data === null && activeTab === 'map') ||
                    (tableData === null && activeTab === 'list')) && <Loader />}
                <div
                    style={{
                        display:
                            tableData !== null && activeTab === 'list'
                                ? 'block'
                                : 'none',
                    }}
                >
                    {tableData !== null && (
                        <PointsTable
                            data={tableData}
                            newPOIData={newPOIData}
                            setCurrentPoi={setCurrentPoi}
                            setDetailsMode={setDetailsMode}
                        />
                    )}
                </div>
                {(addMode || editMode) && (
                    <PoiCreator
                        addOrEditPOI={addOrEditPOI}
                        disableAddAndEditMode={disableAddAndEditMode}
                    />
                )}
                {data !== null && activeTab === 'map' && (
                    <PointsMap
                        markers={data}
                        newPOIData={newPOIData}
                        type={'POI'}
                        addMode={addMode || editMode}
                        setCurrentPoi={setCurrentPoi}
                        setDetailsMode={setDetailsMode}
                    />
                )}
                {detailsMode && currentPoi !== null && (
                    <ShowPoi
                        currentPoi={currentPoi}
                        onOpenEditMode={onOpenEditMode}
                        onHide={onHideDetailsMode}
                        removePoiHandler={removePOIConfirmAlert}
                    />
                )}
            </div>
        </div>
    );
};

export default Poi;
