import type {
    DeviceToVehicleRelation,
    UpdateDeviceToVehicleRelation,
} from '../../utils/interfaces/vehicle';

export const SET_DEVICE_TO_VEHICLE_RELATION_LIST =
    'SET_DEVICE_TO_VEHICLE_RELATION_LIST';
export const ADD_DEVICE_TO_VEHICLE_RELATION = 'ADD_DEVICE_TO_VEHICLE_RELATION';
export const UPDATE_DEVICE_TO_VEHICLE_RELATION =
    'UPDATE_DEVICE_TO_VEHICLE_RELATION';
export const REMOVE_DEVICE_TO_VEHICLE_RELATION =
    'REMOVE_DEVICE_TO_VEHICLE_RELATION';

export function setDeviceToVehicleRelationList(
    relations: DeviceToVehicleRelation[],
) {
    return {
        type: SET_DEVICE_TO_VEHICLE_RELATION_LIST,
        relations,
    };
}
export function addDeviceToVehicleRelation(
    relationData: DeviceToVehicleRelation,
) {
    return {
        type: ADD_DEVICE_TO_VEHICLE_RELATION,
        relationData,
    };
}

export function updateDeviceToVehicleRelation(
    updatedRelationData: UpdateDeviceToVehicleRelation,
) {
    return {
        type: UPDATE_DEVICE_TO_VEHICLE_RELATION,
        updatedRelationData,
    };
}

export function removeDeviceToVehicleRelation(
    updatedRelationData: UpdateDeviceToVehicleRelation,
) {
    return {
        type: REMOVE_DEVICE_TO_VEHICLE_RELATION,
        updatedRelationData,
    };
}
