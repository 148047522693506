import React from 'react';
import {useTranslation} from 'react-i18next';

import {MainListHeader} from '../../components/MainListsHeader/MainListHeader';
import PuescVehicles from '../../containers/PuescVehicles/PuescVehicles';
import TransportExchanges from '../../containers/TransportExchanges/TransportExchanges';

import './ExternalIntegrations.scss';

const ExternalIntegrations = () => {
    const {t} = useTranslation(['Settings', 'common']);

    return (
        <div id="external-integrations">
            <MainListHeader headerText={t('common:EXTERNAL_INTEGRATIONS')} />
            <div className="columns">
                <div className="column">
                    <div className="column-content">
                        <PuescVehicles />
                    </div>
                </div>
                <div className="column">
                    <div className="column-content">
                        <TransportExchanges />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ExternalIntegrations;
