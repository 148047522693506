import React, {useEffect, useState} from 'react';
import './MapChatStyles.scss';
import MapChatContent from '../MapChatContent/MapChatContent';
import {connect} from 'react-redux';
import useServiceProvider from '../../utils/service';
import {toast} from 'react-toastify';
import {useTranslation} from 'react-i18next';

function MapChat({chatUserList, userData, chatTemplateList}) {
    const {t} = useTranslation();
    const [chatExpanded, setChatExpanded] = useState(false);
    const [wasExpanded, setWasExpanded] = useState(false);
    const [notifiedAboutUnread, setNotifiedAboutUnread] = useState(false);

    const {chatService} = useServiceProvider();

    useEffect(() => {
        if (wasExpanded) {
            return;
        }

        if (!chatExpanded) {
            return;
        }

        setWasExpanded(true);
        chatService.onOpen();
    }, [chatExpanded, wasExpanded, chatService]);

    const unreadMessages = (function () {
        let counter = 0;
        chatUserList.forEach((chatUser) => {
            chatUser.messages.forEach((message) => {
                if (
                    message.id_from !== userData.id &&
                    !message.log?.some((log) => log.type === 'read')
                ) {
                    counter++;
                }
            });
        });
        return counter;
    })();

    useEffect(() => {
        if (notifiedAboutUnread) {
            return;
        }
        if (unreadMessages > 0) {
            setNotifiedAboutUnread(true);
            toast.warn(t('UNREAD_CHAT_MESSAGES'));
            // if (window.Notification && Notification.permission === 'granted') {
            //     let n = new Notification('Fleet Manager', {
            //         tag: 'FM_WEB_UNREAD_CHAT',
            //         icon: process.env.PUBLIC_URL + '/graphics/fm_icon.png',
            //         body: t('UNREAD_CHAT_MESSAGES'),
            //         requireInteraction: true,
            //         renotify: true
            //     });
            //     n.addEventListener("click", (e) => {
            //         e.target.close();
            //     });
            // }
        }
    }, [chatUserList, notifiedAboutUnread, unreadMessages, t]);

    return (
        <div id={'map-chat'} className={chatExpanded ? 'expanded' : ''}>
            {chatExpanded ? (
                <div className={'chat-container'}>
                    <MapChatContent
                        chatUserList={chatUserList}
                        hideChat={() => setChatExpanded(!chatExpanded)}
                        userData={userData}
                        chatService={chatService}
                        chatTemplateList={chatTemplateList}
                    />
                </div>
            ) : (
                <div
                    className={'folded-chat-icon-container'}
                    onClick={() => setChatExpanded(!chatExpanded)}
                >
                    {unreadMessages ? (
                        <div
                            className={
                                'folded-chat-icon-background unread-messages-counter'
                            }
                        >
                            {unreadMessages}
                        </div>
                    ) : (
                        ''
                    )}
                    <div
                        className={
                            'folded-chat-icon-background ' +
                            (unreadMessages ? 'unread-messages' : '')
                        }
                    >
                        <img
                            className={'folded-chat-icon'}
                            alt={''}
                            src={
                                require('../../graphics/chatIcons/iko_chat_white.png')
                                    .default
                            }
                        />
                    </div>
                </div>
            )}
        </div>
    );
}

const mapStateToProps = (state) => {
    const {chatUserList, chatTemplateList} = state;
    return {
        chatUserList,
        chatTemplateList,
    };
};
export default connect(mapStateToProps)(MapChat);
