import moment from 'moment';
import {convertSecondsToDurationString} from '../date';
import {
    createReportHeader,
    DATE_CELL_WIDTH,
    DURATION_CELL_WIDTH,
    getSheet,
    PLACE_CELL_WIDTH,
} from './reportsXLSXUtils';
import {darkRowStyle, lightRowStyle} from './sheetStyles';
import {FULL_DATE_WITHOUT_SECONDS_FORMAT} from '../constants';

export function countryCrossingsReportSheet(
    workbook,
    t,
    reportData,
    simplified,
) {
    if (!reportData.multiple) {
        reportData.vehicles = [
            {rows: reportData.rows, vehicle_name: reportData.vehicle_name},
        ];
    }

    reportData.vehicles.forEach((vehicle) => {
        let sheet = getSheet(workbook, vehicle.vehicle_name);

        reportData.fields[
            reportData.fields.findIndex((key) => key === 'duration')
        ] = 'visit_duration';
        createReportHeader(
            sheet,
            t,
            t(
                simplified
                    ? 'Reports:SIMPLIFIED_CROSSING_BORDERS_REPORT_HEADER'
                    : 'Reports:CROSSING_BORDERS_REPORT_HEADER',
                {vehicleName: vehicle.vehicle_name},
            ),
            reportData.days,
            reportData.date_start,
            reportData.date_end,
            reportData.fields,
        );
        if (simplified) {
            vehicle.rows.forEach((row, index) => {
                let rowRelativeCell = sheet.cell('A' + (index + 3));
                row.forEach((value, index) => {
                    if (index === 1) {
                        rowRelativeCell
                            .value(
                                moment
                                    .unix(value)
                                    .format(FULL_DATE_WITHOUT_SECONDS_FORMAT),
                            )
                            .style(
                                rowRelativeCell.rowNumber() % 2 === 0
                                    ? darkRowStyle
                                    : lightRowStyle,
                            );
                    } else if (index === 3) {
                        rowRelativeCell
                            .value(convertSecondsToDurationString(value, true))
                            .style(
                                rowRelativeCell.rowNumber() % 2 === 0
                                    ? darkRowStyle
                                    : lightRowStyle,
                            );
                    } else {
                        rowRelativeCell
                            .value(value)
                            .style(
                                rowRelativeCell.rowNumber() % 2 === 0
                                    ? darkRowStyle
                                    : lightRowStyle,
                            );
                    }
                    rowRelativeCell = rowRelativeCell.relativeCell(0, 1);
                });
            });
        } else {
            vehicle.rows.forEach((row, index) => {
                let rowRelativeCell = sheet.cell('A' + (index + 3));
                row.forEach((value, index) => {
                    if (index === 2 || index === 3) {
                        rowRelativeCell
                            .value(
                                moment
                                    .unix(value)
                                    .format(FULL_DATE_WITHOUT_SECONDS_FORMAT),
                            )
                            .style(
                                rowRelativeCell.rowNumber() % 2 === 0
                                    ? darkRowStyle
                                    : lightRowStyle,
                            );
                    } else if (index === 5) {
                        rowRelativeCell
                            .value(convertSecondsToDurationString(value, true))
                            .style(
                                rowRelativeCell.rowNumber() % 2 === 0
                                    ? darkRowStyle
                                    : lightRowStyle,
                            );
                    } else {
                        rowRelativeCell
                            .value(value)
                            .style(
                                rowRelativeCell.rowNumber() % 2 === 0
                                    ? darkRowStyle
                                    : lightRowStyle,
                            );
                    }
                    rowRelativeCell = rowRelativeCell.relativeCell(0, 1);
                });
            });
        }

        sheet
            .column('A')
            .width(simplified ? DATE_CELL_WIDTH : PLACE_CELL_WIDTH);
        sheet
            .column('B')
            .width(simplified ? DATE_CELL_WIDTH : PLACE_CELL_WIDTH);
        sheet.column('C').width(DATE_CELL_WIDTH);
        sheet.column('D').width(DATE_CELL_WIDTH);
        sheet.column('E').width(20);
        sheet.column('F').width(DURATION_CELL_WIDTH);
    });
}
