import React, {useCallback, useMemo} from 'react';
import {confirmAlert} from 'react-confirm-alert';
import {Cell} from 'react-table';
import {useTranslation} from 'react-i18next';
import Table from '../../../components/Table';

/**
 *
 * @param rowAction {Function}
 * @param service {ClientService}
 * @param clientData {Counterparty}
 * @param clientList {Counterparty[]}
 * @param searchValue {string}
 * @returns {Element}
 * @constructor
 */
export default function ClientTable({
    rowAction,
    service,
    clientData,
    clientList,
    searchValue,
}) {
    const {t} = useTranslation(['Client', 'common']);

    const getRemoveCellContent = useCallback(
        (rowInfo) => {
            const handler = (e: MouseEvent) => {
                e.stopPropagation();
                return confirmAlert({
                    customUI: ({onClose}) => {
                        return (
                            <div className="react-confirm-alert-body">
                                <h1>
                                    {t('CONFIRM_ARCHIVING_COUNTERPARTY_HEADER')}
                                </h1>
                                <p>
                                    {t(
                                        'CONFIRM_ARCHIVING_COUNTERPARTY_DESCRIPTION',
                                        {name: rowInfo.name},
                                    )}
                                </p>
                                <div
                                    className={
                                        'react-confirm-alert-button-group'
                                    }
                                >
                                    <button onClick={onClose}>
                                        {t('common:CANCEL')}
                                    </button>
                                    <button
                                        onClick={() => {
                                            service.removeClient(rowInfo);
                                            onClose();
                                        }}
                                        className={'confirm'}
                                    >
                                        {t('common:CONFIRM')}
                                    </button>
                                </div>
                            </div>
                        );
                    },
                });
            };
            return (
                <div className={'cell-content center'}>
                    <img
                        className={'image-cell-content action'}
                        alt=""
                        src={
                            require('../../../graphics/iko_delete_blue.png')
                                .default
                        }
                        onClick={handler}
                    />
                </div>
            );
        },
        [service, t],
    );

    const columns = useMemo(
        () => [
            {
                Header: t('COMPANY_NAME'),
                accessor: 'name',
            },
            {
                Header: t('COMPANY_TAX_ID'),
                accessor: 'tax_id',
                width: 120,
            },
            {
                Header: t('COMPANY_KRS'),
                accessor: 'krs',
                width: 120,
            },
            {
                Header: t('COMPANY_STREET'),
                accessor: 'street',
            },
            {
                Header: t('COMPANY_CITY'),
                accessor: 'city',
                width: 180,
            },
            {
                Header: t('COMPANY_POSTCODE'),
                accessor: 'postcode',
                width: 80,
            },
            {
                id: 'country',
                Header: t('COMPANY_COUNTRY'),
                width: 70,
                accessor: (client) => client.country ?? '',
                Cell: (cell: Cell) => {
                    const client = cell.row.original;
                    return (
                        <div className={'cell-content center'}>
                            {client.country && (
                                <img
                                    className={'image-cell-content multiple'}
                                    alt=""
                                    src={
                                        '/graphics/countries/' +
                                        client.country.toLowerCase() +
                                        '.png'
                                    }
                                />
                            )}
                        </div>
                    );
                },
            },
            {
                id: 'correspondence',
                Header: t('COMPANY_CORRESPONDENCE'),
                minWidth: 140,
                accessor: (client) =>
                    client.correspondence?.street +
                    ',' +
                    client.correspondence?.postcode +
                    ',' +
                    client.correspondence?.city +
                    ',' +
                    client.correspondence?.country,
                Cell: (cell: Cell) => {
                    const client = cell.row.original;
                    const c = client.correspondence;
                    const p = [];
                    if (c.street) {
                        p.push(c.street);
                    }
                    if (c.postcode || c.city) {
                        p.push(c.postcode + ' ' + c.city);
                    }
                    if (c.country) {
                        p.push(c.country);
                    }
                    return <div>{p.join(', ')}</div>;
                },
            },
            {
                id: 'remove_icon',
                Header: '',
                Cell: (rowInfo) => getRemoveCellContent(rowInfo.row.original),
                width: 70,
            },
        ],
        [t, getRemoveCellContent],
    );

    if (searchValue !== '') {
        clientList = clientList.filter((row) => {
            const searchString = searchValue.toLowerCase();
            return (
                row.name.toLowerCase().includes(searchString) ||
                (row.tax_id !== null &&
                    row.tax_id.toLowerCase().includes(searchString)) ||
                (row.krs !== null &&
                    row.krs.toLowerCase().includes(searchString)) ||
                (row.street !== null &&
                    row.street.toLowerCase().includes(searchString)) ||
                (row.city !== null &&
                    row.city.toLowerCase().includes(searchString)) ||
                (row.postcode !== null &&
                    row.postcode.toLowerCase().includes(searchString)) ||
                (row.correspondence &&
                    row.correspondence.street &&
                    row.correspondence.street
                        .toLowerCase()
                        .includes(searchString)) ||
                (row.correspondence &&
                    row.correspondence.city &&
                    row.correspondence.city
                        .toLowerCase()
                        .includes(searchString)) ||
                (row.correspondence &&
                    row.correspondence.postcode &&
                    row.correspondence.postcode
                        .toLowerCase()
                        .includes(searchString))
            );
        });
    }

    return (
        <div id={'client-table'}>
            <Table
                data={clientList}
                columns={columns}
                defaultSortBy={[
                    {
                        id: 'name',
                        asc: true,
                    },
                ]}
                getRowProps={(row) => ({
                    className:
                        row.counterparty_id === clientData?.counterparty_id
                            ? 'selected'
                            : '',
                })}
                onRowClick={rowAction}
            />
        </div>
    );
}
