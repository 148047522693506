import React, {useCallback, useEffect, useMemo, useState} from 'react';
import {useTranslation} from 'react-i18next';
import ReactTooltip from 'react-tooltip';
import moment from 'moment';

import RightPane from '../../../components/RightPane/RightPane';
import {InlineLoader} from '../../../components/Loader/Loader';
import Table from '../../../components/Table';
import DeleteTransportExchange from './DeleteTransportExchange';
import EditTransportExchange from './EditTransportExchange';
import EditVehicles from './EditVehicles';
import ResetPassword from './ResetPassword';
import {IconDelete, IconEdit, IconPencilEdit} from '../../../graphics/icons';
import {FULL_DATE_FORMAT} from '../../../utils/constants';
import type {AtlasVehicle} from '../../../utils/interfaces/transportExchange';

/**
 *
 * @param currentExchange {AtlasUser}
 * @param setCurrentExchange {Function}
 * @param atlasService {AtlasService}
 * @param fetchAtlasUsers {Function}
 * @param onClose {Function}
 * @returns {Element}
 * @constructor
 */
const ShowTransportExchange = ({
    currentExchange,
    setCurrentExchange,
    atlasService,
    fetchAtlasUsers,
    onClose,
}) => {
    const {t} = useTranslation(['Settings', 'common']);

    const [editMode, setEditMode] = useState(false);
    const [deleteMode, setDeleteMode] = useState(false);
    const [resetPasswordMode, setResetPasswordMode] = useState(false);
    const [editVehiclesMode, setEditVehiclesMode] = useState(false);
    const [vehicles, setVehicles] = useState(null);

    useEffect(() => {
        setTimeout(() => {
            ReactTooltip.rebuild();
        }, 0);

        return () => {
            ReactTooltip.hide();
        };
    }, []);

    const fetchVehicles = useCallback(() => {
        let isMounted = true;

        atlasService.getVehicles(
            currentExchange.id,
            (result: AtlasVehicle[]) => {
                if (isMounted) {
                    console.debug(
                        'ShowTransportExchange :: getVehicles',
                        result,
                    );
                    setVehicles(result);
                }
            },
            (error) => {
                console.error('ShowTransportExchange :: getVehicles', error);
            },
        );

        return () => {
            isMounted = false;
        };
    }, [atlasService, currentExchange.id]);

    useEffect(() => {
        fetchVehicles();
    }, [fetchVehicles]);

    const Buttons = () => {
        return (
            <>
                <button
                    className="button red icon"
                    onClick={() => setDeleteMode(true)}
                    data-tip={t('common:REMOVE')}
                    data-place="left"
                >
                    <IconDelete />
                </button>
                <button
                    className="button action icon"
                    onClick={() => setEditMode(true)}
                    data-tip={t('common:EDIT')}
                    data-place="left"
                >
                    <IconEdit />
                </button>
            </>
        );
    };

    const columns = useMemo(
        () => [
            {
                id: 'vehicle_name',
                Header: t('common:NAME'),
                accessor: (row: AtlasVehicle) => row.name,
            },
        ],
        [t],
    );

    const Body = () => {
        return (
            <>
                <ReactTooltip />
                <div className="group">
                    <div className="title">{t('INFO_ABOUT_EXCHANGE')}</div>
                    <dl>
                        <dt>{t('EXCHANGE_NAME')}</dt>
                        <dd>{t(`common:${currentExchange.exchange}`)}</dd>
                        {typeof currentExchange.last_sync === 'number' && (
                            <>
                                <dt>{t('LAST_SYNC')}</dt>
                                <dd>
                                    {moment
                                        .unix(currentExchange.last_sync)
                                        .format(FULL_DATE_FORMAT)}
                                </dd>
                            </>
                        )}
                        <dt>{t('common:COMMENT')}</dt>
                        <dd>
                            {currentExchange.comment
                                ? currentExchange.comment
                                : '--'}
                        </dd>
                    </dl>
                </div>
                <div className="group">
                    <div className="title">{t('LOGIN_DETAILS')}</div>
                    <dl>
                        <dt>Login</dt>
                        <dd>{currentExchange.login}</dd>
                    </dl>
                    <div className="reset-password-button-container">
                        <button
                            className="button cancel"
                            onClick={() => setResetPasswordMode(true)}
                        >
                            {t('RESET_PASSWORD')}
                        </button>
                    </div>
                </div>
                <div className="group vehicles-container">
                    <div className="title">
                        {t('common:VEHICLES')}
                        {vehicles !== null && (
                            <button
                                className="button basic"
                                onClick={() => setEditVehiclesMode(true)}
                            >
                                <IconPencilEdit />
                                {t('common:EDIT')}
                            </button>
                        )}
                    </div>
                    {vehicles === null && (
                        <div className="loader-container">
                            <InlineLoader />
                        </div>
                    )}
                    {vehicles !== null && (
                        <div className="table-container">
                            {vehicles.length === 0 && (
                                <p className="no-data">{t('NO_VEHICLES')}.</p>
                            )}
                            {vehicles.length > 0 && (
                                <Table
                                    data={vehicles}
                                    columns={columns}
                                    defaultSortBy={defaultSortBy}
                                />
                            )}
                        </div>
                    )}
                </div>
            </>
        );
    };

    const defaultSortBy = useMemo(
        () => [{id: 'vehicle_name', desc: false}],
        [],
    );

    return (
        <>
            {deleteMode && (
                <DeleteTransportExchange
                    exchange={currentExchange}
                    atlasService={atlasService}
                    onClose={() => setDeleteMode(false)}
                    onSuccess={() => {
                        onClose();
                        fetchAtlasUsers();
                        setDeleteMode(false);
                    }}
                />
            )}
            {editMode && (
                <EditTransportExchange
                    exchange={currentExchange}
                    setExchange={setCurrentExchange}
                    atlasService={atlasService}
                    fetchAtlasUsers={fetchAtlasUsers}
                    onClose={() => setEditMode(false)}
                />
            )}
            {resetPasswordMode && (
                <ResetPassword
                    exchange={currentExchange}
                    atlasService={atlasService}
                    onClose={() => setResetPasswordMode(false)}
                />
            )}
            {editVehiclesMode && vehicles !== null && (
                <EditVehicles
                    exchange={currentExchange}
                    currentVehicles={vehicles.map((v) => v.id)}
                    atlasService={atlasService}
                    fetchVehicles={fetchVehicles}
                    onClose={() => setEditVehiclesMode(false)}
                />
            )}
            {!deleteMode &&
                !editMode &&
                !resetPasswordMode &&
                !editVehiclesMode && (
                    <RightPane
                        id="show-transport-exchange"
                        className="panel-right-form panel-right-entity-details"
                        title={t(`common:${currentExchange.exchange}`)}
                        onComponentHidden={onClose}
                        buttons={Buttons}
                        body={Body}
                    />
                )}
        </>
    );
};

export default ShowTransportExchange;
