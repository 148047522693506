import React, {useState} from 'react';
import {useTranslation} from 'react-i18next';
import {toast} from 'react-toastify';

import Modal from '../../../components/Modal/Modal';
import {OverlayLoader} from '../../../components/Loader/Loader';

/**
 *
 * @param exchange {AtlasUser}
 * @param atlasService {AtlasService}
 * @param onClose {Function}
 * @returns {Element}
 * @constructor
 */
const ResetPassword = ({exchange, atlasService, onClose}) => {
    const {t} = useTranslation(['Settings', 'common']);

    const [newPassword: null | string, setNewPassword] = useState(null);
    const [processing: boolean, setProcessing] = useState(false);

    const submitHandler = () => {
        setProcessing(true);
        atlasService.regeneratePassword(
            exchange.id,
            (result: string) => {
                toast.success(t('RESET_PASSWORD_SUCCESS'));
                console.debug('ResetPassword :: submitHandler', result);
                setNewPassword(result);
                setProcessing(false);
            },
            (error) => {
                setProcessing(false);
                console.error('ResetPassword :: submitHandler', error);
                toast.error(t('RESET_PASSWORD_ERROR'));
            },
        );
    };

    return (
        <Modal id="reset-password-transport-exchange-modal">
            <header>{t('RESET_PASSWORD')}</header>
            {newPassword === null ? (
                <>
                    {processing && <OverlayLoader />}
                    <main>
                        <p>
                            {t('RESET_PASSWORD_TRANSPORT_EXCHANGE_CONFIRM', {
                                exchange: t(`common:${exchange.exchange}`),
                            })}
                        </p>
                    </main>
                    <footer>
                        <button
                            className="button cancel"
                            disabled={processing}
                            onClick={onClose}
                        >
                            {t('common:NO')}
                        </button>
                        <button
                            className="button save"
                            disabled={processing}
                            onClick={submitHandler}
                        >
                            {t('common:YES')}
                        </button>
                    </footer>
                </>
            ) : (
                <>
                    <main>
                        <span className="logo-container">
                            <img
                                src={
                                    require(
                                        `../../../graphics/transportExchanges/${exchange.exchange}.png`,
                                    ).default
                                }
                                alt=""
                            />
                            {t(`common:${exchange.exchange}`)}
                        </span>
                        <p>
                            Login: <b>{exchange.login}</b>
                        </p>
                        <p>
                            {t('PASSWORD')}: <b>{newPassword}</b>
                        </p>
                    </main>
                    <footer className="summary">
                        <button className="button cancel" onClick={onClose}>
                            {t('common:CLOSE')}
                        </button>
                    </footer>
                </>
            )}
        </Modal>
    );
};

export default ResetPassword;
