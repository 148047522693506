import type {Counterparty} from '../../utils/interfaces/counterparty';

export const SET_CLIENT_LIST = 'SET_CLIENT_LIST';
export const ADD_CLIENT = 'ADD_CLIENT';
export const UPDATE_CLIENT = 'UPDATE_CLIENT';
export const REMOVE_CLIENT = 'REMOVE_CLIENT';

export function setClientList(clients: Counterparty[]) {
    return {
        type: SET_CLIENT_LIST,
        clients,
    };
}
export function addClient(client) {
    return {
        type: ADD_CLIENT,
        client,
    };
}

export function updateClient(client) {
    return {
        type: UPDATE_CLIENT,
        client,
    };
}

export function removeClient(client) {
    return {
        type: REMOVE_CLIENT,
        client,
    };
}
