export const ADD_NOTIFICATION = 'ADD_NOTIFICATION';
export const ADD_NOTIFICATIONS = 'ADD_NOTIFICATIONS';
export const MARK_NOTIFICATIONS_READ = 'MARK_NOTIFICATION_READ';
export const SET_UNREAD_NOTIFICATION_COUNT = 'SET_UNREAD_NOTIFICATION_COUNT';
export const SET_TOTAL_NOTIFICATION_COUNT = 'SET_TOTAL_NOTIFICATION_COUNT';
export const DECREASE_UNREAD_NOTIFICATION_COUNT =
    'DECREASE_UNREAD_NOTIFICATION_COUNT';
export const INCREASE_UNREAD_NOTIFICATION_COUNT =
    'INCREASE_UNREAD_NOTIFICATION_COUNT';
export const INCREASE_TOTAL_NOTIFICATION_COUNT =
    'INCREASE_TOTAL_NOTIFICATION_COUNT';
export const ADD_UNREAD_NOTIFICATIONS = 'ADD_UNREAD_NOTIFICATIONS';
export const ADD_READ_NOTIFICATIONS = 'ADD_READ_NOTIFICATIONS';

export function addNotifications(notifications) {
    return {
        type: ADD_NOTIFICATIONS,
        notifications,
    };
}

export const addUnreadNotifications = (notifications) => ({
    type: ADD_UNREAD_NOTIFICATIONS,
    payload: notifications,
});

export const addReadNotifications = (notifications) => ({
    type: ADD_READ_NOTIFICATIONS,
    payload: notifications,
});

export function addNotification(notificationData) {
    return {
        type: ADD_NOTIFICATION,
        notificationData,
    };
}

export function markNotificationsRead(notificationIds: Array) {
    return {
        type: MARK_NOTIFICATIONS_READ,
        notificationIds,
    };
}

export function setUnreadNotificationCount(count) {
    return {
        type: SET_UNREAD_NOTIFICATION_COUNT,
        count,
    };
}

export function setTotalNotificationCount(count) {
    return {
        type: SET_TOTAL_NOTIFICATION_COUNT,
        count,
    };
}

export function decreaseUnreadNotificationCount() {
    return {
        type: DECREASE_UNREAD_NOTIFICATION_COUNT,
    };
}

export function increaseUnreadNotificationCount() {
    return {
        type: INCREASE_UNREAD_NOTIFICATION_COUNT,
    };
}

export function increaseTotalNotificationCount() {
    return {
        type: INCREASE_TOTAL_NOTIFICATION_COUNT,
    };
}
