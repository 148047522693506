import moment from 'moment';
import {
    createReportHeader,
    DATE_CELL_WIDTH,
    DURATION_CELL_WIDTH,
    getSheet,
    PLACE_CELL_WIDTH,
} from './reportsXLSXUtils';
import {convertSecondsToDurationString} from '../date';
import {darkRowStyle, fieldStyle, lightRowStyle} from './sheetStyles';
import {FULL_DATE_WITHOUT_SECONDS_FORMAT} from '../constants';
import type {GPSShortReportData} from '../interfaces/report';

export function gpsShortReportSheet(
    workbook,
    t,
    reportData: GPSShortReportData,
) {
    if (!reportData.multiple) {
        reportData.vehicles = [
            {rows: reportData.rows, vehicle_name: reportData.vehicle_name},
        ];
    }
    reportData.vehicles.forEach((vehicle) => {
        let sheet = getSheet(workbook, vehicle.vehicle_name);
        createReportHeader(
            sheet,
            t,
            t('Reports:GPS_SHORT_REPORT_HEADER', {
                vehicleName: vehicle.vehicle_name,
            }),
            reportData.days,
            reportData.date_start,
            reportData.date_end,
            reportData.fields,
        );
        sheet.cell('A2').value(t(reportData.fields[2])).style(fieldStyle);
        sheet.cell('B2').value(t(reportData.fields[3])).style(fieldStyle);
        sheet.cell('C2').value(t(reportData.fields[0])).style(fieldStyle);
        sheet.cell('D2').value(t(reportData.fields[1])).style(fieldStyle);
        sheet.cell('E2').value(t('business_stop_period')).style(fieldStyle);
        sheet.cell('F2').value(t('business_drive_period')).style(fieldStyle);
        sheet.cell('G2').value(t(reportData.fields[6])).style(fieldStyle);
        vehicle.rows.forEach((row, index) => {
            const data = row.slice();
            const rowType = data[0];
            const style =
                rowType !== 'row'
                    ? fieldStyle
                    : index % 2 === 0
                      ? lightRowStyle
                      : darkRowStyle;

            sheet
                .cell('A' + (index + 3))
                .value(
                    moment
                        .unix(data[3])
                        .format(FULL_DATE_WITHOUT_SECONDS_FORMAT),
                )
                .style(style);
            sheet
                .cell('B' + (index + 3))
                .value(
                    moment
                        .unix(data[4])
                        .format(FULL_DATE_WITHOUT_SECONDS_FORMAT),
                )
                .style(style);

            if (rowType !== 'row') {
                sheet
                    .range('C' + (index + 3) + ':D' + (index + 3))
                    .value(t(rowType))
                    .merged(true)
                    .style(style);
            } else {
                sheet
                    .cell('C' + (index + 3))
                    .value(data[1] !== null ? data[1] : '')
                    .style(style);
                sheet
                    .cell('D' + (index + 3))
                    .value(data[2] !== null ? data[2] : '')
                    .style(style);
            }

            data[5] === null
                ? sheet
                      .cell('E' + (index + 3))
                      .value('')
                      .style(style)
                : sheet
                      .cell('E' + (index + 3))
                      .value(convertSecondsToDurationString(data[5], true))
                      .style(style);

            data[6] === null
                ? sheet
                      .cell('F' + (index + 3))
                      .value('')
                      .style(style)
                : sheet
                      .cell('F' + (index + 3))
                      .value(convertSecondsToDurationString(data[6], true))
                      .style(style);

            sheet
                .cell('G' + (index + 3))
                .value(convertSecondsToDurationString(data[7], true))
                .style(style);
        });
        sheet.column('A').width(DATE_CELL_WIDTH);
        sheet.column('B').width(DATE_CELL_WIDTH);
        sheet.column('C').width(PLACE_CELL_WIDTH);
        sheet.column('D').width(PLACE_CELL_WIDTH);
        sheet.column('E').width(DURATION_CELL_WIDTH);
        sheet.column('F').width(DURATION_CELL_WIDTH);
        sheet.column('G').width(DURATION_CELL_WIDTH);
    });
}
