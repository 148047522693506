import React from 'react';

export const IconSpeed = () => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="14"
            height="11.95"
            viewBox="0 0 14 11.95"
        >
            <g transform="translate(678 -1975)" opacity="0.703">
                <circle
                    cx="1.4"
                    cy="1.4"
                    r="1.4"
                    transform="translate(-672.4 1980.6)"
                    fill="#25354d"
                />
                <rect
                    width="1.4"
                    height="4.511"
                    transform="matrix(0.897, -0.441, 0.441, 0.897, -673.619, 1978.262)"
                    fill="#25354d"
                />
                <path
                    d="M-674.96,1978.04a5.607,5.607,0,0,1,7.92,0l.99-.99a7,7,0,0,0-9.9,0,7,7,0,0,0,0,9.9l.99-.99A5.607,5.607,0,0,1-674.96,1978.04Z"
                    fill="#25354d"
                />
                <path
                    d="M-666.161,1978.04a5.607,5.607,0,0,1,0,7.92l.99.99a7,7,0,0,0,0-9.9,7,7,0,0,0-9.9,0l.99.99A5.607,5.607,0,0,1-666.161,1978.04Z"
                    transform="translate(-0.879)"
                    fill="#25354d"
                />
            </g>
        </svg>
    );
};

export const IconSpeedCAN = () => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="15.011"
            height="14.06"
            viewBox="0 0 15.011 14.06"
        >
            <path
                d="M2280.375,3175.957a1.356,1.356,0,0,1-.495-.31,1.47,1.47,0,0,1-.338-.52,1.941,1.941,0,0,1-.125-.727,1.908,1.908,0,0,1,.13-.727,1.6,1.6,0,0,1,.347-.536,1.509,1.509,0,0,1,.5-.333,1.562,1.562,0,0,1,.592-.114,1.286,1.286,0,0,1,.581.127,1.649,1.649,0,0,1,.43.3l-.451.55a1.247,1.247,0,0,0-.248-.173.613.613,0,0,0-.292-.068.59.59,0,0,0-.272.065.69.69,0,0,0-.225.185.912.912,0,0,0-.156.3,1.284,1.284,0,0,0-.057.4,1.125,1.125,0,0,0,.192.7.617.617,0,0,0,.508.245.679.679,0,0,0,.337-.083.994.994,0,0,0,.253-.2l.45.54a1.374,1.374,0,0,1-.482.36,1.442,1.442,0,0,1-.588.12A1.72,1.72,0,0,1,2280.375,3175.957Zm7.192.043-.72-1.455-.31-.725h-.021q.031.281.07.61a5.2,5.2,0,0,1,.041.63V3176h-.82v-3.251h.88l.72,1.45.31.731h.02c-.01-.087-.021-.182-.035-.285s-.024-.209-.035-.318-.019-.217-.027-.325-.013-.212-.013-.312v-.941h.82V3176Zm-3.18,0-.16-.69h-.91l-.16.69h-.88l.99-3.251h1.04l.99,3.251Zm-.742-2.088c-.039.182-.077.351-.118.508l-.055.225h.6l-.055-.225q-.061-.235-.12-.51c-.041-.184-.08-.356-.121-.52h-.02C2283.72,3173.557,2283.683,3173.73,2283.645,3173.912Zm6.132.047-1.208-.716a5.6,5.6,0,0,0-8.007-7.663,5.836,5.836,0,0,0-.519.46,5.585,5.585,0,0,0-.6,7.2l-1.208.715h0a7,7,0,0,1,5.625-10.957l.15,0a7,7,0,0,1,5.775,10.958h0ZM2282.6,3170a1.4,1.4,0,0,1,1.308-1.4l1.455-2.96,1.257.618-1.456,2.96a1.4,1.4,0,1,1-2.563.778Z"
                transform="translate(-2276.497 -3162.5)"
                fill="#25354d"
                stroke="rgba(0,0,0,0)"
                strokeWidth="1"
                opacity="0.7"
            />
        </svg>
    );
};
