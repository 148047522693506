import React, {useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useSelector} from 'react-redux';

import RightPane from '../../../components/RightPane/RightPane';
import {IconDelete, IconEdit} from '../../../graphics/icons';
import type {RootState} from '../../../redux/reducers/rootReducer';

/**
 *
 * @param currentPoi {POI}
 * @param onHide {Function}
 * @param onOpenEditMode {Function}
 * @param removePoiHandler {Function}
 * @returns {JSX.Element}
 * @constructor
 */
const ShowPoi = ({currentPoi, onHide, onOpenEditMode, removePoiHandler}) => {
    const {t} = useTranslation(['Poi', 'common']);

    const {POITypeList} = useSelector((state: RootState) => state);

    const [isParking: boolean, setIsParking: Function<boolean>] =
        useState(false);

    useEffect(() => {
        if (!POITypeList) return;
        const parkingTypeId = POITypeList.filter(
            (type) => type.name === 'parking',
        )[0].id;
        setIsParking(parkingTypeId === currentPoi.type);
    }, [POITypeList, currentPoi]);

    const getTypeCell = () => {
        const type = currentPoi?.poi_type;
        const icon = type?.icon.toLowerCase();
        const isDark = icon && icon.toLowerCase().includes('dark');

        return (
            <div className="cell-content poi-type">
                {icon && isDark ? (
                    <img
                        className="image-cell-content multiple"
                        alt="POI Icon"
                        src={
                            require('../../../graphics/PoiTypeIcons/' + icon)
                                .default
                        }
                    />
                ) : (
                    <img
                        className="image-cell-content multiple"
                        alt="POI Icon"
                        src={
                            require(
                                '../../../graphics/PoiTypeIcons/' +
                                    icon +
                                    '_dark.png',
                            ).default
                        }
                    />
                )}
                <span className="cell-text-with-margin">{t(type.name)}</span>
            </div>
        );
    };

    const getCountryCell = (country: string) => {
        return (
            <div className="cell-content center">
                {country && (
                    <img
                        className="image-cell-content multiple"
                        alt=""
                        src={
                            '../../graphics/countries/' +
                            country.toLowerCase() +
                            '.png'
                        }
                    />
                )}
            </div>
        );
    };

    const getRateCell = (rate: number) => {
        let stars = [];

        for (let i = 5; i > 0; i--) {
            if (rate >= 1) {
                stars.push(
                    <img
                        className="image-cell-content"
                        alt=""
                        key={'star_' + i}
                        src={
                            require('../../../graphics/iko_rank_a.png').default
                        }
                    />,
                );
            } else if (rate >= 0.5) {
                stars.push(
                    <img
                        className="image-cell-content"
                        alt=""
                        key={'star_' + i}
                        src={
                            require('../../../graphics/iko_rank_b.png').default
                        }
                    />,
                );
            } else {
                stars.push(
                    <img
                        className="image-cell-content"
                        alt=""
                        key={'star_' + i}
                        src={
                            require('../../../graphics/iko_rank_c.png').default
                        }
                    />,
                );
            }
            rate--;
        }

        return <div className={'rate-container'}>{stars}</div>;
    };

    const Body = () => (
        <div>
            <div className="group">
                <dl>
                    <dt>{t('common:NAME')}</dt>
                    <dd>{currentPoi.name}</dd>
                    <dt>{t('common:TYPE')}</dt>
                    <dd>{getTypeCell()}</dd>
                    <dt>{t('common:COUNTRY')}</dt>
                    <dd>
                        {currentPoi.extra.country
                            ? getCountryCell(currentPoi.extra.country)
                            : '--'}
                    </dd>
                    <dt>{t('common:ADDRESS')}</dt>
                    <dd>{currentPoi.address || '--'}</dd>
                    <dt>{t('common:COORDINATES')}</dt>
                    <dd>
                        {currentPoi.lat.toFixed(5) +
                            ', ' +
                            currentPoi.lng.toFixed(5)}
                    </dd>
                    <dt>{t('common:RADIUS')}</dt>
                    <dd>
                        {currentPoi.extra?.radius
                            ? currentPoi.extra.radius + 'm'
                            : '--'}
                    </dd>
                    {isParking && (
                        <>
                            <dt>{t('common:DIMENSIONS')}</dt>
                            <dd>
                                {currentPoi.extra.width &&
                                currentPoi.extra.length
                                    ? `${currentPoi.extra.width}m x ${currentPoi.extra.length}m`
                                    : '--'}
                            </dd>
                            <dt>{t('common:COST_WITH_CURRENCY')}</dt>
                            <dd>
                                {currentPoi.extra?.cost
                                    ? currentPoi.extra.cost
                                    : '--'}
                            </dd>
                            <dt>{t('common:protected')}</dt>
                            <dd>
                                {currentPoi.extra?.protected
                                    ? t('YES')
                                    : t('NO')}
                            </dd>
                            <dt>{t('common:fenced')}</dt>
                            <dd>
                                {currentPoi.extra?.fenced ? t('YES') : t('NO')}
                            </dd>
                            <dt>{t('common:illuminated')}</dt>
                            <dd>
                                {currentPoi.extra?.illuminated
                                    ? t('YES')
                                    : t('NO')}
                            </dd>
                            <dt>{t('common:monitored')}</dt>
                            <dd>
                                {currentPoi.extra?.monitored
                                    ? t('YES')
                                    : t('NO')}
                            </dd>
                        </>
                    )}
                    <dt>{t('common:COMMENTS')}</dt>
                    <dd>
                        {currentPoi.extra?.description
                            ? currentPoi.extra.description
                            : '--'}
                    </dd>
                    <dt>{t('common:RATE')}</dt>
                    <dd>
                        {currentPoi.extra?.rate
                            ? getRateCell(currentPoi.extra.rate)
                            : '--'}
                    </dd>
                </dl>
            </div>
        </div>
    );

    const Buttons = () => (
        <>
            <button
                className="button cancel icon"
                data-tip={t('DELETE_POI')}
                onClick={removePoiHandler}
            >
                <IconDelete />
            </button>
            <button
                className="button action icon"
                data-tip={t('EDIT_POI')}
                onClick={onOpenEditMode}
            >
                <IconEdit />
            </button>
        </>
    );

    return (
        <RightPane
            id="poi-details"
            className="poi-details panel-right-entity-details"
            title={currentPoi.name}
            onComponentHidden={onHide}
            buttons={Buttons}
            body={Body}
        />
    );
};

export default ShowPoi;
