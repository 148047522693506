import moment from 'moment';
import {getSheet} from './reportsXLSXUtils';
import {FULL_DATE_WITHOUT_SECONDS_FORMAT} from '../constants';

function createDriverActivitiesReportHeader(sheet, driverName, t) {
    sheet.cell('B1').value(t('common:LAST_NAME')).style({});
    sheet.cell('C1').value(t('common:FIRST_NAME'));
    sheet.cell('D1').value('ID / PESEL');
    sheet.cell('A2').value(t('common:DRIVER'));
    try {
        sheet
            .cell('B2')
            .value(driverName.split(' ')[0])
            .style({fontColor: 'FF0000'});
    } catch (e) {
        console.error('INVALID_DRIVER_NAME');
    }
    try {
        sheet
            .cell('C2')
            .value(driverName.split(' ')[1])
            .style({fontColor: 'FF0000'});
    } catch (e) {
        console.error('INVALID_DRIVER_NAME');
    }
    sheet.cell('A4').value('Lp');
    sheet.cell('B4').value('START');
    sheet.cell('C4').value(t('common:END'));
    sheet.cell('D4').value(t('common:MODE'));
    sheet.column('A').style({horizontalAlignment: 'center'});
    sheet.column('B').style({horizontalAlignment: 'center'});
    sheet.column('C').style({horizontalAlignment: 'center'});
    sheet.column('D').style({horizontalAlignment: 'center'});
}

export function driverActivitiesReportSheet(
    workbook,
    t,
    fields,
    rows,
    date_start,
    date_end,
    days,
    driver_name,
) {
    let sheet = getSheet(workbook, driver_name);
    createDriverActivitiesReportHeader(sheet, driver_name, t);
    rows.forEach((row, index) => {
        let rowRelativeCell = sheet.cell('A' + (index + 5));
        rowRelativeCell.value(index + 1);
        rowRelativeCell = rowRelativeCell.relativeCell(0, 1);
        row.forEach((value, index) => {
            if (index === 0 || index === 1) {
                rowRelativeCell.value(
                    moment.unix(value).format(FULL_DATE_WITHOUT_SECONDS_FORMAT),
                );
            } else {
                rowRelativeCell.value(value ? t('JA') : t('OD'));
            }
            rowRelativeCell = rowRelativeCell.relativeCell(0, 1);
        });
    });
    sheet.column('A').width(20);
    sheet.column('B').width(20);
    sheet.column('C').width(20);
    sheet.column('D').width(20);
    sheet.column('E').width(20);
    sheet.column('F').width(20);
    sheet.column('G').width(20);
}
