import {store} from '../redux/store/store';
import {setOffersServiceInitialized} from '../redux/actions/appActions';
import {
    addOffer,
    removeOffer,
    setOfferList,
    updateOffer,
} from '../redux/actions/offerActions';
import {updateLicenseList} from '../redux/actions/licenseActions';
import type {Contract, Offer, OfferStatus} from '../utils/interfaces/offer';
import type {License} from '../utils/interfaces/license';

export default class OffersService {
    constructor(connection) {
        this.connection = connection;

        this.connection.addHandler('offer_created', (notification) => {
            let data = notification.data;
            console.debug('OffersService::EVENT(offer_created) => %O', data);
        });
        this.connection.addHandler('offer_updated', (notification) => {
            let data = notification.data;
            console.debug('OffersService::EVENT(offer_updated) => %O', data);
        });
        this.connection.addHandler('offer_removed', (notification) => {
            let data = notification.data;
            console.debug('OffersService::EVENT(offer_removed) => %O', data);
        });
    }

    init() {
        const {app} = store.getState();
        if (app.offersServiceInitialized === true) {
            console.debug(
                'OffersService::initStore() => store already initialized',
            );
            return;
        }
        console.debug('OffersService::initStore() => initializing store...');
        store.dispatch(setOffersServiceInitialized(true));
        this.getOffers(null, (offers: Offer[]) => {
            store.dispatch(setOfferList(offers));
            console.debug('OffersService::init() => store initialized', offers);
        });
    }

    getOffers(
        status: OfferStatus,
        callbackSuccess: Function,
        callbackError: Function,
    ) {
        const args = {};
        if (status) {
            args.status = status;
        }
        const onSuccess = (offers: Offer[]) => {
            const sortedOffers = offers.sort(
                (a, b) => a.created_at - b.created_at,
            );
            callbackSuccess && callbackSuccess(sortedOffers);
        };
        this.connection.query('offers.getAll', args, onSuccess, callbackError);
    }

    getOffer(
        offerId: string,
        callbackSuccess: Function,
        callbackError: Function,
    ) {
        this.connection.query(
            'offers.getById',
            {id: offerId},
            callbackSuccess,
            callbackError,
        );
    }

    createOffer(
        fmb_920_count: number,
        obd_010_count: number,
        fmb_125_can_count: number,
        contracts: Contract[],
        callbackSuccess: Function,
        callbackError: Function,
    ) {
        const args = {
            fmb_920_count:
                typeof fmb_920_count === 'number' ? parseInt(fmb_920_count) : 0,
            obd_010_count:
                typeof obd_010_count === 'number' ? parseInt(obd_010_count) : 0,
            fmb_125_can_count:
                typeof fmb_125_can_count === 'number'
                    ? parseInt(fmb_125_can_count)
                    : 0,
        };

        if (contracts && contracts instanceof Array) {
            args.contracts = contracts;
        }

        const onSuccess = (offer: Offer) => {
            store.dispatch(addOffer(offer));
            callbackSuccess && callbackSuccess(offer);
        };
        this.connection.query('offers.create', args, onSuccess, callbackError);
    }

    updateOffer(
        offer: Offer,
        callbackSuccess: Function,
        callbackError: Function,
    ) {
        const args = {
            id: offer.id,
            fmb_920_count: offer?.fmb_920_count >= 0 ? offer.fmb_920_count : 0,
            obd_010_count: offer?.obd_010_count >= 0 ? offer.obd_010_count : 0,
            fmb_125_can_count:
                offer?.fmb_125_can_count >= 0 ? offer.fmb_125_can_count : 0,
            contracts:
                offer.contracts && offer.contracts.length > 0
                    ? offer.contracts
                    : [],
        };

        const onSuccess = (updatedOffer: Offer) => {
            store.dispatch(updateOffer(updatedOffer));
            callbackSuccess && callbackSuccess(updatedOffer);
        };
        this.connection.query('offers.update', args, onSuccess, callbackError);
    }

    deleteOffer(
        offer: Offer,
        callbackSuccess: Function,
        callbackError: Function,
    ) {
        const onSuccess = (resolve: boolean) => {
            store.dispatch(removeOffer(offer));
            callbackSuccess && callbackSuccess(resolve);
        };
        this.connection.query(
            'offers.delete',
            {id: offer.id},
            onSuccess,
            callbackError,
        );
    }

    sendOffer(
        offer: Offer,
        email: string,
        callbackSuccess: Function,
        callbackError: Function,
    ) {
        const onSuccess = (_response: boolean) => {
            const updatedOffer: Offer = {...offer, status: 'sent'};
            store.dispatch(updateOffer(updatedOffer));

            if (offer.contracts && offer.contracts.length > 0) {
                const {licenseList} = store.getState();

                offer.contracts.forEach((contract: Contract) => {
                    const license = licenseList.find(
                        (license: License) =>
                            contract.id === license.contract_id,
                    );
                    if (license) {
                        store.dispatch(
                            updateLicenseList({...license, in_offer: true}),
                        );
                    }
                });
            }
            callbackSuccess && callbackSuccess(updatedOffer);
        };

        this.connection.query(
            'offers.send',
            {id: offer.id, send_copy_to: email},
            onSuccess,
            callbackError,
        );
    }
}
