import React, {useState} from 'react';
import {useSelector} from 'react-redux';
import {useTranslation} from 'react-i18next';

import RightPane from '../../../components/RightPane/RightPane';
import {CheckboxField} from '../../../components/Input/Input';
import {InlineLoader, OverlayLoader} from '../../../components/Loader/Loader';
import type {RootState} from '../../../redux/reducers/rootReducer';

/**
 *
 * @param exchange {AtlasUser}
 * @param currentVehicles {number[]}
 * @param atlasService {AtlasService}
 * @param fetchVehicles {Function}
 * @param onClose {Function}
 * @returns {JSX.Element}
 * @constructor
 */
const EditVehicles = ({
    exchange,
    currentVehicles,
    atlasService,
    fetchVehicles,
    onClose,
}) => {
    const {t} = useTranslation(['Settings', 'common']);

    const vehicleList = useSelector((state: RootState) => state.vehicleList);

    const [selectedVehicleIds: number[], setSelectedVehicleIds] =
        useState(currentVehicles);
    const [processing, setProcessing] = useState(false);

    const submitHandler = () => {
        setProcessing(true);

        console.debug('EditVehicles :: submitHandler', selectedVehicleIds);
        const promises: Promise[] = [];

        selectedVehicleIds.forEach((vehicleId) => {
            if (!currentVehicles.includes(vehicleId)) {
                promises.push(
                    atlasService.addVehicle({
                        vehicle_id: vehicleId,
                        atlas_user_id: exchange.id,
                    }),
                );
            }
        });

        currentVehicles.forEach((vehicleId) => {
            if (!selectedVehicleIds.includes(vehicleId)) {
                promises.push(
                    atlasService.removeVehicle({
                        vehicle_id: vehicleId,
                        atlas_user_id: exchange.id,
                    }),
                );
            }
        });

        const _results: number[] = [];
        const _errors: {id: number, reason: string}[] = [];

        Promise.allSettled(promises).then((results) => {
            setProcessing(false);
            results.forEach((result, index) => {
                if (result.status === 'fulfilled') {
                    _results.push(selectedVehicleIds[index]);
                } else if (result.status === 'rejected') {
                    const reason =
                        result.reason && typeof result.reason === 'string'
                            ? result.reason
                            : '';
                    _errors.push({
                        id: selectedVehicleIds[index],
                        reason: reason,
                    });
                }
            });
            console.debug(
                'EditVehicles :: submitHandler, success: ',
                _results,
                ', errors: ',
                _errors,
            );
            fetchVehicles();
            onClose();
        });
    };

    const Body = () => (
        <>
            {!vehicleList && (
                <div className="loader-container">
                    <InlineLoader />
                </div>
            )}
            {vehicleList && vehicleList.length === 0 && (
                <p>{t('NO_VEHICLES')}</p>
            )}
            {vehicleList && vehicleList.length > 0 && (
                <div className="vehicles-row">
                    {vehicleList.map((vehicle) => (
                        <CheckboxField
                            key={vehicle.vehicle_id}
                            id={`vehicle_${vehicle.vehicle_id}`}
                            name={`vehicle_${vehicle.vehicle_id}`}
                            label={vehicle.name}
                            value={selectedVehicleIds.includes(
                                vehicle.vehicle_id,
                            )}
                            onChange={(value) =>
                                setSelectedVehicleIds(
                                    !value
                                        ? [
                                              ...selectedVehicleIds,
                                              vehicle.vehicle_id,
                                          ]
                                        : selectedVehicleIds.filter(
                                              (id) => id !== vehicle.vehicle_id,
                                          ),
                                )
                            }
                        />
                    ))}
                </div>
            )}
        </>
    );

    const Footer = () => (
        <>
            <button type="reset" className="button edit" onClick={onClose}>
                {t('common:CANCEL')}
            </button>
            <button className="button save" onClick={submitHandler}>
                {t('common:SAVE')}
            </button>
        </>
    );

    return (
        <RightPane
            id="edit-vehicles-transport-exchange"
            className="panel-right-form panel-right-entity-details"
            title={t('EDIT_VEHICLES')}
            onComponentHidden={onClose}
            body={processing ? () => <OverlayLoader /> : Body}
            footer={Footer}
        />
    );
};

export default EditVehicles;
