import L from 'leaflet';

export const mapIcons = {
    // iconRide : L.icon({
    //     iconUrl: require("../graphics/pin_drive_blue.png").default,
    //     // iconRetinaUrl: 'marker-icon-2x.png',
    //     // shadowUrl:     'marker-shadow.png',
    //     iconSize: [82, 50],
    //     iconAnchor: [41, 50],
    //     popupAnchor: [0, -50],
    //     // tooltipAnchor: [16, -28],
    //     // shadowSize: [41, 41]
    // }),
    iconPark: L.icon({
        iconUrl: require('../graphics/pin_park_blue.png').default,
        // iconRetinaUrl: 'marker-icon-2x.png',
        // shadowUrl:     'marker-shadow.png',
        iconSize: [82, 50],
        iconAnchor: [41, 50],
        popupAnchor: [0, -60],
        tooltipAnchor: [16, -28],
        // shadowSize: [41, 41]
    }),
    iconRide: L.icon({
        iconUrl: require('../graphics/pins/pin_blue.png').default,
        iconSize: [48, 48],
        iconAnchor: [24, 24],
        popupAnchor: [0, -25],
    }),
    iconNewParking: L.icon({
        iconUrl: require('../graphics/pin_park_dark.png').default,
        // iconRetinaUrl: 'marker-icon-2x.png',
        // shadowUrl:     'marker-shadow.png',
        iconSize: [82, 50],
        iconAnchor: [41, 50],
        popupAnchor: [0, -50],
        // tooltipAnchor: [16, -28],
        // shadowSize: [41, 41]
    }),
    iconRouteEnd: L.icon({
        iconUrl: require('../graphics/pins/pin_routehist_end_light.png')
            .default,
        iconSize: [82, 50],
        iconAnchor: [41, 50],
        popupAnchor: [0, -50],
    }),
    iconRouteStart: L.icon({
        iconUrl: require('../graphics/pins/pin_routehist_start_light.png')
            .default,
        iconSize: [82, 50],
        iconAnchor: [41, 50],
        popupAnchor: [0, -50],
    }),
    iconRouteCurrent: L.icon({
        iconUrl: require('../graphics/pins/pin_red.png').default,
        iconSize: [48, 48],
        iconAnchor: [24, 24],
        popupAnchor: [0, -25],
        className: 'kitt',
    }),
    iconDefault: L.icon({
        iconUrl: require('../graphics/pin_def1_dark.png').default,
        iconSize: [82, 50],
        iconAnchor: [41, 50],
        popupAnchor: [0, -50],
    }),
};
