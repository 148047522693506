import {
    SET_DEVICE_TO_VEHICLE_RELATION_LIST,
    ADD_DEVICE_TO_VEHICLE_RELATION,
    REMOVE_DEVICE_TO_VEHICLE_RELATION,
    UPDATE_DEVICE_TO_VEHICLE_RELATION,
} from '../actions/deviceToVehicleRelationActions';
import type {DeviceToVehicleRelation} from '../../utils/interfaces/vehicle';

/*
 * state === null means that store is not initialized
 * no action can be made unless store is initialized...
 */

const initialState: ?(DeviceToVehicleRelation[]) = null;
export default function deviceToVehicleRelationsList(
    state = initialState,
    action,
) {
    switch (action.type) {
        case SET_DEVICE_TO_VEHICLE_RELATION_LIST:
            return action.relations;

        case ADD_DEVICE_TO_VEHICLE_RELATION: {
            if (state !== null) {
                let index = state.findIndex(
                    (relation) =>
                        relation.device_to_vehicle_id ===
                        action.relationData.device_to_vehicle_id,
                );
                if (index === -1) {
                    return [...state, action.relationData];
                }
            }
            return state;
        }
        case UPDATE_DEVICE_TO_VEHICLE_RELATION: {
            if (state !== null) {
                return state.map((relation) => {
                    if (
                        relation.device_to_vehicle_id !==
                        action.updatedRelationData.device_to_vehicle_id
                    ) {
                        return relation;
                    } else {
                        return Object.assign(
                            {},
                            relation,
                            action.updatedRelationData,
                        );
                    }
                });
            }
            return state;
        }
        case REMOVE_DEVICE_TO_VEHICLE_RELATION: {
            if (state !== null) {
                return state.filter(
                    (relation) =>
                        relation.device_to_vehicle_id !== action.relationId,
                );
            }
            return state;
        }
        default: {
            return state;
        }
    }
}
