import React, {useEffect, useState} from 'react';
import {connect} from 'react-redux';
import {NavLink, useLocation} from 'react-router-dom';
import {useTranslation} from 'react-i18next';
import ReactTooltip from 'react-tooltip';
import {toast} from 'react-toastify';
import {store} from '../../redux/store/store';
import {setAccountPaneVisible} from '../../redux/actions/appActions';
import {
    IconCart,
    IconNotification,
    IconSettings,
    IconUser,
} from '../../graphics/icons';
import useEventListener from '../../hooks/useEventListener';
import type {Offer} from '../../utils/interfaces/offer';

import './TopMenuStyles.scss';

interface GeneratorState {
    completed: number;
    total: number;
}

interface ItemProps {
    label: string;
    to: string;
    visible?: boolean;
    labelOnly?: boolean;
    badge?: JSX.Element;
    children?: JSX.Element;
}

const Item = (props: ItemProps) => {
    const {pathname} = useLocation();
    if (props.visible !== undefined && props.visible === false) {
        return null;
    }
    return (
        <div className="item">
            <NavLink
                to={props.to}
                className={({isActive}) =>
                    `${isActive || pathname.split('/')[1] === props.to.split('/')[1] ? 'active' : ''} label`
                }
                onClick={(e) => (props.labelOnly ? e.preventDefault() : null)}
            >
                {props.label}
                {props.badge ? (
                    <span className="badge">{props.badge}</span>
                ) : null}
            </NavLink>
            {props.children && <div className="dropdown">{props.children}</div>}
        </div>
    );
};

/**
 *
 * @param notificationCount {number}
 * @param userData {User}
 * @param app {App}
 * @param offerList {Offer[]}
 * @returns {Element}
 * @constructor
 */
function TopMenu({notificationCount, userData, app, offerList}) {
    const {t} = useTranslation(['common', 'Reports']);

    const location = useLocation();

    const [generatorState: GeneratorState, setGeneratorState] = useState({
        total: 0,
        completed: 0,
    });

    const showTopMenu = (pathname: string) => {
        return pathname !== '/login';
    };
    const roleDispositor = ['dispositor', 'manager'].includes(userData.type);
    const roleManager = userData.type === 'manager';
    const appVariantBusiness = app.variant === 'fm';
    const appVariantIndividual = app.variant === 'fm_lite';

    useEventListener('generator-progress', (e: {detail: GeneratorState}) => {
        setGeneratorState(e.detail);
    });

    useEventListener('generator-progress-increment', () => {
        setGeneratorState((prev) => ({
            completed: prev.completed + 1,
            total: prev.total,
        }));
    });

    useEffect(() => {
        ReactTooltip.rebuild();
        if (
            generatorState.total > 0 &&
            generatorState.completed === generatorState.total
        ) {
            setGeneratorState((prev) => ({
                total: 0,
                completed: prev.completed,
            }));
        }
    }, [generatorState]);

    const generatorClick = (e: Event) => {
        e.preventDefault();
        toast.info(t('Reports:GENERATING', generatorState), {
            toastId: 'generator-progress',
            pauseOnHover: false,
            autoClose: 1000,
        });
    };

    return (
        <>
            {showTopMenu(location.pathname) && (
                <div
                    className={'top-menu-container'}
                    data-user-type={userData.type}
                    data-app={app.variant}
                >
                    <div className="menu">
                        <Item to="/map" label={t('MAP')} />
                        <Item
                            to="/dispatchers"
                            label={t('DISPOSITORS')}
                            visible={appVariantBusiness && roleManager}
                        />
                        <Item
                            to="/drivers/list"
                            label={t('DRIVERS')}
                            visible={appVariantBusiness && roleDispositor}
                        />
                        <Item
                            to="/clients"
                            label={t('CLIENTS')}
                            visible={appVariantBusiness && roleDispositor}
                        />
                        <Item
                            to="/vehicles/list"
                            label={t('VEHICLES')}
                            visible={
                                (appVariantIndividual && roleDispositor) ||
                                roleDispositor
                            }
                        />
                        <Item
                            to="/devices"
                            label={t('DEVICES')}
                            visible={
                                (appVariantIndividual && roleDispositor) ||
                                roleDispositor
                            }
                        />
                        <Item
                            to="/orders/list"
                            label={t('ORDERS')}
                            visible={appVariantBusiness}
                        />
                        <Item
                            to="/poi"
                            label={t('POI')}
                            visible={appVariantBusiness && roleDispositor}
                        />
                        {/*<Item to="/parkings" label={t('PARKINGS')} visible={appVariantBusiness && roleDispositor}/>*/}
                        <Item
                            to="/reports/vehicle"
                            label={t('REPORTS')}
                            visible={
                                (appVariantIndividual && roleDispositor) ||
                                roleDispositor
                            }
                        />
                        <Item
                            to="/forms/vehicle_pickup/active"
                            label={t('FORMS')}
                            visible={appVariantBusiness && roleDispositor}
                        />
                        {/*<Item to="/cameras" label={t('CAMERAS')} visible={appVariantBusiness && roleDispositor}/>*/}
                        {/*<div className="divider"/>*/}
                        {/*<Item to="/notifications" label={t('NOTIFICATIONS')} badge={notificationCount.unread}/>*/}
                    </div>
                    <div
                        className={
                            'report-generator-state' +
                            (generatorState.total > 0 ? ' visible' : '')
                        }
                        onClick={generatorClick}
                        data-tip={t('Reports:GENERATING', generatorState)}
                        data-place="left"
                    >
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke="currentColor"
                            className="cog1"
                        >
                            <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth={1}
                                d="M10.325 4.317c.426-1.756 2.924-1.756 3.35 0a1.724 1.724 0 002.573 1.066c1.543-.94 3.31.826 2.37 2.37a1.724 1.724 0 001.065 2.572c1.756.426 1.756 2.924 0 3.35a1.724 1.724 0 00-1.066 2.573c.94 1.543-.826 3.31-2.37 2.37a1.724 1.724 0 00-2.572 1.065c-.426 1.756-2.924 1.756-3.35 0a1.724 1.724 0 00-2.573-1.066c-1.543.94-3.31-.826-2.37-2.37a1.724 1.724 0 00-1.065-2.572c-1.756-.426-1.756-2.924 0-3.35a1.724 1.724 0 001.066-2.573c-.94-1.543.826-3.31 2.37-2.37.996.608 2.296.07 2.572-1.065z"
                            />
                            <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth={1}
                                d="M15 12a3 3 0 11-6 0 3 3 0 016 0z"
                            />
                        </svg>
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke="currentColor"
                            className="cog2"
                        >
                            <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth={1}
                                d="M10.325 4.317c.426-1.756 2.924-1.756 3.35 0a1.724 1.724 0 002.573 1.066c1.543-.94 3.31.826 2.37 2.37a1.724 1.724 0 001.065 2.572c1.756.426 1.756 2.924 0 3.35a1.724 1.724 0 00-1.066 2.573c.94 1.543-.826 3.31-2.37 2.37a1.724 1.724 0 00-2.572 1.065c-.426 1.756-2.924 1.756-3.35 0a1.724 1.724 0 00-2.573-1.066c-1.543.94-3.31-.826-2.37-2.37a1.724 1.724 0 00-1.065-2.572c-1.756-.426-1.756-2.924 0-3.35a1.724 1.724 0 001.066-2.573c-.94-1.543.826-3.31 2.37-2.37.996.608 2.296.07 2.572-1.065z"
                            />
                            <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth={1}
                                d="M15 12a3 3 0 11-6 0 3 3 0 016 0z"
                            />
                        </svg>
                    </div>
                    {userData && userData.type === 'manager' && (
                        <NavLink to="/cart" className="notifications">
                            {offerList && (
                                <span
                                    className="badge green"
                                    data-count={
                                        offerList.filter(
                                            (offer: Offer) =>
                                                offer.status === 'new',
                                        ).length
                                    }
                                />
                            )}
                            <IconCart />
                        </NavLink>
                    )}
                    <NavLink
                        to="/notifications"
                        className={({isActive}) =>
                            `${isActive ? 'active' : ''} notifications`
                        }
                    >
                        <span
                            className="badge"
                            data-count={notificationCount.unread}
                        />
                        <IconNotification />
                    </NavLink>
                    <div
                        className={
                            'account' +
                            (app.accountPaneVisible ? ' active' : '')
                        }
                        onClick={(e) => {
                            e.preventDefault();
                            store.dispatch(setAccountPaneVisible(true));
                        }}
                    >
                        <div className="variant">
                            {t('APP_VARIANT_' + app.variant)}
                        </div>
                        <div className="user">
                            <span className="name">{userData.login}</span>
                        </div>
                        <IconUser />
                    </div>
                    <NavLink
                        to="/settings/account"
                        className={({isActive}) =>
                            `settings ${isActive || location.pathname.split('/')[1] === 'settings' ? 'active' : ''}`
                        }
                    >
                        <IconSettings />
                    </NavLink>
                </div>
            )}
        </>
    );
}

const mapStateToProps = (state) => {
    const {userData, notificationCount, app, offerList} = state;
    return {
        userData,
        notificationCount,
        app,
        offerList,
    };
};
export default connect(mapStateToProps)(TopMenu);
