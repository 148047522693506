export const SET_NEW_CLIENT_INITIAL_STATE = 'SET_NEW_CLIENT_INITIAL_STATE';
export const SET_NEW_CLIENT_DATA = 'SET_NEW_CLIENT_DATA';
export const SET_NEW_CLIENT_CORRESPONDENCE_DATA =
    'SET_NEW_CLIENT_CORRESPONDENCE_DATA';
export const SET_NEW_CLIENT_DATA_FROM_EXISTING =
    'SET_NEW_CLIENT_DATA_FROM_EXISTING';

export function setNewClientData(value: any, field: string) {
    return {
        type: SET_NEW_CLIENT_DATA,
        value,
        field,
    };
}

export function setNewClientDataFromExisting(data) {
    return {
        type: SET_NEW_CLIENT_DATA_FROM_EXISTING,
        data,
    };
}

export function setNewClientDataInitialState() {
    return {
        type: SET_NEW_CLIENT_INITIAL_STATE,
    };
}

export function setNewClientCorrespondenceData(value: any, field: string) {
    return {
        type: SET_NEW_CLIENT_CORRESPONDENCE_DATA,
        value,
        field,
    };
}
