import type {Order} from '../../utils/interfaces/order';

export const SET_ORDER_LIST = 'SET_ORDER_LIST';
export const ADD_ORDER = 'ADD_ORDER';
export const UPDATE_ORDER = 'UPDATE_ORDER';

export function setOrderList(orders: Order[]) {
    return {
        type: SET_ORDER_LIST,
        orders,
    };
}
export function addOrder(orderData) {
    return {
        type: ADD_ORDER,
        orderData,
    };
}

export function updateOrder(orderData) {
    return {
        type: UPDATE_ORDER,
        orderData,
    };
}
