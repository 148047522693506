import React, {useState} from 'react';
import {toast} from 'react-toastify';
import {useTranslation} from 'react-i18next';
import Select from 'react-select';
import DatePicker from 'react-datepicker';

import Modal from '../../Modal/Modal';
import {selectStyles} from '../../../utils/selectStyles';
import {DATEPICKER_FULL_DATE_WITHOUT_SECONDS_FORMAT} from '../../../utils/constants';

/**
 *
 * @param currentAccess {UpdatedAccess}
 * @param onClose {Function}
 * @param contactList {Person[]}
 * @param vehicleList {Vehicle[]}
 * @param accessSchemaList {AccessSchema[]}
 * @param vehicleService {VehicleService}
 * @param reloadAccesses {Function}
 * @returns {Element}
 * @constructor
 */
const EditVehicleSharing = ({
    currentAccess,
    onClose,
    contactList,
    vehicleList,
    accessSchemaList,
    vehicleService,
    reloadAccesses,
}) => {
    const {t} = useTranslation(['VehicleSharing', 'common']);

    const person = contactList.find(
        (p) => p.person_id === currentAccess.access.person_id,
    );
    const vehicle = vehicleList.find(
        (v) => v.device_id === currentAccess.access.device_id,
    );

    const [data, setData] = useState(currentAccess);

    const submitHandler = async () => {
        console.debug('EditVehicleSharing :: submitHandler() => ', data);

        const _data = {...data.access, vehicle_id: vehicle?.vehicle_id};

        try {
            await vehicleService.modifyAccessParameters(_data);
            await vehicleService.updateAccess(_data, data.start, data.end);
            toast.success(
                t('GRANT_UPDATED', {person: person, vehicle: vehicle}),
            );
            reloadAccesses();
            onClose();
        } catch (reason) {
            toast.error(
                t('GRANT_UPDATE_ERROR', {
                    person: person,
                    vehicle: vehicle,
                    error: t(reason),
                }),
            );
        }
    };

    return (
        <Modal
            id="react-confirm-alert"
            className="edit-vehicle-sharing-modal"
            onHide={onClose}
        >
            <div className="react-confirm-alert-body">
                <h1>{t('UPDATE_ACCESS_HEADER')}</h1>
                <p>
                    {t('UPDATE_ACCESS_BODY', {
                        person: person,
                        vehicle: vehicle,
                    })}
                </p>
                <Select
                    value={(function () {
                        const selectedSchema = accessSchemaList?.find(
                            (item) => item.id === data.access?.parameters_id,
                        );
                        return selectedSchema
                            ? {
                                  label: selectedSchema.name,
                                  value: selectedSchema.id,
                              }
                            : null;
                    })()}
                    onChange={(selectedSchema) => {
                        setData((prev) => ({
                            ...prev,
                            access: {
                                ...prev.access,
                                parameters_id: selectedSchema.value,
                            },
                        }));
                    }}
                    options={[
                        {
                            value: null,
                            label: t('common:BASIC_PARAMETERS'),
                        },
                    ].concat(
                        accessSchemaList.map((schema) => ({
                            value: schema.id,
                            label: schema.name,
                        })),
                    )}
                    placeholder={t('common:BASIC_PARAMETERS')}
                    menuPortalTarget={document.body}
                    styles={{
                        ...selectStyles,
                        menuPortal: (styles) => ({...styles, zIndex: 9999}),
                        menuList: (styles) => ({
                            ...styles,
                            zIndex: 9999,
                            maxHeight: 200,
                        }),
                    }}
                />
                <div className="dates">
                    <DatePicker
                        selected={data.start}
                        onChange={(date) => {
                            setData((prev) => ({...prev, start: date}));
                        }}
                        dateFormat={DATEPICKER_FULL_DATE_WITHOUT_SECONDS_FORMAT}
                        showTimeSelect
                        timeFormat="HH:mm"
                        selectsStart
                        startDate={data.start}
                        endDate={data.end}
                        timeIntervals={5}
                        calendarStartDay={1}
                    />
                    &nbsp;&mdash;&nbsp;
                    <DatePicker
                        selected={data.end}
                        onChange={(date) => {
                            setData((prev) => ({...prev, end: date}));
                        }}
                        dateFormat={DATEPICKER_FULL_DATE_WITHOUT_SECONDS_FORMAT}
                        showTimeSelect
                        timeFormat="HH:mm"
                        selectsEnd
                        startDate={data.start}
                        endDate={data.end}
                        minDate={data.start}
                        minTime={
                            data.start.toDateString() ===
                            data.end.toDateString()
                                ? data.start
                                : new Date().setHours(0, 0, 0, 0)
                        }
                        maxTime={new Date().setHours(23, 55, 0, 0)}
                        timeIntervals={5}
                        calendarStartDay={1}
                    />
                </div>
                <div className="react-confirm-alert-button-group">
                    <button onClick={onClose} className="button cancel">
                        {t('common:CANCEL')}
                    </button>
                    <button
                        onClick={submitHandler}
                        className="button save confirm"
                    >
                        {t('common:CONFIRM')}
                    </button>
                </div>
            </div>
        </Modal>
    );
};

export default EditVehicleSharing;
