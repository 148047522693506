import React, {useRef, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {toast} from 'react-toastify';
import Select from 'react-select';

import RightPane from '../../../components/RightPane/RightPane';
import type {NewIdentifier} from '../../../utils/interfaces/driver';

const IdentifierTypes = ['beacon', 'ibutton', 'rfid'];

/**
 *
 * @param onHide {Function}
 * @param driversService
 * @returns {JSX.Element}
 * @constructor
 */
const AddIdentifier = ({onHide, driversService}) => {
    const {t} = useTranslation('Identifiers');

    const paneRef = useRef(null);

    const [
        identifierData: NewIdentifier,
        setIdentifierData: Function<NewIdentifier>,
    ] = useState({identifier_ident: ''});

    const createIdentifier = (e: Event) => {
        e.preventDefault();
        driversService.createIdentifier(
            identifierData,
            () => {
                toast.success(t('IDENTIFIER_CREATED'));
                paneRef && paneRef.current && paneRef.current.hideComponent();
            },
            (reason) => {
                console.warn(
                    'Identifiers:createIdentifier() => reason: %s',
                    reason,
                );
                toast.error(t('IDENTIFIER_CREATE_ERROR', {error: t(reason)}));
            },
        );
    };

    const getIdentifierDataForm = () => {
        return (
            <div className="group">
                <div className="field">
                    <label htmlFor="identifier_ident">{t('IDENT')}</label>
                    <input
                        type="text"
                        id="identifier_ident"
                        name="identifier_ident"
                        value={identifierData.identifier_ident}
                        onChange={(e) => {
                            setIdentifierData({
                                ...identifierData,
                                identifier_ident: e.target.value,
                            });
                        }}
                        placeholder={t('IDENT_HINT')}
                    />
                </div>
                <div className="field">
                    <label htmlFor="type">{t('TYPE')}</label>
                    <Select
                        options={IdentifierTypes.map((option) => ({
                            label: t(option),
                            value: option,
                        }))}
                        value={
                            identifierData.type
                                ? {
                                      label: t(identifierData.type),
                                      value: identifierData.type,
                                  }
                                : null
                        }
                        onChange={(o) => {
                            setIdentifierData({
                                ...identifierData,
                                type: o.value,
                            });
                        }}
                        placeholder={t('TYPE_HINT')}
                        className={'select'}
                        styles={{
                            control: (styles) => ({
                                ...styles,
                                backgroundColor: '#EAECEF',
                            }),
                            placeholder: (styles) => ({
                                ...styles,
                                width: '100%',
                                textAlign: 'right',
                                paddingRight: '1em',
                            }),
                            singleValue: (styles) => ({
                                ...styles,
                                width: '100%',
                                textAlign: 'right',
                                paddingRight: '1em',
                            }),
                        }}
                    />
                </div>
            </div>
        );
    };

    return (
        <form onSubmit={createIdentifier}>
            <RightPane
                ref={paneRef}
                id="identifier-new"
                className="identifier-new panel-right-form"
                title={t('CREATE')}
                onComponentHidden={onHide}
                body={getIdentifierDataForm}
                footer={() => (
                    <button className="button save">{t('CREATE')}</button>
                )}
            />
        </form>
    );
};

export default AddIdentifier;
