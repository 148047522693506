import React from 'react';
import {IconInfo} from '../../graphics/icons';

import './Input.scss';

interface TextFieldProps {
    id: string;
    value: string;
    name: string;
    onChange: Function<InputEvent>;
    label?: string;
    hasError?: boolean;
    type?: string;
    disabled?: boolean;
    required?: boolean;
    hint?: string;
    tip?: string;
    tipPlace?: string;
    placeholder?: string;
    withoutLabel?: boolean;
    dataTip?: boolean;
    dataFor?: boolean;
    autoComplete?: string;
}

interface NumberFieldProps extends TextFieldProps {
    min?: number;
    max?: number;
    step?: number;
}

interface SelectFieldProps extends TextFieldProps {
    options: Function;
}

export function TextField(props: TextFieldProps) {
    let _type = 'text';
    if (props.type) {
        _type = props.type;
    }
    return (
        <div
            className={
                'field' +
                (props.required ? ' required' : '') +
                (props.hasError ? ' error' : '')
            }
            data-tip={props.tip}
            data-place={props.tipPlace}
        >
            <label htmlFor={props.id}>{props.label}</label>
            <input
                type={_type}
                id={props.id}
                value={props.value}
                name={props.name}
                onChange={props.onChange}
                disabled={props.disabled}
                placeholder={props.placeholder}
                autoComplete={props.autoComplete}
            />
            {props.hint && (
                <span className="hint">
                    <IconInfo />
                    <p>{props.hint}</p>
                </span>
            )}
        </div>
    );
}

export function NumberField(props: NumberFieldProps) {
    return (
        <div
            className={
                'field' +
                (props.required ? ' required' : '') +
                (props.hasError ? ' error' : '')
            }
        >
            <label htmlFor={props.id}>{props.label}</label>
            <input
                type="number"
                id={props.id}
                value={props.value}
                name={props.name}
                onChange={props.onChange}
                min={props.min}
                max={props.max}
                step={props.step}
                disabled={props.disabled}
            />
        </div>
    );
}

export function SelectField(props: SelectFieldProps) {
    return (
        <div
            className={
                'field' +
                (props.required ? ' required' : '') +
                (props.hasError ? ' error' : '')
            }
        >
            <p className="input-label">{props.label}</p>
            <select
                id={props.id}
                name={props.name}
                value={props.value}
                onChange={props.onChange}
                required={props.required}
                disabled={props.disabled}
            >
                {props.options()}
            </select>
        </div>
    );
}

export function CheckboxField(props: TextFieldProps) {
    const image = props.value
        ? require('../../graphics/iko_poi_checkbox_yes_dark.png').default
        : require('../../graphics/iko_poi_checkbox_no.png').default;

    return (
        <div
            className={
                'field checkbox-container' +
                (props.required ? ' required' : '') +
                (props.hasError ? ' error' : '') +
                (props.disabled ? ' disabled' : '') +
                (props.withoutLabel ? ' without-label' : '')
            }
            data-tip={props.dataTip || null}
            data-for={props.dataFor || null}
        >
            {!props.withoutLabel && (
                <p className="input-label">{props.label}</p>
            )}
            <img
                alt=""
                src={image}
                onClick={() => !props.disabled && props.onChange(!!props.value)}
            />
        </div>
    );
}
