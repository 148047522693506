import React, {FormEvent, MutableRefObject, useRef, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {toast} from 'react-toastify';
import {useSelector} from 'react-redux';

import useServiceProvider from '../../../utils/service';
import RightPane from '../../../components/RightPane/RightPane';
import {TextField} from '../../../components/Input/Input';
import type {AddDevice as IAddDevice} from '../../../utils/interfaces/device';
import type {RootState} from '../../../redux/reducers/rootReducer';

/**
 *
 * @param setAddMode {Function}
 * @returns {JSX.Element}
 * @constructor
 */
const AddDevice = ({setAddMode}) => {
    const {devicesService} = useServiceProvider();
    const {t} = useTranslation('Devices');

    const app = useSelector((state: RootState) => state.app);

    const [
        serialNumberOrIMEI: string,
        setSerialNumberOrIMEI: Function<string>,
    ] = useState('');
    const [processingData: boolean, setProcessingData: Function<boolean>] =
        useState(false);
    const [hasError, setHasError] = useState(false);

    const ref: MutableRefObject<RightPane> = useRef();

    const addDeviceHandler = (e: FormEvent) => {
        e.preventDefault();

        if (processingData) return;

        const input = serialNumberOrIMEI.trim();

        if (input.length === 0) {
            setHasError(true);
            return;
        }

        setProcessingData(true);
        setHasError(false);

        const _data: IAddDevice = {};

        if (input.length >= 15) {
            _data.imei = input;
        } else {
            _data.serialNumber = input;
        }

        devicesService.assignDevice(
            _data,
            (result) => {
                devicesService.getDevices();
                toast.success(t('DEVICE_ADDED'));
                console.debug(
                    'AddDevice :: addDeviceHandler() => result: %O',
                    result,
                );
                setProcessingData(false);
                ref.current.hideComponent();
            },
            (reason) => {
                toast.error(t('DEVICE_ERROR', {error: t(reason)}));
                console.error(
                    'AddDevice :: addDeviceHandler() => reason: %s',
                    reason,
                );
                setProcessingData(false);
            },
        );
    };

    const handleInputChange = (e: InputEvent) => {
        e.preventDefault();
        setSerialNumberOrIMEI(e.target.value.replace(/\s/g, ''));
    };

    const onClose = () => {
        setSerialNumberOrIMEI('');
        setAddMode(false);
    };

    return (
        <RightPane
            id="add-device"
            className="add-device panel-right-form"
            title={t('NEW_DEVICE')}
            onComponentHidden={onClose}
            ref={ref}
            body={() => {
                return (
                    <div>
                        <TextField
                            id="device_serial_number_or_imei"
                            value={serialNumberOrIMEI}
                            name="serial_number_or_imei"
                            onChange={handleInputChange}
                            label={t('SERIAL_NUMBER_OR_IMEI')}
                            hasError={hasError}
                        />
                        <div className="hint-container">
                            <div className="group hint">
                                <img
                                    src={
                                        require('../../../graphics/iko_info_round.png')
                                            .default
                                    }
                                    alt="Info icon"
                                    className="icon"
                                />
                                <span className="text">
                                    {t('ACTIVATION_REQUIRED')}
                                </span>
                            </div>
                            {app.variant === 'fm' && (
                                <div className="group hint">
                                    <img
                                        src={
                                            require('../../../graphics/iko_info_round.png')
                                                .default
                                        }
                                        alt="Info icon"
                                        className="icon"
                                    />
                                    <span className="text">
                                        {t('VEHICLE_GROUP_HINT')}
                                    </span>
                                </div>
                            )}
                        </div>
                    </div>
                );
            }}
            footer={() => {
                return (
                    <div className="group">
                        <button className="button" onClick={onClose}>
                            {t('CANCEL')}
                        </button>
                        <button
                            className="button save"
                            type="submit"
                            onClick={addDeviceHandler}
                            disabled={processingData}
                        >
                            {t('ADD_DEVICE')}
                        </button>
                    </div>
                );
            }}
        />
    );
};

export default AddDevice;
