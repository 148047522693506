import type {Device} from '../../utils/interfaces/device';

export const SET_DEVICE_LIST = 'SET_DEVICE_LIST';
export const ADD_DEVICE = 'ADD_DEVICE';
export const UPDATE_DEVICE = 'UPDATE_DEVICE';
export const UPDATE_DEVICE_POSITION = 'UPDATE_DEVICE_POSITION';
export const SET_DEVICE_LOCALIZATION = 'SET_DEVICE_LOCALIZATION';
export const SET_ASSIGNED_VEHICLE_TO_DEVICE = 'SET_ASSIGNED_VEHICLE_TO_DEVICE';
export const ASSIGN_DEVICE = 'ASSIGN_DEVICE';

export function setDeviceList(devices: Device[]) {
    return {
        type: SET_DEVICE_LIST,
        devices,
    };
}

export function addDevice(device) {
    return {
        type: ADD_DEVICE,
        device,
    };
}

export function updateDevice(deviceData) {
    return {
        type: UPDATE_DEVICE,
        deviceData,
    };
}

export function updateDevicePosition(deviceData) {
    return {
        type: UPDATE_DEVICE_POSITION,
        deviceData,
    };
}

export function setDeviceLocalization(deviceId, localization) {
    return {
        type: SET_DEVICE_LOCALIZATION,
        deviceId,
        localization,
    };
}

export function setAssignedVehicleToDevice(device: Device, result) {
    return {
        type: SET_ASSIGNED_VEHICLE_TO_DEVICE,
        device,
        result,
    };
}

export function assignDevice(serialNumber: string) {
    return {
        type: ASSIGN_DEVICE,
        serialNumber,
    };
}
