import {store} from '../redux/store/store';
import type {Identifier} from './interfaces/driver';
import type {Vehicle} from './interfaces/vehicle';

export const getVehicleDriverOrIdentifier = (vehicle: Vehicle) => {
    const driverList = store.getState().driverList;
    if (vehicle.driver_id) {
        const driver = driverList?.find((d) => d.id === vehicle.driver_id);
        if (driver) {
            return driver?.first_name + ' ' + driver?.last_name;
        }
    }
    if (vehicle.identifier_id) {
        const identifierList = store.getState().identifierList;
        const identifier: Identifier | undefined = identifierList?.find(
            (i) => i.id === vehicle.identifier_id,
        );
        if (identifier) {
            return identifier?.identifier_ident;
        }
    }
    if (vehicle.direct_driver_id) {
        const driver = driverList?.find(
            (d) => d.id === vehicle.direct_driver_id,
        );
        if (driver) {
            return driver?.first_name + ' ' + driver?.last_name;
        }
    }
    return '';
};
