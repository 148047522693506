import React, {useCallback, useEffect, useRef, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {toast} from 'react-toastify';
import moment from 'moment';
import {useSelector} from 'react-redux';

import RightPane from '../../../components/RightPane/RightPane';
import {InlineLoader} from '../../../components/Loader/Loader';
import EditVehicle from './EditVehicle';
import VehicleSharing from '../../../components/VehicleSharing/VehicleSharing';
import VehicleSharingHistory from './VehicleSharingHistory';
import PuescIntegration from './PuescIntegration';
import {IconEdit} from '../../../graphics/icons';
import {getDateCell} from '../../../utils/date';
import useServiceProvider from '../../../utils/service';
import type {RootState} from '../../../redux/reducers/rootReducer';
import type {Driver} from '../../../utils/interfaces/driver';
import type {Person} from '../../../utils/interfaces/person';
import type {Access} from '../../../utils/interfaces/access';

/**
 *
 * @param currentVehicle {Vehicle}
 * @param onHide {Function}
 * @param app {App}
 * @param userData {User}
 * @param vehicleService {VehicleService}
 * @param deviceList {Device[]}
 * @param driverList {Driver[]}
 * @param vehicleList {Vehicle[]}
 * @param clientService {ClientService}
 * @returns {JSX.Element}
 * @constructor
 */
const ShowVehicle = ({
    currentVehicle,
    onHide,
    app,
    userData,
    vehicleService,
    deviceList,
    driverList,
    vehicleList,
    clientService,
}) => {
    const {t} = useTranslation(['Vehicles', 'common']);

    const paneRef = useRef(null);

    const {driversService} = useServiceProvider();

    const groups = useSelector((state: RootState) => state.groupList).filter(
        (group) => group.vehicles.includes(currentVehicle.vehicle_id),
    );
    const {contactList} = useSelector((state: RootState) => state);

    const [assignedDriver: Driver, setAssignedDriver: Function<Driver>] =
        useState(null);
    const [vehicleToShare, setVehicleToShare] = useState(null);
    const [editMode, setEditMode] = useState(false);
    const [currentObservers: Person[], setCurrentObservers] = useState(null);

    useEffect(() => {
        app.variant === 'fm' && clientService.initStore();
        driversService.initStore();
    }, [clientService, driversService, app.variant]);

    useEffect(() => {
        if (!driverList) {
            return;
        }
        const driver = driverList.find(
            (d) => d.id === currentVehicle?.direct_driver_id,
        );
        if (driver) {
            setAssignedDriver(driver);
        } else {
            setAssignedDriver(false);
        }
    }, [driverList, currentVehicle]);

    const reloadObservers = useCallback(() => {
        if (currentVehicle === null || !contactList) {
            return;
        }
        if (app.variant !== 'fm') {
            setCurrentObservers([]);
            return;
        }
        vehicleService.getAccesses(
            currentVehicle.vehicle_id,
            null,
            (result: Access[]) => {
                setCurrentObservers(
                    result.map((a) =>
                        contactList.find(
                            (p: Person) => p.person_id === a.person_id,
                        ),
                    ),
                );
            },
            () => {
                setCurrentObservers([]);
            },
        );
    }, [contactList, currentVehicle, vehicleService, app.variant]);

    useEffect(() => {
        reloadObservers();
        const formCreatedWatcher = (notification) => {
            console.debug(
                'VehicleTable::[form_created] => notification: %O, currentVehicle: %O',
                notification,
                currentVehicle,
            );
            let vehicleId = notification.data.vehicle_id;

            if (
                currentVehicle !== null &&
                currentVehicle.vehicle_id === vehicleId
            ) {
                console.debug(
                    'VehicleTable::[form_created] => should reload driver assignment',
                );
            }
        };

        vehicleService.connection.addHandler(
            'form_created',
            formCreatedWatcher,
        );
        return () => {
            vehicleService.connection.removeHandler(
                'form_created',
                formCreatedWatcher,
            );
        };
    }, [reloadObservers, contactList, currentVehicle, vehicleService]);

    const enableVehicle = (e: Event) => {
        e.preventDefault();
        vehicleService.enableVehicle(
            currentVehicle.vehicle_id,
            () => {
                toast.success(t('VEHICLE_ACTIVATED'));
            },
            (reason) => {
                toast.error(t('VEHICLE_ACTIVATION_ERROR', {error: t(reason)}));
            },
        );
    };

    const disableVehicle = (e: Event) => {
        e.preventDefault();
        vehicleService.disableVehicle(
            currentVehicle.vehicle_id,
            () => {
                toast.success(t('VEHICLE_DEACTIVATED'));
            },
            (reason) => {
                toast.error(
                    t('VEHICLE_DEACTIVATION_ERROR', {error: t(reason)}),
                );
            },
        );
    };

    return (
        <>
            <div className="vehicle-details">
                <RightPane
                    ref={paneRef}
                    id="vehicle-details"
                    className="vehicle-details panel-right-entity-details"
                    title={currentVehicle.name}
                    onComponentHidden={onHide}
                    buttons={() => (
                        <button
                            className="button action icon"
                            onClick={() => setEditMode(true)}
                            data-tip={t('EDIT_VEHICLE')}
                        >
                            <IconEdit />
                        </button>
                    )}
                    body={() => {
                        const currentDevice = deviceList.find(
                            (d) => d.id === currentVehicle.device_id,
                        );
                        return (
                            <div>
                                <div className="group">
                                    <dl>
                                        <dt>{t('LOCALIZATION')}</dt>
                                        <dd>
                                            {currentDevice &&
                                                currentDevice.localization}
                                        </dd>
                                        {app.variant === 'fm' && (
                                            <>
                                                <dt>{t('OBSERVERS')}</dt>
                                                <dd className="vehicle-observers">
                                                    {currentObservers ===
                                                        null && (
                                                        <InlineLoader />
                                                    )}
                                                    {currentObservers !==
                                                        null &&
                                                        currentObservers.length ===
                                                            0 && (
                                                            <span>
                                                                &mdash;&mdash;
                                                            </span>
                                                        )}
                                                    {currentObservers !==
                                                        null && (
                                                        <div
                                                            className="share"
                                                            onClick={(
                                                                e: Event,
                                                            ) => {
                                                                e.stopPropagation();
                                                                setVehicleToShare(
                                                                    currentVehicle,
                                                                );
                                                            }}
                                                        >
                                                            <img
                                                                src={
                                                                    require('../../../graphics/iko_share_blue.png')
                                                                        .default
                                                                }
                                                                alt=""
                                                            />
                                                        </div>
                                                    )}
                                                    {currentObservers !==
                                                        null &&
                                                        currentObservers.length >
                                                            0 && (
                                                            <div className="observer-list-container">
                                                                {currentObservers.map(
                                                                    (p) => (
                                                                        <span
                                                                            className="observer"
                                                                            key={
                                                                                p.person_id
                                                                            }
                                                                        >
                                                                            {
                                                                                p.first_name
                                                                            }{' '}
                                                                            {
                                                                                p.last_name
                                                                            }
                                                                        </span>
                                                                    ),
                                                                )}
                                                            </div>
                                                        )}
                                                </dd>
                                            </>
                                        )}
                                        <dt>{t('COMMENT')}</dt>
                                        <dd>{currentVehicle.comment}</dd>
                                        <dt>{t('PHONE_NUMBER')}</dt>
                                        <dd>
                                            {currentVehicle?.phone_number
                                                ? currentVehicle.phone_number
                                                : t('NOT_SET')}
                                        </dd>
                                        <dt>{t('ASSIGNED_DEVICE')}</dt>
                                        <dd>
                                            {currentDevice
                                                ? currentDevice.serial_num
                                                : currentVehicle.device_id ===
                                                    null
                                                  ? t('DEVICE_NOT_ASSIGNED')
                                                  : t('NO_ACCESS_TO_DEVICE')}
                                        </dd>
                                        {app.variant === 'fm' && (
                                            <>
                                                <dt>{t('ASSIGNED_DRIVER')}</dt>
                                                <dd>
                                                    {assignedDriver ===
                                                        null && (
                                                        <InlineLoader />
                                                    )}
                                                    {assignedDriver === false &&
                                                        t(
                                                            'DRIVER_NOT_ASSIGNED',
                                                        )}
                                                    {assignedDriver
                                                        ? assignedDriver.first_name +
                                                          ' ' +
                                                          assignedDriver.last_name
                                                        : ''}
                                                </dd>
                                            </>
                                        )}
                                    </dl>
                                </div>
                                <div className="group">
                                    <dl>
                                        <dt>{t('FUEL_CONSUMPTION')}</dt>
                                        <dd>
                                            {currentVehicle.fuel_consumption_avg
                                                ? currentVehicle.fuel_consumption_avg +
                                                  ' l/100km'
                                                : ''}
                                        </dd>
                                        <dt>
                                            {t('FUEL_CONSUMPTION_DEVIATION')}
                                        </dt>
                                        <dd>
                                            {currentVehicle.fuel_consumption_deviation
                                                ? currentVehicle.fuel_consumption_deviation *
                                                      100 +
                                                  '%'
                                                : ''}
                                        </dd>
                                        <dt>{t('FUEL_TANK_1')}</dt>
                                        <dd>
                                            {currentVehicle.fuel_tank_capacity1
                                                ? currentVehicle.fuel_tank_capacity1 +
                                                  'l'
                                                : ''}
                                        </dd>
                                        <dt>{t('FUEL_TANK_2')}</dt>
                                        <dd>
                                            {currentVehicle.fuel_tank_capacity2
                                                ? currentVehicle.fuel_tank_capacity2 +
                                                  'l'
                                                : ''}
                                        </dd>
                                    </dl>
                                </div>
                                <div className="group">
                                    <dl>
                                        <dt>{t('POLICY_DATE')}</dt>
                                        <dd>
                                            {getDateCell(
                                                currentVehicle.policy_date,
                                            )}
                                        </dd>
                                        <dt>{t('INSPECTION_DATE')}</dt>
                                        <dd>
                                            {getDateCell(
                                                currentVehicle.inspection_date,
                                            )}
                                        </dd>
                                        {/*<dt>{t('INSPECTION_DISTANCE')}</dt>*/}
                                        {/*<dd>{currentDevice?.info?.dotsens?.inspectionDistance ? parseInt(currentDevice.info?.dotsens?.inspectionDistance / 1000) + ' km' : ''}</dd>*/}
                                        <dt>{t('DIAGNOSTIC_DATE')}</dt>
                                        <dd>
                                            {getDateCell(
                                                currentVehicle.diagnostic_date,
                                            )}
                                        </dd>
                                        <dt>{t('CONTRACT_VALID_TILL')}</dt>
                                        <dd>
                                            {currentVehicle.contract_valid_till
                                                ? getDateCell(
                                                      moment
                                                          .unix(
                                                              currentVehicle.contract_valid_till,
                                                          )
                                                          .toDate(),
                                                      true,
                                                  )
                                                : ''}
                                        </dd>
                                    </dl>
                                </div>
                                {currentVehicle.active && (
                                    <div className="group inline-info-action">
                                        <strong>{t('VEHICLE_ACTIVE')}</strong>
                                        <button
                                            className="button edit"
                                            onClick={disableVehicle}
                                        >
                                            {t('DEACTIVATE')}
                                        </button>
                                    </div>
                                )}
                                {!currentVehicle.active && (
                                    <div className="group inline-info-action">
                                        <strong>{t('VEHICLE_INACTIVE')}</strong>
                                        <button
                                            className="button basic"
                                            onClick={enableVehicle}
                                        >
                                            {t('ACTIVATE')}
                                        </button>
                                    </div>
                                )}
                                {['manager', 'dispositor'].includes(
                                    userData.type,
                                ) && (
                                    <PuescIntegration
                                        vehicle={currentVehicle}
                                        userData={userData}
                                    />
                                )}
                                {['manager', 'dispositor'].includes(
                                    userData.type,
                                ) && (
                                    <div className="group">
                                        <p className="title">
                                            {t('common:TRANSPORT_EXCHANGES')}
                                        </p>
                                        {(!currentVehicle.exchanges ||
                                            (currentVehicle.exchanges &&
                                                currentVehicle.exchanges
                                                    .length === 0)) && (
                                            <p>
                                                {t(
                                                    'common:NO_TRANSPORT_EXCHANGES',
                                                )}
                                            </p>
                                        )}
                                        {currentVehicle.exchanges &&
                                            currentVehicle.exchanges?.length >
                                                0 && (
                                                <ul className="exchanges-list">
                                                    {currentVehicle.exchanges.map(
                                                        (exchange) => (
                                                            <li
                                                                key={
                                                                    exchange.id
                                                                }
                                                                className="logo-container"
                                                            >
                                                                <img
                                                                    src={
                                                                        require(
                                                                            `../../../graphics/transportExchanges/${exchange.exchange}.png`,
                                                                        )
                                                                            .default
                                                                    }
                                                                    alt=""
                                                                />
                                                                {t(
                                                                    `common:${exchange.exchange}`,
                                                                )}
                                                            </li>
                                                        ),
                                                    )}
                                                </ul>
                                            )}
                                    </div>
                                )}
                                {app.variant === 'fm' && (
                                    <div className="group">
                                        <p className="title">
                                            {t('common:GROUPS')}
                                        </p>
                                        {!groups && <InlineLoader />}
                                        {groups && groups.length > 0 && (
                                            <ul className="group-list">
                                                {groups.map((group) => (
                                                    <li key={group.id}>
                                                        {group.name}
                                                    </li>
                                                ))}
                                            </ul>
                                        )}
                                        {groups && groups.length === 0 && (
                                            <p>
                                                {t(
                                                    'common:VEHICLE_DOES_NOT_BELONG_TO_ANY_GROUP',
                                                )}
                                            </p>
                                        )}
                                    </div>
                                )}
                                {app.variant === 'fm' &&
                                    currentVehicle &&
                                    currentVehicle.vehicle_id && (
                                        <VehicleSharingHistory
                                            vehicleId={
                                                currentVehicle.vehicle_id
                                            }
                                        />
                                    )}
                            </div>
                        );
                    }}
                />
            </div>
            {vehicleToShare !== null && (
                <VehicleSharing
                    vehicleService={vehicleService}
                    clientService={clientService}
                    vehicleToShare={vehicleToShare}
                    setVehicleToShare={setVehicleToShare}
                    onChange={reloadObservers}
                />
            )}
            {editMode &&
                app &&
                driverList &&
                deviceList &&
                vehicleList &&
                vehicleService &&
                driversService && (
                    <EditVehicle
                        onHide={() => setEditMode(false)}
                        currentVehicle={currentVehicle}
                        app={app}
                        driverList={driverList}
                        deviceList={deviceList}
                        vehicleList={vehicleList}
                        vehicleService={vehicleService}
                        driversService={driversService}
                        assignedDriver={assignedDriver}
                    />
                )}
        </>
    );
};

export default ShowVehicle;
