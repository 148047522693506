import _ from 'lodash';
import {getLocalizationSuggestions} from '../services/OpenRouteService';

export const loadOptions = _.debounce((inputValue, callback) => {
    getLocalizationSuggestions(inputValue)
        .then((result) => {
            callback(
                result
                    .filter((row) => row.localization !== '')
                    .map((row) => {
                        return {
                            label: row.localization,
                            value: row.localization,
                            lat: row.lat,
                            lng: row.lng,
                            type: 'place',
                        };
                    }),
            );
            // callback(result.map(row=>{return {label:row.localization}}))
        })
        .catch((reason) => {
            console.log(reason);
        });
}, 500);
