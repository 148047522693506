import moment from 'moment';
import {DATE_CELL_WIDTH, getSheet, PLACE_CELL_WIDTH} from './reportsXLSXUtils';
import {
    darkRowStyle,
    fieldStyle,
    lightRowStyle,
    mergedFieldsStyle,
} from './sheetStyles';
import {FULL_DATE_WITHOUT_SECONDS_FORMAT} from '../constants';

function createReportThermometerHeader(
    sheet,
    t,
    header,
    days,
    date_start,
    date_end,
    fields,
) {
    sheet.range('A1:C1').value(header).merged(true).style(fieldStyle);
    sheet
        .cell('D1')
        .value(t('Reports:NUMBER_OF_DAYS', {numberOfDays: days}))
        .style(fieldStyle);
    sheet
        .range('E1:L1')
        .merged(true)
        .value(
            moment.unix(date_start).format(FULL_DATE_WITHOUT_SECONDS_FORMAT) +
                ' - ' +
                moment.unix(date_end).format(FULL_DATE_WITHOUT_SECONDS_FORMAT),
        )
        .style(fieldStyle);
    sheet.row(1).height(50);
    sheet.row(2).height(20);
    sheet.row(3).height(20);
    sheet.range('A1:L3').style(mergedFieldsStyle);
    let headerRelativeCell = sheet.cell('A2');
    fields.forEach((field) => {
        if (
            field === 't1' ||
            field === 't2' ||
            field === 't3' ||
            field === 't4'
        ) {
            headerRelativeCell
                .rangeTo(headerRelativeCell.relativeCell(0, 1))
                .merged('true')
                .value(t('Reports:' + field))
                .style(fieldStyle);
            headerRelativeCell = headerRelativeCell.relativeCell(0, 1);
            headerRelativeCell = headerRelativeCell.relativeCell(0, 1);
        } else {
            headerRelativeCell
                .rangeTo(headerRelativeCell.relativeCell(1, 0))
                .value(t('Reports:' + field))
                .style(fieldStyle)
                .merged(true);
            headerRelativeCell = headerRelativeCell.relativeCell(0, 1);
        }
    });
    sheet.freezePanes('A4');
}

export function ThermometersReportSheet(
    workbook,
    t,
    fields,
    bluetooth_section,
    dallas_section,
    date_start,
    date_end,
    days,
    vehicleName,
) {
    function generateSheet(sheetName, sheetHeader, data) {
        let sheet = getSheet(workbook, sheetName);
        createReportThermometerHeader(
            sheet,
            t,
            sheetHeader,
            days,
            date_start,
            date_end,
            fields,
        );
        sheet.column('A').width(DATE_CELL_WIDTH);
        sheet.column('B').width(15);
        sheet.column('C').width(15);
        sheet.column('D').width(15);
        sheet.column('E').width(15);
        sheet.column('F').width(15);
        sheet.column('G').width(15);
        sheet.column('H').width(15);
        sheet.column('I').width(15);
        sheet.column('J').width(15);
        sheet.column('K').width(15);
        sheet.column('L').width(PLACE_CELL_WIDTH);
        data.t1_min !== null &&
            sheet
                .cell('B3')
                .value('Min: ' + data.t1_min.toFixed(1))
                .style(fieldStyle);
        data.t1_max !== null &&
            sheet
                .cell('C3')
                .value('Max: ' + data.t1_max.toFixed(1))
                .style(fieldStyle);
        data.t2_min !== null &&
            sheet
                .cell('D3')
                .value('Min: ' + data.t2_min.toFixed(1))
                .style(fieldStyle);
        data.t2_max !== null &&
            sheet
                .cell('E3')
                .value('Max: ' + data.t2_max.toFixed(1))
                .style(fieldStyle);
        data.t3_min !== null &&
            sheet
                .cell('F3')
                .value('Min: ' + data.t3_min.toFixed(1))
                .style(fieldStyle);
        data.t3_max !== null &&
            sheet
                .cell('G3')
                .value('Max: ' + data.t3_max.toFixed(1))
                .style(fieldStyle);
        data.t4_min !== null &&
            sheet
                .cell('H3')
                .value('Min: ' + data.t4_min.toFixed(1))
                .style(fieldStyle);
        data.t4_max !== null &&
            sheet
                .cell('I3')
                .value('Max: ' + data.t4_max.toFixed(1))
                .style(fieldStyle);
        data.rows.forEach((row, index) => {
            let rowRelativeCell = sheet.cell('A' + (index + 4));
            row.forEach((value, rowIndex) => {
                if (
                    rowIndex === 1 ||
                    rowIndex === 2 ||
                    rowIndex === 3 ||
                    rowIndex === 4
                ) {
                    rowRelativeCell
                        .rangeTo(rowRelativeCell.relativeCell(0, 1))
                        .value(value)
                        .merged(true)
                        .style(
                            rowRelativeCell.rowNumber() % 2 === 0
                                ? darkRowStyle
                                : lightRowStyle,
                        );
                    rowRelativeCell = rowRelativeCell.relativeCell(0, 1);
                    rowRelativeCell = rowRelativeCell.relativeCell(0, 1);
                } else {
                    rowRelativeCell
                        .value(
                            rowIndex === 0
                                ? moment
                                      .unix(value)
                                      .format(FULL_DATE_WITHOUT_SECONDS_FORMAT)
                                : value,
                        )
                        .style(
                            rowRelativeCell.rowNumber() % 2 === 0
                                ? darkRowStyle
                                : lightRowStyle,
                        );
                    rowRelativeCell = rowRelativeCell.relativeCell(0, 1);
                }
            });
        });
    }

    generateSheet(
        vehicleName + ' - dallas',
        t('Reports:THERMOMETERS_DALLAS_REPORT_HEADER', {
            vehicleName: vehicleName,
        }),
        dallas_section,
    );
    generateSheet(
        vehicleName + ' - bluetooth',
        t('Reports:THERMOMETERS_BLUETOOTH_REPORT_HEADER', {
            vehicleName: vehicleName,
        }),
        bluetooth_section,
    );
}
