// src/redux/reducers/notificationReducer.js
import {
    ADD_NOTIFICATION,
    ADD_NOTIFICATIONS,
    ADD_READ_NOTIFICATIONS,
    ADD_UNREAD_NOTIFICATIONS,
    MARK_NOTIFICATIONS_READ,
} from '../actions/notificationActions';

const initialState = {
    notificationList: [],
    unreadNotificationList: [],
    readNotificationList: [],
};

export default function notificationReducer(state = initialState, action) {
    switch (action.type) {
        case ADD_NOTIFICATION: {
            const {notificationData} = action;
            if (state.notificationList.some(n => n.id === notificationData.id)) {
                return state;
            }
            return {
                ...state,
                notificationList: [...state.notificationList, notificationData],
                unreadNotificationList: notificationData.read
                    ? state.unreadNotificationList
                    : [...state.unreadNotificationList, notificationData],
                readNotificationList: notificationData.read
                    ? [...state.readNotificationList, notificationData]
                    : state.readNotificationList,
            };
        }
        case ADD_NOTIFICATIONS: {
            const {notifications} = action;
            const newNotifications = notifications.filter(n => !state.notificationList.some(existing => existing.id === n.id));
            const unreadNotifications = newNotifications.filter(n => !n.read);
            const readNotifications = newNotifications.filter(n => n.read);
            return {
                ...state,
                notificationList: [...state.notificationList, ...newNotifications],
                unreadNotificationList: [...state.unreadNotificationList, ...unreadNotifications],
                readNotificationList: [...state.readNotificationList, ...readNotifications],
            };
        }
        case ADD_UNREAD_NOTIFICATIONS: {
            const {payload} = action;
            const newUnreadNotifications = payload.filter(n => !state.unreadNotificationList.some(existing => existing.id === n.id));
            return {
                ...state,
                unreadNotificationList: [...state.unreadNotificationList, ...newUnreadNotifications],
            };
        }
        case ADD_READ_NOTIFICATIONS: {
            const {payload} = action;
            const newReadNotifications = payload.filter(n => !state.readNotificationList.some(existing => existing.id === n.id));
            return {
                ...state,
                readNotificationList: [...state.readNotificationList, ...newReadNotifications],
            };
        }
        case MARK_NOTIFICATIONS_READ: {
            const {notificationIds} = action;
            const updatedNotificationList = state.notificationList.map(notification =>
                notificationIds.includes(notification.id) ? {...notification, read: true} : notification,
            );
            const updatedUnreadNotificationList = state.unreadNotificationList.filter(
                notification => !notificationIds.includes(notification.id),
            );
            const updatedReadNotificationList = [
                ...state.readNotificationList,
                ...state.notificationList.filter(notification =>
                    notificationIds.includes(notification.id) && !notification.read,
                ).map(notification => ({...notification, read: true})),
            ];
            return {
                ...state,
                notificationList: updatedNotificationList,
                unreadNotificationList: updatedUnreadNotificationList,
                readNotificationList: updatedReadNotificationList,
            };
        }
        default:
            return state;
    }
}
