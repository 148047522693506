import React, {MutableRefObject, useRef, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {toast} from 'react-toastify';
import RightPane from '../../components/RightPane/RightPane';
import {Loader} from '../../components/Loader/Loader';
import {TextField} from '../../components/Input/Input';

const initialData = {
    old_password: '',
    new_password: '',
    new_password_retyped: '',
};

/**
 *
 * @param userService {UserService}
 * @param onHide {Function}
 * @returns {Element}
 * @constructor
 */
const ChangePassword = ({userService, onHide}) => {
    const {t} = useTranslation('Account');

    const passwordRef: MutableRefObject<RightPane> = useRef(null);

    const [data, setData] = useState(initialData);
    const [dataErrors: string[], setDataErrors] = useState([]);
    const [isProcessing, setIsProcessing] = useState(false);

    const validate = () => {
        const errors = [];

        if (data.old_password.length === 0) {
            errors.push('old_password');
        }
        if (data.new_password.length < 6) {
            errors.push('new_password');
        }
        if (data.new_password !== data.new_password_retyped) {
            errors.push('new_password_retyped');
        }
        setDataErrors(errors);
        return errors.length === 0;
    };

    const submitHandler = (e: Event) => {
        e.preventDefault();

        console.debug('ChangePassword :: submitHandler() => data: %O', data);

        if (!validate()) {
            return;
        }

        const newUserData = {
            old_password: data.old_password,
            new_password: data.new_password,
        };
        setIsProcessing(true);
        userService.update(
            newUserData,
            (result) => {
                console.debug(
                    'ChangePassword :: submitHandler() => result: %O',
                    result,
                );
                setIsProcessing(false);

                if (
                    result.errors.hasOwnProperty('old_password') &&
                    result.errors.old_password === 'err_incorrect_password'
                ) {
                    setDataErrors((prev) => [...prev, 'bad_old_password']);
                    return;
                }

                setData(initialData);
                toast.success(t('PASSWORD_UPDATED'));
                onHide();
            },
            (reason) => {
                console.warn(
                    'ChangePassword :: submitHandler() => reason: %s',
                    reason,
                );
                toast.error(t('PASSWORD_UPDATE_ERROR'), {error: t(reason)});
                setIsProcessing(false);
            },
        );
    };

    const onChangeHandler = (e: Event) => {
        const {name, value} = e.target;
        setData((prev) => ({...prev, [name]: value}));
    };

    const Body = () => (
        <div>
            {isProcessing && <Loader />}
            {!isProcessing && (
                <form onSubmit={submitHandler}>
                    <TextField
                        id="account_old_password"
                        type="password"
                        value={data.old_password}
                        name="old_password"
                        label={t('OLD_PASSWORD')}
                        autoComplete="current-password"
                        hasError={dataErrors.includes('old_password')}
                        onChange={onChangeHandler}
                    />
                    <TextField
                        id="account_new_password"
                        type="password"
                        value={data.new_password}
                        name="new_password"
                        label={t('NEW_PASSWORD')}
                        autoComplete="off"
                        onChange={onChangeHandler}
                        hasError={dataErrors.includes('new_password')}
                    />
                    <TextField
                        id="account_new_password_retyped"
                        type="password"
                        value={data.new_password_retyped}
                        name="new_password_retyped"
                        label={t('NEW_PASSWORD_RETYPE')}
                        autoComplete="off"
                        onChange={onChangeHandler}
                        hasError={dataErrors.includes('new_password_retyped')}
                    />
                    <div className="errors">
                        {dataErrors.includes('bad_old_password') && (
                            <div className="error">
                                {t('ERROR_BAD_OLD_PASSWORD')}
                            </div>
                        )}
                        {dataErrors.includes('new_password_retyped') && (
                            <div className="error">
                                {t('ERROR_PASSWORD_MISMATCH')}
                            </div>
                        )}
                        {dataErrors.includes('new_password') && (
                            <div className="error">
                                {t('ERROR_NEW_PASSWORD')}
                            </div>
                        )}
                    </div>
                </form>
            )}
        </div>
    );

    const Footer = () => (
        <>
            <button
                type="reset"
                className="button action"
                onClick={(e: Event) => {
                    e.preventDefault();
                    passwordRef.current.hideComponent();
                }}
            >
                {t('ABORT')}
            </button>
            <button
                type="submit"
                className="button save"
                onClick={submitHandler}
            >
                {t('UPDATE_PASSWORD')}
            </button>
        </>
    );

    return (
        <RightPane
            ref={passwordRef}
            id="account-pane-password"
            className="account-pane panel-right-form"
            title={t('CHANGE_PASSWORD')}
            body={Body}
            footer={Footer}
            onComponentHidden={onHide}
        />
    );
};

export default ChangePassword;
