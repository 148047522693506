import React from 'react';

export const IconMileage = () => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="15.003"
            height="14.95"
            viewBox="0 0 15.003 14.95"
        >
            <g transform="translate(-481.503 -437.5)">
                <g opacity="0.7">
                    <circle
                        cx="1.4"
                        cy="1.4"
                        r="1.4"
                        transform="translate(487.6 443.6)"
                        fill="#25354d"
                    />
                    <rect
                        width="1.4"
                        height="4.511"
                        transform="translate(488.145 445.7) rotate(-90)"
                        fill="#25354d"
                    />
                    <rect
                        width="1.4"
                        height="2.081"
                        transform="translate(493.575 445.7) rotate(-90)"
                        fill="#25354d"
                    />
                    <path
                        d="M-674.96,1978.04a5.607,5.607,0,0,1,7.92,0l.99-.99a7,7,0,0,0-9.9,0,7,7,0,0,0,0,9.9l.99-.99A5.607,5.607,0,0,1-674.96,1978.04Z"
                        transform="translate(1160 -1537)"
                        fill="#25354d"
                    />
                    <path
                        d="M1128.915,4707.7H1127.5a5.6,5.6,0,0,0-9.514-4.659l-.989-.991a7,7,0,0,1,11.915,5.648Z"
                        transform="translate(-632.949 -4262)"
                        fill="#25354d"
                        stroke="rgba(0,0,0,0)"
                        strokeWidth="1"
                    />
                    <path
                        d="M2284,3174.95h0a7,7,0,0,1-4.949-11.95l.99.99a5.6,5.6,0,0,0,3.96,9.557v1.4Z"
                        transform="translate(-1795 -2723)"
                        fill="#25354d"
                        stroke="rgba(0,0,0,0)"
                        strokeWidth="1"
                    />
                </g>
            </g>
        </svg>
    );
};

export const IconMileageWithGPS = () => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="15.436"
            height="15.061"
            viewBox="0 0 15.436 15.061"
        >
            <g transform="translate(-481.503 -477.5)">
                <g transform="translate(-159 40)" opacity="0.7">
                    <circle
                        cx="1.4"
                        cy="1.4"
                        r="1.4"
                        transform="translate(646.6 443.6)"
                        fill="#25354d"
                    />
                    <rect
                        width="1.4"
                        height="4.511"
                        transform="translate(647.145 445.7) rotate(-90)"
                        fill="#25354d"
                    />
                    <rect
                        width="1.4"
                        height="2.081"
                        transform="translate(652.575 445.7) rotate(-90)"
                        fill="#25354d"
                    />
                    <path
                        d="M-674.96,1978.04a5.607,5.607,0,0,1,7.92,0l.99-.99a7,7,0,0,0-9.9,0,7,7,0,0,0,0,9.9l.99-.99A5.607,5.607,0,0,1-674.96,1978.04Z"
                        transform="translate(1319 -1537)"
                        fill="#25354d"
                    />
                    <path
                        d="M2287.4,3176.948a1.705,1.705,0,0,1-.592-.353l.49-.59a1.633,1.633,0,0,0,.377.228.966.966,0,0,0,.383.087.518.518,0,0,0,.282-.059.2.2,0,0,0,.087-.17.161.161,0,0,0-.03-.1.322.322,0,0,0-.087-.075,1.007,1.007,0,0,0-.138-.068l-.186-.072-.405-.17a1.215,1.215,0,0,1-.25-.132.976.976,0,0,1-.209-.2.871.871,0,0,1-.142-.258.929.929,0,0,1-.053-.32.884.884,0,0,1,.09-.392,1.007,1.007,0,0,1,.25-.32,1.2,1.2,0,0,1,.383-.218,1.437,1.437,0,0,1,.488-.08,1.639,1.639,0,0,1,.567.105,1.408,1.408,0,0,1,.513.329l-.43.541a1.542,1.542,0,0,0-.318-.176.908.908,0,0,0-.332-.06.475.475,0,0,0-.25.056.178.178,0,0,0-.09.165.193.193,0,0,0,.128.177c.084.042.2.091.352.148l.4.155a1.077,1.077,0,0,1,.471.335.891.891,0,0,1,.165.555.968.968,0,0,1-.332.727,1.229,1.229,0,0,1-.4.228,1.581,1.581,0,0,1-.54.085A1.975,1.975,0,0,1,2287.4,3176.948Zm-5.752.01a1.407,1.407,0,0,1-.873-.831,2.129,2.129,0,0,1,0-1.455,1.565,1.565,0,0,1,.353-.535,1.519,1.519,0,0,1,.518-.332,1.691,1.691,0,0,1,.62-.115,1.4,1.4,0,0,1,.622.128,1.574,1.574,0,0,1,.438.3l-.45.55a1.224,1.224,0,0,0-.252-.173.719.719,0,0,0-.328-.067.7.7,0,0,0-.305.066.73.73,0,0,0-.245.185.846.846,0,0,0-.163.3,1.273,1.273,0,0,0-.058.4,1.068,1.068,0,0,0,.205.7.793.793,0,0,0,.645.245.616.616,0,0,0,.137-.014.357.357,0,0,0,.114-.045v-.45h-.481v-.7h1.24v1.55a1.488,1.488,0,0,1-.464.282,1.68,1.68,0,0,1-.635.118A1.964,1.964,0,0,1,2281.649,3176.958Zm2.37.042v-3.25h1.19a2.339,2.339,0,0,1,.5.052,1.142,1.142,0,0,1,.42.18.919.919,0,0,1,.287.335,1.1,1.1,0,0,1,.108.513,1.135,1.135,0,0,1-.108.515,1,1,0,0,1-.287.353,1.273,1.273,0,0,1-.415.205,1.806,1.806,0,0,1-.49.066h-.35V3177Zm.861-1.71h.31a.518.518,0,0,0,.377-.121.45.45,0,0,0,.123-.339.346.346,0,0,0-.135-.31.7.7,0,0,0-.385-.09h-.29Zm4.035-4.591H2287.5a5.6,5.6,0,0,0-9.514-4.658l-.989-.991a7,7,0,0,1,11.915,5.648h0Z"
                        transform="translate(-1633.949 -2725)"
                        fill="#25354d"
                        stroke="rgba(0,0,0,0)"
                        strokeWidth="1"
                    />
                    <path
                        d="M2282.129,3174.7h0a7,7,0,0,1-3.078-11.7l.99.99a5.6,5.6,0,0,0,2.089,9.238v1.468Z"
                        transform="translate(-1636 -2723)"
                        fill="#25354d"
                        stroke="rgba(0,0,0,0)"
                        strokeWidth="1"
                    />
                </g>
            </g>
        </svg>
    );
};
