import {
    ADD_ACCESS_SCHEMA,
    REMOVE_ACCESS_SCHEMA,
    SET_ACCESS_SCHEMA_LIST,
    UPDATE_ACCESS_SCHEMA,
} from '../actions/accessSchemaActions';

const initialState = [];
export default function accessSchemaList(state = initialState, action) {
    switch (action.type) {
        case ADD_ACCESS_SCHEMA: {
            let index = state.findIndex(
                (schema) => schema.id === action.schema.id,
            );
            if (index === -1) {
                return [...state, action.schema];
            }
            break;
        }
        case SET_ACCESS_SCHEMA_LIST: {
            return action.schemaList;
        }
        case UPDATE_ACCESS_SCHEMA: {
            return state.map((schema) => {
                if (schema.id !== action?.schema?.id) {
                    return schema;
                } else {
                    return action.schema;
                }
            });
        }
        case REMOVE_ACCESS_SCHEMA: {
            return state.filter((schema) => schema.id !== action.schemaId);
        }
        default: {
            return state;
        }
    }
}
