import moment from 'moment';
import {convertSecondsToDurationString} from '../date';
import {
    alphabet,
    DATE_CELL_WIDTH,
    DISTANCE_CELL_WIDTH,
    DURATION_CELL_WIDTH,
    getSheet,
    PLACE_CELL_WIDTH,
} from './reportsXLSXUtils';
import {
    darkRowStyle,
    fieldStyle,
    lightRowStyle,
    mergedFieldsStyle,
} from './sheetStyles';
import {FULL_DATE_WITHOUT_SECONDS_FORMAT} from '../constants';
import type {CANMileageReportData} from '../interfaces/report';

function createCANMileageReportHeader(
    sheet,
    t,
    header,
    days,
    date_start,
    date_end,
    fields,
    vehicleDistanceStart,
    vehicleDistanceEnd,
) {
    sheet.range('A1:C1').value(header).merged(true).style(fieldStyle);
    sheet
        .cell('D1')
        .value(t('Reports:NUMBER_OF_DAYS', {numberOfDays: days}))
        .style(fieldStyle);
    sheet
        .range('E1:F1')
        .merged(true)
        .value(
            moment.unix(date_start).format(FULL_DATE_WITHOUT_SECONDS_FORMAT) +
                ' - ' +
                moment.unix(date_end).format(FULL_DATE_WITHOUT_SECONDS_FORMAT),
        )
        .style(fieldStyle);
    sheet
        .cell('G1')
        .value(t('Reports:MILEAGE_AT_THE_BEGINNING_OF_THE_BILLING_PERIOD'))
        .style(fieldStyle);
    if (typeof vehicleDistanceStart === 'number') {
        sheet
            .cell('H1')
            .value(vehicleDistanceStart.toFixed(2) + ' km')
            .style(fieldStyle);
    } else {
        sheet.cell('H1').value(t('common:NO_DATA')).style(fieldStyle);
    }

    sheet
        .cell('I1')
        .value(t('Reports:MILEAGE_AT_THE_END_OF_THE_BILLING_PERIOD'))
        .style(fieldStyle);
    if (typeof vehicleDistanceEnd === 'number') {
        sheet
            .cell('J1')
            .value(vehicleDistanceEnd.toFixed(2) + ' km')
            .style(fieldStyle);
    } else {
        sheet.cell('J1').value(t('common:NO_DATA')).style(fieldStyle);
    }

    sheet.row(1).height(50);
    sheet.row(2).height(50);
    sheet
        .range(
            'A1:' + alphabet[fields.length <= 6 ? 6 : fields.length - 1] + '2',
        )
        .style(mergedFieldsStyle);
    let headerRelativeCell = sheet.cell('A2');
    fields.forEach((field) => {
        headerRelativeCell.value(t('Reports:' + field)).style(fieldStyle);
        headerRelativeCell = headerRelativeCell.relativeCell(0, 1);
    });
    sheet.freezePanes('A3');
}

export function CANMileageReportSheet(
    workbook,
    t,
    reportData: CANMileageReportData,
) {
    if (!reportData.multiple) {
        reportData.vehicles = [
            {rows: reportData.rows, vehicle_name: reportData.vehicle_name},
        ];
    }

    reportData.vehicles.forEach((vehicle) => {
        let sheet = getSheet(workbook, vehicle.vehicle_name);

        createCANMileageReportHeader(
            sheet,
            t,
            t('Reports:CAN_MILEAGE_REPORT_HEADER', {
                vehicleName: vehicle.vehicle_name,
            }),
            reportData.days,
            reportData.date_start,
            reportData.date_end,
            reportData.fields,
            vehicle.vehicle_distance_start,
            vehicle.vehicle_distance_end,
        );
        let rowRelativeCell = sheet.cell('A3');
        let summary = false;
        vehicle.rows.forEach((row) => {
            if (row[0] !== 'row') {
                sheet
                    .range(
                        'A' +
                            rowRelativeCell.rowNumber() +
                            ':' +
                            alphabet[row.length - 2] +
                            rowRelativeCell.rowNumber(),
                    )
                    .value(t('Reports:' + row[0]))
                    .merged(true)
                    .style(fieldStyle);
                rowRelativeCell = rowRelativeCell.relativeCell(1, 0);
                summary = true;
            }
            row.forEach((value, index) => {
                if (index !== 0) {
                    if (index === 2 || index === 3) {
                        rowRelativeCell
                            .value(
                                moment
                                    .unix(value)
                                    .format(FULL_DATE_WITHOUT_SECONDS_FORMAT),
                            )
                            .style(
                                summary
                                    ? fieldStyle
                                    : rowRelativeCell.rowNumber() % 2 === 0
                                      ? darkRowStyle
                                      : lightRowStyle,
                            );
                    } else if (index === 7 || index === 8) {
                        rowRelativeCell
                            .value(convertSecondsToDurationString(value, true))
                            .style(
                                summary
                                    ? fieldStyle
                                    : rowRelativeCell.rowNumber() % 2 === 0
                                      ? darkRowStyle
                                      : lightRowStyle,
                            );
                    } else if (index === 1 && !summary) {
                        rowRelativeCell
                            .value(t('Reports:' + value))
                            .style(
                                summary
                                    ? fieldStyle
                                    : rowRelativeCell.rowNumber() % 2 === 0
                                      ? darkRowStyle
                                      : lightRowStyle,
                            );
                    } else if (index === 6 || index === 9 || index === 10) {
                        rowRelativeCell
                            .value(value.toFixed(2))
                            .style(
                                summary
                                    ? fieldStyle
                                    : rowRelativeCell.rowNumber() % 2 === 0
                                      ? darkRowStyle
                                      : lightRowStyle,
                            );
                    } else {
                        rowRelativeCell
                            .value(value)
                            .style(
                                summary
                                    ? fieldStyle
                                    : rowRelativeCell.rowNumber() % 2 === 0
                                      ? darkRowStyle
                                      : lightRowStyle,
                            );
                    }
                    rowRelativeCell = rowRelativeCell.relativeCell(0, 1);
                }
            });
            rowRelativeCell = rowRelativeCell.relativeCell(
                1,
                -(row.length - 1),
            );
            summary = false;
        });
        sheet.column('A').width(20);
        sheet.column('B').width(DATE_CELL_WIDTH);
        sheet.column('C').width(DATE_CELL_WIDTH);
        sheet.column('D').width(PLACE_CELL_WIDTH);
        sheet.column('E').width(PLACE_CELL_WIDTH);
        sheet.column('F').width(DISTANCE_CELL_WIDTH);
        sheet.column('G').width(DURATION_CELL_WIDTH);
        sheet.column('H').width(DURATION_CELL_WIDTH);
        sheet.column('I').width(DURATION_CELL_WIDTH);
        sheet.column('J').width(DISTANCE_CELL_WIDTH);
    });
}
