import React, {useEffect, useState} from 'react';
import {toast} from 'react-toastify';
import {useTranslation} from 'react-i18next';
import {useSelector} from 'react-redux';

import {store} from '../../redux/store/store';
import {
    SET_NEW_CLIENT_INITIAL_STATE,
    setNewClientDataFromExisting,
    setNewClientDataInitialState,
} from '../../redux/actions/clientDataActions';
import ClientTable from './parts/ClientTable';
import ClientEditorMain from './parts/ClientCreator';
import ClientPreview from './parts/ClientPreview';
import {MainListHeader} from '../../components/MainListsHeader/MainListHeader';
import {Loader} from '../../components/Loader/Loader';
import Filters from '../../components/Filters/Filters';
import {getFilteredData} from '../../utils/filtering';
import type {RootState} from '../../redux/reducers/rootReducer';
import type {Counterparty} from '../../utils/interfaces/counterparty';
import type {filterOptions} from '../../utils/filtering';

import './Clients.scss';

/**
 *
 * @param clientService {ClientService}
 * @returns {Element}
 * @constructor
 */
const Clients = ({clientService}) => {
    const {t} = useTranslation(['Client', 'Dispositors', 'common']);

    const {clientData, clientList, contactList} = useSelector(
        (state: RootState) => state,
    );

    const [searchValue: string, setSearchValue: Function<string>] =
        useState('');
    const [addMode: boolean, setAddMode: Function<boolean>] = useState(false);
    const [editMode: boolean, setEditMode: Function<boolean>] = useState(false);
    const [previewMode: boolean, setPreviewMode: Function<boolean>] =
        useState(false);
    const [activeTab: filterOptions, setActiveTab: Function<filterOptions>] =
        useState('all');

    const [tableData: Counterparty[], setTableData: Function<Counterparty[]>] =
        useState(null);

    useEffect(() => {
        clientService.initStore();
    }, [clientService]);

    useEffect(() => {
        if (!clientList) return;
        const filteredClientList = getFilteredData(clientList, activeTab);
        setTableData(filteredClientList);
    }, [activeTab, clientList]);

    const switchAddMode = () => {
        setAddMode((prev) => !prev);
        store.dispatch({
            type: SET_NEW_CLIENT_INITIAL_STATE,
        });
    };

    const switchEditMode = () => {
        const counterparty_id = clientData.counterparty_id;
        if (!counterparty_id) {
            toast.info(t('ERR_NO_CLIENT_SELECTED'), {
                position: toast.POSITION.TOP_RIGHT,
            });
            return;
        }
        setEditMode((prev) => !prev);
        const _clientData = clientList.find((client) => {
            return client.counterparty_id === counterparty_id;
        });
        store.dispatch(setNewClientDataFromExisting(_clientData));
    };

    const handleChangeSearchValue = (event) => {
        console.debug(
            'Clients::handleChangeSearchValue => "%s"',
            event.target.value,
        );
        setSearchValue(event.target.value);
    };

    const previewOpen = (clientData) => {
        const counterparty_id = clientData.counterparty_id;
        if (!counterparty_id) {
            console.warn('Clients::previewOpen() => missing "counterparty_id"');
            return;
        }
        setPreviewMode(true);
        store.dispatch(setNewClientDataFromExisting(clientData));
    };

    const previewClosed = () => {
        setPreviewMode(false);
        store.dispatch(setNewClientDataInitialState());
    };

    const editorClosed = () => {
        setAddMode(false);
        setEditMode(false);
    };

    return (
        <div id={'main-client-list'}>
            <MainListHeader
                headerText={t('common:CLIENTS')}
                searchValue={searchValue}
                handleChangeSearchValue={handleChangeSearchValue}
                addText={t('NEW_CLIENT')}
                switchAddMode={switchAddMode}
                filters={
                    <Filters
                        filters={['all', 'active', 'inactive']}
                        labels={[
                            t('Dispositors:ALL'),
                            t('Dispositors:ACTIVE'),
                            t('Dispositors:INACTIVE'),
                        ]}
                        onTabChange={setActiveTab}
                    />
                }
            />
            {(tableData === null || contactList === null) && <Loader />}
            {tableData !== null && contactList !== null && (
                <>
                    <ClientTable
                        clientList={tableData}
                        searchValue={searchValue}
                        clientData={clientData}
                        service={clientService}
                        rowAction={previewOpen}
                    />
                    {previewMode && clientData && (
                        <ClientPreview
                            clientData={clientData}
                            title={clientData.name}
                            service={clientService}
                            editMode={switchEditMode}
                            onComponentHidden={previewClosed}
                        />
                    )}
                    {addMode && (
                        <ClientEditorMain
                            title={t('NEW_CLIENT')}
                            onComponentHidden={editorClosed}
                            service={clientService}
                        />
                    )}
                    {editMode && (
                        <ClientEditorMain
                            title={t('EDIT_CLIENT')}
                            onComponentHidden={editorClosed}
                            service={clientService}
                        />
                    )}
                </>
            )}
        </div>
    );
};

export default Clients;
