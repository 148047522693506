import type {
    IdentifierAssignment,
    IdentifierAssignmentUpdate,
} from '../../utils/interfaces/driver';

export const SET_IDENTIFIER_ASSIGNMENT_LIST = 'SET_IDENTIFIER_ASSIGNMENT_LIST';
export const ADD_IDENTIFIER_ASSIGNMENT = 'ADD_IDENTIFIER_ASSIGNMENT';
export const UPDATE_IDENTIFIER_ASSIGNMENT = 'UPDATE_IDENTIFIER_ASSIGNMENT';
export const REMOVE_IDENTIFIER_ASSIGNMENT = 'REMOVE_IDENTIFIER_ASSIGNMENT';

export function setIdentifierAssignmentList(
    identifierAssignments: IdentifierAssignment[],
) {
    return {
        type: SET_IDENTIFIER_ASSIGNMENT_LIST,
        identifierAssignments,
    };
}
export function addIdentifierAssignment(
    identifierAssignmentData: IdentifierAssignment,
) {
    return {
        type: ADD_IDENTIFIER_ASSIGNMENT,
        identifierAssignmentData,
    };
}

export function updateIdentifierAssignment(
    identifierAssignmentData: IdentifierAssignmentUpdate,
) {
    return {
        type: UPDATE_IDENTIFIER_ASSIGNMENT,
        identifierAssignmentData,
    };
}
export function removeIdentifierAssignment(
    identifierAssignmentData: IdentifierAssignmentUpdate,
) {
    return {
        type: REMOVE_IDENTIFIER_ASSIGNMENT,
        identifierAssignmentData,
    };
}
