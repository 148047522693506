import React from 'react';
import type Connection from '../Connection';
import type VehicleService from '../services/VehicleService';
import type UserService from '../services/UserService';
import type POIService from '../services/POIService';
import type OrderService from '../services/OrderService';
import type ClientService from '../services/ClientService';
import type NotificationService from '../services/NotificationService';
import type ReportService from '../services/ReportService';
import type ChatService from '../services/ChatService';
import type UsersService from '../services/UsersService';
import type DriversService from '../services/DriversService';
import type AccessSchemaService from '../services/AccessSchemaService';
import type DevicesService from '../services/DevicesService';
import type LicenseService from '../services/LicenseService';
import type OffersService from '../services/OffersService';
import type GroupService from '../services/GroupService';
import type AtlasService from '../services/AtlasService';
import type PuescService from '../services/PuescService';

export interface ServiceProvider {
    connection: Connection;
    vehicleService: VehicleService;
    userService: UserService;
    poiService: POIService;
    orderService: OrderService;
    clientService: ClientService;
    notificationService: NotificationService;
    reportService: ReportService;
    chatService: ChatService;
    usersService: UsersService;
    driversService: DriversService;
    accessSchemaService: AccessSchemaService;
    devicesService: DevicesService;
    licenseService: LicenseService;
    offersService: OffersService;
    groupService: GroupService;
    atlasService: AtlasService;
    puescService: PuescService;
}

const d: ServiceProvider = {};
export const ServiceProviderContext = React.createContext(d);

export default function useServiceProvider(): ServiceProvider {
    const ctx = React.useContext(ServiceProviderContext);
    if (ctx === undefined) {
        throw new Error(
            'useServiceProvider must be used within a ServiceProviderContext.Provider',
        );
    }

    return ctx;
}
