export const ADD_HISTORY_ROUTE = 'ADD_HISTORY_ROUTE';
export const REMOVE_HISTORY_ROUTE = 'REMOVE_HISTORY_ROUTE';
export const UPDATE_HISTORY_ROUTE = 'UPDATE_HISTORY_ROUTE';
export const REMOVE_ALL_HISTORY_ROUTES = 'REMOVE_ALL_HISTORY_ROUTES';

export function addHistoryRoute(historyRouteData) {
    return {
        type: ADD_HISTORY_ROUTE,
        historyRouteData,
    };
}

export function removeHistoryRoute(vehicleId) {
    return {
        type: REMOVE_HISTORY_ROUTE,
        vehicleId,
    };
}

export function updateHistoryRoute(historyRouteData) {
    return {
        type: UPDATE_HISTORY_ROUTE,
        historyRouteData,
    };
}
export function removeAllHistoryRoutes() {
    return {
        type: REMOVE_ALL_HISTORY_ROUTES,
    };
}
