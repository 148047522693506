import moment from 'moment';
import {convertSecondsToDurationString} from '../date';
import {getSheet} from './reportsXLSXUtils';
import {darkRowStyle, fieldStyle, lightRowStyle} from './sheetStyles';
import {FULL_DATE_WITHOUT_SECONDS_FORMAT} from '../constants';

interface PointReportData {
    poi_id: number;
    poi_name: string;
    labels: string[];
    report_type: 'point';
    rows: string[][];
}

export function pointReportSheet(workbook, t, reportData) {
    const data: PointReportData = reportData;
    let sheet = getSheet(workbook, data.poi_name);
    sheet.cell('A1').value(t(data.labels[1])).style(fieldStyle);
    sheet.cell('B1').value(t(data.labels[2])).style(fieldStyle);
    sheet.cell('C1').value(t(data.labels[3])).style(fieldStyle);
    sheet.cell('D1').value(t(data.labels[4])).style(fieldStyle);

    let index = 0;

    reportData.rows.forEach((row) => {
        let style = index % 2 ? lightRowStyle : darkRowStyle;
        if (row[4] >= 60) {
            sheet
                .cell('A' + (index + 2))
                .value(row[1])
                .style(style);
            sheet
                .cell('B' + (index + 2))
                .value(
                    moment
                        .unix(row[2])
                        .format(FULL_DATE_WITHOUT_SECONDS_FORMAT),
                )
                .style(style);
            sheet
                .cell('C' + (index + 2))
                .value(
                    moment
                        .unix(row[3])
                        .format(FULL_DATE_WITHOUT_SECONDS_FORMAT),
                )
                .style(style);
            sheet
                .cell('D' + (index + 2))
                .value(convertSecondsToDurationString(row[4]))
                .style(style);
            index += 1;
        }
    });
    sheet.column('A').width(50);
    sheet.column('B').width(50);
    sheet.column('C').width(50);
    sheet.column('D').width(16);
}
