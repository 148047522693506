import React, {
    MutableRefObject,
    useCallback,
    useEffect,
    useRef,
    useState,
} from 'react';
import {useSelector} from 'react-redux';
import {useTranslation} from 'react-i18next';
import {toast} from 'react-toastify';
import L, {CircleMarker, LeafletMouseEvent, Marker} from 'leaflet';
import 'leaflet.gridlayer.googlemutant';
import 'leaflet.markercluster';

import {store} from '../../redux/store/store';
import {
    setNewPoiData,
    setNewPoiDataFromExisted,
    setNewPoiInitialState,
    setNewPoiLatLngData,
} from '../../redux/actions/newPOIActions';
import useApi from '../../utils/api';
import useServiceProvider from '../../utils/service';
import useMap from '../../hooks/useMap';
import {mapIcons} from '../../utils/mapIcons';
import {poiIcons} from '../../utils/POITypes';
import type {POI} from '../../utils/interfaces/poi';
import type {RootState} from '../../redux/reducers/rootReducer';

import './PointsMapStyles.scss';
import 'leaflet.markercluster/dist/MarkerCluster.css';
import 'leaflet.markercluster/dist/MarkerCluster.Default.css';

L.Marker.Decorated = L.Marker.extend({
    options: {
        decorationLayer: null,
    },
    onAdd(map) {
        L.Marker.prototype.onAdd.call(this, map);
        this.options.decorationLayer?.addTo(map);
    },
    onRemove(map) {
        L.Marker.prototype.onRemove.call(this, map);
        this.options.decorationLayer?.remove();
    },
});

/**
 *
 * @param markers {POI[]}
 * @param newPOIData {POI}
 * @param addMode {boolean}
 * @param setCurrentPoi {Function}
 * @param setDetailsMode {Function}
 * @returns {JSX.Element}
 * @constructor
 */
export default function PointsMap({
    markers,
    newPOIData,
    addMode,
    setCurrentPoi,
    setDetailsMode,
}) {
    const {t} = useTranslation();

    const POITypeList = useSelector((state: RootState) => state.POITypeList);
    const newPointMarker: MutableRefObject<Marker> = useRef(null);
    const newPointCircleMarker: MutableRefObject<CircleMarker> = useRef(null);

    const [isClusteringEnabled: null | boolean, setIsClusteringEnabled] =
        useState(null);

    const {mapRef, markerLayerRef} = useMap();

    const api = useApi();

    const {userService} = useServiceProvider();

    const onPointClickHandler = useCallback(
        (e) => {
            let currentPoi;
            if (markers.length > 0) {
                currentPoi = markers.filter(
                    (poi) => poi.id === e.layer.options.id,
                )[0];
            }

            if (currentPoi) {
                setDetailsMode(true);
                store.dispatch(setNewPoiDataFromExisted(currentPoi));
                setCurrentPoi(currentPoi);
            } else {
                setCurrentPoi(null);
                store.dispatch(setNewPoiInitialState());
            }
        },
        [markers, setCurrentPoi, setDetailsMode],
    );

    useEffect(() => {
        if (isClusteringEnabled !== null) {
            return;
        }
        let isMounted = true;

        userService
            .getStore('isClusteringEnabledOnPoiView')
            .then((result) => {
                console.debug(
                    'PointsMap :: getStore (key=isClusteringEnabledOnPoiView)',
                    result,
                );
                if (isMounted) {
                    const value = result.isClusteringEnabledOnPoiView;
                    setIsClusteringEnabled(value !== false);
                    if (value === false) {
                        mapRef.current.removeLayer(markerLayerRef.current);
                        markerLayerRef.current = L.featureGroup()
                            .addTo(mapRef.current)
                            .on('click', onPointClickHandler);
                    }
                }
            })
            .catch((error) => {
                console.error(
                    'PointsMap :: getStore (key=isClusteringEnabledOnPoiView)',
                    error,
                );
                if (isMounted) {
                    setIsClusteringEnabled(true);
                }
            });

        return () => {
            isMounted = false;
        };
    }, [
        isClusteringEnabled,
        mapRef,
        markerLayerRef,
        onPointClickHandler,
        userService,
    ]);

    const onDoubleClickHandler = useCallback(
        (e: LeafletMouseEvent) => {
            if (!addMode) return;
            const {lat, lng} = e.latlng;
            mapRef.current.setView([lat, lng]);
            const newLat = parseFloat(
                lat.toFixed(5).toString().replace(',', '.'),
            );
            const newLng = parseFloat(
                lng.toFixed(5).toString().replace(',', '.'),
            );
            store.dispatch(setNewPoiLatLngData(newLat, newLng));
        },
        [addMode, mapRef],
    );

    const getFormattedAddress = useCallback(
        async (newLat: number, newLng: number) => {
            try {
                const result = await api.promisedQuery('geocoding.geodecode', {
                    lat: newLat,
                    lng: newLng,
                    formatted: true,
                });
                console.debug('PointsMap :: geocoding.geodecode =>', result);
                store.dispatch(setNewPoiData(result, 'address'));
            } catch (error) {
                console.error('PointsMap :: geocoding.geodecode =>', error);
            }
        },
        [api],
    );

    useEffect(() => {
        if (addMode) {
            if (newPOIData.type === null) return;
            mapRef.current.setView([newPOIData.lat, newPOIData.lng]);
            mapRef.current.on('dblclick', onDoubleClickHandler);
            mapRef.current.doubleClickZoom.disable();
            let typeId = newPOIData.type;
            if (markerLayerRef.current) {
                markerLayerRef.current.removeFrom(mapRef.current);
            }
            // if we're changing POI type we need to remove current marker and circle
            if (newPointMarker.current) {
                // typeId = typeIdFromEvent;
                newPointMarker.current.remove();
                newPointCircleMarker.current.remove();
            }
            // if there is no marker or we're changing types we need to create marker and circle
            if (!newPointMarker.current || typeId) {
                newPointMarker.current = L.marker(
                    [newPOIData.lat, newPOIData.lng],
                    {
                        icon: mapIcons.iconDefault,
                        draggable: true,
                    },
                )
                    .bindPopup('New point')
                    .addTo(mapRef.current);
            }
            if (!newPointCircleMarker.current || typeId) {
                newPointCircleMarker.current = L.circle(
                    [newPOIData.lat, newPOIData.lng],
                    {
                        radius: newPOIData.extra.radius
                            ? newPOIData.extra.radius
                            : 0,
                    },
                )
                    .bindPopup('New point')
                    .addTo(mapRef.current);
                newPointMarker.current.on('drag', (e) => {
                    newPointCircleMarker.current.setLatLng([
                        e.target._latlng.lat,
                        e.target._latlng.lng,
                    ]);
                });
                newPointMarker.current.on('dragend', (e) => {
                    mapRef.current.setView([
                        e.target._latlng.lat,
                        e.target._latlng.lng,
                    ]);
                    const newLat = parseFloat(
                        e.target._latlng.lat
                            .toFixed(5)
                            .toString()
                            .replace(',', '.'),
                    );
                    const newLng = parseFloat(
                        e.target._latlng.lng
                            .toFixed(5)
                            .toString()
                            .replace(',', '.'),
                    );
                    store.dispatch(setNewPoiLatLngData(newLat, newLng));

                    getFormattedAddress(newLat, newLng);
                });
            }
        } else {
            markerLayerRef.current
                .addTo(mapRef.current)
                .on('click', onPointClickHandler);

            if (newPointMarker.current) {
                newPointMarker.current.removeFrom(mapRef.current);
                newPointCircleMarker.current.removeFrom(mapRef.current);
                newPointMarker.current = null;
                newPointCircleMarker.current = null;
            }
        }
    }, [
        newPOIData,
        addMode,
        POITypeList,
        onDoubleClickHandler,
        markers,
        setCurrentPoi,
        setDetailsMode,
        api,
        mapRef,
        markerLayerRef,
        getFormattedAddress,
        onPointClickHandler,
    ]);

    useEffect(() => {
        if (newPOIData.type === null) return;
        if (addMode) {
            newPointMarker.current
                .setLatLng([newPOIData.lat, newPOIData.lng])
                .bindPopup(newPOIData.name);
            newPointCircleMarker.current.setLatLng([
                newPOIData.lat,
                newPOIData.lng,
            ]);
            newPointCircleMarker.current.setRadius(
                newPOIData.extra.radius ? newPOIData.extra.radius : 0,
            );
        } else {
            if (markerLayerRef.current.getLayers().length) {
                markerLayerRef.current.getLayers().forEach((marker) => {
                    if (marker.options.id === newPOIData.id) {
                        mapRef.current.setView(marker.getLatLng(), 15);
                    }
                });
            }
        }
    }, [newPOIData, addMode, markerLayerRef, mapRef]);

    const createMarkerIcon = useCallback(
        (point: POI) => {
            if (isClusteringEnabled === null) {
                return;
            }
            const icon = point.poi_type.icon;

            const shouldBeDefaultIcon = !poiIcons.includes(icon);

            if (isClusteringEnabled) {
                new L.Marker.Decorated([point.lat, point.lng], {
                    id: point.id,
                    icon:
                        icon && !shouldBeDefaultIcon
                            ? L.icon({
                                  iconUrl: require(
                                      '../../graphics/PoiTypeIcons/' + icon,
                                  ).default,
                                  iconSize: [32, 32],
                                  iconAnchor: [16, 16],
                                  popupAnchor: [0, -25],
                              })
                            : mapIcons.iconDefault,
                    decorationLayer: L.circle([point.lat, point.lng], {
                        id: 'circle_' + point.id,
                        radius: point.extra.radius
                            ? parseInt(point.extra.radius)
                            : 0,
                    }),
                }).addTo(markerLayerRef.current);
            } else {
                L.marker([point.lat, point.lng], {
                    id: point.id,
                    icon:
                        icon && !shouldBeDefaultIcon
                            ? L.icon({
                                  iconUrl: require(
                                      '../../graphics/PoiTypeIcons/' + icon,
                                  ).default,
                                  iconSize: [48, 48],
                                  iconAnchor: [24, 24],
                                  popupAnchor: [0, -25],
                              })
                            : mapIcons.iconDefault,
                }).addTo(markerLayerRef.current);
            }
        },
        [isClusteringEnabled, markerLayerRef],
    );

    useEffect(() => {
        mapRef.current.invalidateSize();
        markerLayerRef.current.clearLayers();
        markers.forEach((point) => {
            createMarkerIcon(point);
        });
        if (
            markerLayerRef.current.getLayers().length &&
            !addMode &&
            !newPOIData.id
        ) {
            mapRef.current.fitBounds(markerLayerRef.current.getBounds());
        }
    }, [
        newPOIData.id,
        markers,
        addMode,
        POITypeList,
        mapRef,
        markerLayerRef,
        createMarkerIcon,
    ]);

    const onChangeClusteringHandler = (e) => {
        const value = e.target.checked;
        setIsClusteringEnabled(value);

        mapRef.current.removeLayer(markerLayerRef.current);
        if (value) {
            markerLayerRef.current = L.markerClusterGroup()
                .addTo(mapRef.current)
                .on('click', onPointClickHandler);
        } else {
            markerLayerRef.current = L.featureGroup()
                .addTo(mapRef.current)
                .on('click', onPointClickHandler);
        }

        userService.setStore(
            'isClusteringEnabledOnPoiView',
            value,
            (result) => {
                console.debug('PointsMap :: onChangeClusteringHandler', result);
                toast.success(
                    value ? t('CLUSTERING_ENABLED') : t('CLUSTERING_DISABLED'),
                );
                toast.info(t('MARKERS_LOADING_IN_PROGRESS'));
            },
            (error) => {
                console.error('PointsMap :: onChangeClusteringHandler', error);
            },
        );
    };

    return (
        <div id="points-map">
            {isClusteringEnabled !== null && (
                <div className="clustering-checkbox-container">
                    <label htmlFor="clustering">{t('CLUSTERING')}</label>
                    <input
                        type="checkbox"
                        id="clustering"
                        checked={isClusteringEnabled}
                        onChange={onChangeClusteringHandler}
                    />
                </div>
            )}
        </div>
    );
}
