import React, {useState, FormEvent, useEffect} from 'react';
import {useTranslation} from 'react-i18next';
import ReactTooltip from 'react-tooltip';
import {ulid} from 'ulid';

import RightPane from '../../../components/RightPane/RightPane';
import {OverlayLoader} from '../../../components/Loader/Loader';
import SummaryModal from './SummaryModal';
import {SelectField, TextField} from '../../../components/Input/Input';
import {IconDelete, IconPlus} from '../../../graphics/icons';
import {transportExchanges} from '../../../utils/transportExchanges';
import type {
    AtlasUser,
    CreateAtlasUser,
    CreateAtlasUserResult,
    CreationError,
} from '../../../utils/interfaces/transportExchange';
import type {SelectOption} from '../../../utils/interfaces/interfaces';

/**
 *
 * @param atlasService {AtlasService}
 * @param onClose {Function}
 * @param fetchAtlasUsers {Function}
 * @returns {Element}
 * @constructor
 */
const AddTransportExchanges = ({atlasService, onClose, fetchAtlasUsers}) => {
    const {t} = useTranslation(['Settings', 'common']);

    const initialData: CreateAtlasUser = {
        id: `exchange_${ulid()}`,
        exchange: '',
        comment: '',
    };

    const [data: CreateAtlasUser[], setData] = useState([initialData]);
    const [dataErrors: string[], setDataErrors] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [exchangeOptions: SelectOption[], setExchangeOptions] = useState(
        transportExchanges.map((item) => ({
            value: item.name,
            label: item.label,
        })),
    );
    const [showSummaryModal, setShowSummaryModal] = useState(false);
    const [
        createdExchanges: CreateAtlasUserResult[] | null,
        setCreatedExchanges,
    ] = useState(null);
    const [creationErrors: CreationError[] | null, setCreationErrors] =
        useState(null);

    useEffect(() => {
        setTimeout(() => {
            ReactTooltip.rebuild();
        }, 0);

        return () => {
            ReactTooltip.hide();
        };
    }, []);

    const createExchangeCandidate = () => {
        setData((prev) => [
            ...prev,
            {...initialData, id: `exchange_${ulid()}`},
        ]);
    };

    const updateExchangeOptions = (_data: CreateAtlasUser[]) => {
        const selectedExchanges = _data
            .filter((item) => item.exchange)
            .map((item) => item.exchange);
        const options = exchangeOptions.map((k) => {
            return {...k, disabled: !!selectedExchanges.includes(k.value)};
        });
        setExchangeOptions(options);
    };

    const deleteExchangeCandidate = (exchange: CreateAtlasUser) => {
        const _data = data.filter((item) => item.id !== exchange.id);
        setData(_data);
        updateExchangeOptions(_data);
    };

    const validate = () => {
        const errors = [];

        data.forEach((item) => {
            if (item.exchange === '') {
                errors.push(`${item.id}_exchange`);
            }
        });

        setDataErrors(errors);
        return errors.length === 0;
    };

    const submitHandler = async (e: FormEvent) => {
        e.preventDefault();

        if (isLoading) return;

        if (!validate()) {
            return;
        }

        setIsLoading(true);

        const promises: Promise[] = [];

        data.forEach((item) => {
            promises.push(
                atlasService.createUser({
                    exchange: item.exchange,
                    comment: item.comment.trim(),
                }),
            );
        });

        const _createdExchanges: AtlasUser[] = [];
        const _creationErrors: CreationError[] = [];

        Promise.allSettled(promises).then((results) => {
            setIsLoading(false);
            results.forEach((result, index) => {
                if (result.status === 'fulfilled') {
                    _createdExchanges.push({
                        ...data[index],
                        ...result.value[0],
                    });
                } else if (result.status === 'rejected') {
                    const reason =
                        result.reason && typeof result.reason === 'string'
                            ? result.reason
                            : '';
                    _creationErrors.push({...data[index], reason: reason});
                }
            });
            console.debug(
                'AddTransportExchanges :: submitHandler, success:',
                _createdExchanges,
                ', errors: ',
                _creationErrors,
            );
            setCreatedExchanges(_createdExchanges);
            setCreationErrors(_creationErrors);
            setShowSummaryModal(true);
            fetchAtlasUsers();
        });
    };

    const handleInputChange = (e, exchange_id: string) => {
        if (!exchange_id) return;
        const index = data.findIndex((a) => a.id === exchange_id);
        const _data = [...data];
        const {name, value} = e.target;
        _data[index][name] = value;
        updateExchangeOptions(_data);
        setData(_data);
    };

    const Body = () => {
        return (
            <div>
                <ReactTooltip />
                {data &&
                    data.map((item) => {
                        return (
                            <div key={item.id} className="group">
                                {exchangeOptions && (
                                    <SelectField
                                        id="exchange"
                                        name="exchange"
                                        label={t('EXCHANGE_NAME')}
                                        value={item.exchange}
                                        required={true}
                                        hasError={dataErrors.includes(
                                            `${item.id}_exchange`,
                                        )}
                                        onChange={(e) =>
                                            handleInputChange(e, item.id)
                                        }
                                        options={() => {
                                            return [
                                                ...exchangeOptions,
                                                {value: '', label: ''},
                                            ].map((option) => {
                                                return (
                                                    <option
                                                        key={`exchange_option_${option.value}`}
                                                        value={option.value}
                                                        disabled={
                                                            option.disabled
                                                        }
                                                    >
                                                        {option.label}
                                                    </option>
                                                );
                                            });
                                        }}
                                    />
                                )}
                                <TextField
                                    id="comment"
                                    name="comment"
                                    value={item.comment}
                                    onChange={(e) =>
                                        handleInputChange(e, item.id)
                                    }
                                    label={t('common:COMMENT')}
                                />
                                <div className="buttons">
                                    {data.length > 1 && (
                                        <div className="delete_exchange">
                                            <button
                                                className="button red icon"
                                                onClick={() =>
                                                    deleteExchangeCandidate(
                                                        item,
                                                    )
                                                }
                                                data-tip={t('DELETE_EXCHANGE')}
                                            >
                                                <IconDelete />
                                            </button>
                                        </div>
                                    )}
                                    {data.length <
                                        transportExchanges.length && (
                                        <button
                                            className="button icon"
                                            type="button"
                                            onClick={createExchangeCandidate}
                                            data-tip={t('ADD_EXCHANGE')}
                                        >
                                            <IconPlus />
                                        </button>
                                    )}
                                </div>
                            </div>
                        );
                    })}
            </div>
        );
    };

    const Footer = () => {
        return (
            <div className="group">
                <button className="button cancel" onClick={onClose}>
                    {t('common:CANCEL')}
                </button>
                <button
                    className="button save"
                    onClick={submitHandler}
                    disabled={isLoading}
                >
                    {t('common:CONFIRM')}
                </button>
            </div>
        );
    };

    return (
        <>
            {!showSummaryModal && (
                <RightPane
                    id="add-transport-exchanges"
                    className="panel-right-form panel-right-entity-details"
                    title={t('ADD_EXCHANGES')}
                    onComponentHidden={onClose}
                    body={isLoading ? () => <OverlayLoader /> : Body}
                    footer={Footer}
                />
            )}
            {showSummaryModal &&
                createdExchanges !== null &&
                creationErrors !== null && (
                    <SummaryModal
                        createdExchanges={createdExchanges}
                        creationErrors={creationErrors}
                        onClose={() => {
                            setShowSummaryModal(false);
                            onClose();
                        }}
                    />
                )}
        </>
    );
};

export default AddTransportExchanges;
