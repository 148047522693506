export const countriesEurope = [
    {value: '', name: 'Brak'},
    {value: 'AD', name: 'Andorra'},
    {value: 'AL', name: 'Albania'},
    {value: 'AM', name: 'Armenia'},
    {value: 'AT', name: 'Austria'},
    {value: 'BA', name: 'Bosnia and Herzegovina'},
    {value: 'BE', name: 'Belgium'},
    {value: 'BG', name: 'Bulgaria'},
    {value: 'BY', name: 'Belarus'},
    {value: 'CH', name: 'Switzerland'},
    {value: 'CY', name: 'Cyprus'},
    {value: 'CZ', name: 'Czech Republic'},
    {value: 'DE', name: 'Germany'},
    {value: 'DK', name: 'Denmark'},
    {value: 'EE', name: 'Estonia'},
    {value: 'ES', name: 'Spain'},
    {value: 'FI', name: 'Finland'},
    {value: 'FR', name: 'France'},
    {value: 'GB', name: 'United Kingdom'},
    {value: 'GE', name: 'Georgia'},
    {value: 'GI', name: 'Gibraltar'},
    {value: 'GR', name: 'Greece'},
    {value: 'HR', name: 'Croatia'},
    {value: 'HU', name: 'Hungary'},
    {value: 'IE', name: 'Ireland'},
    {value: 'IS', name: 'Iceland'},
    {value: 'IT', name: 'Italy'},
    {value: 'LI', name: 'Liechtenstein'},
    {value: 'LT', name: 'Lithuania'},
    {value: 'LU', name: 'Luxembourg'},
    {value: 'LV', name: 'Latvia'},
    {value: 'MC', name: 'Monaco'},
    {value: 'MD', name: 'Moldova'},
    {value: 'MT', name: 'Malta'},
    {value: 'NL', name: 'Netherlands'},
    {value: 'NO', name: 'Norway'},
    {value: 'PL', name: 'Poland'},
    {value: 'PT', name: 'Portugal'},
    {value: 'RO', name: 'Romania'},
    {value: 'RS', name: 'Serbia'},
    {value: 'RU', name: 'Russian Federation'},
    {value: 'SE', name: 'Sweden'},
    {value: 'SI', name: 'Slovenia'},
    {value: 'SK', name: 'Slovakia'},
    {value: 'SM', name: 'San Marino'},
    {value: 'TR', name: 'Turkey'},
    {value: 'UA', name: 'Ukraine'},
    {value: 'VA', name: 'Holy See (Vatican City State)'},
];

/*let countiesAll = [
    {value: "AD", name: "Andorra"},
    {value: "AE", name: "United Arab Emirates"},
    {value: "AF", name: "Afghanistan"},
    {value: "AG", name: "Antigua and Barbuda"},
    {value: "AI", name: "Anguilla"},
    {value: "AL", name: "Albania"},
    {value: "AM", name: "Armenia"},
    {value: "AN", name: "Netherlands Antilles"},
    {value: "AO", name: "Angola"},
    {value: "AQ", name: "Antarctica"},
    {value: "AR", name: "Argentina"},
    {value: "AS", name: "American Samoa"},
    {value: "AT", name: "Austria"},
    {value: "AU", name: "Australia"},
    {value: "AW", name: "Aruba"},
    {value: "AX", name: "Åland Islands"},
    {value: "AZ", name: "Azerbaijan"},
    {value: "BA", name: "Bosnia and Herzegovina"},
    {value: "BB", name: "Barbados"},
    {value: "BD", name: "Bangladesh"},
    {value: "BE", name: "Belgium"},
    {value: "BF", name: "Burkina Faso"},
    {value: "BG", name: "Bulgaria"},
    {value: "BH", name: "Bahrain"},
    {value: "BI", name: "Burundi"},
    {value: "BJ", name: "Benin"},
    {value: "BL", name: "Saint Barthélemy"},
    {value: "BM", name: "Bermuda"},
    {value: "BN", name: "Brunei Darussalam"},
    {value: "BO", name: "Bolivia, Plurinational State of"},
    {value: "BQ", name: "Caribbean Netherlands"},
    {value: "BR", name: "Brazil"},
    {value: "BS", name: "Bahamas"},
    {value: "BT", name: "Bhutan"},
    {value: "BV", name: "Bouvet Island"},
    {value: "BW", name: "Botswana"},
    {value: "BY", name: "Belarus"},
    {value: "BZ", name: "Belize"},
    {value: "CA", name: "Canada"},
    {value: "CC", name: "Cocos (Keeling) Islands"},
    {value: "CD", name: "Congo, the Democratic Republic of the"},
    {value: "CF", name: "Central African Republic"},
    {value: "CG", name: "Congo"},
    {value: "CH", name: "Switzerland"},
    {value: "CI", name: "Côte d'Ivoire"},
    {value: "CK", name: "Cook Islands"},
    {value: "CL", name: "Chile"},
    {value: "CM", name: "Cameroon"},
    {value: "CN", name: "China"},
    {value: "CO", name: "Colombia"},
    {value: "CR", name: "Costa Rica"},
    {value: "CU", name: "Cuba"},
    {value: "CV", name: "Cape Verde"},
    {value: "CW", name: "Curaçao"},
    {value: "CX", name: "Christmas Island"},
    {value: "CY", name: "Cyprus"},
    {value: "CZ", name: "Czech Republic"},
    {value: "DE", name: "Germany"},
    {value: "DJ", name: "Djibouti"},
    {value: "DK", name: "Denmark"},
    {value: "DM", name: "Dominica"},
    {value: "DO", name: "Dominican Republic"},
    {value: "DZ", name: "Algeria"},
    {value: "EC", name: "Ecuador"},
    {value: "EE", name: "Estonia"},
    {value: "EG", name: "Egypt"},
    {value: "EH", name: "Western Sahara"},
    {value: "ER", name: "Eritrea"},
    {value: "ES", name: "Spain"},
    {value: "ET", name: "Ethiopia"},
    {value: "EU", name: "Europe"},
    {value: "FI", name: "Finland"},
    {value: "FJ", name: "Fiji"},
    {value: "FK", name: "Falkland Islands (Malvinas)"},
    {value: "FM", name: "Micronesia, Federated States of"},
    {value: "FO", name: "Faroe Islands"},
    {value: "FR", name: "France"},
    {value: "GA", name: "Gabon"},
    {value: "GB-NG", name: "England"},
    {value: "GB-IR", name: "Northern Ireland"},
    {value: "GB-CT", name: "Scotland"},
    {value: "GB-LS", name: "Wales"},
    {value: "GB", name: "United Kingdom"},
    {value: "GD", name: "Grenada"},
    {value: "GE", name: "Georgia"},
    {value: "GF", name: "French Guiana"},
    {value: "GG", name: "Guernsey"},
    {value: "GH", name: "Ghana"},
    {value: "GI", name: "Gibraltar"},
    {value: "GL", name: "Greenland"},
    {value: "GM", name: "Gambia"},
    {value: "GN", name: "Guinea"},
    {value: "GP", name: "Guadeloupe"},
    {value: "GQ", name: "Equatorial Guinea"},
    {value: "GR", name: "Greece"},
    {value: "GS", name: "South Georgia and the South Sandwich Islands"},
    {value: "GT", name: "Guatemala"},
    {value: "GU", name: "Guam"},
    {value: "GW", name: "Guinea-Bissau"},
    {value: "GY", name: "Guyana"},
    {value: "HK", name: "Hong Kong"},
    {value: "HM", name: "Heard Island and McDonald Islands"},
    {value: "HN", name: "Honduras"},
    {value: "HR", name: "Croatia"},
    {value: "HT", name: "Haiti"},
    {value: "HU", name: "Hungary"},
    {value: "ID", name: "Indonesia"},
    {value: "IE", name: "Ireland"},
    {value: "IL", name: "Israel"},
    {value: "IM", name: "Isle of Man"},
    {value: "IN", name: "India"},
    {value: "IO", name: "British Indian Ocean Territory"},
    {value: "IQ", name: "Iraq"},
    {value: "IR", name: "Iran, Islamic Republic of"},
    {value: "IS", name: "Iceland"},
    {value: "IT", name: "Italy"},
    {value: "JE", name: "Jersey"},
    {value: "JM", name: "Jamaica"},
    {value: "JO", name: "Jordan"},
    {value: "JP", name: "Japan"},
    {value: "KE", name: "Kenya"},
    {value: "KG", name: "Kyrgyzstan"},
    {value: "KH", name: "Cambodia"},
    {value: "KI", name: "Kiribati"},
    {value: "KM", name: "Comoros"},
    {value: "KN", name: "Saint Kitts and Nevis"},
    {value: "KP", name: "Korea, Democratic People's Republic of"},
    {value: "KR", name: "Korea, Republic of"},
    {value: "KW", name: "Kuwait"},
    {value: "KY", name: "Cayman Islands"},
    {value: "KZ", name: "Kazakhstan"},
    {value: "LA", name: "Lao People's Democratic Republic"},
    {value: "LB", name: "Lebanon"},
    {value: "LC", name: "Saint Lucia"},
    {value: "LI", name: "Liechtenstein"},
    {value: "LK", name: "Sri Lanka"},
    {value: "LR", name: "Liberia"},
    {value: "LS", name: "Lesotho"},
    {value: "LT", name: "Lithuania"},
    {value: "LU", name: "Luxembourg"},
    {value: "LV", name: "Latvia"},
    {value: "LY", name: "Libya"},
    {value: "MA", name: "Morocco"},
    {value: "MC", name: "Monaco"},
    {value: "MD", name: "Moldova, Republic of"},
    {value: "ME", name: "Montenegro"},
    {value: "MF", name: "Saint Martin"},
    {value: "MG", name: "Madagascar"},
    {value: "MH", name: "Marshall Islands"},
    {value: "MK", name: "Macedonia, the former Yugoslav Republic of"},
    {value: "ML", name: "Mali"},
    {value: "MM", name: "Myanmar"},
    {value: "MN", name: "Mongolia"},
    {value: "MO", name: "Macao"},
    {value: "MP", name: "Northern Mariana Islands"},
    {value: "MQ", name: "Martinique"},
    {value: "MR", name: "Mauritania"},
    {value: "MS", name: "Montserrat"},
    {value: "MT", name: "Malta"},
    {value: "MU", name: "Mauritius"},
    {value: "MV", name: "Maldives"},
    {value: "MW", name: "Malawi"},
    {value: "MX", name: "Mexico"},
    {value: "MY", name: "Malaysia"},
    {value: "MZ", name: "Mozambique"},
    {value: "NA", name: "Namibia"},
    {value: "NC", name: "New Caledonia"},
    {value: "NE", name: "Niger"},
    {value: "NF", name: "Norfolk Island"},
    {value: "NG", name: "Nigeria"},
    {value: "NI", name: "Nicaragua"},
    {value: "NL", name: "Netherlands"},
    {value: "NO", name: "Norway"},
    {value: "NP", name: "Nepal"},
    {value: "NR", name: "Nauru"},
    {value: "NU", name: "Niue"},
    {value: "NZ", name: "New Zealand"},
    {value: "OM", name: "Oman"},
    {value: "PA", name: "Panama"},
    {value: "PE", name: "Peru"},
    {value: "PF", name: "French Polynesia"},
    {value: "PG", name: "Papua New Guinea"},
    {value: "PH", name: "Philippines"},
    {value: "PK", name: "Pakistan"},
    {value: "PL", name: "Poland"},
    {value: "PM", name: "Saint Pierre and Miquelon"},
    {value: "PN", name: "Pitcairn"},
    {value: "PR", name: "Puerto Rico"},
    {value: "PS", name: "Palestine"},
    {value: "PT", name: "Portugal"},
    {value: "PW", name: "Palau"},
    {value: "PY", name: "Paraguay"},
    {value: "QA", name: "Qatar"},
    {value: "RE", name: "Réunion"},
    {value: "RO", name: "Romania"},
    {value: "RS", name: "Serbia"},
    {value: "RU", name: "Russian Federation"},
    {value: "RW", name: "Rwanda"},
    {value: "SA", name: "Saudi Arabia"},
    {value: "SB", name: "Solomon Islands"},
    {value: "SC", name: "Seychelles"},
    {value: "SD", name: "Sudan"},
    {value: "SE", name: "Sweden"},
    {value: "SG", name: "Singapore"},
    {value: "SH", name: "Saint Helena, Ascension and Tristan da Cunha"},
    {value: "SI", name: "Slovenia"},
    {value: "SJ", name: "Svalbard and Jan Mayen Islands"},
    {value: "SK", name: "Slovakia"},
    {value: "SL", name: "Sierra Leone"},
    {value: "SM", name: "San Marino"},
    {value: "SN", name: "Senegal"},
    {value: "SO", name: "Somalia"},
    {value: "SR", name: "Suriname"},
    {value: "SS", name: "South Sudan"},
    {value: "ST", name: "Sao Tome and Principe"},
    {value: "SV", name: "El Salvador"},
    {value: "SX", name: "Sint Maarten (Dutch part)"},
    {value: "SY", name: "Syrian Arab Republic"},
    {value: "SZ", name: "Swaziland"},
    {value: "TC", name: "Turks and Caicos Islands"},
    {value: "TD", name: "Chad"},
    {value: "TF", name: "French Southern Territories"},
    {value: "TG", name: "Togo"},
    {value: "TH", name: "Thailand"},
    {value: "TJ", name: "Tajikistan"},
    {value: "TK", name: "Tokelau"},
    {value: "TL", name: "Timor-Leste"},
    {value: "TM", name: "Turkmenistan"},
    {value: "TN", name: "Tunisia"},
    {value: "TO", name: "Tonga"},
    {value: "TR", name: "Turkey"},
    {value: "TT", name: "Trinidad and Tobago"},
    {value: "TV", name: "Tuvalu"},
    {value: "TW", name: "Taiwan"},
    {value: "TZ", name: "Tanzania, United Republic of"},
    {value: "UA", name: "Ukraine"},
    {value: "UG", name: "Uganda"},
    {value: "UM", name: "US Minor Outlying Islands"},
    {value: "US", name: "United States"},
    {value: "UY", name: "Uruguay"},
    {value: "UZ", name: "Uzbekistan"},
    {value: "VA", name: "Holy See (Vatican City State)"},
    {value: "VC", name: "Saint Vincent and the Grenadines"},
    {value: "VE", name: "Venezuela, Bolivarian Republic of"},
    {value: "VG", name: "Virgin Islands, British"},
    {value: "VI", name: "Virgin Islands, U.S."},
    {value: "VN", name: "Viet Nam"},
    {value: "VU", name: "Vanuatu"},
    {value: "WF", name: "Wallis and Futuna Islands"},
    {value: "XK", name: "Kosovo"},
    {value: "WS", name: "Samoa"},
    {value: "YE", name: "Yemen"},
    {value: "YT", name: "Mayotte"},
    {value: "ZA", name: "South Africa"},
    {value: "ZM", name: "Zambia"},
    {value: "ZW", name: "Zimbabwe"}
];*/
