export const SET_NEW_POI_DATA = 'SET_NEW_POI_DATA';
export const SET_NEW_POI_LAT_LNG = 'SET_NEW_POI_LAT_LNG';
export const SET_NEW_POI_DATA_EXTRA = 'SET_NEW_POI_DATA_EXTRA';
export const SET_NEW_POI_INITIAL_STATE = 'SET_NEW_POI_INITIAL_STATE';
export const SET_NEW_POI_DATA_FROM_EXISTED = 'SET_NEW_POI_DATA_FROM_EXISTED';

export function setNewPoiData(value: any, field: string) {
    return {
        type: SET_NEW_POI_DATA,
        value,
        field,
    };
}
export function setNewPoiLatLngData(lat, lng) {
    return {
        type: SET_NEW_POI_LAT_LNG,
        lat,
        lng,
    };
}
export function setNewPoiDataExtra(value: any, field: string) {
    return {
        type: SET_NEW_POI_DATA_EXTRA,
        value,
        field,
    };
}
export function setNewPoiDataFromExisted(data) {
    return {
        type: SET_NEW_POI_DATA_FROM_EXISTED,
        data,
    };
}

export function setNewPoiInitialState() {
    return {
        type: SET_NEW_POI_INITIAL_STATE,
    };
}
