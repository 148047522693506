export const poiIcons = [
    'iko_poi_bus.png',
    'iko_poi_bus_dark.png',
    'iko_poi_business.png',
    'iko_poi_business_dark.png',
    'iko_poi_car.png',
    'iko_poi_car_dark.png',
    'iko_poi_car_wash.png',
    'iko_poi_car_wash_dark.png',
    'iko_poi_factory.png',
    'iko_poi_factory_dark.png',
    'iko_poi_gas_station.png',
    'iko_poi_gas_station_dark.png',
    'iko_poi_house.png',
    'iko_poi_house_dark.png',
    'iko_poi_money.png',
    'iko_poi_money_dark.png',
    'iko_poi_parking.png',
    'iko_poi_parking_dark.png',
    'iko_poi_point.png',
    'iko_poi_point_dark.png',
    'iko_poi_restaurant.png',
    'iko_poi_restaurant_dark.png',
    'iko_poi_shop.png',
    'iko_poi_shop_dark.png',
    'iko_poi_workshop.png',
    'iko_poi_workshop_dark.png',
    'iko_poi_bus_stop_dark.png',
    'iko_poi_church_dark.png',
    'iko_poi_hospital_dark.png',
    'iko_poi_hotel_dark.png',
    'iko_poi_office_dark.png',
    'iko_poi_radar_dark.png',
    'iko_poi_road_check_1_dark.png',
    'iko_poi_tourist_cam_dark.png',
];
