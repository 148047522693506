import moment from 'moment';
import {convertSecondsToDurationString} from '../date';
import {
    createReportHeader,
    DATE_CELL_WIDTH,
    DISTANCE_CELL_WIDTH,
    DURATION_CELL_WIDTH,
    getSheet,
    PLACE_CELL_WIDTH,
} from './reportsXLSXUtils';
import {darkRowStyle, fieldStyle, lightRowStyle} from './sheetStyles';
import {FULL_DATE_WITHOUT_SECONDS_FORMAT} from '../constants';
import type {DrivingReportData} from '../interfaces/report';

export function drivingReportSheet(workbook, t, reportData: DrivingReportData) {
    if (!reportData.multiple) {
        reportData.vehicles = [
            {rows: reportData.rows, vehicle_name: reportData.vehicle_name},
        ];
    }
    reportData.vehicles.forEach((vehicle) => {
        let sheet = getSheet(workbook, vehicle.vehicle_name);
        createReportHeader(
            sheet,
            t,
            t('Reports:DRIVING_REPORT_HEADER', {
                vehicleName: vehicle.vehicle_name,
            }),
            reportData.days,
            reportData.date_start,
            reportData.date_end,
            reportData.fields,
        );
        sheet.cell('A2').value(t(reportData.fields[2])).style(fieldStyle);
        sheet.cell('B2').value(t(reportData.fields[3])).style(fieldStyle);
        sheet.cell('C2').value(t(reportData.fields[0])).style(fieldStyle);
        sheet.cell('D2').value(t(reportData.fields[1])).style(fieldStyle);
        sheet.cell('E2').value(t(reportData.fields[4])).style(fieldStyle);
        sheet.cell('F2').value(t('business_drive_time')).style(fieldStyle);
        vehicle.rows.forEach((row, index) => {
            const data = row.slice();
            const rowType = data.shift();
            const style =
                rowType !== 'row'
                    ? fieldStyle
                    : index % 2
                      ? lightRowStyle
                      : darkRowStyle;
            sheet
                .cell('A' + (index + 3))
                .value(
                    moment
                        .unix(data[2])
                        .format(FULL_DATE_WITHOUT_SECONDS_FORMAT),
                )
                .style(style);
            sheet
                .cell('B' + (index + 3))
                .value(
                    moment
                        .unix(data[3])
                        .format(FULL_DATE_WITHOUT_SECONDS_FORMAT),
                )
                .style(style);
            if (rowType !== 'row') {
                // merge columns C & D in this row
                sheet
                    .range('C' + (index + 3) + ':D' + (index + 3))
                    .value(t(rowType))
                    .merged(true)
                    .style(style);
            } else {
                sheet
                    .cell('C' + (index + 3))
                    .value(data[0])
                    .style(style);
                sheet
                    .cell('D' + (index + 3))
                    .value(data[1])
                    .style(style);
            }
            sheet
                .cell('E' + (index + 3))
                .value(data[4].toFixed(2))
                .style(style);
            sheet
                .cell('F' + (index + 3))
                .value(convertSecondsToDurationString(data[5], true))
                .style(style);
        });
        sheet.column('A').width(DATE_CELL_WIDTH);
        sheet.column('B').width(DATE_CELL_WIDTH);
        sheet.column('C').width(PLACE_CELL_WIDTH);
        sheet.column('D').width(PLACE_CELL_WIDTH);
        sheet.column('E').width(DISTANCE_CELL_WIDTH);
        sheet.column('F').width(DURATION_CELL_WIDTH);
    });
}
