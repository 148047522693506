import React from 'react';
import moment from 'moment';
import {useTranslation} from 'react-i18next';

import i18n from '../../i18n/i18n';
import {createTemperaturesArray} from '../../utils/temperatureHelpers';
import {
    convertISOToUnixTimeStamp,
    getDaysFromNowToDate,
} from '../../utils/date';
import {
    IconVehicleDrive,
    IconVehicleParking,
    IconWarning,
} from '../../graphics/icons';
import {FULL_DATE_FORMAT} from '../../utils/constants';
import {
    getEtollStateColor,
    getEtollStateTooltipText,
} from '../../utils/vehicle';
import {getVehicleDriverOrIdentifier} from '../../utils/driver';

import './DeviceListItemStyles.scss';

/**
 *
 * @param {Device} device
 * @param {Vehicle} vehicle
 * @param selectVehicle
 * @param {boolean} vehicleSelected
 * @returns {JSX.Element|null}
 * @constructor
 */
export default function DeviceListItem({
    device,
    vehicle,
    selectVehicle,
    vehicleSelected,
}) {
    const vehicleDriverOrIdentifier = getVehicleDriverOrIdentifier(vehicle);

    function handleClick(e) {
        e.stopPropagation();
        selectVehicle(vehicle.vehicle_id);
    }

    function getIgnitionImage() {
        if (device?.iodata?.ignition) {
            return vehicleSelected
                ? require('../../graphics/iko_key_dark.png').default
                : require('../../graphics/iko_key_white.png').default;
        } else {
            return false;
        }
    }

    function getVehicleDriverImage() {
        if (vehicleSelected)
            return require('../../graphics/iko_user_dark.png').default;
        else return require('../../graphics/iko_user_white.png').default;
    }

    return device && vehicle ? (
        <div
            id={'device-list-item-' + device.id}
            className={'device-list-item'}
            onClick={(event) => handleClick(event)}
        >
            <div
                className={
                    'device-list-item-content ' +
                    (vehicleSelected && 'selected')
                }
            >
                <div className={'device-list-item-row'}>
                    {device?.iodata?.ignition ? (
                        <IconVehicleDrive />
                    ) : (
                        <IconVehicleParking />
                    )}
                    <div
                        className={
                            'device-name ' + (vehicleSelected && 'selected')
                        }
                    >
                        {vehicle.name}
                    </div>
                    {!!device.iodata?.ignition && (
                        <img
                            className={'device-ignition-image'}
                            alt=""
                            src={getIgnitionImage()}
                        />
                    )}
                </div>
                <div
                    className={
                        'localization ' + (vehicleSelected && 'selected')
                    }
                >
                    {device?.address}
                </div>
                {device.last_update && (
                    <div
                        className={
                            'reading-time ' + (vehicleSelected && 'selected')
                        }
                    >
                        {moment
                            .unix(device.last_update)
                            .format(FULL_DATE_FORMAT)}
                    </div>
                )}
                {device.iodata ? (
                    <TemperatureRow
                        key={'device_temperature_row_' + device.id}
                        iodata={device.iodata}
                        vehicleSelected={vehicleSelected}
                    />
                ) : null}
                {vehicleDriverOrIdentifier ? (
                    <div
                        className={
                            'vehicle-driver ' + (vehicleSelected && 'selected')
                        }
                    >
                        <img src={getVehicleDriverImage()} alt="" />
                        {vehicleDriverOrIdentifier}
                    </div>
                ) : null}
                {vehicle.puesc_register_state === 'registered' &&
                    vehicle.puesc_etoll_active && (
                        <PuescIntegrationRow vehicle={vehicle} />
                    )}
                <LicenseInfoRow
                    key={'license_info_' + vehicle.vehicle_id}
                    device={device}
                    vehicleSelected={vehicleSelected}
                />
            </div>
        </div>
    ) : null;
}

/**
 *
 * @param iodata {IOData}
 * @param vehicleSelected {boolean}
 * @returns {JSX.Element|null}
 * @constructor
 */
function TemperatureRow({iodata, vehicleSelected}) {
    let temperatures = createTemperaturesArray(iodata);
    if (temperatures.length === 0) {
        return null;
    }
    return (
        <div className={`temperatures ${vehicleSelected ? 'selected' : ''}`}>
            {temperatures.map((thermometer, index) => {
                if (thermometer.value) {
                    const tip = i18n.t('THERMOMETER_NO_TYPE', {
                        type: thermometer.type,
                        numberOfThermometer: index + 1,
                    });
                    return (
                        <div
                            data-tip={tip}
                            id={
                                'temperature_row_' +
                                thermometer.type +
                                '_' +
                                index
                            }
                            key={
                                'temperature_row_' +
                                thermometer.type +
                                '_' +
                                index
                            }
                            data-for="device-parameters-tooltip"
                            className={'temperature-item'}
                        >
                            {thermometer.value}
                        </div>
                    );
                }
                return null;
            })}
        </div>
    );
}

/**
 *
 * @param device {Device}
 * @param vehicleSelected {boolean}
 * @returns {JSX.Element|null}
 * @constructor
 */
const LicenseInfoRow = ({device, vehicleSelected}) => {
    const contractEndTs =
        typeof device?.contract_until === 'string'
            ? convertISOToUnixTimeStamp(device.contract_until)
            : null;

    if (!contractEndTs) {
        return null;
    }

    const days = getDaysFromNowToDate(contractEndTs);

    return (
        <>
            {days >= 0 && days <= 30 && contractEndTs > moment().unix() && (
                <span
                    className={
                        'license-warning' + (vehicleSelected ? ' selected' : '')
                    }
                >
                    <IconWarning color="yellow" />
                    <p>{i18n.t('LICENSE_EXPIRE_IN_DAYS', {days: days})}</p>
                </span>
            )}
            {contractEndTs < moment().unix() && (
                <span
                    className={
                        'license-warning' + (vehicleSelected ? ' selected' : '')
                    }
                >
                    <IconWarning color="red" />
                    <p>{i18n.t('LICENSE_EXPIRED')}</p>
                </span>
            )}
        </>
    );
};

/**
 *
 * @param vehicle {Vehicle}
 * @returns {Element}
 * @constructor
 */
const PuescIntegrationRow = ({vehicle}) => {
    const {t} = useTranslation('Vehicles');
    const color = getEtollStateColor(vehicle);
    const tooltipText = getEtollStateTooltipText(vehicle);
    return (
        <div className="puesc-integration-row">
            <div
                className="puesc-integration-item"
                style={{backgroundColor: color}}
                data-tip={t(tooltipText)}
            >
                e-TOLL
            </div>
        </div>
    );
};
