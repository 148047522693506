import moment from 'moment';
import i18n from '../i18n/i18n';
import {store} from '../redux/store/store';
import {
    addNotification,
    addNotifications,
    addReadNotifications,
    addUnreadNotifications,
    increaseTotalNotificationCount,
    increaseUnreadNotificationCount,
    markNotificationsRead,
    setTotalNotificationCount,
    setUnreadNotificationCount,
} from '../redux/actions/notificationActions';
import Connection from '../Connection';
import {FULL_DATE_FORMAT} from '../utils/constants';
import type {FMNotificationListEntry} from '../utils/interfaces/notifications';
import {toast} from 'react-toastify';

export default class NotificationService {
    constructor(connection: Connection) {
        // noinspection JSIgnoredPromiseFromCall
        i18n.loadNamespaces('NotificationTypes');

        this.connection = connection;
        this.connection.addHandler('notification', (notification) => {
            console.debug(
                'NotificationService::[notification] => state: %O, notification',
                store.getState().notificationList,
                notification,
            );
            store.dispatch(
                addNotification(
                    this.buildNotificationListEntry(notification.data),
                ),
            );
            if (notification.data.read === false) {
                store.dispatch(increaseUnreadNotificationCount());
                store.dispatch(increaseTotalNotificationCount());
            }

            if (window.Notification && Notification.permission === 'granted') {
                let n = new Notification('Fleet Manager', {
                    tag: 'FM_WEB_NEW_NOTIFICATION',
                    icon: process.env.PUBLIC_URL + '/graphics/fm_icon.png',
                    body: i18n.t('NEW_NOTIFICATIONS'),
                    requireInteraction: true,
                    renotify: true,
                });
                n.addEventListener('click', (e) => {
                    console.debug(
                        'OSNotification::[click] => System notification clicked!',
                        e,
                    );
                    e.target.close();
                });
            }
        });
        this.connection.addHandler('puesc_warning', (notification) => {
            this.showNotification(notification);

        });
        this.connection.addHandler('notification_read', (event) => {
            console.debug(
                'NotificationService::[notification_read] => event: %O',
                event,
            );
            let notificationIds = event.data.ids;
            store.dispatch(markNotificationsRead(notificationIds));
            this.getNotificationCount(false);
        });
    }

    showNotification(notification) {
        return toast.warn(i18n.t('NotificationTypes:puesc_warning_notification', {device_name: notification.data.vehicle_name}));
    }

    buildNotificationListEntry(n) {
        const shouldStartEnd = [
            'order_corridor',
            'order_theft',
            'order_arrival',
        ];
        const r = {
            original: n,
            id: n.id,
            occurrence: n.content.occured_at ?? n.created,
            driver: n.content.driver_name ?? '',
            vehicle: n.content.vehicle_name ?? n.content.name ?? '',
            read: n.read,
            type:
                n.type +
                (n.content.data?.alarmType
                    ? '_' + n.content.data.alarmType
                    : '') +
                (n.content.alarmType ? '_' + n.content.alarmType : '') +
                (shouldStartEnd.includes(n.content.alarmType)
                    ? n.content.has_started
                        ? '_start'
                        : '_end'
                    : ''),
            count: parseInt(n.content.data?.threshold) ?? null,
            description: n.type === 'custom_fcm' && n.content.body ? n.content.body : null,
        };
        if (n.type === 'grant_access') {
            if (n.content.access_begin && n.content.access_end) {
                r.begin = moment(n.content.access_begin * 1000).format(
                    FULL_DATE_FORMAT,
                );
                r.end = moment(n.content.access_end * 1000).format(
                    FULL_DATE_FORMAT,
                );
            } else if (n.content.access_begin && !n.content.access_end) {
                r.begin = moment(n.content.access_begin * 1000).format(
                    FULL_DATE_FORMAT,
                );
                r.type = 'grant_access_FROM_BEGIN_TO_TERMLESS';
            } else {
                r.type = 'grant_access_TERMLESS';
            }
        } else if (
            n.type === 'invitation_sent' ||
            n.type === 'account_created'
        ) {
            r.user = n.content.user_email;
        } else if (n.type === 'access_expired') {
            r.vehicle = n.content.vehicle_name;
        } else if (n.type === 'device.activation') {
            const device = store
                .getState()
                .deviceList.find((device) => device.id === n.content.device_id);
            r.device_name = device ? device.serial_num : '';
        } else if (r.type === 'puesc.registration') {
            if (!n.content.ident || !n.content.pin) {
                r.type = 'puesc.registration_in_progress';
            }
        }
        r.text = i18n.t('NotificationTypes:' + r.type, r);
        if (r.type === 'custom_fcm') {
            r.text = n.content.title;
        }
        return r;
    }

    getNotifications(status = null, beforeTs = null, limit = 100) {
        const args = {limit};
        if (beforeTs !== null && beforeTs !== undefined) {
            args.created_ts = beforeTs;
        }
        if (status !== null && status !== 'ALL') {
            if (status === 'UNREAD') {
                args.read = false;
            } else if (status === 'READ') {
                args.read = true;
            }
        }
        return new Promise((resolve, reject) =>
            this.connection.query(
                'account.getNotifications',
                args,
                (result: FMNotificationListEntry[]) => {
                    if (result.length === 0) {
                        reject('No more data');
                        return;
                    }

                    let notifications = result.map(this.buildNotificationListEntry);
                    let requiredDataFeeding = notifications
                        .filter((n) => n.original.content.order_id)
                        .map((n) => n.original.content.order_id)
                        .filter((v, i, a) => a.indexOf(v) === i);

                    Promise.allSettled(
                        requiredDataFeeding.map(
                            (orderId) =>
                                new Promise((resolve1, reject1) =>
                                    this.connection.query(
                                        'orders.getOrders',
                                        {
                                            filter_order_id: orderId,
                                            default_include: false,
                                            include_name: true,
                                        },
                                        resolve1,
                                        reject1,
                                    ),
                                ),
                        ),
                    ).then((settled) => {
                        const orders = {};
                        settled
                            .filter((r) => r.status === 'fulfilled')
                            .forEach((r) => {
                                if (r.value.length === 0) {
                                    return;
                                }
                                orders[r.value[0].order_id] = r.value[0];
                            });
                        console.debug(
                            'NotificationService::getNotifications() => data gathering completed: %O',
                            orders,
                        );
                        notifications.forEach((n) => {
                            if (n.original.content.order_id) {
                                n.order =
                                    orders[n.original.content.order_id]?.name ?? '';
                            }
                        });
                        if (status === 'UNREAD') {
                            store.dispatch(addUnreadNotifications(notifications));
                        } else if (status === 'READ') {
                            store.dispatch(addReadNotifications(notifications));
                        } else {
                            console.warn('args12', notifications, status);
                            store.dispatch(addNotifications(notifications));
                        }
                        resolve();
                    });
                },
                (reason) => {
                    reject(reason);
                },
            ),
        );
    }

    markNotificationRead(notificationIds: number[]) {
        return new Promise((resolve, reject) =>
            this.connection.query(
                'account.markNotificationsRead',
                {ids: notificationIds},
                resolve,
                reject,
            ),
        );
    }

    getNotificationCount(read: boolean) {
        // return new Promise((resolve, reject) => {
        const args = {};
        if (read !== undefined) {
            args.read = read;
        }
        this.connection.query(
            'account.getNotificationCount',
            args,
            (result) => {
                console.debug(
                    'NotificationService::getNotificationCount(%s) => %O',
                    read,
                    result,
                );
                if (read === false) {
                    store.dispatch(setUnreadNotificationCount(result));
                }
                if (read === undefined) {
                    store.dispatch(setTotalNotificationCount(result));
                }
                // resolve(result);
            },
            () => {
                // reject(reason);
            },
        );
        // }
        // );
    }
}

