import type {Client} from '../../utils/interfaces/client';

export const SET_INVOICE_DATA = 'SET_INVOICE_DATA';
export const UPDATE_INVOICE_DATA = 'UPDATE_INVOICE_DATA';

export function setInvoiceData(invoiceData: Client) {
    return {
        type: SET_INVOICE_DATA,
        invoiceData,
    };
}

export function updateInvoiceData(invoiceData: Client) {
    return {
        type: UPDATE_INVOICE_DATA,
        invoiceData,
    };
}
