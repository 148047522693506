import moment from 'moment';
import {createReportHeader, getSheet} from './reportsXLSXUtils';
import {darkRowStyle, lightRowStyle} from './sheetStyles';
import {FULL_DATE_WITHOUT_SECONDS_FORMAT} from '../constants';

export function RefuelingReportSheet(workbook, t, reportData) {
    if (!reportData.multiple) {
        reportData.vehicles = [
            {rows: reportData.rows, vehicle_name: reportData.vehicle_name},
        ];
    }

    reportData.vehicles.forEach((vehicle) => {
        let sheet = getSheet(workbook, vehicle.vehicle_name);

        createReportHeader(
            sheet,
            t,
            t('Reports:REFUELING_REPORT_HEADER', {
                vehicleName: vehicle.vehicle_name,
            }),
            reportData.days,
            reportData.date_start,
            reportData.date_end,
            reportData.fields,
        );
        vehicle.rows.forEach((row, index) => {
            let rowRelativeCell = sheet.cell('A' + (index + 3));
            row.forEach((value, index) => {
                if (index === 0) {
                    rowRelativeCell
                        .value(t('Reports:' + value))
                        .style(
                            rowRelativeCell.rowNumber() % 2 === 0
                                ? darkRowStyle
                                : lightRowStyle,
                        );
                } else if (index === 1) {
                    rowRelativeCell
                        .value(
                            moment
                                .unix(value)
                                .format(FULL_DATE_WITHOUT_SECONDS_FORMAT),
                        )
                        .style(
                            rowRelativeCell.rowNumber() % 2 === 0
                                ? darkRowStyle
                                : lightRowStyle,
                        );
                } else {
                    rowRelativeCell
                        .value(value)
                        .style(
                            rowRelativeCell.rowNumber() % 2 === 0
                                ? darkRowStyle
                                : lightRowStyle,
                        );
                }
                rowRelativeCell = rowRelativeCell.relativeCell(0, 1);
            });
        });
        sheet.column('A').width(20);
        sheet.column('B').width(20);
        sheet.column('C').width(55);
        sheet.column('D').width(20);
        sheet.column('E').width(20);
        sheet.column('F').width(20);
        sheet.column('G').width(20);
        sheet.column('H').width(20);
        sheet.column('I').width(20);
    });
}
