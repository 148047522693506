import {
    ADD_IDENTIFIER,
    SET_IDENTIFIER_LIST,
    UPDATE_IDENTIFIER,
} from '../actions/identifierActions';
import type {Identifier} from '../../utils/interfaces/driver';

/*
 * state === null means that store is not initialized
 * no action can be made unless store is initialized...
 */

const initialState: ?(Identifier[]) = null;
export default function identifierList(state = initialState, action) {
    switch (action.type) {
        case SET_IDENTIFIER_LIST:
            return action.identifiers;
        case ADD_IDENTIFIER: {
            if (state === null) {
                return state;
            }
            let i: Identifier = action.identifierData;
            let index = state.findIndex(
                (identifier: Identifier) => identifier.id === i.id,
            );
            if (index === -1) {
                return [...state, i];
            }
            return state;
        }
        case UPDATE_IDENTIFIER: {
            if (state === null) {
                return state;
            }
            let i: Identifier = action.identifierData;
            return state.map((identifier: Identifier) => {
                if (identifier.id !== i.id) {
                    return identifier;
                } else {
                    i = Object.assign({}, identifier, i);
                    return i;
                }
            });
        }
        default: {
            return state;
        }
    }
}
