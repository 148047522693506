import React, {ChangeEvent, ReactNode} from 'react';
import Select from 'react-select';
import {useTranslation} from 'react-i18next';
import type {SelectOption} from '../../utils/interfaces/interfaces';

import './MainListsHeader.scss';

interface MainListHeaderProps {
    headerText: string;
    headerIcon?: ReactNode;
    searchValue?: string;
    handleChangeSearchValue?: Function<ChangeEvent>;
    addMode?: boolean;
    editMode?: boolean;
    addText?: string;
    editText?: string;
    switchAddMode?: Function;
    switchEditMode?: Function;
    reloadData?: Function;
    selectedDataAction?: Function;
    selectedDataActionLabel?: string;
    selectedDataActionEnabled?: boolean;
    selectedDataActionTitle?: string;
    dropdownOptions?: SelectOption[];
    dropdownAction?: Function<ChangeEvent>;
    dropdownValue?: SelectOption;
    filters?: ReactNode;
    checkboxLabel?: string;
    checkboxFilter?: boolean;
    setCheckboxFilter?: Function;
}
export function MainListHeader(props: MainListHeaderProps) {
    const {
        searchValue,
        handleChangeSearchValue,
        headerText,
        headerIcon,
        addMode,
        editMode,
        addText,
        editText,
        switchAddMode,
        switchEditMode,
        reloadData,
        selectedDataAction,
        selectedDataActionLabel,
        selectedDataActionEnabled,
        selectedDataActionTitle,
        dropdownAction,
        dropdownOptions,
        dropdownValue,
        filters,
        checkboxLabel,
        checkboxFilter,
        setCheckboxFilter,
    } = props;
    const {t} = useTranslation('common');

    return (
        <div className={'main-lists-header'}>
            {selectedDataAction && selectedDataActionLabel && (
                <div className="selected-data-action">
                    <span className="indicator" />
                    <button
                        className="button basic"
                        disabled={!(selectedDataActionEnabled ?? true)}
                        title={selectedDataActionTitle}
                        onClick={(e: Event) => {
                            e.preventDefault();
                            selectedDataAction();
                        }}
                    >
                        {selectedDataActionLabel}
                    </button>
                </div>
            )}
            <div className={'header-text'}>
                {headerText}
                {headerIcon}
            </div>
            <div className={'search-and-controls'}>
                {dropdownAction && dropdownOptions && (
                    <Select
                        className="dropdown"
                        options={dropdownOptions}
                        onChange={dropdownAction}
                        value={dropdownValue}
                    />
                )}
                {filters}
                {checkboxLabel &&
                    checkboxFilter !== null &&
                    setCheckboxFilter && (
                        <label htmlFor="only-active" className="filter">
                            {checkboxLabel}
                            <input
                                type="checkbox"
                                name="filter"
                                id="filter"
                                checked={checkboxFilter}
                                onChange={() =>
                                    setCheckboxFilter((prev) => !prev)
                                }
                            />
                        </label>
                    )}
                {!addMode && !editMode && handleChangeSearchValue && (
                    <div className={'input-container'}>
                        <img
                            className={'input-image'}
                            alt="Search"
                            src={
                                require('../../graphics/iko_list_search_dark.png')
                                    .default
                            }
                        />
                        <input
                            value={searchValue}
                            className={'search'}
                            placeholder={t('SEARCH')}
                            onChange={handleChangeSearchValue}
                        />
                    </div>
                )}
                {reloadData && (
                    <img
                        alt=""
                        onClick={reloadData}
                        src={
                            require('../../graphics/iko_refresh_dark.png')
                                .default
                        }
                        style={{
                            cursor: 'pointer',
                            margin: '.3em .6em',
                            width: 20,
                        }}
                    />
                )}
                {!addMode && !editMode && switchEditMode && (
                    <button className={'button edit'} onClick={switchEditMode}>
                        {editText}
                    </button>
                )}
                {!addMode && !editMode && switchAddMode && (
                    <button className={'button action'} onClick={switchAddMode}>
                        {addText}
                    </button>
                )}
            </div>
        </div>
    );
}
export function MainOrderListHeader(props) {
    const {
        searchValue,
        headerText,
        handleChangeSearchValue,
        addMode,
        editMode,
        addText,
        editText,
        switchAddMode,
        switchEditMode,
        archiveOrder,
        archiveOrderVisible,
        archiveOrderText,
    } = props;
    return (
        <div className={'main-lists-header'}>
            {archiveOrderVisible && (
                <div className={'archive-orders'}>
                    <button className={'button basic'} onClick={archiveOrder}>
                        {archiveOrderText}
                    </button>
                </div>
            )}
            <div className={'header-text'}>{headerText}</div>
            <div className={'search-and-controls'}>
                {!addMode && !editMode && handleChangeSearchValue && (
                    <div className={'input-container'}>
                        <img
                            className={'input-image'}
                            alt="Search"
                            src={
                                require('../../graphics/iko_list_search_dark.png')
                                    .default
                            }
                        />
                        <input
                            value={searchValue}
                            className={'search'}
                            placeholder={'Szukaj'}
                            onChange={handleChangeSearchValue}
                        />
                    </div>
                )}
                {!addMode && !editMode && switchEditMode && (
                    <button className={'button edit'} onClick={switchEditMode}>
                        {editText}
                    </button>
                )}
                {!addMode && !editMode && switchAddMode && (
                    <button className={'button action'} onClick={switchAddMode}>
                        {addText}
                    </button>
                )}
            </div>
        </div>
    );
}

export function HeaderWithSearch(props) {
    const {headerText, searchValue, handleChangeSearchValue} = props;
    return (
        <div className={'main-lists-header'}>
            <div className={'header-text'}>{headerText}</div>
            <div className={'search-and-controls'}>
                <div className={'input-container'}>
                    <img
                        className={'input-image'}
                        alt="Search"
                        src={
                            require('../../graphics/iko_list_search_dark.png')
                                .default
                        }
                    />
                    <input
                        value={searchValue}
                        className={'search'}
                        placeholder={'Szukaj'}
                        onChange={handleChangeSearchValue}
                    />
                </div>
            </div>
        </div>
    );
}
