import React, {useRef, useState} from 'react';
import {toast} from 'react-toastify';
import {useTranslation} from 'react-i18next';
import {useSelector} from 'react-redux';

import {store} from '../../../redux/store/store';
import {setNewClientCorrespondenceData, setNewClientData} from '../../../redux/actions/clientDataActions';
import {countriesEurope} from '../../../utils/countries';
import RightPane from '../../../components/RightPane/RightPane';
import type {RootState} from '../../../redux/reducers/rootReducer';

/**
 *
 * @param title {string}
 * @param onComponentHidden {Function}
 * @param onComponentVisible {Function}
 * @param service {ClientService}
 * @returns {Element}
 * @constructor
 */
const ClientCreator = ({
                           title,
                           onComponentHidden,
                           onComponentVisible,
                           service,
                       }) => {
    const {t} = useTranslation(['Client', 'Countries', 'common']);

    const paneRef = useRef(null);

    const clientData = useSelector((state: RootState) => state.clientData);

    const [
        otherCorrespondenceAddress: boolean,
        setOtherCorrespondenceAddress: Function<boolean>,
    ] = useState(false);
    const [errors: string[], setErrors: Function<string[]>] = useState([]);

    const storeClient = () => {
        console.debug('ClientCreator::storeClient => init');

        const _data = clientData;

        if (_data.krs === '') {
            delete _data.krs;
        }
        if (_data.tax_id === '') {
            delete _data.tax_id;
        }

        const _errors = [];

        if (_data.name.trim().length === 0) {
            _errors.push('name');
        }

        if (_errors.length > 0) {
            setErrors(_errors);
            return;
        }

        if (!otherCorrespondenceAddress) {
            _data.correspondence = {
                city: _data.city !== '' ? _data.city : '',
                country: _data.country !== '' ? _data.country : '',
                postcode: _data.postcode !== '' ? _data.postcode : '',
                street: _data.street !== '' ? _data.street : '',

            };
            store.dispatch(
                setNewClientCorrespondenceData(
                    _data.city !== '' ? _data.city : '',
                    'city',
                ),
            );
            store.dispatch(
                setNewClientCorrespondenceData(
                    _data.country !== '' ? _data.country : '',
                    'country',
                ),
            );
            store.dispatch(
                setNewClientCorrespondenceData(
                    _data.postcode !== '' ? _data.postcode : '',
                    'postcode',
                ),
            );
            store.dispatch(
                setNewClientCorrespondenceData(
                    _data.street !== '' ? _data.street : '',
                    'street',
                ),
            );
        }

        service.storeClient(
            _data,
            () => {
                console.debug('ClientCreator::storeClient => Success');
                toast.success(
                    t('Client:CLIENT_STORED', {name: clientData.name}),
                );
                paneRef.current.hideComponent();
            },
            (reason) => {
                console.warn('ClientCreator::storeClient => Failure');
                console.error(reason);
                toast.error(
                    t('Client:ERR_CLIENT_NOT_STORED', {reason: t(reason)}),
                );
            },
        );
    };

    const Body = () => (
        <div>
            <div className="group">
                <div className="title">{t('Client:COMPANY_DATA')}</div>
                <div
                    className={`field required ${errors.includes('name') && 'error'}`}
                >
                    <label htmlFor="counterparty_name">
                        {t('Client:COMPANY_NAME')}
                    </label>
                    <input
                        type="text"
                        id={'counterparty_name'}
                        value={clientData.name ?? ''}
                        onChange={(event) =>
                            store.dispatch(
                                setNewClientData(event.target.value, 'name'),
                            )
                        }
                    />
                </div>
                <div className="field">
                    <label htmlFor="counterparty_tax_id">
                        {t('Client:COMPANY_TAX_ID')}
                    </label>
                    <input
                        type="text"
                        id={'counterparty_tax_id'}
                        value={clientData.tax_id ?? ''}
                        onChange={(event) =>
                            store.dispatch(
                                setNewClientData(event.target.value, 'tax_id'),
                            )
                        }
                    />
                </div>
                <div className="field">
                    <label htmlFor="counterparty_krs">
                        {t('Client:COMPANY_KRS')}
                    </label>
                    <input
                        type="text"
                        id={'counterparty_krs'}
                        value={clientData.krs ?? ''}
                        onChange={(event) =>
                            store.dispatch(
                                setNewClientData(event.target.value, 'krs'),
                            )
                        }
                    />
                </div>
                <div className="field">
                    <label htmlFor="counterparty_street">
                        {t('Client:COMPANY_STREET')}
                    </label>
                    <input
                        type="text"
                        id={'counterparty_street'}
                        value={clientData.street ?? ''}
                        onChange={(event) =>
                            store.dispatch(
                                setNewClientData(event.target.value, 'street'),
                            )
                        }
                    />
                </div>
                <div className="field">
                    <label htmlFor="counterparty_postcode">
                        {t('Client:COMPANY_POSTCODE')}
                    </label>
                    <input
                        type="text"
                        id={'counterparty_postcode'}
                        value={clientData.postcode ?? ''}
                        onChange={(event) =>
                            store.dispatch(
                                setNewClientData(
                                    event.target.value,
                                    'postcode',
                                ),
                            )
                        }
                    />
                </div>
                <div className="field">
                    <label htmlFor="counterparty_city">
                        {t('Client:COMPANY_CITY')}
                    </label>
                    <input
                        type="text"
                        id={'counterparty_city'}
                        value={clientData.city ?? ''}
                        onChange={(event) =>
                            store.dispatch(
                                setNewClientData(event.target.value, 'city'),
                            )
                        }
                    />
                </div>
                <div className="field">
                    <label htmlFor="counterparty_country">
                        {t('Client:COMPANY_COUNTRY')}
                    </label>
                    <select
                        className={'table-text-input'}
                        value={clientData.country ?? ''}
                        name={'country'}
                        autoComplete={'country'}
                        onChange={(event) =>
                            store.dispatch(
                                setNewClientData(event.target.value, 'country'),
                            )
                        }
                    >
                        {countriesEurope.map((country) => {
                            return (
                                <option
                                    key={
                                        'client_country_option_' + country.value
                                    }
                                    value={country.value}
                                >
                                    {t(
                                        'Countries:' +
                                        (country.value
                                            ? country.value
                                            : 'XX'),
                                    )}
                                </option>
                            );
                        })}
                    </select>
                </div>
                <div className="field">
                    <label htmlFor="counterparty_country">
                        {t('CHOOSE_LANGUAGE')}
                    </label>
                    <select
                        className={'table-text-input'}
                        name={'lang'}
                        placeholder={t('LANGUAGE')}
                        value={clientData.lang || 'pl'}
                        onChange={(e) => {
                            store.dispatch(setNewClientData(e.target.value, 'lang'));
                        }}
                    >
                        <option value="pl">{t('PL')}</option>
                        <option value="en">{t('EN')}</option>
                        <option value="de">{t('DE')}</option>
                    </select>
                </div>
            </div>

            <div className="group correspondence-address">
                <div className="title">
                    {t('Client:COMPANY_CORRESPONDENCE')}
                    <div>
                        <input
                            type="checkbox"
                            id="counterparty_other_correspondence_addreess"
                            onChange={(e) =>
                                setOtherCorrespondenceAddress(e.target.checked)
                            }
                            checked={otherCorrespondenceAddress}
                        />
                        <label htmlFor="counterparty_other_correspondence_addreess">
                            {t('Client:OTHER_CORRESPONDENCE_ADDRESS')}
                        </label>
                    </div>
                </div>
                {otherCorrespondenceAddress && (
                    <>
                        <div className="field">
                            <label htmlFor="counterparty_correspondence_street">
                                {t('Client:COMPANY_STREET')}
                            </label>
                            <input
                                type="text"
                                id={'counterparty_correspondence_street'}
                                value={clientData.correspondence.street ?? ''}
                                onChange={(event) =>
                                    store.dispatch(
                                        setNewClientCorrespondenceData(
                                            event.target.value,
                                            'street',
                                        ),
                                    )
                                }
                            />
                        </div>
                        <div className="field">
                            <label htmlFor="counterparty_correspondence_postcode">
                                {t('Client:COMPANY_POSTCODE')}
                            </label>
                            <input
                                type="text"
                                id={'counterparty_correspondence_postcode'}
                                value={clientData.correspondence.postcode ?? ''}
                                onChange={(event) =>
                                    store.dispatch(
                                        setNewClientCorrespondenceData(
                                            event.target.value,
                                            'postcode',
                                        ),
                                    )
                                }
                            />
                        </div>
                        <div className="field">
                            <label htmlFor="counterparty_correspondence_city">
                                {t('Client:COMPANY_CITY')}
                            </label>
                            <input
                                type="text"
                                id={'counterparty_correspondence_city'}
                                value={clientData.correspondence.city ?? ''}
                                onChange={(event) =>
                                    store.dispatch(
                                        setNewClientCorrespondenceData(
                                            event.target.value,
                                            'city',
                                        ),
                                    )
                                }
                            />
                        </div>
                        <div className="field">
                            <label htmlFor="counterparty_correspondence_country">
                                {t('Client:COMPANY_COUNTRY')}
                            </label>
                            <select
                                className={'table-text-input'}
                                value={clientData.correspondence.country ?? ''}
                                name={'country'}
                                autoComplete={'country'}
                                onChange={(event) =>
                                    store.dispatch(
                                        setNewClientCorrespondenceData(
                                            event.target.value,
                                            'country',
                                        ),
                                    )
                                }
                            >
                                {countriesEurope.map((country) => (
                                    <option
                                        key={
                                            'client_country_option_' +
                                            country.value
                                        }
                                        value={country.value}
                                    >
                                        {t(
                                            'Countries:' +
                                            (country.value
                                                ? country.value
                                                : 'XX'),
                                        )}
                                    </option>
                                ))}
                            </select>
                        </div>
                    </>
                )}
            </div>
        </div>
    );

    const Footer = () => (
        <>
            <button
                className="button edit"
                onClick={(e) => {
                    e.preventDefault();
                    paneRef.current.hideComponent();
                }}
            >
                {t('common:CANCEL')}
            </button>
            <button className="button save" onClick={storeClient}>
                {t('SAVE')}
            </button>
        </>
    );

    return (
        <RightPane
            ref={paneRef}
            id="client-editor"
            className={'client-preview wide'}
            title={title}
            body={Body}
            footer={Footer}
            onComponentVisible={onComponentVisible}
            onComponentHidden={onComponentHidden}
        />
    );
};

export default ClientCreator;
