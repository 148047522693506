import moment from 'moment';
import {
    alphabet,
    createReportHeader,
    DATE_CELL_WIDTH,
    DISTANCE_CELL_WIDTH,
    DURATION_CELL_WIDTH,
    getSheet,
    PLACE_CELL_WIDTH,
} from './reportsXLSXUtils';
import {darkRowStyle, fieldStyle, lightRowStyle} from './sheetStyles';
import {convertSecondsToDurationString} from '../date';
import {FULL_DATE_WITHOUT_SECONDS_FORMAT} from '../constants';
import type {EcoScoreReportData} from '../interfaces/report';

export function EcoDrivingReportSheet(
    workbook,
    t,
    reportData: EcoScoreReportData,
) {
    if (!reportData.multiple) {
        reportData.vehicles = [
            {rows: reportData.rows, vehicle_name: reportData.vehicle_name},
        ];
    }

    reportData.vehicles.forEach((vehicle) => {
        let sheet = getSheet(workbook, vehicle.vehicle_name);

        const _fields = reportData.fields;
        _fields[6] = 'business_drive_time';
        createReportHeader(
            sheet,
            t,
            t('Reports:ECO_DRIVING_REPORT_HEADER', {
                vehicleName: vehicle.vehicle_name,
            }),
            reportData.days,
            reportData.date_start,
            reportData.date_end,
            _fields,
        );

        let rowRelativeCell = sheet.cell('A3');

        let summary = false;
        vehicle.rows.forEach((row) => {
            if (row[0] !== 'row') {
                sheet
                    .range(
                        'A' +
                            rowRelativeCell.rowNumber() +
                            ':' +
                            alphabet[row.length - 2] +
                            rowRelativeCell.rowNumber(),
                    )
                    .value(t('Reports:' + row[0]))
                    .merged(true)
                    .style(fieldStyle);
                rowRelativeCell = rowRelativeCell.relativeCell(1, 0);
                summary = true;
            }
            row.forEach((value, index) => {
                if (index !== 0) {
                    const style = summary
                        ? fieldStyle
                        : rowRelativeCell.rowNumber() % 2 === 0
                          ? darkRowStyle
                          : lightRowStyle;
                    if (index === 1 || index === 2) {
                        rowRelativeCell
                            .value(
                                moment
                                    .unix(value)
                                    .format(FULL_DATE_WITHOUT_SECONDS_FORMAT),
                            )
                            .style(style);
                    } else if (index === 5 || index === 6) {
                        rowRelativeCell.value(value.toFixed(2)).style(style);
                    } else if (index === 7) {
                        rowRelativeCell
                            .value(convertSecondsToDurationString(value, true))
                            .style(style);
                    } else if (index === 8 || index === 9) {
                        rowRelativeCell
                            .value(typeof value === 'number' ? value : '-')
                            .style(style);
                    } else {
                        rowRelativeCell.value(value).style(style);
                    }
                    rowRelativeCell = rowRelativeCell.relativeCell(0, 1);
                }
            });
            rowRelativeCell = rowRelativeCell.relativeCell(
                1,
                -(row.length - 1),
            );
            summary = false;
        });
        sheet.column('A').width(DATE_CELL_WIDTH);
        sheet.column('B').width(DATE_CELL_WIDTH);
        sheet.column('C').width(PLACE_CELL_WIDTH);
        sheet.column('D').width(PLACE_CELL_WIDTH);
        sheet.column('E').width(DISTANCE_CELL_WIDTH);
        sheet.column('F').width(25);
        sheet.column('G').width(DURATION_CELL_WIDTH);
        sheet.column('H').width(25);
        sheet.column('I').width(15);
    });
}
