import React, {useState} from 'react';
import {useTranslation} from 'react-i18next';
import {toast} from 'react-toastify';
import Select from 'react-select';
import {shallowEqual, useSelector} from 'react-redux';
import {confirmAlert} from 'react-confirm-alert';
import _ from 'lodash';

import useServiceProvider from '../../utils/service';
import {selectStyles} from '../../utils/selectStyles';
import type {RootState} from '../../redux/reducers/rootReducer';
import type {AccessSchema} from '../../utils/interfaces/accessSchema';

import './AccessSchemas.scss';

/**
 *
 * @param value {string|boolean}
 * @param switchValue {Function}
 * @param itemName {string}
 * @returns {JSX.Element}
 * @constructor
 */
const AccessSchemaItem = ({value, switchValue, itemName}) => {
    return (
        <div className={'schema-option'} onClick={switchValue}>
            <img
                alt={''}
                className={'check-box'}
                src={
                    value
                        ? require('../../graphics/iko_poi_checkbox_yes_dark.png')
                              .default
                        : require('../../graphics/iko_poi_checkbox_no_dark.png')
                              .default
                }
            />
            <span className={'item-name'}>{itemName}</span>
        </div>
    );
};

function AccessSchemas() {
    const {t} = useTranslation();
    const {accessSchemaService} = useServiceProvider();
    const accessSchemaList = useSelector(
        (state: RootState) => state.accessSchemaList,
        shallowEqual,
    );

    const [
        accessSchemaEditorVisible: boolean,
        setAccessSchemaEditorVisibility: Function<boolean>,
    ] = useState(false);
    const [
        selectedAccessSchemaId: number,
        selectAccessSchemaId: Function<number>,
    ] = useState(null);
    const [
        accessSchemaData: AccessSchema,
        setAccessSchemaData: Function<AccessSchema>,
    ] = useState({});

    function createEditSchema(e: Event) {
        e.preventDefault();
        if (!validateSchema()) {
            return;
        }
        if (accessSchemaData.id) {
            accessSchemaService.updateSchema(accessSchemaData);
            hideSchemaPreview();
        } else {
            accessSchemaService.addSchema(accessSchemaData);
            hideSchemaPreview();
        }
    }

    function removeSchemaConfirmAlert() {
        confirmAlert({
            customUI: ({onClose}) => {
                return (
                    <div className="react-confirm-alert-body">
                        <h1>{t('REMOVE_ACCESS_SCHEMA_CONFIRM_HEADER')}</h1>
                        <p>
                            {t('REMOVE_ACCESS_SCHEMA_CONFIRM_BODY', {
                                name: accessSchemaData.name,
                            })}
                        </p>
                        <div className={'react-confirm-alert-button-group'}>
                            <button onClick={onClose}>{t('CANCEL')}</button>
                            <button
                                onClick={() => {
                                    onClose();
                                    removeSchema();
                                }}
                                className={'confirm'}
                            >
                                {t('CONFIRM')}
                            </button>
                        </div>
                    </div>
                );
            },
        });
    }

    function removeSchema() {
        accessSchemaService
            .removeSchema(accessSchemaData.id)
            .then(() => {
                toast.success(t('SELECTED_SCHEMA_HAS_BEEN_REMOVED'));
                hideSchemaPreview();
            })
            .catch(() => {
                toast.error(t('SELECTED_SCHEMA_HAS_NOT_BEEN_REMOVED'));
            });
    }

    function validateSchema() {
        if (
            !_.isObject(accessSchemaData?.parameters) ||
            !Object.keys(accessSchemaData?.parameters).find(
                (key) => accessSchemaData?.parameters[key] === true,
            )
        ) {
            toast.info(t('SELECT_AT_LEAST_ONE_PARAMETER'));
            return false;
        } else if (
            accessSchemaData?.name === undefined ||
            accessSchemaData?.name?.trim().length === 0
        ) {
            toast.info(t('NAME_CANNOT_BE_EMPTY'));
            return false;
        }
        return true;
    }

    function getSelectedSchemaNameAndValue() {
        let selectedSchema = accessSchemaList.find(
            (schema) => schema.id === selectedAccessSchemaId,
        );
        if (selectedSchema) {
            return {label: selectedSchema.name, value: selectedSchema.id};
        } else {
            return null;
        }
    }

    function showSchemaPreview() {
        if (selectedAccessSchemaId) {
            setAccessSchemaData(
                accessSchemaList.find(
                    (item) => item.id === selectedAccessSchemaId,
                ),
            );
            setAccessSchemaEditorVisibility(true);
        } else {
            toast.info(t('SELECT_SCHEMA_TO_PREVIEW'));
        }
    }

    function showNewSchemaSettings() {
        setAccessSchemaData({});
        setAccessSchemaEditorVisibility(true);
    }

    function hideSchemaPreview() {
        setAccessSchemaData({});
        setAccessSchemaEditorVisibility(false);
    }

    function switchValue(field) {
        // if (accessSchemaData?.parameters) {
        //     setAccessSchemaData({
        //         ...accessSchemaData,
        //         parameters: {...accessSchemaData?.parameters, [field]: !accessSchemaData?.parameters?.[field]}
        //     })
        // } else {
        //     setAccessSchemaData({...accessSchemaData, parameters: {[field]: true}})
        // }
        setAccessSchemaData({
            ...accessSchemaData,
            parameters: {
                ...accessSchemaData?.parameters,
                [field]: !accessSchemaData?.parameters?.[field],
            },
        });
    }

    function switchAllThermometers() {
        setAccessSchemaData({
            ...accessSchemaData,
            parameters: {
                ...accessSchemaData?.parameters,
                t1: !accessSchemaData?.parameters?.t1,
                t2: !accessSchemaData?.parameters?.t1,
                t3: !accessSchemaData?.parameters?.t1,
                t4: !accessSchemaData?.parameters?.t1,
                BLE_temp_1: !accessSchemaData?.parameters?.t1,
                BLE_temp_2: !accessSchemaData?.parameters?.t1,
                BLE_temp_3: !accessSchemaData?.parameters?.t1,
                BLE_temp_4: !accessSchemaData?.parameters?.t1,
            },
        });
    }

    function setAccessSchemaDataName(newName: string) {
        setAccessSchemaData((prev) => ({...prev, name: newName}));
    }

    return (
        <div id={'access-schemas'}>
            <div className={'header'}>
                {t('SCHEMES_OF_ACCESS_TO_VEHICLE_PARAMETERS')}
            </div>
            {!accessSchemaEditorVisible && (
                <Select
                    value={getSelectedSchemaNameAndValue()}
                    onChange={(selectedSchema) => {
                        selectAccessSchemaId(selectedSchema.value);
                    }}
                    options={accessSchemaList.map((schema) => ({
                        value: schema.id,
                        label: schema.name,
                    }))}
                    placeholder={t('SELECT_THE_SCHEMA_OF_SHARED_PARAMETERS')}
                    styles={selectStyles}
                />
            )}
            {accessSchemaEditorVisible && (
                <form
                    className={'access-schema-editor'}
                    onSubmit={createEditSchema}
                >
                    <div className={'header center'}>
                        {accessSchemaData?.id
                            ? t('EDIT_THE_SCHEMA_OF_SHARED_PARAMETERS')
                            : t('ADD_A_NEW_SCHEME_OF_SHARED_PARAMETERS')}
                    </div>
                    <input
                        className={'access-schema-name'}
                        value={accessSchemaData?.name || ''}
                        placeholder={t('ENTER_SCHEMA_NAME')}
                        onChange={(e) =>
                            setAccessSchemaDataName(e.target.value)
                        }
                    />
                    <AccessSchemaItem
                        value={accessSchemaData?.parameters?.speed}
                        itemName={t('VEHICLE_SPEED_GPS')}
                        switchValue={() => switchValue('speed')}
                    />
                    <AccessSchemaItem
                        value={accessSchemaData?.parameters?.LVCAN_speed}
                        itemName={t('VEHICLE_SPEED_CAN')}
                        switchValue={() => switchValue('LVCAN_speed')}
                    />
                    <AccessSchemaItem
                        value={
                            accessSchemaData?.parameters
                                ?.LVCAN_fuel_level_procent
                        }
                        itemName={t('FUEL_LEVEL') + ' (%)'}
                        switchValue={() =>
                            switchValue('LVCAN_fuel_level_procent')
                        }
                    />
                    <AccessSchemaItem
                        value={
                            accessSchemaData?.parameters
                                ?.LVCAN_fuel_level_liters
                        }
                        itemName={t('FUEL_LEVEL') + ' (L)'}
                        switchValue={() =>
                            switchValue('LVCAN_fuel_level_liters')
                        }
                    />
                    <AccessSchemaItem
                        value={accessSchemaData?.parameters?.LVCAN_engine_RPM}
                        itemName={t('ENGINE_SPEED')}
                        switchValue={() => switchValue('LVCAN_engine_RPM')}
                    />
                    {/*<AccessSchemaItem value={accessSchemaData?.parameters?.t} itemName={t("ENGINE_SPEED")}*/}
                    {/*                  switchValue={() => switchValue("LVCAN_engine_RPM")}/>*/}
                    <AccessSchemaItem
                        value={accessSchemaData?.parameters?.gsm_signal}
                        itemName={t('GSM_SIGNAL')}
                        switchValue={() => switchValue('gsm_signal')}
                    />
                    <AccessSchemaItem
                        value={accessSchemaData?.parameters?.power_supply}
                        itemName={t('BATTERY_VOLTAGE')}
                        switchValue={() => switchValue('power_supply')}
                    />
                    <AccessSchemaItem
                        value={
                            accessSchemaData?.parameters?.battery_capacity_level
                        }
                        itemName={t('THE_BATTERY_LEVEL_OF_THE_GPS_DEVICE')}
                        switchValue={() =>
                            switchValue('battery_capacity_level')
                        }
                    />
                    <AccessSchemaItem
                        value={accessSchemaData?.parameters?.power_battery}
                        itemName={t('THE_GPS_DEVICE_BATTERY_VOLTAGE')}
                        switchValue={() => switchValue('power_battery')}
                    />
                    <AccessSchemaItem
                        value={accessSchemaData?.parameters?.t1}
                        itemName={t('VEHICLE_THERMOMETERS')}
                        switchValue={() => switchAllThermometers()}
                    />
                    <div className={'footer'}>
                        <button
                            className={'button cancel'}
                            type={'button'}
                            onClick={hideSchemaPreview}
                        >
                            {t('CANCEL')}
                        </button>
                        {accessSchemaData?.id && (
                            <button
                                className={'button action'}
                                type={'button'}
                                onClick={removeSchemaConfirmAlert}
                            >
                                {t('REMOVE')}
                            </button>
                        )}
                        <button className={'button save'}>
                            {t('SAVE_CHANGES')}
                        </button>
                    </div>
                </form>
            )}
            {!accessSchemaEditorVisible && (
                <div className={'footer'}>
                    <button
                        className={'button cancel'}
                        onClick={showSchemaPreview}
                    >
                        {t('PREVIEW_SELECTED')}
                    </button>
                    <button
                        className={'button save'}
                        onClick={showNewSchemaSettings}
                    >
                        {t('ADD_NEW')}
                    </button>
                </div>
            )}
        </div>
    );
}

export default AccessSchemas;
