import React, {useEffect, useMemo, useState} from 'react';
import {shallowEqual, useSelector} from 'react-redux';
import {useTranslation} from 'react-i18next';
import {toast} from 'react-toastify';
import DatePicker from 'react-datepicker';
import {subDays, subMonths} from 'date-fns';

import Table from '../../../components/Table';
import {Loader} from '../../../components/Loader/Loader';
import ReportCheckbox from '../ReportCheckbox';
import AssignIdentifier from '../../Identifiers/parts/AssignIdentifier';
import {MainListHeader} from '../../../components/MainListsHeader/MainListHeader';
import useEventListener from '../../../hooks/useEventListener';
import {createXlsx} from '../../../utils/reports/reportsXLSXUtils';
import useServiceProvider from '../../../utils/service';
import {DATEPICKER_FULL_DATE_WITHOUT_SECONDS_FORMAT} from '../../../utils/constants';
import type {Driver} from '../../../utils/interfaces/driver';
import type {RootState} from '../../../redux/reducers/rootReducer';

export default function DriverReportGenerator() {
    const {t} = useTranslation(['Reports', 'Identifiers', 'common']);

    const {driversService, reportService} = useServiceProvider();

    const driverList: Driver[] = useSelector(
        (state: RootState) => state.driverList,
        shallowEqual,
    );

    const [searchValue, setSearchValue] = useState('');
    const [tableData: Driver[], setTableData: Function<Driver[]>] =
        useState(null);
    const [selectedDrivers, setSelectedDrivers] = useState([]);
    const [
        selectedReports: Array<string>,
        setSelectedReports: Function<Array<string>>,
    ] = useState([]);

    const [reportsStartDate, setReportsStartDate] = useState(
        subDays(new Date(), 7),
    );
    const [reportsEndDate, setReportsEndDate] = useState(new Date());

    const [
        assignIdentifierMode: boolean,
        setAssignIdentifierMode: Function<boolean>,
    ] = useState(false);
    const [currentDriver: Driver, setCurrentDriver: Function<Driver>] =
        useState(null);

    const supportedReports = ['driver_country_crossings', 'driver_activities'];

    useEventListener('connection_closed', () => {
        dispatchEvent(
            new CustomEvent('generator-progress', {
                detail: {completed: 0, total: selectedReports.length},
            }),
        );
    });

    useEffect(() => {
        driversService.initStore();
    }, [driversService]);

    useEffect(() => {
        if (driverList === null) return;
        const _tableData = driverList
            .filter((driver) =>
                (driver.first_name + driver.last_name)
                    .toLowerCase()
                    .includes(searchValue.toLowerCase()),
            )
            .map((driver) => ({
                ...driver,
                disabled:
                    driver.last_identifier_id === null &&
                    driver.identifier === null,
            }));
        setTableData(_tableData);
    }, [searchValue, driverList]);

    function reportDataValidation() {
        let isCorrect: boolean = true;
        if (reportsStartDate > reportsEndDate) {
            toast.info(
                t(
                    'Reports:THE_END_DATE_OF_THE_REPORT_CAN_NOT_BE_EARLIER_THAN_THE_START_OF_REPORT',
                ),
            );
            isCorrect = false;
        }
        if (selectedDrivers.length === 0) {
            toast.info(t('Reports:SELECT_AT_LEAST_ONE_DRIVER'));
            isCorrect = false;
        }
        if (selectedReports.length === 0) {
            toast.info(t('Reports:SELECT_AT_LEAST_ONE_REPORT_TYPE'));
            isCorrect = false;
        }
        return isCorrect;
    }

    function getReports() {
        if (!reportDataValidation()) {
            return;
        }
        dispatchEvent(
            new CustomEvent('generator-progress', {
                detail: {
                    completed: 0,
                    total: selectedDrivers.length * selectedReports.length,
                },
            }),
        );
        toast.info(t('Reports:GENERATION_STARTED'));

        let coreReportData = {
            geocoding: true,
            since: Math.round(reportsStartDate.getTime() / 1000),
            until: Math.round(reportsEndDate.getTime() / 1000),
        };
        selectedDrivers.forEach((driver: Driver) => {
            Object.assign(coreReportData, {driverId: driver.id});
            selectedReports.forEach((type) => {
                reportService
                    .getDriverReport(
                        Object.assign({}, coreReportData, {report_type: type}),
                    )
                    .then((result) => createXlsx(result, t))
                    .catch((reason) => {
                        console.warn(
                            'DriverReportGenerator::"%s" =>',
                            type,
                            reason,
                        );
                        toast.error(
                            t('Reports:GENERATION_FAILURE_' + type, {
                                driver: driverList.find(
                                    (d) => d.id === driver.id,
                                ),
                                error: t('Reports:' + reason),
                            }),
                        );
                    })
                    .finally(() => {
                        dispatchEvent(
                            new CustomEvent('generator-progress-increment'),
                        );
                    });
            });
        });
    }

    const totalReports = selectedDrivers.length * selectedReports.length;
    const buttonGenerateDisabled = totalReports === 0;

    const columns = useMemo(
        () => [
            {
                Header: t('Reports:FIRST_NAME'),
                accessor: 'first_name',
            },
            {
                Header: t('Reports:LAST_NAME'),
                accessor: 'last_name',
            },
            {
                Header: t('Identifiers:IDENT'),
                accessor: ({identifier}) =>
                    identifier ? identifier.identifier_ident : '-',
            },
            {
                Header: t('Identifiers:IDENTIFIER_TYPE'),
                accessor: (driver: Driver) =>
                    driver.identifier ? (
                        t(`Identifiers:${driver.identifier.type}`)
                    ) : (
                        <button
                            className={'button basic'}
                            onClick={() => {
                                setAssignIdentifierMode(true);
                                setCurrentDriver(driver);
                            }}
                        >
                            {t('Identifiers:ASSIGN')}
                        </button>
                    ),
            },
        ],
        [t],
    );

    return (
        <>
            {assignIdentifierMode && currentDriver !== null && (
                <AssignIdentifier
                    onHide={setAssignIdentifierMode}
                    currentDriver={currentDriver}
                />
            )}
            <MainListHeader
                headerText={t('common:DRIVER_REPORTS')}
                searchValue={searchValue}
                handleChangeSearchValue={(e) => {
                    setSearchValue(e.target.value);
                }}
            />
            <div id="report-generator" className="driver-report-generator">
                <div className={'table-container'}>
                    {tableData === null && <Loader />}
                    {tableData !== null && (
                        <Table
                            columns={columns}
                            data={tableData ? tableData : []}
                            keyField={'id'}
                            selectType="checkbox"
                            setSelectedRows={setSelectedDrivers}
                            getProps={() => ({id: 'driver-table'})}
                            getRowProps={(row) => ({
                                className:
                                    row.original.last_identifier_id === null &&
                                    row.original.identifier === null
                                        ? 'dimmed'
                                        : '',
                            })}
                        />
                    )}
                </div>
                <div className={'footer'} id="report-generator-footer">
                    <div className={'row'}>
                        <span className={'row-description'}>
                            {t('Reports:SELECT_REPORT_TYPE')}
                        </span>
                        <div className={'report-items'}>
                            {supportedReports.map((type) => (
                                <ReportCheckbox
                                    key={type}
                                    state={selectedReports.includes(type)}
                                    setter={() =>
                                        selectedReports.includes(type)
                                            ? setSelectedReports(
                                                  selectedReports.filter(
                                                      (r) => r !== type,
                                                  ),
                                              )
                                            : setSelectedReports([
                                                  ...selectedReports,
                                                  type,
                                              ])
                                    }
                                    label={t('Reports:TYPE_' + type)}
                                />
                            ))}
                        </div>
                    </div>
                    <div className={'row'}>
                        <span className={'row-description'}>
                            {t('Reports:SELECT_DATA_RANGE')}
                        </span>
                        <div className={'date-range-and-confirm'}>
                            <div className={'data-range-container'}>
                                <DatePicker
                                    locale={'pl'}
                                    selected={reportsStartDate}
                                    onChange={(date) =>
                                        setReportsStartDate(date)
                                    }
                                    showTimeSelect
                                    timeFormat="HH:mm"
                                    timeIntervals={30}
                                    timeCaption="time"
                                    dateFormat={
                                        DATEPICKER_FULL_DATE_WITHOUT_SECONDS_FORMAT
                                    }
                                    calendarStartDay={1}
                                    minDate={subMonths(new Date(), 12)}
                                    maxDate={reportsEndDate}
                                />
                                <span>{t('TO')}</span>
                                <DatePicker
                                    locale={'pl'}
                                    selected={reportsEndDate}
                                    onChange={(date) => setReportsEndDate(date)}
                                    showTimeSelect
                                    timeFormat="HH:mm"
                                    timeIntervals={30}
                                    timeCaption="time"
                                    dateFormat={
                                        DATEPICKER_FULL_DATE_WITHOUT_SECONDS_FORMAT
                                    }
                                    calendarStartDay={1}
                                    minDate={reportsStartDate}
                                    maxDate={new Date()}
                                />
                            </div>
                            <button
                                className={'button action'}
                                onClick={getReports}
                                disabled={buttonGenerateDisabled}
                            >
                                {t(
                                    'Reports:' +
                                        (totalReports > 0
                                            ? 'GENERATE'
                                            : 'SELECT') +
                                        '_REPORTS',
                                    {count: totalReports},
                                )}
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}
