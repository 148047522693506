import {useTranslation} from 'react-i18next';
import {toast} from 'react-toastify';
import Modal from '../../../components/Modal/Modal';

/**
 *
 * @param exchange {AtlasUser}
 * @param atlasService {AtlasService}
 * @param onClose {Function}
 * @param onSuccess {Function}
 * @returns {JSX.Element}
 * @constructor
 */
const DeleteTransportExchange = ({
    exchange,
    atlasService,
    onClose,
    onSuccess,
}) => {
    const {t} = useTranslation(['Settings', 'common']);

    const deleteExchangeHandler = () => {
        atlasService.removeUser(
            exchange.id,
            (result) => {
                console.debug(
                    'DeleteTransportExchange :: deleteExchangeHandler',
                    result,
                );
                toast.success(t('DELETE_TRANSPORT_EXCHANGE_SUCCESS'));
                onSuccess();
            },
            (error) => {
                console.error(
                    'DeleteTransportExchange :: deleteExchangeHandler',
                    error,
                );
                toast.error(t('DELETE_TRANSPORT_EXCHANGE_ERROR'));
            },
        );
    };

    return (
        <Modal onHide={onClose}>
            <header>{t('DELETE_TRANSPORT_EXCHANGE')}</header>
            <main>
                {t('DELETE_TRANSPORT_EXCHANGE_CONFIRM', {
                    exchange: t(`common:${exchange.exchange}`),
                })}
            </main>
            <footer>
                <button className="button cancel" onClick={onClose}>
                    {t('common:NO')}
                </button>
                <button className="button red" onClick={deleteExchangeHandler}>
                    {t('common:YES')}
                </button>
            </footer>
        </Modal>
    );
};

export default DeleteTransportExchange;
