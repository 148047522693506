import type {
    GetDriverReport,
    GetMultipleVehicleReport,
} from '../utils/interfaces/report';

export default class ReportService {
    constructor(connection) {
        this.connection = connection;
    }

    getDriverReport(reportSettings: GetDriverReport) {
        return new Promise((resolve, reject) => {
            this.connection.query(
                'reports.getDriver',
                reportSettings,
                (result) => {
                    resolve(result);
                },
                (reason) => {
                    reject(reason);
                },
            );
        });
    }

    getMultipleReport(reportSettings: GetMultipleVehicleReport) {
        return new Promise((resolve, reject) => {
            this.connection.query(
                'reports.getMultiple',
                reportSettings,
                (result) => {
                    resolve(result);
                },
                (reason) => {
                    reject(reason);
                },
            );
        });
    }
}
