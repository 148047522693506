import React, {useState} from 'react';
import {useTranslation} from 'react-i18next';
import {toast} from 'react-toastify';

import useServiceProvider from '../../../utils/service';
import {TextField} from '../../../components/Input/Input';
import {phoneValidator} from '../../../utils/validator';
import type {Account} from '../../../utils/interfaces/account';

import '../AccountSettings.scss';

/**
 *
 * @param title {string}
 * @param userData {Account}
 * @param setEditMode {Function}
 * @returns {JSX.Element}
 * @constructor
 */
const EditAccount = ({title, userData, setEditMode}) => {
    const {t} = useTranslation(['Settings', 'common']);

    const {userService} = useServiceProvider();

    const initialData = {
        first_name: userData.first_name ?? '',
        last_name: userData.last_name ?? '',
        email: userData.email ?? '',
        phone_number: userData.phone_number ?? '',
    };

    const [data: Account, setData: Function<Account>] = useState(initialData);
    const [errors: string[], setErrors: Function<string[]>] = useState([]);

    const editAccountHandler = (e: Event) => {
        e.preventDefault();

        setErrors([]);

        const _data = {};
        const _errors = [];

        if (data.first_name !== initialData.first_name) {
            _data.first_name = data.first_name.trim();
        }
        if (data.last_name !== initialData.last_name) {
            _data.last_name = data.last_name.trim();
        }
        if (data.phone_number !== initialData.phone_number) {
            if (phoneValidator(data.phone_number)) {
                _data.phone_number = data.phone_number;
            } else {
                _errors.push('phone_number');
            }
        }

        if (_errors.length > 0) {
            setErrors(_errors);
            return;
        }

        if (Object.entries(_data).length === 0) {
            toast.info(t('NOTHING_TO_UPDATE'));
            setEditMode(false);
            return;
        }

        userService.update(
            data,
            (result) => {
                console.debug('EditAccount::editAccountHandler:', result);
                toast.success(t('EDIT_ACCOUNT_SUCCESS'));
                setEditMode(false);
            },
            (error) => {
                console.error('EditAccount::editAccountHandler:', error);
                toast.error(t('EDIT_ACCOUNT_ERROR'));
            },
        );
    };

    const onChangeHandler = (e) => {
        setData((prev) => ({...prev, [e.target.name]: e.target.value}));
    };

    return (
        <div className="item">
            <p className="title">{title}</p>
            <form
                className="body panel-right-form"
                onSubmit={editAccountHandler}
            >
                <TextField
                    id={'account_first_name'}
                    label={t('common:FIRST_NAME')}
                    value={data.first_name}
                    name={'first_name'}
                    onChange={onChangeHandler}
                    hasError={errors.includes('first_name')}
                />
                <TextField
                    id={'account_last_name'}
                    label={t('common:LAST_NAME')}
                    value={data.last_name}
                    name={'last_name'}
                    onChange={onChangeHandler}
                    hasError={errors.includes('last_name')}
                />
                <TextField
                    id={'account_phone_number'}
                    label={t('common:PHONE_NUMBER')}
                    value={data.phone_number}
                    name={'phone_number'}
                    onChange={onChangeHandler}
                    hasError={errors.includes('phone_number')}
                    hint={t('PHONE_NUMBER_HINT')}
                />
                <div className="actions">
                    <button
                        type="button"
                        className="button cancel"
                        onClick={() => setEditMode(false)}
                    >
                        {t('common:CANCEL')}
                    </button>
                    <button className="button save">{t('common:SAVE')}</button>
                </div>
            </form>
        </div>
    );
};

export default EditAccount;
