import React, {useCallback, useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {DragDropContext, Draggable, Droppable} from 'react-beautiful-dnd';
import Autosuggest from 'react-autosuggest';
import {usePrevious} from 'react-use';
import Gmdp from 'google-maps-data-parameter-parser';
import {toast} from 'react-toastify';
import Select from 'react-select';
import {Loader} from '../Loader/Loader';
import useApi from '../../utils/api';
import useServiceProvider from '../../utils/service';
import {loadOptions} from '../../utils/suggestionOptions';
import type {Suggestion} from '../../utils/interfaces/interfaces';
import type {POI} from '../../utils/interfaces/poi';

import './DragAndDropCreateRouteStyles.scss';

interface PoiSelectOption {
    value: string;
    label: string;
    poi?: POI;
}

const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);
    return result;
};
const getItemStyle = (isDragging, draggableStyle) => ({
    // some basic styles to make the items look a bit nicer
    userSelect: 'none',
    padding: '4px 0',
    margin: `0 0 4px 0`,
    // change background colour if dragging
    background: isDragging ? '#FBFBFC' : 'white',
    // styles we need to apply on draggables
    // boxShadow: "0 -2px 2px rgba(0, 0, 0, 0.1)",
    ...draggableStyle,
});

const getListStyle = (isDraggingOver) => ({
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
    // height:"100%",
    background: isDraggingOver ? 'lightblue' : 'white',
    // overflow: "scroll"
    // padding: 6,
});

/**
 *
 * @param routePoints
 * @param setRoutePoints
 * @param newPointLatLng
 * @param {Function<{lat: number, lng: number}>} setNewPointLatLng
 * @param setAuxiliaryMarkerVisible
 * @param setNewRouteCorridor
 * @param POIList
 * @param POITypeList
 * @returns {JSX.Element}
 * @constructor
 */
function DragAndDropCreateRoute({
    routePoints,
    setRoutePoints,
    newPointLatLng,
    setNewPointLatLng,
    setAuxiliaryMarkerVisible,
    setNewRouteCorridor,
    POIList,
    POITypeList,
}) {
    const {t} = useTranslation(['OrderCreator']);
    const [pointCreatorVisible, setPointCreatorVisible] = useState(false);
    const [newPointTime, setNewPointTime] = useState('');
    const [newPointDescription, setNewPointDescription] = useState('');
    const [newPointLocalization, setNewPointLocalization] = useState('');
    const [localizationSuggestions: Suggestion[], setLocalizationSuggestions] =
        useState([]);
    const [newPointLocalizationError, setNewPointLocalizationError] =
        useState(false);
    const [newPointTimeError, setNewPointTimeError] = useState(false);
    const [skipGeodecoding, setSkipGeodecoding] = useState(false);
    const [coordsInputValue, setCoordsInputValue] = useState('');
    const [poiOptions: PoiSelectOption[], setPoiOptions] = useState([]);
    const [selectedPoi: PoiSelectOption, setSelectedPoi] = useState(null);

    const prevSkipState = usePrevious(skipGeodecoding);
    const prevPointLatLng = usePrevious(newPointLatLng);

    const api = useApi();
    const {poiService} = useServiceProvider();

    useEffect(() => {
        poiService.initStore();
    }, [poiService]);

    useEffect(() => {
        if (!POIList) {
            return;
        }
        const _poiOptions = [];
        if (POIList.length > 0) {
            POIList.forEach((poi) => {
                _poiOptions.push({
                    label: poi.name,
                    value: `${poi.lat},${poi.lng}`,
                    poi: poi,
                });
            });
        }
        setPoiOptions(_poiOptions);
    }, [POIList]);

    // use geodecoding on point change
    useEffect(() => {
        if (newPointLatLng === undefined || newPointLatLng === null) {
            return;
        }
        if (prevPointLatLng === newPointLatLng) {
            return;
        }
        if (prevSkipState === true && skipGeodecoding === false) {
            // most probably this useEffect switched state.
            return;
        }
        if (skipGeodecoding === true) {
            setSkipGeodecoding(false);
            return;
        }
        console.debug(
            'DragAndDropCreateRoute::[skipGeodecoding, newPointLatLng] => skipGeodecoding: %O, newPointLatLng: %O',
            skipGeodecoding,
            newPointLatLng,
        );
        api.promisedQuery('geocoding.geodecode', {
            ...newPointLatLng,
            formatted: true,
        })
            .then((result) => {
                console.debug(
                    'DragAndDropCreateRoute::[skipGeodecoding, newPointLatLng] =>',
                    result,
                );
                if (result) {
                    setNewPointLocalization(result);
                }
            })
            .catch((reason) => {
                console.error(
                    'DragAndDropCreateRoute::[skipGeodecoding, newPointLatLng] =>',
                    reason,
                );
                setNewPointLocalization(
                    newPointLatLng.lat + ', ' + newPointLatLng.lng,
                );
            });
    }, [api, skipGeodecoding, prevSkipState, newPointLatLng, prevPointLatLng]);

    function onDragEnd(result) {
        if (!result.destination) {
            return;
        }
        const items = reorder(
            routePoints,
            result.source.index,
            result.destination.index,
        );
        setNewRouteCorridor([]);
        setRoutePoints(items);
    }

    function addPoint(routePoints) {
        const newRoutePoint = {
            id: Math.random().toString(16).slice(2),
            localization: newPointLocalization,
            time: newPointTime !== '' ? parseInt(newPointTime) : newPointTime,
            lat: newPointLatLng.lat,
            lng: newPointLatLng.lng,
            description: newPointDescription,
        };
        if (selectedPoi) {
            newRoutePoint.poi = selectedPoi.poi;
        }
        setRoutePoints(routePoints.concat(newRoutePoint));
        setPointCreatorVisible(false);
        setAuxiliaryMarkerVisible(false);
        setNewPointLocalization('');
        setNewPointTime(0);
        setNewPointDescription('');
        setNewRouteCorridor([]);
        setCoordsInputValue('');
        setSelectedPoi(null);
    }

    function removePoint(pointId) {
        let newArray = routePoints.filter((point) => point.id !== pointId);
        setRoutePoints(newArray);
        setNewRouteCorridor([]);
    }

    function abortAddPoint() {
        setNewPointLocalization('');
        setNewPointTime(0);
        setNewPointDescription('');
        setPointCreatorVisible(false);
        setAuxiliaryMarkerVisible(false);
        setCoordsInputValue('');
        setSelectedPoi(null);
        document.querySelector('.route-creator').style.width = '500px';
    }

    function enableAddPoint() {
        setAuxiliaryMarkerVisible(true);
        setPointCreatorVisible(true);
    }

    function onSuggestionsFetchRequested({value}) {
        // {label,value,lat,lng}
        loadOptions(value, (result) => {
            setLocalizationSuggestions(getSuggestions(result, value));
        });
    }

    function getSuggestions(value, searchValue) {
        let suggestions = [];
        value.length &&
            suggestions.push({
                title: t('PLACES'),
                values: value,
            });
        let parkings = getParkings(searchValue);
        parkings.length &&
            suggestions.push({
                title: t('PARKINGS'),
                values: parkings,
            });
        let pois = getPois(searchValue);
        pois.length &&
            suggestions.push({
                title: t('POI'),
                values: pois,
            });
        return suggestions;
    }

    function getParkings(searchValue) {
        const parkingType = POITypeList.find(
            (pointType) => pointType.name.toLowerCase() === 'parking',
        );
        return POIList.filter(
            (point) =>
                point.type === parkingType?.id &&
                point.name.toLowerCase().includes(searchValue.toLowerCase()),
        ).map((point) => {
            return {
                label: point.name,
                value: point.name,
                lat: point.lat,
                lng: point.lng,
                type: 'parking',
            };
        });
    }

    function getPois(searchValue) {
        const parkingType = POITypeList.find(
            (pointType) => pointType.name.toLowerCase() !== 'parking',
        );
        return POIList.filter(
            (point) =>
                point.type === parkingType?.id &&
                point.name.toLowerCase().includes(searchValue.toLowerCase()),
        ).map((point) => {
            return {
                label: point.name,
                value: point.name,
                lat: point.lat,
                lng: point.lng,
                type: 'poi',
            };
        });
    }

    function onSuggestionsClearRequested() {
        setLocalizationSuggestions([]);
    }

    function getSuggestionValue(suggestion) {
        setNewPointLatLng({lat: suggestion.lat, lng: suggestion.lng});
        return suggestion.label;
    }

    function getSectionSuggestions(section) {
        return section.values;
    }

    function renderSuggestion(suggestion) {
        const getSuggestionImage = () => {
            if (suggestion.type === 'place') {
                return (
                    <img
                        src={
                            require('../../graphics/iko_vehicle_marker_blue.png')
                                .default
                        }
                        alt=""
                    />
                );
            } else if (suggestion.type === 'parking') {
                return (
                    <img
                        src={
                            require('../../graphics/iko_vehicle_park.png')
                                .default
                        }
                        alt=""
                    />
                );
            }
            return (
                <img
                    src={
                        require('../../graphics/iko_vehicle_poi_blue.png')
                            .default
                    }
                    alt=""
                />
            );
        };

        return (
            <>
                {getSuggestionImage()}
                <span>{suggestion.label}</span>
            </>
        );
    }

    function renderSectionTitle(section) {
        return <span className={'custom-section-title'}>{section.title}</span>;
    }

    const renderInputComponent = (inputProps) => {
        delete inputProps.className;
        return (
            <input
                className={
                    'new-point-input ' +
                    (newPointLocalizationError ? 'error' : '')
                }
                {...inputProps}
            />
        );
    };

    function onChange(event, {newValue}) {
        setSkipGeodecoding(true);
        // console.debug('DragAndDropCreateRoute::["onChange"] =>', event, newValue);
        setNewPointLocalization(newValue);
        setNewPointLocalizationError(false);
    }

    const inputProps = {
        placeholder: t('ENTER_LOCALIZATION_OR_NAME_OF_POINT'),
        value: newPointLocalization,
        onChange: onChange,
    };

    const onCoordsInputChange = useCallback(
        (e) => {
            // https://www.google.com/maps/place/Ks.+Tadeusza+Borkowskiego+15,+11-041+Olsztyn/@53.7698622,20.4934942,16.78z/data=!4m5!3m4!1s0x46e27f2b6e6f6855:0xe016c6dbae6bfdba!8m2!3d53.767043!4d20.475569
            // https://www.google.com/maps/dir/Treesat,+Wędkarska+38,+11-041+Olsztyn,+Polska/53.77203,20.481014/@53.7793867,20.4428709,5972m/data=!3m2!1e3!4b1!4m9!4m8!1m5!1m1!1s0x46e27f3544258929:0xf36efd818954c9c2!2m2!1d20.436095!2d53.7932444!1m0!3e0
            const input: string = e.target.value;
            let coords;
            // check if it is gmaps url
            if (input.match(/^http/)) {
                try {
                    const g = new Gmdp(input);
                    // console.debug('onCoordsInputChange() => ', g);
                    if (g.getRoute()) {
                        // ask to drop that line xD
                        toast.error(t('GMAPS_PARSER_ROUTE_NOT_SUPPORTED'));
                        return;
                    }
                    if (g.pins.length === 0) {
                        toast.error(t('GMAPS_PARSER_NO_PINS_FOUND'));
                        return;
                    }
                    if (g.pins.length > 1) {
                        toast.error(t('GMAPS_PARSER_TOO_MANY_PINS_FOUND'));
                        return;
                    }
                    const pin = g.pins[0];
                    if (!pin.lat || !pin.lng) {
                        toast.error(t('GMAPS_PARSER_PIN_WITHOUT_COORDS'));
                    }
                    coords = {
                        lat: parseFloat(g.pins[0].lat),
                        lng: parseFloat(g.pins[0].lng),
                    };
                } catch (e) {
                    console.error('onCoordsInputChang() => "%s"', input, e);
                    toast.error(t('GMAPS_PARSER_MALFORMED_URL'));
                }
            }
            // check if pasted text matches coordinates regex
            let coordsMatch = input.match(
                /^(-?[0-9]+(\.[0-9]+)?)[^0-9]+(-?[0-9]+(\.[0-9]+))/,
            );
            if (coordsMatch) {
                console.debug(
                    'onCoordsInputChange() => parsing numerical coords: "%s"',
                    input,
                );
                coords = {
                    lat: parseFloat(coordsMatch[1]),
                    lng: parseFloat(coordsMatch[3]),
                };
            }
            if (coords) {
                console.debug(
                    'onCoordsInputChange() => parsed coords:',
                    coords,
                );
                setNewPointLatLng(coords);
                toast.success(t('COORDS_PARSED_SUCCESSFULLY'));
            }
            setCoordsInputValue(input);
        },
        [t, setNewPointLatLng, setCoordsInputValue],
    );

    const onSelectPoi = (selectedOption: PoiSelectOption) => {
        setCoordsInputValue(selectedOption.value);
        const coords = selectedOption.value.split(',');
        setNewPointLatLng({
            lat: parseFloat(coords[0]),
            lng: parseFloat(coords[1]),
        });
        setSelectedPoi(selectedOption);
    };

    if (POIList === null || POITypeList === null) {
        return (
            <div id={'drag-and-drop-route-creator'}>
                <Loader />
            </div>
        );
    }

    return (
        <div id={'drag-and-drop-route-creator'}>
            <DragDropContext onDragEnd={onDragEnd}>
                <Droppable droppableId="droppable">
                    {(provided, snapshot) => (
                        <div
                            {...provided.droppableProps}
                            ref={provided.innerRef}
                            style={getListStyle(
                                snapshot.isDraggingOver,
                                routePoints.length,
                            )}
                        >
                            {routePoints.map((item, index) => (
                                <Draggable
                                    key={item.id}
                                    draggableId={item.id}
                                    index={index}
                                >
                                    {(provided, snapshot) => (
                                        <div
                                            ref={provided.innerRef}
                                            {...provided.draggableProps}
                                            {...provided.dragHandleProps}
                                            style={getItemStyle(
                                                snapshot.isDragging,
                                                provided.draggableProps.style,
                                                routePoints.length,
                                            )}
                                        >
                                            <div className={'row-content'}>
                                                <div
                                                    className={'icon-container'}
                                                >
                                                    <img
                                                        className={'icon'}
                                                        alt={''}
                                                        src={
                                                            require('../../graphics/iko_move_dark.png')
                                                                .default
                                                        }
                                                    />
                                                </div>
                                                <div
                                                    className={
                                                        'row-data-container'
                                                    }
                                                >
                                                    {item.localization && (
                                                        <div
                                                            className={
                                                                'row-data-item'
                                                            }
                                                        >
                                                            <div
                                                                className={
                                                                    'data-description'
                                                                }
                                                            >
                                                                <div>
                                                                    {t(
                                                                        'LOCALIZATION',
                                                                    )}
                                                                </div>
                                                            </div>
                                                            <div
                                                                className={
                                                                    'data-value'
                                                                }
                                                            >
                                                                {
                                                                    item.localization
                                                                }
                                                            </div>
                                                        </div>
                                                    )}
                                                    {item.time !== '' ? (
                                                        <div
                                                            className={
                                                                'row-data-item'
                                                            }
                                                        >
                                                            <div
                                                                className={
                                                                    'data-description'
                                                                }
                                                            >
                                                                <div>
                                                                    {t('TIME')}
                                                                </div>
                                                            </div>
                                                            <div
                                                                className={
                                                                    'data-value'
                                                                }
                                                            >
                                                                {item.time}
                                                            </div>
                                                        </div>
                                                    ) : null}
                                                    {item.description !== '' ? (
                                                        <div
                                                            className={
                                                                'row-data-item'
                                                            }
                                                        >
                                                            <div
                                                                className={
                                                                    'data-description'
                                                                }
                                                            >
                                                                <div>
                                                                    {t(
                                                                        'COMMENT',
                                                                    )}
                                                                </div>
                                                            </div>
                                                            <div
                                                                className={
                                                                    'data-value'
                                                                }
                                                            >
                                                                {
                                                                    item.description
                                                                }
                                                            </div>
                                                        </div>
                                                    ) : null}
                                                    {item.poi &&
                                                        item.poi.name && (
                                                            <div
                                                                className={
                                                                    'row-data-item'
                                                                }
                                                            >
                                                                <div
                                                                    className={
                                                                        'data-description'
                                                                    }
                                                                >
                                                                    <div>
                                                                        POI
                                                                    </div>
                                                                </div>
                                                                <div
                                                                    className={
                                                                        'data-value'
                                                                    }
                                                                >
                                                                    {
                                                                        item.poi
                                                                            .name
                                                                    }
                                                                </div>
                                                            </div>
                                                        )}
                                                </div>
                                                <div
                                                    className={'icon-container'}
                                                >
                                                    <img
                                                        className={'icon'}
                                                        alt={''}
                                                        onClick={() =>
                                                            removePoint(item.id)
                                                        }
                                                        src={
                                                            require('../../graphics/iko_delete_blue.png')
                                                                .default
                                                        }
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    )}
                                </Draggable>
                            ))}
                            <div className={'item-content'}>
                                {provided.placeholder}
                            </div>
                        </div>
                    )}
                </Droppable>
            </DragDropContext>
            {!pointCreatorVisible && (
                <div
                    className={'row-content new-point-row'}
                    onClick={enableAddPoint}
                >
                    <div className={'icon-container'}>
                        <img
                            className={'icon'}
                            alt={''}
                            src={
                                require('../../graphics/iko_mobi_plus_dark.png')
                                    .default
                            }
                        />
                    </div>
                    <div className={'new-point-row-description'}>
                        {t('ADD_NEW_ROUTE_POINT')}
                    </div>
                </div>
            )}
            {pointCreatorVisible && (
                <div className={'new-point-creator'}>
                    <div className={'new-point-creator-header'}>
                        {t('ADD_NEW_ROUTE_POINT')}
                    </div>
                    <div className={'input-data-container'}>
                        <div className={'input-row'}>
                            <div className={'input-description'}>
                                {t('LOCALIZATION')}
                            </div>
                            <Autosuggest
                                suggestions={localizationSuggestions}
                                onSuggestionsFetchRequested={
                                    onSuggestionsFetchRequested
                                }
                                onSuggestionsClearRequested={
                                    onSuggestionsClearRequested
                                }
                                getSectionSuggestions={getSectionSuggestions}
                                renderSectionTitle={renderSectionTitle}
                                renderSuggestion={renderSuggestion}
                                renderInputComponent={renderInputComponent}
                                getSuggestionValue={getSuggestionValue}
                                shouldRenderSuggestions={(v) =>
                                    v.trim().length > 2
                                }
                                inputProps={inputProps}
                                multiSection={true}
                            />
                        </div>
                        <div className="input-row">
                            <div className="input-description">
                                {t('COORDS')}
                            </div>
                            <div className="coords-container">
                                <input
                                    type="text"
                                    className="new-point-input"
                                    onChange={onCoordsInputChange}
                                    value={coordsInputValue}
                                    placeholder={t('PASTE_COORDS_OR_GMAPS_URL')}
                                />
                                <Select
                                    className="poi-select"
                                    name="coords"
                                    placeholder={t('SELECT_POI')}
                                    value={selectedPoi}
                                    options={poiOptions}
                                    onChange={(selectedOption) =>
                                        onSelectPoi(selectedOption)
                                    }
                                />
                            </div>
                        </div>
                        <div className={'input-row'}>
                            <div className={'input-description'}>
                                {t('TIME')}
                            </div>
                            <input
                                className={
                                    'new-point-input ' +
                                    (newPointTimeError ? 'error' : '')
                                }
                                type={'number'}
                                onChange={(event) => {
                                    setNewPointTime(event.target.value);
                                    setNewPointTimeError(false);
                                }}
                                value={newPointTime}
                                placeholder={t('TIME_IN_MINUTES')}
                            />
                        </div>
                        <div className={'input-row'}>
                            <div className={'input-description'}>
                                {t('COMMENT')}{' '}
                            </div>
                            <input
                                className={'new-point-input'}
                                onChange={(event) =>
                                    setNewPointDescription(event.target.value)
                                }
                                value={newPointDescription}
                                placeholder={t('COMMENT')}
                            />
                        </div>
                    </div>
                    <div className={'buttons-container'}>
                        <button
                            className={'button cancel'}
                            onClick={abortAddPoint}
                        >
                            {t('CANCEL')}
                        </button>
                        <button
                            className={'button save'}
                            onClick={() => addPoint(routePoints)}
                        >
                            {t('ADD_POINT')}
                        </button>
                    </div>
                </div>
            )}
        </div>
    );
}

export default DragAndDropCreateRoute;
