import type {POIType} from '../../utils/interfaces/poi';

export const SET_POI_TYPE_LIST = 'SET_POI_TYPE_LIST';

export function setPOITypeList(poiTypes: POIType[]) {
    return {
        type: SET_POI_TYPE_LIST,
        poiTypes,
    };
}
