import React, {useEffect, useRef, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useSelector} from 'react-redux';
import DatePicker from 'react-datepicker';
import {toast} from 'react-toastify';
import moment from 'moment/moment';

import RightPane from '../../../components/RightPane/RightPane';
import useServiceProvider from '../../../utils/service';
import {store} from '../../../redux/store/store';
import {updateDriver} from '../../../redux/actions/driverActions';
import {DATEPICKER_DATE_FORMAT} from '../../../utils/constants';
import type {
    Driver,
    Identifier,
    IdentifierAssignment,
} from '../../../utils/interfaces/driver';

/**
 *
 * @param onHide {Function}
 * @param currentDriver {Driver}
 * @returns {JSX.Element}
 * @constructor
 */
const AssignIdentifier = ({onHide, currentDriver}) => {
    const {t} = useTranslation('Identifiers');
    const paneRef = useRef(null);
    const {driversService} = useServiceProvider();

    const {driverList, identifierList, identifierAssignmentList} = useSelector(
        (state) => state,
    );

    const [
        availableIdentifiers: Identifier[],
        setAvailableIdentifiers: Function<Identifier[]>,
    ] = useState(null);
    const [
        selectedIdentifier: number,
        setSelectedIdentifier: Function<number>,
    ] = useState('');
    const [selectedDriverId: number, setSelectedDriverId: Function<number>] =
        useState(currentDriver ? currentDriver.id : '');
    const [startAssignmentDate: Date, setStartAssignmentDate: Function<Date>] =
        useState(new Date());
    const [endAssignmentDate: Date, setEndAssignmentDate: Function<Date>] =
        useState(null);

    useEffect(() => {
        if (identifierList === null || identifierAssignmentList === null)
            return;
        const _availableIdentifiers = identifierList.filter((i: Identifier) => {
            const index = identifierAssignmentList.findIndex(
                (ia: IdentifierAssignment) => ia.identifier_id !== i.id,
            );
            return index !== -1;
        });
        setAvailableIdentifiers(_availableIdentifiers);
    }, [identifierList, identifierAssignmentList]);

    const assignIdentifierToDriver = (e: Event) => {
        e.preventDefault();

        if (!selectedDriverId || !selectedIdentifier) {
            toast.error(t('NO_ASSIGNMENT_TARGET'));
            return;
        }

        const begin =
            startAssignmentDate !== null
                ? moment(startAssignmentDate)
                : moment();
        const end =
            endAssignmentDate !== null
                ? moment(endAssignmentDate)
                : moment().add(100, 'years');

        driversService.createAssignment(
            selectedIdentifier,
            selectedDriverId,
            begin,
            end,
            (result: IdentifierAssignment) => {
                console.debug(
                    'Identifiers::assignIdentifier() => result: %O',
                    result,
                );
                toast.success(t('IDENTIFIER_ASSIGNED'));
                const updatedDriver = driverList.find(
                    (d: Driver) => d.id === selectedDriverId,
                );
                const identifier = availableIdentifiers.find(
                    (i) => i.id === selectedIdentifier,
                );
                const driverData = {...updatedDriver, identifier: identifier};

                store.dispatch(updateDriver(driverData));

                setSelectedIdentifier('');
                setSelectedDriverId('');
                paneRef && paneRef.current && paneRef.current.hideComponent();
            },
            (reason) => {
                console.warn('assignIdentifier() => reason: %O', reason);
                toast.error(t('IDENTIFIER_ASSIGN_ERROR', {error: t(reason)}));
            },
        );
    };

    const getPaneBody = () => {
        return (
            <>
                <div className="field">
                    <label htmlFor="assignment_identifier">
                        {t('ASSIGN_TO')}
                    </label>
                    <select
                        id="assignment_identifier"
                        onChange={(e) => {
                            setSelectedIdentifier(parseInt(e.target.value));
                        }}
                        value={selectedIdentifier}
                    >
                        <option value="" />
                        {availableIdentifiers &&
                            availableIdentifiers.map((i) => (
                                <option key={i.id} value={i.id}>
                                    {i.identifier_ident} ({t(i.type)})
                                </option>
                            ))}
                    </select>
                </div>
                <div className="field">
                    <label htmlFor="assignment_driver">{t('ASSIGN_TO')}</label>
                    <select
                        id="assignment_driver"
                        onChange={(e) => {
                            setSelectedDriverId(parseInt(e.target.value));
                        }}
                        value={selectedDriverId}
                        disabled={!!currentDriver}
                    >
                        <option value="" />
                        {driverList
                            .filter((d) => d.active)
                            .sort((a, b) =>
                                (a.first_name + a.last_name).localeCompare(
                                    b.first_name + b.last_name,
                                ),
                            )
                            .map((driver) => (
                                <option key={driver.id} value={driver.id}>
                                    {driver.first_name} {driver.last_name}
                                </option>
                            ))}
                    </select>
                </div>
                <div className="field">
                    <label htmlFor="assignment_scope">{t('DATE_SCOPE')}</label>
                    <DatePicker
                        selected={startAssignmentDate}
                        onChange={(dates) => {
                            setStartAssignmentDate(dates[0]);
                            setEndAssignmentDate(dates[1]);
                        }}
                        startDate={startAssignmentDate}
                        endDate={endAssignmentDate}
                        selectsRange={true}
                        isClearable={true}
                        dateFormat={DATEPICKER_DATE_FORMAT}
                    />
                </div>
            </>
        );
    };

    return (
        <form onSubmit={assignIdentifierToDriver}>
            <RightPane
                ref={paneRef}
                id="identifier-new"
                className="identifier-new panel-right-form"
                title={t('ASSIGN')}
                onComponentHidden={() => {
                    onHide(false);
                }}
                body={getPaneBody}
                footer={() => {
                    return (
                        <button className="button save">{t('ASSIGN')}</button>
                    );
                }}
            />
        </form>
    );
};

export default AssignIdentifier;
