import {useTranslation} from 'react-i18next';
import {NavLink, Navigate, Route, Routes} from 'react-router-dom';

import FormList from '../FormList/FormList';
import {IconArchive, IconVehiclePickupForm} from '../../graphics/icons';

const Forms = () => {
    const {t} = useTranslation('common');

    return (
        <div>
            <div className="sub-menu">
                <NavLink to="vehicle_pickup/active" className="tab">
                    <IconVehiclePickupForm />
                    {t('FORMS_VEHICLE_PICKUP_ACTIVE')}
                </NavLink>
                <NavLink to="vehicle_pickup/archived" className="tab">
                    <IconArchive />
                    {t('FORMS_VEHICLE_PICKUP_ARCHIVED')}
                </NavLink>
            </div>
            <Routes>
                <Route
                    path="vehicle_pickup/active"
                    element={<FormList type="vehicle_pickup" active={true} />}
                />
                <Route
                    path="vehicle_pickup/archived"
                    element={<FormList type="vehicle_pickup" active={false} />}
                />
                <Route
                    path="*"
                    element={<Navigate to="vehicle_pickup/active" />}
                />
            </Routes>
        </div>
    );
};

export default Forms;
