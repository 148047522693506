import React, {MutableRefObject, useEffect, useRef, useState} from 'react';
import {useTranslation} from 'react-i18next';
import DatePicker from 'react-datepicker';
import {toast} from 'react-toastify';
import Select from 'react-select';
import moment from 'moment';

import RightPane from '../../../components/RightPane/RightPane';
import {NumberField, TextField} from '../../../components/Input/Input';
import {IconInfo} from '../../../graphics/icons';
import useServiceProvider from '../../../utils/service';
import {multiSelectStyles} from '../../../utils/selectStyles';
import {phoneValidator} from '../../../utils/validator';
import {DATEPICKER_DATE_FORMAT} from '../../../utils/constants';
import type {NewVehicle, Vehicle} from '../../../utils/interfaces/vehicle';
import type {Device} from '../../../utils/interfaces/device';
import type {Driver} from '../../../utils/interfaces/driver';

const initialVehicleData = {
    name: '',
    fuel_consumption_avg: '',
    fuel_consumption_deviation: '',
    fuel_tank_capacity1: '',
    fuel_tank_capacity2: '',
    comment: '',
    device_id: '',
    driver_id: '',
    assigned_driver_id: '',
    phone_number: '',
    policy_date: undefined,
    inspection_date: undefined,
    diagnostic_date: undefined,
    inspection_distance: undefined,
};

/**
 *
 * @param onHide {Function}
 * @param vehicleService {VehicleService}
 * @param deviceList {Device[]}
 * @param driverList {Driver[]}
 * @param app {App}
 * @param vehicleList {Vehicle[]}
 * @param groupList {Group[]}
 * @constructor
 */
const AddVehicle = ({
    onHide,
    vehicleService,
    deviceList,
    driverList,
    app,
    vehicleList,
    groupList,
}) => {
    const {t} = useTranslation(['Vehicles', 'common']);

    const paneRef: MutableRefObject<RightPane> = useRef(null);

    const {groupService} = useServiceProvider();

    const [vehicleData: Vehicle, setVehicleData: Function<Vehicle>] =
        useState(initialVehicleData);
    const [updateInProgress: boolean, setUpdateInProgress: Function<boolean>] =
        useState(false);
    const [selectedDriver: Driver, setSelectedDriver: Function<Driver>] =
        useState(null);
    const [selectedGroups: number[], setSelectedGroups: Function<number[]>] =
        useState([]);
    const [dataErrors: string[], setDataErrors: Function<string[]>] = useState(
        [],
    );

    useEffect(() => {
        if (!vehicleData.assigned_driver_id || !driverList) {
            setSelectedDriver(null);
            return;
        }
        setSelectedDriver(
            driverList.filter(
                (d) => d.id === vehicleData.assigned_driver_id,
            )[0],
        );
    }, [vehicleData.assigned_driver_id, driverList]);

    const isValidForm = () => {
        const {name, phone_number} = vehicleData;
        const errors = [];
        if (name.trim().length === 0) {
            errors.push('name');
        }
        if (
            phone_number &&
            phone_number.trim().length > 0 &&
            !phoneValidator(phone_number.trim())
        ) {
            errors.push('phone_number');
        }

        if (errors.length > 0) {
            setDataErrors(errors);
            return false;
        }
        setDataErrors([]);
        return true;
    };

    const createVehicle = (e: Event) => {
        e.preventDefault();

        if (!isValidForm()) {
            return;
        }

        const {
            name,
            fuel_consumption_avg,
            fuel_consumption_deviation,
            fuel_tank_capacity1,
            fuel_tank_capacity2,
            comment,
            device_id,
            phone_number,
        } = vehicleData;

        const vd: NewVehicle = {
            name: name.trim(),
        };
        if (fuel_consumption_avg !== '') {
            vd.fuel_consumption_avg = parseFloat(fuel_consumption_avg);
        }
        if (fuel_consumption_deviation !== '') {
            vd.fuel_consumption_deviation =
                parseInt(fuel_consumption_deviation) / 100;
        }
        if (fuel_tank_capacity1 !== '') {
            vd.fuel_tank_capacity1 = parseInt(fuel_tank_capacity1);
        }
        if (fuel_tank_capacity2 !== '') {
            vd.fuel_tank_capacity2 = parseInt(fuel_tank_capacity2);
        }
        if (comment.trim().length === 0) {
            vd.comment = comment.trim();
        }
        if (phone_number && phone_number.trim().length > 0) {
            vd.phone_number = phone_number.trim();
        }

        console.debug(
            'AddVehicle::createVehicle() => vehicleData: %O; vd: %O',
            vehicleData,
            vd,
        );

        let vehicleId = null;

        const actions = [
            () =>
                new Promise((resolve, reject) => {
                    vehicleService.createVehicle(
                        vd,
                        (result) => {
                            vehicleId = result.vehicle_id;
                            return resolve(result);
                        },
                        reject,
                    );
                }),
        ];
        if (device_id !== '') {
            actions.push(
                (v: Vehicle) =>
                    new Promise((resolve, reject) => {
                        console.debug(
                            'AddVehicle::createVehicle() => Assign device #%i to: %O',
                            device_id,
                            v,
                        );
                        vehicleService.createRelation(
                            {
                                device_id: parseInt(device_id),
                                vehicle_id: v.vehicle_id,
                                begin_ts: parseInt(moment().format('X')),
                                end_ts: null,
                            },
                            (result) => {
                                console.debug(
                                    'AddVehicle::createVehicle() => relation created: %O',
                                    result,
                                );
                                resolve();
                            },
                            (reason) => {
                                console.warn(
                                    'AddVehicle::createVehicle() => relation NOT created: %s',
                                    reason,
                                );
                                reject(reason);
                            },
                        );
                    }),
            );
            const device = deviceList.find(
                (d: Device) => d.id === vehicleData.device_id,
            );
            const newDeviceInfo = Object.assign({dotsens: {}}, device.info, {
                dotsens: {
                    diagDay: vehicleData.diagnostic_date,
                    inspectionDay: vehicleData.inspection_date,
                    polDay: vehicleData.policy_date,
                    inspectionDistance: vehicleData.inspection_distance,
                },
            });
            actions.push(
                () =>
                    new Promise((resolve, reject) => {
                        vehicleService.updateDeviceInfo(
                            vehicleData.device_id,
                            newDeviceInfo,
                            resolve,
                            reject,
                        );
                    }),
            );
            actions.push(
                () =>
                    new Promise((resolve, reject) => {
                        vehicleService.updateDeviceAlerts(
                            vehicleData.device_id,
                            newDeviceInfo,
                            resolve,
                            reject,
                        );
                    }),
            );
        }

        const onSuccess = () => {
            toast.success(t('VEHICLE_CREATED'));
            paneRef.current.hideComponent();
        };

        let p = Promise.resolve();
        for (let i = 0; i < actions.length; i++) {
            p = p.then(actions[i]);
        }
        p.then(() => {
            if (
                selectedGroups &&
                selectedGroups.length > 0 &&
                vehicleId !== null
            ) {
                const groupActions = [];
                const groupsToUpdate = groupList
                    .filter((group) => selectedGroups.includes(group.id))
                    .map((group) => ({
                        id: group.id,
                        vehicles: [...group.vehicles, vehicleId],
                        devices:
                            device_id !== ''
                                ? [...group.devices, parseInt(device_id)]
                                : group.devices,
                    }));
                groupsToUpdate.forEach((groupToUpdate) => {
                    groupActions.push(
                        () =>
                            new Promise((resolve, reject) => {
                                groupService.updateGroup(
                                    groupToUpdate,
                                    resolve,
                                    reject,
                                );
                            }),
                    );
                });
                let p = Promise.resolve();
                for (let i = 0; i < groupActions.length; i++) {
                    p = p.then(groupActions[i]);
                }
                p.then(() => {
                    onSuccess();
                });
            } else {
                onSuccess();
            }
        })
            .catch((reason) => {
                toast.error(t('VEHICLE_CREATION_ERROR', {error: t(reason)}));
            })
            .finally(() => setUpdateInProgress(false));
    };

    const setVehicleDataField = (e: Event) => {
        let {name, value} = e.target;
        if (name === 'device_id' || name === 'assigned_driver_id') {
            value = value ? parseInt(value) : '';
        }
        setVehicleData({...vehicleData, [name]: value});
    };

    const vehicleDataFields = () => {
        return (
            <>
                <div className="group">
                    <TextField
                        id="vehicle_name"
                        name="name"
                        label={t('VEHICLE_NAME')}
                        value={vehicleData.name ?? ''}
                        onChange={setVehicleDataField}
                        required={true}
                        hasError={dataErrors.includes('name')}
                    />
                    <NumberField
                        id="fuel_consumption_avg"
                        name="fuel_consumption_avg"
                        label={t('FUEL_CONSUMPTION')}
                        value={vehicleData.fuel_consumption_avg ?? ''}
                        onChange={setVehicleDataField}
                        min="1"
                        max="9999.99"
                        step="0.01"
                    />
                    <NumberField
                        id="fuel_consumption_deviation"
                        name="fuel_consumption_deviation"
                        label={t('FUEL_CONSUMPTION_DEVIATION')}
                        value={vehicleData.fuel_consumption_deviation ?? ''}
                        onChange={setVehicleDataField}
                        min="1"
                        max="99999"
                        step="1"
                    />
                    <NumberField
                        id="fuel_tank_capacity1"
                        name="fuel_tank_capacity1"
                        label={t('FUEL_TANK_1')}
                        value={vehicleData.fuel_tank_capacity1 ?? ''}
                        onChange={setVehicleDataField}
                        min="0"
                        max="65536"
                        step="1"
                    />
                    <NumberField
                        id="fuel_tank_capacity2"
                        name="fuel_tank_capacity1"
                        label={t('FUEL_TANK_2')}
                        value={vehicleData.fuel_tank_capacity2 ?? ''}
                        onChange={setVehicleDataField}
                        min="0"
                        max="65536"
                        step="1"
                    />
                    <TextField
                        id="phone_number"
                        name="phone_number"
                        label={t('PHONE_NUMBER')}
                        value={vehicleData.phone_number ?? ''}
                        onChange={setVehicleDataField}
                        hasError={dataErrors.includes('phone_number')}
                        hint={t('common:PHONE_NUMBER_HINT')}
                    />
                    <TextField
                        id="comment"
                        name="comment"
                        label={t('COMMENT')}
                        value={vehicleData.comment ?? ''}
                        onChange={setVehicleDataField}
                    />
                </div>
                <div className="group">
                    <div key="assigned_device" className="field">
                        <label htmlFor="assigned_device">
                            {t('ASSIGNED_DEVICE')}
                        </label>
                        <select
                            name="device_id"
                            id="assigned_device"
                            value={vehicleData.device_id | ''}
                            onChange={setVehicleDataField}
                        >
                            <option value="" key="none">
                                {t('DEVICE_NOT_ASSIGNED')}
                            </option>
                            {deviceList.map((device) => (
                                <option
                                    key={'device_' + device.id}
                                    value={device.id ?? ''}
                                    disabled={
                                        !!vehicleList.find(
                                            (v: Vehicle) =>
                                                v.device_id === device.id,
                                        )
                                    }
                                >
                                    {device.serial_num}
                                </option>
                            ))}
                        </select>
                    </div>
                    {app.variant === 'fm' && (
                        <>
                            <div key="assigned_driver" className="field">
                                <label htmlFor="assigned_driver">
                                    {t('ASSIGNED_DRIVER')}
                                </label>
                                <select
                                    name="assigned_driver_id"
                                    id="assigned_driver"
                                    value={vehicleData.assigned_driver_id}
                                    disabled={!vehicleData.device_id}
                                    onChange={setVehicleDataField}
                                >
                                    <option value="" key="none">
                                        {t('DRIVER_NOT_ASSIGNED')}
                                    </option>
                                    {driverList
                                        .filter(
                                            (d) => d.active && d.user_active,
                                        )
                                        .sort((a, b) =>
                                            (
                                                a.first_name +
                                                ' ' +
                                                a.last_name
                                            ).localeCompare(
                                                b.first_name +
                                                    ' ' +
                                                    b.last_name,
                                            ),
                                        )
                                        .map((driver: Driver) => (
                                            <option
                                                key={'driver_' + driver.id}
                                                value={driver.id}
                                            >
                                                {driver.first_name +
                                                    ' ' +
                                                    driver.last_name}
                                            </option>
                                        ))}
                                </select>
                            </div>
                        </>
                    )}
                    {selectedDriver && selectedDriver.vehicle_id && (
                        <span className="hint">
                            <IconInfo />
                            <p>{t('ASSIGNED_DRIVER_INFO')}</p>
                        </span>
                    )}
                    {app.variant === 'fm' && groupList && (
                        <div className="field">
                            <label htmlFor="group_id">
                                {t('ASSIGN_TO_GROUP')}
                            </label>
                            <Select
                                isMulti
                                name="group_id"
                                placeholder={t('SELECT_GROUPS')}
                                defaultValue={[]}
                                options={groupList
                                    .sort((a, b) =>
                                        a.name.localeCompare(b.name),
                                    )
                                    .map((group) => ({
                                        value: group.id,
                                        label: group.name,
                                    }))}
                                onChange={(selected) => {
                                    setSelectedGroups(
                                        selected?.map((item) => item.value) ??
                                            [],
                                    );
                                }}
                                styles={multiSelectStyles}
                            />
                        </div>
                    )}
                </div>
                {vehicleData.device_id && (
                    <div className="group">
                        <div key="policy_date" className="field">
                            <label htmlFor="policy_date">
                                {t('POLICY_DATE')}
                            </label>
                            <div>
                                <DatePicker
                                    id="policy_date"
                                    locale={'pl'}
                                    autoComplete={'off'}
                                    selected={vehicleData.policy_date}
                                    onChange={(date: Date) => {
                                        setVehicleData({
                                            ...vehicleData,
                                            policy_date: date,
                                        });
                                    }}
                                    dateFormat={DATEPICKER_DATE_FORMAT}
                                    calendarStartDay={1}
                                />
                            </div>
                        </div>
                        <div key="inspection_date" className="field">
                            <label htmlFor="inspection_date">
                                {t('INSPECTION_DATE')}
                            </label>
                            <div>
                                <DatePicker
                                    id="inspection_date"
                                    locale={'pl'}
                                    autoComplete={'off'}
                                    selected={vehicleData.inspection_date}
                                    onChange={(date: Date) => {
                                        setVehicleData({
                                            ...vehicleData,
                                            inspection_date: date,
                                        });
                                    }}
                                    dateFormat={DATEPICKER_DATE_FORMAT}
                                    calendarStartDay={1}
                                />
                            </div>
                        </div>
                        {/*<div key="inspection_distance" className="field">*/}
                        {/*    <label htmlFor="inspection_distance">{t('INSPECTION_DISTANCE')}</label>*/}
                        {/*    {!vehicleData.device_id && <input type="text" id="inspection_distance" name="inspection_distance" value="" readOnly={true}/>}*/}
                        {/*    {vehicleData.device_id && <input*/}
                        {/*        id="inspection_distance" name="inspection_distance"*/}
                        {/*        value={vehicleData.inspection_distance} onChange={setVehicleDataField}*/}
                        {/*        type="number" min={10} max={1000000000} step={1}*/}
                        {/*    />}*/}
                        {/*</div>*/}
                        <div key="diagnostic_date" className="field">
                            <label htmlFor="diagnostic_date">
                                {t('DIAGNOSTIC_DATE')}
                            </label>
                            <div>
                                <DatePicker
                                    id="diagnostic_date"
                                    locale={'pl'}
                                    autoComplete={'off'}
                                    selected={vehicleData.diagnostic_date}
                                    onChange={(date: Date) => {
                                        setVehicleData({
                                            ...vehicleData,
                                            diagnostic_date: date,
                                        });
                                    }}
                                    dateFormat={DATEPICKER_DATE_FORMAT}
                                    calendarStartDay={1}
                                />
                            </div>
                        </div>
                    </div>
                )}
            </>
        );
    };

    return (
        <form id="vehicle-add-form" onSubmit={createVehicle}>
            <RightPane
                id="vehicle-add"
                className="vehicle-add panel-right-form panel-right-entity-details"
                ref={paneRef}
                title={t('CREATE_VEHICLE')}
                onComponentHidden={onHide}
                body={() => vehicleDataFields()}
                footer={() => (
                    <button disabled={updateInProgress} className="button save">
                        {t('SAVE')}
                    </button>
                )}
            />
        </form>
    );
};

export default AddVehicle;
