import Connection from '../Connection';
import moment, {Moment} from 'moment';
import {store} from '../redux/store/store';
import {addDriver, setDriverList, updateDriver} from '../redux/actions/driverActions';
import type {Driver, Identifier, IdentifierAssignment, IdentifierAssignmentUpdate} from '../utils/interfaces/driver';
import {setDriverServiceInitialized} from '../redux/actions/appActions';
import {addIdentifier, setIdentifierList, updateIdentifier} from '../redux/actions/identifierActions';
import {
    addIdentifierAssignment,
    removeIdentifierAssignment,
    setIdentifierAssignmentList,
    updateIdentifierAssignment,
} from '../redux/actions/driverToIdentifierActions';
import type {Vehicle} from '../utils/interfaces/vehicle';

export default class DriversService {
    handlers = {};

    constructor(connection: Connection) {
        this.connection = connection;
        this.connection.addHandler('drivers.driver_created', (notification) => {
            let d: Driver = notification.data;
            console.debug('Drivers::EVENT(drivers.driver_created) => %O', d);
            store.dispatch(addDriver(d));
        });
        this.connection.addHandler('drivers.driver_updated', (notification) => {
            let d: Driver = notification.data;
            console.debug('Drivers::EVENT(drivers.driver_updated) => %O', d);
            store.dispatch(updateDriver(d));
        });
        this.connection.addHandler(
            'drivers.identifier_created',
            (notification) => {
                let i: Identifier = notification.data;
                console.debug(
                    'Drivers::EVENT(drivers.identifier_created) => %O',
                    i,
                );
                store.dispatch(addIdentifier(i));
            },
        );
        this.connection.addHandler(
            'drivers.identifier_updated',
            (notification) => {
                const {identifier_id, active} = notification.data;
                let i: Identifier = {id: identifier_id, active: active};
                console.debug(
                    'Drivers::EVENT(drivers.identifier_updated) => %O',
                    i,
                );
                store.dispatch(updateIdentifier(i));
            },
        );
        this.connection.addHandler(
            'drivers.identifier_to_driver_created',
            (notification) => {
                let ia: IdentifierAssignment = notification.data;
                console.debug(
                    'Drivers::EVENT(drivers.identifier_to_driver_created) => %O',
                    ia,
                );
                store.dispatch(addIdentifierAssignment(ia));
            },
        );
        this.connection.addHandler(
            'drivers.identifier_to_driver_updated',
            (notification) => {
                let iau: IdentifierAssignmentUpdate = notification.data;
                console.debug(
                    'Drivers::EVENT(drivers.identifier_to_driver_updated) => %O',
                    iau,
                );
                const {identifierAssignmentList} = store.getState();
                let identifierAssignment: IdentifierAssignment =
                    identifierAssignmentList.find(
                        (ia: IdentifierAssignment) =>
                            ia.driver_to_identifier_id === iau.id,
                    );
                if (identifierAssignment) {
                    let now = (Date.now() / 1000) | 0;
                    if (iau.begin_ts <= now && iau.end_ts <= now) {
                        store.dispatch(removeIdentifierAssignment(iau));
                    } else {
                        store.dispatch(updateIdentifierAssignment(iau));
                    }
                    store.dispatch(
                        updateDriver({id: identifierAssignment.driver_id}),
                    );
                    store.dispatch(
                        updateIdentifier({
                            id: identifierAssignment.identifier_id,
                        }),
                    );
                }
            },
        );
        this.connection.addHandler(
            'drivers.identifier_to_driver_removed',
            (notification) => {
                let iau: IdentifierAssignmentUpdate = notification.data;
                store.dispatch(removeIdentifierAssignment(iau));
            },
        );

        this.connection.addHandler('drivers.user_assigned', (notification) => {
            let d: Driver = notification.data;
            d.id = d.driver_id;
            console.debug('Drivers::EVENT(drivers.user_assigned) => %O', d);
            store.dispatch(updateDriver(d));
        });
        this.connection.addHandler('drivers.user_removed', (notification) => {
            let d: Driver = notification.data;
            d.id = d.driver_id;
            d.user_id = null;
            console.debug('Drivers::EVENT(drivers.user_removed) => %O', d);
            store.dispatch(updateDriver(d));
        });

        this.connection.addHandler('account_created', (notification) => {
            const driverList = store.getState().driverList;
            if (driverList === null) {
                return;
            }
            let driver: Driver = driverList.find(
                (d: Driver) => d.user_id === notification.data.user_id,
            );
            if (!driver) {
                return;
            }
            this.getDriver(driver.id);
        });
        this.connection.addHandler('invitation_sent', (notification) => {
            if (notification.data.user_type !== 'driver') {
                return;
            }
            const driverList = store.getState().driverList;
            if (driverList === null) {
                return;
            }
            let driver: Driver = driverList.find(
                (d: Driver) => d.email === notification.data.user_email,
            );
            if (!driver) {
                return;
            }
            this.getDriver(driver.id);
        });
    }

    initStore() {
        const {app} = store.getState();
        if (app.driverServiceInitialized === true) {
            console.debug(
                'DriverService::initStore() => store already initialized',
            );
            return;
        }
        console.debug('DriverService::initStore() => initializing store...');
        store.dispatch(setDriverServiceInitialized(true));
        const drivers = new Promise((resolve) => {
            this.getDrivers(resolve);
        });
        const identifiers = new Promise((resolve) => {
            this.getIdentifiers(resolve);
        });
        const relations = new Promise((resolve) => {
            this.getRelations(resolve);
        });
        Promise.all([drivers, identifiers, relations]).then((values) => {
            console.debug(
                'DriverService::initStore() => store initialized',
                values,
            );
        });
    }

    getDrivers(callbackSuccess: Function, callbackError: Function) {
        let onSuccess = (result) => {
            let drivers = result.map((d: Driver) => {
                d.created = moment(d.creation_ts * 1000);
                return d;
            });
            store.dispatch(setDriverList(drivers));
            callbackSuccess && callbackSuccess(drivers);
        };
        this._handleQuery(
            'getDrivers',
            'drivers.getDrivers',
            {},
            onSuccess,
            callbackError,
        );
    }

    getIdentifiers(callbackSuccess: Function, callbackError: Function) {
        let onSuccess = (result) => {
            store.dispatch(setIdentifierList(result));
            callbackSuccess && callbackSuccess(result);
        };
        this._handleQuery(
            'getIdentifiers',
            'drivers.getIdentifiers',
            {},
            onSuccess,
            callbackError,
        );
    }

    getRelations(callbackSuccess: Function, callbackError: Function) {
        let onSuccess = (result) => {
            store.dispatch(setIdentifierAssignmentList(result));
            const _driverList = store.getState().driverList.map((d: Driver) => {
                const identifierAssignment = result.find(
                    (i: IdentifierAssignment) => i.driver_id === d.id,
                );
                if (identifierAssignment !== undefined) {
                    const identifier = store
                        .getState()
                        .identifierList.find(
                            (i: Identifier) =>
                                i.id === identifierAssignment.identifier_id,
                        );
                    return {
                        ...d,
                        identifier: identifier ? identifier : null,
                    };
                } else {
                    return {
                        ...d,
                        identifier: null,
                    };
                }
            });
            store.dispatch(setDriverList(_driverList));
            callbackSuccess && callbackSuccess(result);
        };
        const args = {
            filter_begin_ts: (Date.now() / 1000 - 1) | 0,
            filter_end_ts: (Date.now() / 1000) | 0,
        };
        this._handleQuery(
            'getRelations',
            'drivers.getDriversToIdentifiers',
            args,
            onSuccess,
            callbackError,
        );
    }

    addHandler(event: string, handler: Function) {
        if (this.handlers.hasOwnProperty(event)) {
            this.connection.removeHandler(
                'drivers.' + event,
                this.handlers[event],
            );
        }
        this.handlers[event] = handler;
        this.connection.addHandler('drivers.' + event, handler);
    }

    getDriver(
        driverId: number,
        callbackSuccess: Function,
        callbackError: Function,
    ) {
        let onSuccess = (result: Driver[]) => {
            let driver;
            if (result.length > 0) {
                driver = result.shift();
                store.dispatch(addDriver(driver));
                store.dispatch(updateDriver(driver));
            } else {
                driver = null;
            }
            callbackSuccess && callbackSuccess(driver);
        };
        this._handleQuery(
            'getDrivers',
            'drivers.getDrivers',
            {filter_driver_id: driverId},
            onSuccess,
            callbackError,
        );
    }

    findAll(callbackSuccess: Function, callbackError: Function) {
        let onSuccess = (result) => {
            let drivers = result.map((d: Driver) => {
                d.created = moment(d.creation_ts * 1000);
                store.dispatch(addDriver(d));
                return d;
            });
            callbackSuccess && callbackSuccess(drivers);
        };
        this._handleQuery(
            'findAll',
            'drivers.getDrivers',
            {},
            onSuccess,
            callbackError,
        );
    }

    create(driverData, callbackSuccess: Function, callbackError: Function) {
        this._handleQuery(
            'create',
            'drivers.createDriver',
            driverData,
            callbackSuccess,
            callbackError,
        );
    }

    update(driverData, callbackSuccess: Function, callbackError: Function) {
        let onSuccess = (d: Driver) => {
            store.dispatch(updateDriver(d));
            callbackSuccess && callbackSuccess(d);
        };
        this._handleQuery(
            'update',
            'drivers.updateDriver',
            driverData,
            onSuccess,
            callbackError,
        );
    }

    enable(driverId, callbackSuccess: Function, callbackError: Function) {
        this._handleQuery(
            'enable',
            'drivers.enableDriver',
            {driver_id: driverId},
            callbackSuccess,
            callbackError,
        );
    }

    disable(driverId, callbackSuccess: Function, callbackError: Function) {
        this._handleQuery(
            'disable',
            'drivers.disableDriver',
            {driver_id: driverId},
            callbackSuccess,
            callbackError,
        );
    }

    invite(driverData: Driver, callbackSuccess: Function, callbackError: Function) {
        this._handleQuery(
            'invite',
            'drivers.inviteDriverEmail',
            driverData,
            callbackSuccess,
            callbackError,
        );
    }

    // findAllIdentifiers(callbackSuccess: Function, callbackError: Function) {
    //     this._handleQuery("findAllIdentifiers", "drivers.getIdentifiers", {}, callbackSuccess, callbackError);
    // }

    createIdentifier(
        identifierData,
        callbackSuccess: Function,
        callbackError: Function,
    ) {
        this._handleQuery(
            'createIdentifier',
            'drivers.createIdentifier',
            identifierData,
            callbackSuccess,
            callbackError,
        );
    }

    enableIdentifier(
        identifierId: number,
        callbackSuccess: Function,
        callbackError: Function,
    ) {
        this._handleQuery(
            'enableIdentifier',
            'drivers.enableIdentifier',
            {identifier_id: identifierId},
            callbackSuccess,
            callbackError,
        );
    }

    disableIdentifier(
        identifierId: number,
        callbackSuccess: Function,
        callbackError: Function,
    ) {
        this._handleQuery(
            'disableIdentifier',
            'drivers.disableIdentifier',
            {identifier_id: identifierId},
            callbackSuccess,
            callbackError,
        );
    }

    findIdentifierAssignments(
        identifierId: number,
        start: Moment,
        end: Moment,
        callbackSuccess: Function,
        callbackError: Function,
    ) {
        let args = {
            filter_identifier_id: identifierId,
            filter_begin_ts: parseInt(start.format('X')),
            filter_end_ts: parseInt(end.format('X')),
        };
        this._handleQuery(
            'findIdentifierAssignments',
            'drivers.getDriversToIdentifiers',
            args,
            callbackSuccess,
            callbackError,
        );
    }

    createAssignment(
        identifierId: number,
        driverId: number,
        begin: Moment,
        end: Moment,
        callbackSuccess: Function,
        callbackError: Function,
    ) {
        let args = {
            driver_id: driverId,
            identifier_id: identifierId,
            begin_ts: parseInt(begin.format('X')),
            end_ts: parseInt(end.format('X')),
        };
        this._handleQuery(
            'createAssignment',
            'drivers.createDriverToIdentifier',
            args,
            callbackSuccess,
            callbackError,
        );
    }

    updateAssignment(
        assignmentId: number,
        start: number,
        end: number,
        callbackSuccess: Function,
        callbackError: Function,
    ) {
        let args = {
            driver_to_identifier_id: assignmentId,
        };
        if (start) args.begin_ts = start;
        if (end) args.end_ts = end;
        this._handleQuery(
            'updateAssignment',
            'drivers.updateDriverToIdentifier',
            args,
            callbackSuccess,
            callbackError,
        );
    }

    removeAssignment(
        assignmentId: number,
        callbackSuccess: Function,
        callbackError: Function,
    ) {
        const args = {
            driver_to_identifier_id: assignmentId,
        };
        this._handleQuery(
            'removeAssignment',
            'drivers.removeDriverToIdentifier',
            args,
            callbackSuccess,
            callbackError,
        );
    }

    linkToUser(
        driverId: number,
        callbackSuccess: Function,
        callbackError: Function,
    ) {
        this._handleQuery(
            'linkToUser',
            'drivers.createDriverToUser',
            {driver_id: driverId},
            callbackSuccess,
            callbackError,
        );
    }

    unlinkFromUser(
        driverId: number,
        callbackSuccess: Function,
        callbackError: Function,
    ) {
        this._handleQuery(
            'unlinkFromUser',
            'drivers.removeDriverToUser',
            {driver_id: driverId},
            callbackSuccess,
            callbackError,
        );
    }

    assignToVehicle(
        driverId: number,
        vehicleId: number,
        callbackSuccess: Function,
        callbackError: Function,
    ) {
        let begin = moment();
        let end = moment().add(1, 'month');
        let args = {
            driver_id: parseInt(driverId),
            vehicle_id: parseInt(vehicleId),
            begin_ts: parseInt(begin.format('X')),
            end_ts: parseInt(end.format('X')),
        };
        this._handleQuery(
            'assignToVehicle',
            'drivers.grantAccessToVehicle',
            args,
            callbackSuccess,
            callbackError,
        );
    }

    unassignFromVehicle(
        vehicle: Vehicle,
        callbackSuccess: Function,
        callbackError: Function,
    ) {
        let args = {
            vehicle_id: vehicle.vehicle_id,
        };
        this._handleQuery(
            'unassignFromVehicle',
            'drivers.terminateAccessToVehicle',
            args,
            callbackSuccess,
            callbackError,
        );
    }

    _handleQuery(
        f,
        method,
        args,
        callbackSuccess: Function,
        callbackError: Function,
    ) {
        let query = new Promise((resolve, reject) => {
            this.connection.query(method, args, resolve, reject);
        });
        query
            .then((result) => {
                console.debug('DriversService::%s => result: %o', f, result);
                callbackSuccess && callbackSuccess(result);
            })
            .catch((reason) => {
                console.warn('DriversService::%s => reason: %s', f, reason);
                callbackError && callbackError(reason);
            });
    }
}
