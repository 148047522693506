import React, {useState} from 'react';
import {useTranslation} from 'react-i18next';
import {toast} from 'react-toastify';

import RightPane from '../../../components/RightPane/RightPane';
import {TextField} from '../../../components/Input/Input';

/**
 *
 * @param exchange {AtlasUser}
 * @param setExchange {Function}
 * @param atlasService {AtlasService}
 * @param fetchAtlasUsers {Function}
 * @param onClose {Function}
 * @returns {JSX.Element}
 * @constructor
 */
const EditTransportExchange = ({
    exchange,
    setExchange,
    atlasService,
    fetchAtlasUsers,
    onClose,
}) => {
    const {t} = useTranslation(['Settings', 'common']);

    const [comment, setComment] = useState(
        typeof exchange.comment === 'string' ? exchange.comment : '',
    );

    const submitHandler = () => {
        atlasService.editUser(
            {id: exchange.id, comment: comment.trim()},
            (result) => {
                console.debug('EditTransportExchange :: submitHandler', result);
                toast.success(t('EDIT_TRANSPORT_EXCHANGE_SUCCESS'));
                setExchange(result);
                fetchAtlasUsers();
                onClose();
            },
            (error) => {
                console.error('EditTransportExchange :: submitHandler', error);
                toast.error(t('EDIT_TRANSPORT_EXCHANGE_ERROR'));
            },
        );
    };

    const Body = () => {
        return (
            <div className="group">
                <TextField
                    id="comment"
                    name="comment"
                    label={t('common:COMMENT')}
                    value={comment}
                    onChange={(e) => setComment(e.target.value)}
                />
            </div>
        );
    };

    const Footer = () => (
        <>
            <button type="reset" className="button edit" onClick={onClose}>
                {t('common:CANCEL')}
            </button>
            <button className="button save" onClick={submitHandler}>
                {t('common:SAVE')}
            </button>
        </>
    );

    return (
        <RightPane
            id="edit-transport-exchange"
            className="panel-right-form panel-right-entity-details"
            title={t(`common:${exchange.exchange}`)}
            onComponentHidden={onClose}
            body={Body}
            footer={Footer}
        />
    );
};

export default EditTransportExchange;
