import {useRef} from 'react';
import {useSelector} from 'react-redux';
import type {RootState} from '../redux/reducers/rootReducer';

// useSelector without re-render
export default function useSelectorRef(
    selectHandler: (state: RootState) => void,
) {
    const ref = useRef();

    useSelector(selectHandler, (_, b) => {
        ref.current = b;
        return true;
    });

    return ref.current;
}
