import React, {useCallback, useEffect, useRef, useState} from 'react';
import {withTranslation} from 'react-i18next';
import {toast} from 'react-toastify';
import L from 'leaflet';
import DragAndDropCreateRoute from '../DragAndDropCreateRoute/DragAndDropCreateRoute';
import {mapIcons} from '../../utils/mapIcons';
import {getRoute} from '../../services/OpenRouteService';
import 'leaflet.gridlayer.googlemutant';
import './OrderRouteCreatorStyles.scss';

const createDnDRoutePoints = (route) => {
    if (route) {
        const routeObject = JSON.parse(route);
        return routeObject.map((row) => {
            return {
                id: Math.random().toString(16).slice(2),
                localization: row[2],
                time: row[3],
                lat: row[0],
                lng: row[1],
                description: row[4],
                poi: row[5],
            };
        });
    } else {
        return [];
    }
};

function OrderRouteCreator({
    setRouteCreatorEnabled,
    route,
    setRoute,
    t,
    routeCorridorPoints,
    setRouteCorridorPoints,
    setRouteUrl,
    POIList,
    POITypeList,
    setDistance,
}) {
    const [routePoints, setRoutePoints] = useState(createDnDRoutePoints(route));
    const [auxiliaryMarkerVisible, setAuxiliaryMarkerVisible] = useState(false);
    const [newPointLatLng, setNewPointLatLng] = useState(null);
    const [newRouteCorridor, setNewRouteCorridor] = useState(
        routeCorridorPoints || [],
    );
    const map = useRef(null);
    const markerLayerRef = useRef(null);
    const routeLayerRef = useRef(null);
    const auxiliaryMarker = useRef(null);

    useEffect(() => {
        let roads = L.tileLayer(
                `${process.env.REACT_APP_MAPS_URL}roads/{z}/{x}/{y}.png`,
                {
                    attribution:
                        '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors',
                },
            ),
            tiles = L.tileLayer(
                `${process.env.REACT_APP_MAPS_URL}default/{z}/{x}/{y}.png`,
                {
                    attribution:
                        '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors',
                },
            ),
            osm = L.tileLayer(
                'http://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png',
                {
                    attribution:
                        '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors',
                },
            ),
            googleRoadMap = L.gridLayer.googleMutant({
                type: 'roadmap',
            }),
            googleHybrid = L.gridLayer.googleMutant({
                type: 'hybrid',
            });

        if (!map.current) {
            map.current = L.map('order-route-map', {
                center: [51.505, 20],
                zoom: 5,
                zoomControl: false,
            });
            roads.addTo(map.current);
            L.control.zoom().setPosition('topright').addTo(map.current);
            L.control
                .layers({
                    'Treesat Routes': roads,
                    'Treesat Basic': tiles,
                    'Open Street Map': osm,
                    'Google Roadmap': googleRoadMap,
                    'Google Hybrid': googleHybrid,
                })
                .setPosition('bottomright')
                .addTo(map.current);
            markerLayerRef.current = L.featureGroup().addTo(map.current);
            routeLayerRef.current = L.polyline([], {
                color: '#EB0000',
                weight: 5,
            }).addTo(map.current);
            auxiliaryMarker.current = L.marker([0, 0], {
                draggable: true,
                icon: mapIcons.iconDefault,
            });
            auxiliaryMarker.current.on('dragend', (evt) => {
                setNewPointLatLng({
                    lat: evt.target._latlng.lat,
                    lng: evt.target._latlng.lng,
                });
            });
        }
        if (newRouteCorridor) {
            routeLayerRef.current.setLatLngs(newRouteCorridor);
        }
        console.debug('OrderRouteCreator::[] => Leaflet ready!');
    });
    useEffect(() => {
        if (auxiliaryMarkerVisible) {
            auxiliaryMarker.current.addTo(map.current);
            auxiliaryMarker.current.setLatLng(map.current.getCenter());
            setNewPointLatLng(map.current.getCenter());
        } else {
            map.current.removeLayer(auxiliaryMarker.current);
        }
    }, [auxiliaryMarkerVisible]);

    useEffect(() => {
        if (newPointLatLng) {
            auxiliaryMarker.current.setLatLng([
                newPointLatLng.lat,
                newPointLatLng.lng,
            ]);
            map.current.setView({
                lat: newPointLatLng.lat,
                lng: newPointLatLng.lng,
            });
        }
    }, [newPointLatLng]);

    useEffect(() => {
        markerLayerRef.current.clearLayers();
        routePoints.forEach((point) => {
            const markerOptions = {
                id: point.id,
                icon: L.icon({
                    iconUrl: require('../../graphics/pin_def1_dark.png')
                        .default,
                    iconSize: [68, 41],
                    iconAnchor: [34, 41],
                }),
            };
            if (
                point.poi &&
                typeof point.poi.type === 'number' &&
                POITypeList
            ) {
                const poiIcon: string = POITypeList.find(
                    (poiType) => poiType.id === point.poi.type,
                ).icon;
                if (poiIcon) {
                    markerOptions.icon = L.icon({
                        iconUrl: require(
                            `../../graphics/PoiTypeIcons/${poiIcon}`,
                        ).default,
                        iconSize: [41, 41],
                        iconAnchor: [20.5, 41],
                    });
                }
            }
            L.marker([point.lat, point.lng], markerOptions).addTo(
                markerLayerRef.current,
            );
        });
    }, [routePoints, POITypeList]);

    const recalculateRoute = useCallback(() => {
        if (routePoints.length >= 2) {
            getRoute(
                routePoints.map((routePoint) => [
                    routePoint.lat,
                    routePoint.lng,
                ]),
            )
                .then((result) => {
                    console.debug(
                        'OrderRouteCreator::getRoute() => result: %O',
                        result,
                    );
                    setNewRouteCorridor(result.points);
                    setRouteUrl(result.url);
                    setDistance(result.distance);
                    // routeLayerRef.current.setLatLngs(result.points);
                })
                .catch((reason) => {
                    if (reason.status === 404) {
                        console.warn(
                            'OrderRouteCreator::getRoute() => %O',
                            reason.response,
                        );
                        toast.warn(
                            t(
                                'THE_ROUTE_COULD_NOT_BE_CALCULATED_FOR_THE_SELECTED_POINTS',
                            ),
                        );
                    } else {
                        console.error(
                            'OrderRouteCreator::getRoute() => %O',
                            reason,
                        );
                        toast.error(t('ROUTE_CALCULATION_ERROR'));
                    }
                });
        } else {
            toast.info(
                'ℹ ' + t('THE_ROUTE_MUST_CONSIST_OF_AT_LEAST_2_POINTS'),
            );
        }
    }, [t, routePoints, setDistance, setRouteUrl]);

    // autofire on routePoints change
    useEffect(() => {
        if (routePoints.length < 2) {
            return;
        }
        recalculateRoute();
    }, [recalculateRoute, routePoints]);

    useEffect(() => {
        if (newRouteCorridor.length === 0) {
            return;
        }
        routeLayerRef.current.setLatLngs(newRouteCorridor);
        if (newRouteCorridor.length) {
            map.current.fitBounds(routeLayerRef.current.getBounds());
        }
        // if (newRouteCorridor.length === 0 && routePoints.length > 1) {
        //     recalculateRoute();
        // } else {
        //     routeLayerRef.current.setLatLngs(newRouteCorridor);
        //     if (newRouteCorridor.length) {
        //         map.current.fitBounds(routeLayerRef.current.getBounds());
        //     }
        // }
    }, [newRouteCorridor]);

    function setNewRoute() {
        if (routePoints.length < 2) {
            toast.info(
                'ℹ ' + t('THE_ROUTE_MUST_CONSIST_OF_AT_LEAST_2_POINTS'),
            );
        } else if (newRouteCorridor?.length === 0) {
            toast.info(
                'ℹ ' +
                    t(
                        'THE_ROUTE_COULD_NOT_BE_CALCULATED_FOR_THE_SELECTED_POINTS',
                    ),
            );
        } else {
            setRoute(
                JSON.stringify(
                    routePoints.map((routePoint) => [
                        routePoint.lat,
                        routePoint.lng,
                        routePoint.localization,
                        routePoint.time,
                        routePoint.description,
                        routePoint.poi ? routePoint.poi : '',
                    ]),
                ),
            );
            setRouteCorridorPoints(newRouteCorridor);
            setRouteCreatorEnabled(false);
            // map.current.fitBounds(routeLayerRef.current.getBounds());
        }
    }

    return (
        <div id={'order-route-creator'}>
            <div className={'creator-and-map'}>
                <div className={'route-creator'}>
                    <span className={'header'}>{t('SET_A_ROUTE')}</span>
                    <DragAndDropCreateRoute
                        routePoints={routePoints}
                        setRoutePoints={setRoutePoints}
                        newPointLatLng={newPointLatLng}
                        setNewPointLatLng={setNewPointLatLng}
                        setAuxiliaryMarkerVisible={setAuxiliaryMarkerVisible}
                        setNewRouteCorridor={setNewRouteCorridor}
                        newRouteCorridor={newRouteCorridor}
                        POIList={POIList}
                        POITypeList={POITypeList}
                    />
                    <div className={'footer'}>
                        <button
                            className="button cancel"
                            onClick={() => setRouteCreatorEnabled(false)}
                        >
                            {t('CANCEL')}
                        </button>
                        <button
                            className="button action"
                            onClick={recalculateRoute}
                            disabled={routePoints.length < 2}
                        >
                            {t('RECALCULATE_ROUTE')}
                        </button>
                        <button className="button save" onClick={setNewRoute}>
                            {t('SAVE_ROUTE')}
                        </button>
                    </div>
                </div>
                <div id={'order-route-map'} />
            </div>
        </div>
    );
}

export default withTranslation()(OrderRouteCreator);
