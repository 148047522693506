export const IconSignal = () => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="15.905"
            height="16.016"
            viewBox="0 0 15.905 16.016"
        >
            <g transform="translate(-2.046 -1.996)" opacity="0.6">
                <path
                    d="M11.778,11.778m-.778,0A.778.778,0,1,0,11.778,11a.778.778,0,0,0-.778.778"
                    transform="translate(-1.778 -1.777)"
                    fill="none"
                    stroke="#24344e"
                    strokeLinecap="square"
                    strokeLinejoin="round"
                    strokeWidth="1.6"
                />
                <path
                    d="M14.479,12.385a3.889,3.889,0,1,0-7.178,0"
                    transform="translate(-0.89 -0.888)"
                    fill="none"
                    stroke="#24344e"
                    strokeLinecap="square"
                    strokeLinejoin="round"
                    strokeWidth="1.6"
                />
                <path
                    d="M16.46,12.7a7,7,0,1,0-12.922,0"
                    transform="translate(0 0)"
                    fill="none"
                    stroke="#24344e"
                    strokeLinecap="square"
                    strokeLinejoin="round"
                    strokeWidth="1.6"
                />
                <path
                    d="M9,19l2.333-7,2.333,7"
                    transform="translate(-1.334 -1.999)"
                    fill="none"
                    stroke="#24344e"
                    strokeLinecap="square"
                    strokeLinejoin="round"
                    strokeWidth="1.6"
                />
                <path
                    d="M10,19h3.111"
                    transform="translate(-1.556 -3.555)"
                    fill="none"
                    stroke="#24344e"
                    strokeLinecap="square"
                    strokeLinejoin="round"
                    strokeWidth="1.6"
                />
            </g>
        </svg>
    );
};
