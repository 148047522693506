import React, {Suspense} from 'react';
import {createRoot} from 'react-dom/client';
import {ToastContainer} from 'react-toastify';
import {Provider} from 'react-redux';
import moment from 'moment';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import './i18n/i18n';
import {Loader} from './components/Loader/Loader';
import {store} from './redux/store/store';

moment().locale('pl');

const container = document.getElementById('root');
const root = createRoot(container);
root.render(
    <Suspense fallback={<Loader />}>
        <Provider store={store}>
            <App />
            <ToastContainer
                theme="colored"
                bodyClassName="toast-body"
                autoClose={2500}
            />
        </Provider>
    </Suspense>,
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
