import {
    ADD_CLIENT,
    UPDATE_CLIENT,
    REMOVE_CLIENT,
    SET_CLIENT_LIST,
} from '../actions/clientListActions';
import type {Counterparty} from '../../utils/interfaces/counterparty';

const initialState: ?(Counterparty[]) = null;

export default function clientList(state = initialState, action) {
    switch (action.type) {
        case SET_CLIENT_LIST:
            return action.clients;

        case ADD_CLIENT:
            if (state === null) {
                return state;
            }
            let index = state.findIndex(
                (client) =>
                    client.counterparty_id === action.client.counterparty_id,
            );
            if (index === -1) {
                return [...state, action.client];
            }
            return state;

        case UPDATE_CLIENT:
            if (state === null) {
                return state;
            }
            return state.map((client) => {
                if (client.counterparty_id !== action.client.counterparty_id) {
                    return client;
                }
                return Object.assign({}, client, action.client);
            });

        case REMOVE_CLIENT:
            if (state === null) {
                return state;
            }
            return state.filter(
                (client) =>
                    client.counterparty_id !== action.client.counterparty_id,
            );

        default:
            return state;
    }
}
