import React from 'react';

export const IconFuel = () => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="12.6"
            height="14"
            viewBox="0 0 12.6 14"
        >
            <g transform="translate(-688 -249.475)">
                <path
                    d="M-620.6,1102.8h1.4v8.4h-1.4Zm9.8-2.8h1.4v14h-8.4v-14h7Zm0,1.4h-5.6v5.6h5.6Zm-11.2,2.8h1.4v-1.4H-622Zm2.8,7h1.4v-1.4h-1.4Z"
                    transform="translate(1310 -850.525)"
                    fill="#25354d"
                    opacity="0.703"
                />
            </g>
        </svg>
    );
};

export const IconFuelWithPercent = () => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="13.6"
            height="15"
            viewBox="0 0 13.6 15"
        >
            <g transform="translate(-766.5 -248.975)">
                <path
                    d="M2289.6,3178h-8.4v-2.8h-2.8v-7H2277v-1.4h2.8v7h1.4V3164h8.4v14Zm-2.19-10.216a1.3,1.3,0,0,0-.536.112,1.2,1.2,0,0,0-.427.324,1.512,1.512,0,0,0-.281.517,2.231,2.231,0,0,0-.1.7,2.273,2.273,0,0,0,.1.7,1.556,1.556,0,0,0,.281.524,1.179,1.179,0,0,0,.427.328,1.294,1.294,0,0,0,.536.113,1.266,1.266,0,0,0,.532-.113,1.2,1.2,0,0,0,.424-.328,1.635,1.635,0,0,0,.283-.524,2.19,2.19,0,0,0,.1-.7,2.151,2.151,0,0,0-.1-.7,1.591,1.591,0,0,0-.283-.517,1.214,1.214,0,0,0-.424-.324A1.268,1.268,0,0,0,2287.41,3167.784Zm-.944-2.08h0l-2.784,5.392h.769l2.784-5.392Zm-2.976,0a1.3,1.3,0,0,0-.536.112,1.215,1.215,0,0,0-.427.324,1.525,1.525,0,0,0-.28.517,2.238,2.238,0,0,0-.1.7,2.278,2.278,0,0,0,.1.7,1.572,1.572,0,0,0,.28.524,1.194,1.194,0,0,0,.427.327,1.294,1.294,0,0,0,.536.113,1.268,1.268,0,0,0,.532-.113,1.2,1.2,0,0,0,.424-.327,1.622,1.622,0,0,0,.284-.524,2.2,2.2,0,0,0,.1-.7,2.157,2.157,0,0,0-.1-.7,1.562,1.562,0,0,0-.284-.517,1.217,1.217,0,0,0-.424-.324A1.27,1.27,0,0,0,2283.49,3165.7Zm3.92,4.64a.339.339,0,0,1-.281-.192,1.5,1.5,0,0,1-.12-.72,2.444,2.444,0,0,1,.032-.436.955.955,0,0,1,.087-.276.344.344,0,0,1,.129-.144.305.305,0,0,1,.152-.041.311.311,0,0,1,.152.041.351.351,0,0,1,.128.144.962.962,0,0,1,.087.276,2.444,2.444,0,0,1,.032.436,1.494,1.494,0,0,1-.12.72A.339.339,0,0,1,2287.41,3170.344Zm-3.92-2.08a.335.335,0,0,1-.28-.192,1.494,1.494,0,0,1-.12-.72,2.465,2.465,0,0,1,.032-.437.95.95,0,0,1,.088-.275.341.341,0,0,1,.128-.144.306.306,0,0,1,.3,0,.34.34,0,0,1,.128.144.95.95,0,0,1,.088.275,2.428,2.428,0,0,1,.031.437,1.494,1.494,0,0,1-.12.72A.335.335,0,0,1,2283.49,3168.264Z"
                    transform="translate(-1510 -2914.525)"
                    fill="#25354d"
                    stroke="rgba(0,0,0,0)"
                    strokeWidth="1"
                    opacity="0.703"
                />
            </g>
        </svg>
    );
};

export const IconFuelWithSum = () => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="13.6"
            height="15"
            viewBox="0 0 13.6 15"
        >
            <g transform="translate(-687.5 -286.5)">
                <path
                    d="M2289.6,3180h-8.4v-2.8h-2.8v-7H2277v-1.4h2.8v7h1.4V3166h8.4v14Zm-6.849-12.475v1.2l2.185,2.507-2.208,2.576v1.2h5.566v-1.656h-3.1l1.725-2.163-1.7-2H2288v-1.656Z"
                    transform="translate(-1589 -2879)"
                    fill="#25354d"
                    stroke="rgba(0,0,0,0)"
                    strokeWidth="1"
                    opacity="0.703"
                />
            </g>
        </svg>
    );
};

export const IconFuelWithGPS = () => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="13.84"
            height="15.06"
            viewBox="0 0 13.84 15.06"
        >
            <g transform="translate(-766.5 -286.5)">
                <g opacity="0.7">
                    <path
                        d="M2287.852,3176.947a1.7,1.7,0,0,1-.592-.352l.49-.59a1.653,1.653,0,0,0,.378.228.963.963,0,0,0,.383.087.508.508,0,0,0,.282-.06.192.192,0,0,0,.087-.169.16.16,0,0,0-.031-.1.308.308,0,0,0-.087-.074.93.93,0,0,0-.137-.068l-.185-.073-.405-.169a1.178,1.178,0,0,1-.25-.133.98.98,0,0,1-.21-.194.908.908,0,0,1-.143-.258.957.957,0,0,1-.052-.32.884.884,0,0,1,.09-.392,1.008,1.008,0,0,1,.25-.321,1.2,1.2,0,0,1,.383-.217,1.415,1.415,0,0,1,.488-.08,1.665,1.665,0,0,1,.567.1,1.411,1.411,0,0,1,.513.33l-.43.54a1.535,1.535,0,0,0-.318-.175.889.889,0,0,0-.332-.06.476.476,0,0,0-.25.055.18.18,0,0,0-.09.165.193.193,0,0,0,.128.178c.084.042.2.091.352.148l.4.155a1.067,1.067,0,0,1,.47.335.889.889,0,0,1,.166.555.964.964,0,0,1-.333.727,1.21,1.21,0,0,1-.4.228,1.583,1.583,0,0,1-.539.085A1.949,1.949,0,0,1,2287.852,3176.947Zm-5.753.01a1.4,1.4,0,0,1-.872-.83,2.114,2.114,0,0,1,0-1.455,1.556,1.556,0,0,1,.353-.535,1.515,1.515,0,0,1,.517-.332,1.683,1.683,0,0,1,.62-.115,1.414,1.414,0,0,1,.623.127,1.606,1.606,0,0,1,.438.3l-.45.55a1.239,1.239,0,0,0-.252-.173.79.79,0,0,0-.633,0,.733.733,0,0,0-.245.186.846.846,0,0,0-.163.3,1.245,1.245,0,0,0-.058.4,1.071,1.071,0,0,0,.205.7.8.8,0,0,0,.645.245.645.645,0,0,0,.137-.015.316.316,0,0,0,.113-.045v-.45h-.48v-.7h1.24v1.55a1.482,1.482,0,0,1-.465.282,1.675,1.675,0,0,1-.635.117A1.967,1.967,0,0,1,2282.1,3176.957Zm2.371.043v-3.25h1.19a2.351,2.351,0,0,1,.5.052,1.134,1.134,0,0,1,.42.18.909.909,0,0,1,.287.335,1.1,1.1,0,0,1,.108.513,1.137,1.137,0,0,1-.108.515.991.991,0,0,1-.287.352,1.243,1.243,0,0,1-.415.205,1.771,1.771,0,0,1-.49.067h-.35V3177Zm.86-1.71h.311a.518.518,0,0,0,.377-.121.451.451,0,0,0,.123-.339.346.346,0,0,0-.135-.31.688.688,0,0,0-.384-.09h-.291Zm4.27-2.091h-11.2v-6H2277v-1.4h2.8v6h1.4V3163h8.4v10.2h0Zm-6.848-7.479,2.185,2.507-2.208,2.576v1.2h5.566v-1.656h-3.1l1.725-2.162-1.7-2H2288v-1.656h-5.244Z"
                        transform="translate(-1510 -2876)"
                        fill="#25354d"
                        stroke="rgba(0,0,0,0)"
                        strokeWidth="1"
                    />
                </g>
            </g>
        </svg>
    );
};
