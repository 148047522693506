import type {License} from '../../utils/interfaces/license';

export const SET_LICENSE_LIST = 'SET_LICENSE_LIST';
export const UPDATE_LICENSE_LIST = 'UPDATE_LICENSE_LIST';

export function setLicenseList(licenses: License[]) {
    return {
        type: SET_LICENSE_LIST,
        licenses,
    };
}

export function updateLicenseList(license: License) {
    return {
        type: UPDATE_LICENSE_LIST,
        license,
    };
}
