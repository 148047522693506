import React from 'react';

export const IconRpm = () => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="14"
            height="14"
            viewBox="0 0 14 14"
        >
            <path
                d="M-573,900a7,7,0,0,0-7,7,7,7,0,0,0,7,7,7,7,0,0,0,7-7A7,7,0,0,0-573,900Zm0,1.4a5.607,5.607,0,0,1,5.6,5.6h-4.616l2.654-2.535-.967-1.012L-574.042,907H-578.6A5.606,5.606,0,0,1-573,901.4Z"
                transform="translate(580 -900)"
                fill="#25354d"
                opacity="0.703"
            />
        </svg>
    );
};
