import {
    ADD_GROUP,
    REMOVE_GROUP,
    SET_GROUP_LIST,
    UPDATE_GROUP,
} from '../actions/groupActions';
import type {Group} from '../../utils/interfaces/group';

/*
 * state === null means that store is not initialized
 * no action can be made unless store is initialized...
 */

const initialState: ?(Group[]) = null;
export default function groupList(state = initialState, action) {
    switch (action.type) {
        case SET_GROUP_LIST:
            return action.groups;

        case ADD_GROUP: {
            if (state !== null) {
                const index = state.findIndex(
                    (group) => group.id === action.group.id,
                );
                if (index === -1) {
                    return [...state, action.group];
                }
            }
            return state;
        }
        case UPDATE_GROUP: {
            if (state !== null) {
                return state.map((group) => {
                    if (group.id !== action.updatedGroup.id) {
                        return group;
                    } else {
                        return Object.assign({}, group, action.updatedGroup);
                    }
                });
            }
            return state;
        }
        case REMOVE_GROUP: {
            if (state !== null) {
                return state.filter((group) => {
                    return group.id !== action.groupId;
                });
            }
            return state;
        }
        default:
            return state;
    }
}
