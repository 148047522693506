import React, {MutableRefObject, useEffect, useRef, useState} from 'react';
import {useSelector} from 'react-redux';
import {useTranslation} from 'react-i18next';
import {useNavigate} from 'react-router-dom';

import {store} from '../../redux/store/store';
import {setAccountPaneVisible} from '../../redux/actions/appActions';
import RightPane from '../../components/RightPane/RightPane';
import LanguageSelector from '../../components/LanguageSelector/LanguageSelector';
import ChangePassword from './ChangePassword';
import EditAccount from './EditAccount';
import Newsletter from '../Newsletter/Newsletter';
import extractChanges from '../ChangeLog/extractChanges';
import ChangeLogPopup from '../ChangeLog/ChangeLogPopup';
import useServiceProvider from '../../utils/service';
import useVersion from '../../hooks/useVersion';
import {IconDocumentMulti, IconEdit} from '../../graphics/icons';
import type {RootState} from '../../redux/reducers/rootReducer';

import './Account.scss';

function Account() {
    let navigate = useNavigate();
    const {t, i18n} = useTranslation(['Account', 'common']);

    const {app, userData} = useSelector((state: RootState) => state);

    const [passwordMode, setPasswordMode] = useState(false);
    const [editMode, setEditMode] = useState(false);

    const [
        showNewsletterForm: boolean,
        setShowNewsletterForm: Function<boolean>,
    ] = useState(false);
    const [
        showNewsletterButton: boolean,
        setShowNewsletterButton: Function<boolean>,
    ] = useState(false);

    const version = useVersion();

    const {userService} = useServiceProvider();

    const [changes: Array, setChanges: Function<Array>] = useState([]);
    const changesRef: MutableRefObject<HTMLFormElement> = useRef();

    useEffect(() => {
        let isMounted = true;
        userService
            .getNewsletterList()
            .then(
                (
                    response: {
                        created_at: string,
                        email: string,
                        subject: string,
                    }[],
                ) => {
                    if (isMounted) {
                        setShowNewsletterButton(true);
                        if (response.length > 0) {
                            response.forEach((newsletter) => {
                                if (newsletter.subject === 'fleetmanager') {
                                    setShowNewsletterButton(false);
                                }
                            });
                        }
                    }
                },
            );

        return () => {
            isMounted = false;
        };
    }, [userService]);

    function showChangeLog(e: Event) {
        e.preventDefault();
        console.debug('Help() => Load ChangeLog...');

        let data = extractChanges('0.0.0', version.match(/\d+\.\d+\.\d+/)[0]);
        data.reverse();
        setChanges(data);
        changesRef.current.classList.add('visible');
    }

    function closeChangeLog(e: Event) {
        e.preventDefault();
        changesRef.current.classList.remove('visible');
    }

    const previewModeBody = () => {
        return (
            <>
                {userData && (
                    <div className="group">
                        <dl>
                            <dt>{t('FIRST_NAME')}</dt>
                            <dd>{userData.first_name}</dd>
                            <dt>{t('LAST_NAME')}</dt>
                            <dd>{userData.last_name}</dd>
                            <dt>{t('EMAIL')}</dt>
                            <dd>{userData.email}</dd>
                            <dt>{t('PHONE')}</dt>
                            <dd>{userData.phone_number}</dd>
                            <dt>{t('ROLE')}</dt>
                            <dd>{t('ACCOUNT_OF(' + userData.type + ')')}</dd>
                        </dl>
                        <div className="buttons">
                            <button
                                className="button edit"
                                onClick={(e: Event) => {
                                    e.preventDefault();
                                    setPasswordMode(true);
                                }}
                            >
                                {t('CHANGE_PASSWORD')}
                            </button>
                            <button
                                className="button red"
                                onClick={(e: Event) => {
                                    e.preventDefault();
                                    store.dispatch(
                                        setAccountPaneVisible(false),
                                    );
                                    userService.logout();
                                    navigate('/');
                                }}
                            >
                                {t('LOGOUT')}
                            </button>
                        </div>
                    </div>
                )}
                <div className="group info">
                    <img
                        src={
                            require('../../graphics/iko_info_round.png').default
                        }
                        alt=""
                    />
                    <div>
                        <p>
                            {t('MANUAL_HINT')}:<br />
                            <a
                                href={`${process.env.PUBLIC_URL}/manual/${i18n.language.includes('en') ? 'en' : 'pl'}/${app.variant}/${encodeURI('Treesat_Fleet_Manager_manual.pdf')}`}
                                target="_blank"
                                rel="noreferrer"
                            >
                                {t('MANUAL_LINK')}
                            </a>
                        </p>
                        <p>
                            {t('CONTACT_HINT')}:{' '}
                            <a href="mailto:info@treesat.io">info@treesat.io</a>
                        </p>
                        <p>
                            {t('SYSTEM_VERSION')}: <strong>{version}</strong>
                            <br />
                            <br />
                            <span
                                onClick={showChangeLog}
                                className="edit button changelog-btn"
                            >
                                <IconDocumentMulti />
                                {t('CHANGELOG')}
                            </span>
                            <br />
                        </p>
                    </div>
                </div>
                <div className="group language-selector-container">
                    <LanguageSelector loggedIn={true} />
                </div>
            </>
        );
    };

    return (
        <>
            {app.accountPaneVisible && (
                <>
                    <RightPane
                        id="account-pane"
                        className="account-pane panel-right-entity-details panel-right-form"
                        title={t('PROFILE')}
                        buttons={() => {
                            return (
                                <>
                                    {showNewsletterButton && (
                                        <button
                                            className="button save"
                                            onClick={() =>
                                                setShowNewsletterForm(true)
                                            }
                                        >
                                            {t('SUBSCRIBE')}
                                        </button>
                                    )}
                                    <button
                                        className="button action icon"
                                        onClick={() => setEditMode(true)}
                                        data-tip={t('CHANGE_DATA')}
                                    >
                                        <IconEdit />
                                    </button>
                                </>
                            );
                        }}
                        body={previewModeBody}
                        onComponentHidden={() => {
                            store.dispatch(setAccountPaneVisible(false));
                            setPasswordMode(false);
                            setEditMode(false);
                        }}
                    />
                    {editMode && (
                        <EditAccount
                            userData={userData}
                            userService={userService}
                            onHide={() => setEditMode(false)}
                        />
                    )}
                    {passwordMode && (
                        <ChangePassword
                            userService={userService}
                            onHide={() => setPasswordMode(false)}
                        />
                    )}
                    {showNewsletterForm && (
                        <Newsletter
                            onHide={() => setShowNewsletterForm(false)}
                            onSuccess={() => setShowNewsletterButton(false)}
                        />
                    )}
                    <ChangeLogPopup
                        popupRef={changesRef}
                        changes={changes}
                        onClose={closeChangeLog}
                    />
                </>
            )}
        </>
    );
}

export default Account;
