import Connection from '../Connection';
import {store} from '../redux/store/store';
import {setDevicesServiceInitialized} from '../redux/actions/appActions';
import type {AddDevice, Device} from '../utils/interfaces/device';
import {setDeviceList} from '../redux/actions/deviceActions';

export default class DevicesService {
    constructor(connection: Connection) {
        this.connection = connection;
    }

    initStore(groupId: null | number) {
        const {app} = store.getState();
        if (app.devicesServiceInitialized === true) {
            console.debug(
                'DevicesService::initStore() => store already initialized',
            );
            return;
        }
        console.debug('DevicesService::initStore() => initializing store...');
        store.dispatch(setDevicesServiceInitialized(true));
        const devices = new Promise((resolve) => {
            this.getDevices(groupId, (result: Device[]) => {
                resolve(result);
            });
        });
        Promise.all([devices]).then((values) => {
            console.debug(
                'DevicesService::initStore() => store initialized',
                values,
            );
        });
    }

    getDevices(
        groupId: null | number,
        callbackSuccess: Function,
        callbackError: Function,
    ) {
        const success = (devices: Device[]) => {
            this.getAssignedVehicleToDevice(groupId, (result) => {
                devices.forEach((device) => {
                    const vehicle = result?.find(
                        (v) => v.device_id === device.id,
                    );
                    if (vehicle) {
                        device.is_active = vehicle.active;
                        device.vehicle_name = vehicle.name;
                        device.vehicle_id = vehicle.vehicle_id;
                    } else {
                        device.is_active = '';
                        device.vehicle_name = '';
                        device.vehicle_id = null;
                    }
                });
                store.dispatch(setDeviceList(devices));
            });

            callbackSuccess && callbackSuccess(devices);
        };

        const args = {geocoding: true};
        let _groupId;
        if (typeof groupId === 'number') {
            _groupId = groupId;
        } else {
            _groupId = store.getState().app.selectedGroupId;
        }

        if (typeof _groupId === 'number') {
            const groupIndex = store
                .getState()
                .groupList?.findIndex((group) => group.id === _groupId);
            if (groupIndex !== -1) {
                args.group_id = _groupId;
            }
        }

        this._handleQuery(
            'getDevices',
            'account.getDevices',
            args,
            success,
            callbackError,
        );
    }

    getAssignedVehicleToDevice(
        groupId: number | null,
        callbackSuccess: Function,
        callbackError: Function,
    ) {
        const args = {
            include_name: true,
            include_active: true,
        };

        if (typeof groupId === 'number') {
            args.filter_group_id = groupId;
        }

        this._handleQuery(
            '_setAssignedVehicleToDevice',
            'vehicles.getVehicles',
            args,
            callbackSuccess,
            callbackError,
        );
    }

    assignDevice(
        data: AddDevice,
        callbackSuccess: Function,
        callbackError: Function,
    ) {
        this._handleQuery(
            'addDevice',
            'device.assign',
            data,
            callbackSuccess,
            callbackError,
        );
    }

    getGroupDevices(
        groupId: number,
        callbackSuccess: Function,
        callbackError: Function,
    ) {
        this._handleQuery(
            'getGroupDevices',
            'account.getDevices',
            {
                group_id: groupId,
                geocoding: true,
            },
            callbackSuccess,
            callbackError,
        );
    }

    _handleQuery(
        f,
        method,
        args,
        callbackSuccess: Function,
        callbackError: Function,
    ) {
        let query = new Promise((resolve, reject) => {
            this.connection.query(method, args, resolve, reject);
        });
        query
            .then((result) => {
                console.debug('DevicesService::%s => result: %o', f, result);
                callbackSuccess && callbackSuccess(result);
            })
            .catch((reason) => {
                console.warn('DevicesService::%s => reason: %s', f, reason);
                callbackError && callbackError(reason);
            });
    }
}
