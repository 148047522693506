export default class PuescService {
    constructor(connection) {
        this.connection = connection;
    }

    registerVehicle(
        vehicleId: number,
        callbackSuccess: Function,
        callbackError: Function,
    ) {
        this._handleQuery(
            'registerVehicle',
            'puesc.register_request',
            {vehicle_id: vehicleId},
            callbackSuccess,
            callbackError,
        );
    }

    unregisterVehicle(
        vehicleId: number,
        callbackSuccess: Function,
        callbackError: Function,
    ) {
        this._handleQuery(
            'unregisterVehicle',
            'puesc.unregister_request',
            {vehicle_id: vehicleId},
            callbackSuccess,
            callbackError,
        );
    }

    activateEtoll(
        vehicleId: number,
        callbackSuccess: Function,
        callbackError: Function,
    ) {
        this._handleQuery(
            'activateEtoll',
            'puesc.etoll_activate',
            {vehicle_id: vehicleId},
            callbackSuccess,
            callbackError,
        );
    }

    deactivateEtoll(
        vehicleId: number,
        callbackSuccess: Function,
        callbackError: Function,
    ) {
        this._handleQuery(
            'deactivateEtoll',
            'puesc.etoll_deactivate',
            {vehicle_id: vehicleId},
            callbackSuccess,
            callbackError,
        );
    }

    activateSent(
        vehicleId: number,
        callbackSuccess: Function,
        callbackError: Function,
    ) {
        this._handleQuery(
            'activateSent',
            'puesc.sent_activate',
            {vehicle_id: vehicleId},
            callbackSuccess,
            callbackError,
        );
    }

    deactivateSent(
        vehicleId: number,
        callbackSuccess: Function,
        callbackError: Function,
    ) {
        this._handleQuery(
            'deactivateSent',
            'puesc.sent_deactivate',
            {vehicle_id: vehicleId},
            callbackSuccess,
            callbackError,
        );
    }

    _handleQuery(
        f: string,
        method: string,
        args: {},
        callbackSuccess: Function,
        callbackError: Function,
    ) {
        const query = new Promise((resolve, reject) => {
            this.connection.query(method, args, resolve, reject);
        });
        query
            .then((result) => {
                console.debug('PuescService::%s => result: %o', f, result);
                callbackSuccess && callbackSuccess(result);
            })
            .catch((reason) => {
                console.warn('PuescService::%s => reason: %s', f, reason);
                callbackError && callbackError(reason);
            });
    }
}
