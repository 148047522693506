import React from 'react';
import {
    IconMinusCircle,
    IconQuestionMarkCircle,
    IconRoundedCircle,
} from '../graphics/icons';
import type {Vehicle} from './interfaces/vehicle';

export const getEtollStateIcon = (vehicle: Vehicle) => {
    const {puesc_etoll_state_front} = vehicle;

    let icon = <IconRoundedCircle color="grey" withoutCircle />;

    if (puesc_etoll_state_front === 'not_registered') {
        icon = <IconRoundedCircle color="grey" withoutCircle />;
    } else if (puesc_etoll_state_front === 'disabled') {
        icon = <IconRoundedCircle color="grey" />;
    } else if (puesc_etoll_state_front === 'ok_60') {
        icon = <IconRoundedCircle color="green" />;
    } else if (puesc_etoll_state_front === 'ok_600') {
        icon = <IconRoundedCircle color="yellow" />;
    } else if (
        puesc_etoll_state_front === 'error' ||
        puesc_etoll_state_front === 'no_device' ||
        puesc_etoll_state_front === 'too_old'
    ) {
        icon = <IconRoundedCircle color="red" />;
    } else if (puesc_etoll_state_front === 'bad_config') {
        icon = <IconRoundedCircle color="red" />;
    } else if (puesc_etoll_state_front === 'register_request') {
        icon = <IconQuestionMarkCircle />;
    } else if (puesc_etoll_state_front === 'not_paid') {
        icon = <IconMinusCircle />;
    }
    return icon;
};

export const getEtollStateColor = (vehicle: Vehicle) => {
    const {puesc_etoll_state_front} = vehicle;

    if (puesc_etoll_state_front === 'ok_60') {
        return '#00D309';
    }
    if (puesc_etoll_state_front === 'ok_600') {
        return '#FBBC04';
    }
    if (
        puesc_etoll_state_front === 'error' ||
        puesc_etoll_state_front === 'no_device' ||
        puesc_etoll_state_front === 'too_old' ||
        puesc_etoll_state_front === 'bad_config'
    ) {
        return '#EB0000';
    }

    return '#D0D3D9';
};

export const getEtollStateTooltipText = (vehicle: Vehicle) => {
    const {puesc_etoll_state_front} = vehicle;

    switch (puesc_etoll_state_front) {
        case 'not_registered':
            return 'VEHICLE_NOT_REGISTERED';
        case 'register_request':
            return 'AWAITING_REGISTRATION';
        case 'ok_60':
            return 'DATA_SENT_CORRECTLY';
        case 'ok_600':
            return 'DATA_SENT_CORRECTLY_WITH_DELAY';
        case 'error':
        case 'too_old':
        case 'no_device':
            return 'DATA_NOT_SENT_CORRECTLY';
        case 'bad_config':
            return 'BAD_CONFIG';
        case 'not_paid':
            return 'UNPAID_SERVICE';
        case 'disabled':
            return 'SERVICE_DISABLED';
        default:
            console.warn(
                'getEtollStateTooltipText :: unknown puesc_etoll_state_front',
                puesc_etoll_state_front,
            );
            return 'DATA_NOT_SENT_CORRECTLY';
    }
};
