import {ADD_ORDER, SET_ORDER_LIST, UPDATE_ORDER} from '../actions/orderActions';
import type {Order} from '../../utils/interfaces/order';

const initialState: ?(Order[]) = null;
export default function orderList(state = initialState, action) {
    switch (action.type) {
        case SET_ORDER_LIST: {
            return action.orders;
        }
        case ADD_ORDER: {
            if (state === null) {
                return state;
            }
            let index = state.findIndex(
                (order) => order.order_id === action.orderData.order_id,
            );
            if (index === -1) return [...state, action.orderData];
            return state;
        }
        case UPDATE_ORDER: {
            if (state === null) {
                return state;
            }
            return state.map((order) => {
                if (order.order_id !== action.orderData.order_id) {
                    return order;
                } else {
                    return Object.assign({}, order, action.orderData);
                }
            });
        }
        default: {
            return state;
        }
    }
}
