import React from 'react';
import './LoaderStyles.scss';

function Loader() {
    return <div className={'loader'} />;
}

function InlineLoader() {
    return (
        <span className="inline-loader">
            <span />
            <span />
            <span />
        </span>
    );
}

function OverlayLoader() {
    return (
        <div className="overlay-loader">
            <Loader />
        </div>
    );
}

export {Loader, InlineLoader, OverlayLoader};
