import {MutableRefObject, useEffect, useRef} from 'react';
import L, {FeatureGroup, Map} from 'leaflet';

const useMap = () => {
    const mapRef: MutableRefObject<Map> = useRef(null);
    const markerLayerRef: MutableRefObject<FeatureGroup> = useRef(null);

    useEffect(() => {
        const roads = L.tileLayer(
                `${process.env.REACT_APP_MAPS_URL}roads/{z}/{x}/{y}.png`,
                {
                    attribution:
                        '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors',
                },
            ),
            tiles = L.tileLayer(
                `${process.env.REACT_APP_MAPS_URL}default/{z}/{x}/{y}.png`,
                {
                    attribution:
                        '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors',
                },
            ),
            osm = L.tileLayer(
                'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png',
                {
                    attribution:
                        '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors',
                },
            ),
            googleRoadMap = L.gridLayer.googleMutant({
                type: 'roadmap',
                maxZoom: 24,
            });
        if (!mapRef.current) {
            mapRef.current = L.map('points-map', {
                center: [51.505, 20],
                zoom: 5,
                zoomControl: false,
            });
            roads.addTo(mapRef.current);
            L.control.zoom().setPosition('topright').addTo(mapRef.current);
            L.control
                .layers({
                    'Treesat Routes': roads,
                    'Treesat Basic': tiles,
                    'Open Street Map': osm,
                    'Google Roadmap': googleRoadMap,
                })
                .setPosition('bottomright')
                .addTo(mapRef.current);
            markerLayerRef.current = L.markerClusterGroup().addTo(
                mapRef.current,
            );
        }
        return () => {
            mapRef.current.remove();
            mapRef.current = null;
        };
    }, []);

    return {mapRef, markerLayerRef};
};

export default useMap;
