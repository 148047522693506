export const fieldStyle = {
    bold: true,
    fill: 'a7a4a4',
    verticalAlignment: 'center',
    horizontalAlignment: 'center',
    bottomBorder: 'thin',
    topBorder: 'thin',
    leftBorder: 'thin',
    rightBorder: 'thin',
    wrapText: true,
};

export const mergedFieldsStyle = {
    fill: 'a7a4a4',
    leftBorder: 'thin',
    rightBorder: 'thin',
    bottomBorder: 'thin',
    topBorder: 'thin',
};

export const darkRowStyle = {
    fill: 'c4c1c1',
    borderStyle: 'thin',
    verticalAlignment: 'center',
    horizontalAlignment: 'center',
};

export const lightRowStyle = {
    fill: 'ffffff',
    borderStyle: 'thin',
    verticalAlignment: 'center',
    horizontalAlignment: 'center',
};
