import React, {forwardRef, useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useFlexLayout, useGlobalFilter, useMountedLayoutEffect, useRowSelect, useSortBy, useTable} from 'react-table';
import {IconChevronDown} from '../graphics/icons';

const defaultPropGetter = () => ({});
const INITIAL_SELECTED_ROW_IDS = {};
const IndeterminateCheckbox = React.forwardRef(
    ({indeterminate, disabled, ...rest}, ref) => {
        const defaultRef = React.useRef();
        const resolvedRef = ref || defaultRef;

        React.useEffect(() => {
            resolvedRef.current.indeterminate = indeterminate;
        }, [resolvedRef, indeterminate]);

        return (
            <>
                {disabled && (
                    <input
                        type="checkbox"
                        disabled={disabled}
                        ref={resolvedRef}
                        onClick={(e) => e.stopPropagation()}
                        {...rest}
                        checked={false}
                    />
                )}
                {!disabled && (
                    <input
                        type="checkbox"
                        disabled={disabled}
                        ref={resolvedRef}
                        onClick={(e) => e.stopPropagation()}
                        {...rest}
                    />
                )}
            </>
        );
    },
);

const Table = forwardRef(({
                              columns,
                              data,
                              onRowClick,
                              defaultSortBy = [],
                              getProps = defaultPropGetter,
                              getTbodyProps = defaultPropGetter,
                              getRowProps = defaultPropGetter,
                              selectType = null,
                              setSelectedRows,
                              initialSelectedRowIds = null,
                              className = '',
                              sortable = true,
                              searchValue = '',
                              footer,
                              onScroll,
                          }, ref) => {
    const {t} = useTranslation();
    const [expandedRow, setExpandedRow] = useState(null);

    const globalFilter = React.useMemo(() => (rows, columnIds, filterValue) => {
        console.debug('Table::[] => globalFilter!', rows, columnIds, filterValue);
        return rows.filter(row => {
            let itMatches = false;
            columnIds.forEach(id => {
                const val = row.values[id];
                const type = {}.toString.call(val).split(' ')[1].slice(0, -1).toLowerCase();
                if (type === 'string') {
                    if (String(val).toLowerCase().includes(String(filterValue).toLowerCase())) {
                        itMatches = true;
                    }
                    return;
                }
                if (type === 'object' && val.toString) {
                    if (val.toString().toLowerCase().includes(String(filterValue).toLowerCase())) {
                        itMatches = true;
                    }
                }
            });
            return itMatches ? row : null;
        });
    }, []);

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        prepareRow,
        rows,
        visibleColumns,
        setGlobalFilter,
        state: {selectedRowIds},
    } = useTable(
        {
            columns,
            data,
            globalFilter,
            initialState: {
                sortBy: defaultSortBy,
                hiddenColumns: columns
                    .filter((c) => c.show === false)
                    .map((c) => c.accessor),
                selectedRowIds:
                    initialSelectedRowIds || INITIAL_SELECTED_ROW_IDS,
            },
            disableSortRemove: true,
            disableMultiRemove: false,
            autoResetSortBy: false,
            disableSortBy: !sortable,
            autoResetSelectedRows: true,
        },
        useGlobalFilter,
        useSortBy,
        useFlexLayout,
        useRowSelect,
        (hooks) => {
            if (selectType !== null) {
                hooks.allColumns.push(columns => [{
                    id: 'selection',
                    // The header can use the table's getToggleAllRowsSelectedProps method
                    // to render a checkbox
                    Header: ({getToggleAllRowsSelectedProps}) => (
                        <IndeterminateCheckbox {...getToggleAllRowsSelectedProps()} />
                    ),
                    // The cell can use the individual row's getToggleRowSelectedProps method
                    // to the render a checkbox
                    Cell: ({row}) => (
                        <IndeterminateCheckbox disabled={row.original.disabled} {...row.getToggleRowSelectedProps()} />
                    ),
                    minWidth: 16,
                    width: 16,
                    maxWidth: 16,
                    disableGlobalFilter: true,
                    disableSortBy: true,
                }, ...columns]);
            }
        },
    );

    const handleRowClick = (row) => {
        if (onRowClick) {
            onRowClick(row.original);
        }
    };

    const _rowClickable = !!onRowClick;

    useMountedLayoutEffect(() => {
        if (!setSelectedRows) return;
        const selectedIds = Object.keys(selectedRowIds);
        const selectedRowsData = selectedIds
            .map((x) => data[x])
            .filter(function(x) {
                return x != null && !x.disabled;
            });

        setSelectedRows(selectedRowsData);
    }, [setSelectedRows, selectedRowIds]);

    useEffect(() => {
        if (!setGlobalFilter) {
            return;
        }
        setGlobalFilter(searchValue);
    }, [setGlobalFilter, searchValue]);

    const {tbodyKey, ...tbodyProps} = getTableBodyProps([getTbodyProps()]);

    return (
        <table {...getTableProps()} {...getProps()}
               className={(className) + (selectType !== null ? ' selectable' : '')}
               ref={ref}
               onScroll={onScroll}>
            <thead>
            {headerGroups.map(headerGroup => {
                const {key, ...props} = headerGroup.getHeaderGroupProps();
                return <tr key={key} {...props}>
                    {headerGroup.headers.map(column => {
                        const {key, ...props} = column.getHeaderProps(column.getSortByToggleProps());
                        return <th key={key} {...props}
                                   data-sorted={column.isSorted ? (column.isSortedDesc ? 'desc' : 'asc') : ''}>{column.render('Header')}</th>;
                    })}
                </tr>;
            })}
            </thead>
            <tbody key={tbodyKey} {...tbodyProps}>
            {rows.length === 0
                ? <tr>
                    <td className="noData" colSpan={visibleColumns.length}>{t('TABLE_NO_DATA')}</td>
                </tr>
                : rows.map(row => {
                    prepareRow(row);
                    let _rowProps = {};
                    if (_rowClickable) {
                        _rowProps = {
                            onClick: (e) => {
                                if (!e.defaultPrevented) {
                                    handleRowClick(row);
                                }
                            },
                            clickable: '',
                        };
                    } else if (selectType !== null) {
                        _rowProps = {
                            onClick: () => {
                                if (row.original.disabled) return;
                                row.toggleRowSelected();
                            },
                            clickable: '',
                        };
                    }

                    const {key, ...props} = row.getRowProps([
                        _rowProps,
                        getRowProps(row),
                    ]);

                    return (
                        <tr key={key} {...props}>
                            {row.cells.map(cell => {
                                const {key, ...props} = cell.getCellProps([{
                                    className: cell.column.className,
                                    style: cell.column.style,
                                    onClick: (e) => {
                                        if (row.original.type === 'custom_fcm') {
                                            setExpandedRow(expandedRow === row.id ? null : row.id);
                                            return e.stopPropagation();
                                        }
                                    },
                                }]);
                                return <td key={key} {...props}>
                                    {cell.render('Cell')}
                                    {cell.column.id === 'text' && row.original.type === 'custom_fcm' && (
                                        <span className="icon">
                                                <IconChevronDown />
                                        </span>
                                    )}
                                    {cell.column.id === 'text' && expandedRow === row.id && row.original.type === 'custom_fcm' && (
                                        <div className="description">{row.original.description}
                                        </div>
                                    )}

                                </td>;
                            })}
                        </tr>
                    );
                })
            }
            </tbody>
            {footer}
        </table>
    );
});

export default Table;
