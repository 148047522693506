import React, {useEffect} from 'react';

import './TableFooter.scss';

/**
 *
 * @param range {number[]}
 * @param page {number}
 * @param setPage {Function}
 * @param slice {{}[]}
 * @returns {Element}
 * @constructor
 */
const TableFooter = ({range, page, setPage, slice}) => {
    useEffect(() => {
        if (slice.length < 1 && page !== 1) {
            setPage(page - 1);
        }
    }, [slice, page, setPage]);

    return (
        <tfoot className="table-footer">
            <tr>
                <td>
                    {range.map((el, index) => (
                        <button
                            key={index}
                            className={`button ${page === el ? 'active' : 'inactive'}`}
                            onClick={() => setPage(el)}
                        >
                            {el}
                        </button>
                    ))}
                </td>
            </tr>
        </tfoot>
    );
};

export default TableFooter;
