import {useTranslation} from 'react-i18next';
import Modal from '../../../components/Modal/Modal';
import {IconCheckCircle, IconInfo, IconXCircle} from '../../../graphics/icons';

/**
 *
 * @param createdExchanges {CreateAtlasUserResult[]}
 * @param creationErrors {CreationError[]}
 * @param onClose {Function}
 * @returns {JSX.Element}
 * @constructor
 */
const SummaryModal = ({createdExchanges, creationErrors, onClose}) => {
    const {t} = useTranslation(['Settings', 'common']);

    return (
        <Modal id="transport-exchanges-summary-modal">
            <header>{t('SUMMARY')}</header>
            <main>
                {createdExchanges && createdExchanges.length > 0 && (
                    <div className="created-exchanges-container">
                        <span>
                            <IconCheckCircle />
                            <p>{t('TRANSPORT_EXCHANGES_ADDED')}:</p>
                        </span>
                        <div className="info-container">
                            <IconInfo />
                            {t('INFO_ABOUT_PASSWORDS')}
                        </div>
                        <ul>
                            {createdExchanges.map((item) => {
                                return (
                                    <li key={item.id}>
                                        <span className="logo-container">
                                            <img
                                                src={
                                                    require(
                                                        `../../../graphics/transportExchanges/${item.exchange}.png`,
                                                    ).default
                                                }
                                                alt=""
                                            />
                                            {t(`common:${item.exchange}`)}
                                        </span>
                                        <p>
                                            Login: <b>{item.login}</b>
                                        </p>
                                        <p>
                                            {t('PASSWORD')}:{' '}
                                            <b>{item.password}</b>
                                        </p>
                                    </li>
                                );
                            })}
                        </ul>
                    </div>
                )}
                {creationErrors && creationErrors.length > 0 && (
                    <div className="creation-errors-container">
                        <span>
                            <IconXCircle />
                            <p>
                                {t('TRANSPORT_EXCHANGES_COULD_NOT_BE_ADDED')}:
                            </p>
                        </span>
                        <ul>
                            {creationErrors.map((error) => {
                                return (
                                    <li
                                        key={error.id}
                                        className="logo-container"
                                    >
                                        <img
                                            src={
                                                require(
                                                    `../../../graphics/transportExchanges/${error.exchange}.png`,
                                                ).default
                                            }
                                            alt=""
                                        />
                                        {t(`common:${error.exchange}`)}:{' '}
                                        {t(error.reason)}
                                    </li>
                                );
                            })}
                        </ul>
                    </div>
                )}
            </main>
            <footer>
                <button className="button cancel" onClick={onClose}>
                    {t('common:CLOSE')}
                </button>
            </footer>
        </Modal>
    );
};

export default SummaryModal;
