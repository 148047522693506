import {SET_POI_TYPE_LIST} from '../actions/POITypeActions';
import type {POIType} from '../../utils/interfaces/poi';

/*
 * state === null means that store is not initialized
 * no action can be made unless store is initialized...
 */

const initialState: ?(POIType[]) = null;
export default function POITypeList(state = initialState, action) {
    switch (action.type) {
        case SET_POI_TYPE_LIST: {
            return action.poiTypes;
        }
        default: {
            return state;
        }
    }
}
