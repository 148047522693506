import React from 'react';

export const IconPedalPosition = () => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="14.898"
            height="11.938"
            viewBox="0 0 14.898 11.938"
        >
            <g transform="translate(-686.905 -368.097)">
                <g transform="translate(-2.174 15.135)" opacity="0.7">
                    <path
                        d="M150.255,188.613h0a2.7,2.7,0,0,1-1.3-1.579.73.73,0,0,0-.353-.431h0a.72.72,0,0,0-.439-.077l-1.8.257a3.883,3.883,0,0,1-2.393-.427l-.44-.237a1.432,1.432,0,0,0-1.94.58l-.676,1.253,5.31,2.865,2.625-.241-.695,1.273.009.005,2.643,1.436,2.122-3.933a.405.405,0,0,0-.167-.55h0a.4.4,0,0,0-.322-.025A2.7,2.7,0,0,1,150.255,188.613Z"
                        transform="translate(550.196 167.917)"
                        fill="none"
                        stroke="#25354d"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="1.6"
                    />
                    <line
                        x1="4.71"
                        y1="2.768"
                        transform="translate(690.174 359.212)"
                        fill="none"
                        stroke="#25354d"
                        strokeLinecap="square"
                        strokeLinejoin="round"
                        strokeWidth="1.6"
                    />
                    <line
                        x1="1.447"
                        y2="2.462"
                        transform="translate(690.767 361.342)"
                        fill="none"
                        stroke="#25354d"
                        strokeLinecap="square"
                        strokeLinejoin="round"
                        strokeWidth="1.6"
                    />
                </g>
            </g>
        </svg>
    );
};
