import {TransportExchange} from './interfaces/transportExchange';

export const transportExchanges: TransportExchange[] = [
    {
        id: 1,
        name: 'elite',
        label: 'Elite',
    },
    {
        id: 2,
        name: 'LKW_walter',
        label: 'LKW WALTER',
    },
    {
        id: 3,
        name: 'trans_eu',
        label: 'Trans.eu',
    },
    {
        id: 4,
        name: 'project44',
        label: 'Project44',
    },
    // {
    //     id: 5,
    //     name: 'cargoradar',
    //     label: 'CargoRadar',
    // },
    {
        id: 6,
        name: 'timocom',
        label: 'TimoCom',
    },
    // {
    //     id: 7,
    //     name: 'shippeo',
    //     label: 'Shippeo',
    // },
    // {
    //     id: 8,
    //     name: 'cargoboard',
    //     label: 'CargoBoard',
    // },
    // {
    //     id: 9,
    //     name: 'teleroute',
    //     label: 'Teleroute',
    // },
    // {
    //     id: 10,
    //     name: 'quicargo',
    //     label: 'Quicargo',
    // },
];
