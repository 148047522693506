import React from 'react';
import {useTranslation} from 'react-i18next';

import RightPane from '../../../components/RightPane/RightPane';
import {IconEdit} from '../../../graphics/icons';

/**
 *
 * @param device {Device}
 * @param setCurrentDevice {Function}
 * @param setEditMode {Function}
 * @returns {JSX.Element}
 * @constructor
 */
const ShowDevice = ({device, setCurrentDevice, setEditMode}) => {
    const {t} = useTranslation('Devices');

    return (
        <RightPane
            id="show-device"
            className="show-device panel-right-entity-details"
            title={device.serial_num}
            onComponentHidden={() => {
                setCurrentDevice(null);
            }}
            body={() => {
                return (
                    <div className="device-details">
                        <div className="group">
                            <dl>
                                <dt>{t('SERIAL_NUMBER')}</dt>
                                <dd>{device.serial_num}</dd>
                                <dt>{t('ASSIGNED_TO_VEHICLE')}</dt>
                                <dd>{device?.vehicle_name || '--'}</dd>
                                <dt>{t('DEVICE_STATUS')}</dt>
                                <dd>{t('STATUS_ACTIVE')}</dd>
                            </dl>
                        </div>
                    </div>
                );
            }}
            buttons={() => {
                return (
                    <button
                        className="button action icon"
                        onClick={() => setEditMode(true)}
                    >
                        <IconEdit />
                    </button>
                );
            }}
        />
    );
};

export default ShowDevice;
