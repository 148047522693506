import React from 'react';
import {useTranslation} from 'react-i18next';
import {useLocation} from 'react-router-dom';

import './LanguageSelector.scss';
import {toast} from 'react-toastify';
import useServiceProvider from '../../utils/service';

export default function LanguageSelector({loggedIn}: {loggedIn?: boolean}) {
    const location = useLocation();
    const {userService} = useServiceProvider();
    const {t, i18n} = useTranslation('Settings');

    const setLanguage = (event: Event) => {
        event.preventDefault();
        let newLanguage = event.target.search.match(/lng=(\w+)/)[1];
        if (loggedIn) {
            return userService.update(
                {lang: newLanguage},
                (result) => {
                    console.debug('EditAccount::changeLanguage:', result);
                    i18n.changeLanguage(newLanguage);
                },
                (error) => {
                    console.error('EditAccount::changeLanguage:', error);
                    toast.error(t('EDIT_LANGUAGE_ERROR'));
                },
            );
        }
        return i18n.changeLanguage(newLanguage);
    };

    return (
        <div className="language-selector">
            <a
                href={location.pathname + '?lng=pl'}
                onClick={setLanguage}
                title="Polski"
            >
                <img src={process.env.PUBLIC_URL + '/pl.png'} alt="Polski" />
            </a>
            <a
                href={location.pathname + '?lng=en'}
                onClick={setLanguage}
                title="English"
            >
                <img src={process.env.PUBLIC_URL + '/gb.png'} alt="English" />
            </a>
            <a
                href={location.pathname + '?lng=de'}
                onClick={setLanguage}
                title="Deutsch"
            >
                <img src={process.env.PUBLIC_URL + '/de.png'} alt="Deutsch" />
            </a>
        </div>
    );
}
