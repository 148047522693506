import React, {useCallback, useEffect, useMemo, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useSelector} from 'react-redux';
import ReactTooltip from 'react-tooltip';
import {toast} from 'react-toastify';
import _ from 'lodash';

import useServiceProvider from '../../utils/service';
import {MainListHeader} from '../../components/MainListsHeader/MainListHeader';
import {Loader} from '../../components/Loader/Loader';
import Table from '../../components/Table';
import OrderPreview from './parts/OrderPreview';
import OrderCreator from './parts/OrderCreator';
import type {Order} from '../../utils/interfaces/order';
import type {RootState} from '../../redux/reducers/rootReducer';

import './OrderList.scss';

/**
 *
 * @param archived {boolean}
 * @returns {Element}
 * @constructor
 */
function OrderList({archived = false}) {
    const {t} = useTranslation();
    const {orderService, clientService, driversService, vehicleService} =
        useServiceProvider();

    const {orderList, clientList, driverList, userData} = useSelector(
        (state: RootState) => state,
    );

    const [addMode: boolean, setAddMode: Function<boolean>] = useState(false);
    const [editMode: boolean, setEditMode: Function<boolean>] = useState(false);

    const [searchValue, setSearchValue] = useState('');
    const [tableData: Order[], setTableData: Function<Order[]>] =
        useState(null);

    const [current: Order, setCurrent: Function<Order>] = useState(null);
    const [selected: Order[], setSelected: Function<Order[]>] = useState([]);

    const setListMode = () => {
        setAddMode(false);
        setEditMode(false);
        setCurrent(null);
    };

    useEffect(() => {
        orderService.initStore();
        clientService.initStore();
        driversService.initStore();
        vehicleService.initStore();
    }, [orderService, clientService, driversService, vehicleService]);

    useEffect(() => {
        if (orderList === null || clientList === null || driverList === null) {
            return;
        }
        let typeEvaluator;
        if (!!archived) {
            typeEvaluator = (o) => o.order_state === 'archived';
        } else {
            typeEvaluator = (o) => o.order_state !== 'archived';
        }
        const searchString = searchValue.toLocaleLowerCase();
        setTableData(
            orderList.filter(typeEvaluator).filter((o: Order) => {
                let driver = driverList.find(
                    (driver) => driver.id === o.driver_id,
                );
                let driverName = driver
                    ? driver.first_name + ' ' + driver.last_name
                    : '';
                return (
                    o.name.toLocaleLowerCase().includes(searchString) ||
                    o.counterparty_name
                        ?.toLocaleLowerCase()
                        .includes(searchString) ||
                    driverName.toLocaleLowerCase().includes(searchString) ||
                    o.creator_name
                        ?.toLocaleLowerCase()
                        .includes(searchString) ||
                    t(o.order_state)
                        .toLocaleLowerCase()
                        .includes(searchString) ||
                    t(o.approval_state)
                        .toLocaleLowerCase()
                        .includes(searchString)
                );
            }),
        );
        setTimeout(() => {
            ReactTooltip.rebuild();
        }, 50);
    }, [t, orderList, clientList, driverList, archived, searchValue]);

    const moveToArchive = useCallback(() => {
        console.debug('OrderList::moveToArchive() => ', selected);
        Promise.allSettled(
            selected.map((o) =>
                orderService.updateOrder({
                    order_id: o.order_id,
                    order_state: 'archived',
                }),
            ),
        ).then((results) => {
            if (results.some((v) => v.status !== 'fulfilled')) {
                results.forEach((result, index) => {
                    if (result.status !== 'fulfilled') {
                        toast.error(
                            t('MOVE_TO_ARCHIVE_ERROR', {
                                name: selected[index].name,
                                reason: t(result.reason),
                            }),
                        );
                    }
                });
            } else {
                toast.success(t('MOVE_TO_ARCHIVE_SUCCESS'));
            }
        });
    }, [t, orderService, selected]);

    const handleSearch = _.debounce(({target}) => {
        setSearchValue(target.value.toLocaleLowerCase());
    }, 200);

    const columns = useMemo(
        () => [
            {
                Header: t('ORDER_NUMBER'),
                accessor: 'order_id',
                show: false,
            },
            {
                Header: t('ORDER_NUMBER'),
                accessor: 'name',
                width: 220,
            },
            {
                id: 'order_client',
                Header: t('CLIENT'),
                accessor: (order: Order) => {
                    console.debug('OrderList :: archived = ', archived);
                    if (order.counterparty_name) {
                        return order.counterparty_name;
                    } else {
                        let client = clientList.find(
                            (client) =>
                                client.counterparty_id ===
                                order.counterparty_id,
                        );
                        if (client) {
                            return client.name;
                        } else {
                            return 'n/d';
                        }
                    }
                },
                Cell: (row) => <div data-tip={row.value}>{row.value}</div>,
                width: 250,
            },
            {
                id: 'approval_state',
                Header: t('ACCEPTANCE_STATUS'),
                accessor: (o: Order) => t(o.approval_state),
                Cell: (row) => <div data-tip={row.value}>{row.value}</div>,
            },
            {
                id: 'order_state',
                Header: t('ORDER_STATE'),
                accessor: (o: Order) => t(o.order_state),
                Cell: (row) => <div data-tip={row.value}>{row.value}</div>,
            },
            {
                id: 'driver',
                Header: t('DRIVER'),
                accessor: (o: Order) => {
                    let d = driverList.find((d) => d.id === o.driver_id);
                    return d ? d.first_name + ' ' + d.last_name : 'n/d';
                },
                Cell: (row) => <div data-tip={row.value}>{row.value}</div>,
                width: 220,
            },

            {
                id: 'order_creator',
                Header: t('DISPOSITOR'),
                accessor: 'creator_name',
                Cell: (row) => <div data-tip={row.value}>{row.value}</div>,
                width: 220,
            },
            {
                id: 'order_cargo_weight',
                Header: t('CARGO_WEIGHT'),
                accessor: (o: Order) =>
                    o.cargo_weight !== null ? o.cargo_weight + ' kg' : '',
                Cell: (row) => <div data-tip={row.value}>{row.value}</div>,
                width: 80,
            },
        ],
        [t, clientList, driverList, archived],
    );

    return (
        <div id="main-order-list">
            <MainListHeader
                headerText={t(!archived ? 'ORDERS' : 'ORDERS_ARCHIVED')}
                handleChangeSearchValue={(e) => {
                    e.persist();
                    handleSearch(e);
                }}
                selectedDataAction={
                    !addMode &&
                    !editMode &&
                    !archived &&
                    userData.type !== 'user'
                        ? moveToArchive
                        : null
                }
                selectedDataActionLabel={
                    !archived ? t('MOVE_TO_ARCHIVE') : null
                }
                selectedDataActionEnabled={selected.length > 0}
                addMode={addMode}
                switchAddMode={
                    userData.type === 'user' || archived
                        ? null
                        : () => {
                              if (
                                  orderList === null ||
                                  clientList === null ||
                                  driverList === null
                              ) {
                                  return false;
                              }
                              setAddMode(true);
                          }
                }
                addText={t('NEW_ORDER')}
                editMode={editMode}
            />
            {tableData === null && <Loader />}
            {!addMode && !editMode && tableData !== null && (
                <Table
                    data={tableData}
                    columns={columns}
                    selectType={!archived ? 'checkbox' : null}
                    setSelectedRows={setSelected}
                    defaultSortBy={[{id: 'order_id', desc: true}]}
                    onRowClick={setCurrent}
                    getRowProps={(row) => {
                        return {
                            className:
                                row.original.order_id === current?.order_id
                                    ? 'selected'
                                    : '',
                        };
                    }}
                />
            )}
            {!addMode && !editMode && current !== null && (
                <OrderPreview
                    order={current}
                    onHide={() => setCurrent(null)}
                    setEditMode={setEditMode}
                />
            )}
            {addMode && (
                <OrderCreator
                    orderData={{}}
                    orderService={orderService}
                    vehicleService={vehicleService}
                    abortAction={setListMode}
                />
            )}
            {editMode && current && (
                <OrderCreator
                    orderData={current}
                    orderService={orderService}
                    vehicleService={vehicleService}
                    abortAction={setListMode}
                />
            )}
        </div>
    );
}

export default OrderList;
