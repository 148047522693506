import React from 'react';

export const IconEngineLoad = () => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="18.069"
            height="12.502"
            viewBox="0 0 18.069 12.502"
        >
            <g transform="translate(-687.024 -481.187)" opacity="0.7">
                <g>
                    <path
                        d="M2286.355,3175.489h-2.7a1.575,1.575,0,0,1-1.312-.7l-1.087-1.631h-1.128a.8.8,0,0,1-.8-.8v-1.533h-.733v2.333H2277v-6.267h1.6v2.333h.733v-1.533a.8.8,0,0,1,.8-.8h1.224l1.321-1.321a.794.794,0,0,1,.566-.234H2284v-.734h-1.555V3163h4.712v1.6H2285.6v.734h.275a1.567,1.567,0,0,1,1.41.872l.691,1.381v2.4a1.573,1.573,0,0,1-1-.817l-1.12-2.238h-2.285l-1.321,1.321a.8.8,0,0,1-.566.234h-.755v3.066h.755a.8.8,0,0,1,.666.356l1.318,1.977h2.661v-1.533a.8.8,0,0,1,.8-.8h2.333a.8.8,0,0,1,.8.8v.756H2291V3170.8h1.6v2.333a1.58,1.58,0,0,1-1.578,1.579h-1.556a.8.8,0,0,1-.8-.8v-.756h-.733v.756A1.58,1.58,0,0,1,2286.355,3175.489Z"
                        transform="translate(-1589.976 -2681.8)"
                        fill="#24344e"
                    />
                    <g transform="translate(-6 -19.097)">
                        <path
                            d="M18.437,20.672a2.236,2.236,0,1,1,2.236-2.236A2.238,2.238,0,0,1,18.437,20.672Zm0-2.872a.636.636,0,1,0,.636.636A.637.637,0,0,0,18.437,17.8Z"
                            transform="translate(689.386 485.161)"
                            fill="#25354d"
                        />
                        <path
                            d="M19.8,17.377H18.2V14.7h1.6Z"
                            transform="translate(688.822 485.584)"
                            fill="#25354d"
                        />
                        <path
                            d="M19.8,22.877H18.2V20.2h1.6Z"
                            transform="translate(688.822 484.032)"
                            fill="#25354d"
                        />
                        <path
                            d="M20.44,18.881l-.8-1.386,2.318-1.338.8,1.386Z"
                            transform="translate(688.334 485.09)"
                            fill="#25354d"
                        />
                        <path
                            d="M15.677,21.631l-.8-1.386L17.2,18.907l.8,1.386Z"
                            transform="translate(689.677 484.314)"
                            fill="#25354d"
                        />
                        <path
                            d="M17.2,18.881l-2.319-1.338.8-1.386L18,17.5Z"
                            transform="translate(689.677 485.09)"
                            fill="#25354d"
                        />
                        <path
                            d="M21.959,21.631,19.64,20.293l.8-1.386,2.319,1.338Z"
                            transform="translate(688.334 484.314)"
                            fill="#25354d"
                        />
                    </g>
                </g>
            </g>
        </svg>
    );
};
