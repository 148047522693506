import type {Group, UpdateGroup} from '../../utils/interfaces/group';

export const SET_GROUP_LIST = 'SET_GROUP_LIST';
export const ADD_GROUP = 'ADD_GROUP';
export const REMOVE_GROUP = 'REMOVE_GROUP';
export const UPDATE_GROUP = 'UPDATE_GROUP';

export function setGroupList(groups: Group[]) {
    return {
        type: SET_GROUP_LIST,
        groups,
    };
}

export function addGroup(group: Group) {
    return {
        type: ADD_GROUP,
        group,
    };
}

export function updateGroup(updatedGroup: UpdateGroup) {
    return {
        type: UPDATE_GROUP,
        updatedGroup,
    };
}

export function removeGroup(groupId: number) {
    return {
        type: REMOVE_GROUP,
        groupId,
    };
}
