import React, {useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {toast} from 'react-toastify';
import {useSelector} from 'react-redux';

import RightPane from '../../../components/RightPane/RightPane';
import {IconEdit} from '../../../graphics/icons';
import useServiceProvider from '../../../utils/service';
import type {RootState} from '../../../redux/reducers/rootReducer';

interface AssignedVehicle {
    id: number;
    since: number;
    until: number | null;
    device_id: number | null;
    vehicle_name?: string;
}

/**
 *
 * @param onHide {Function}
 * @param currentDriver {Driver}
 * @param setCurrentDriver {Function}
 * @param setEditMode {Function}
 * @constructor
 */
const ShowDriver = ({onHide, currentDriver, setCurrentDriver, setEditMode}) => {
    const {t} = useTranslation(['Drivers', 'common']);

    const {driversService} = useServiceProvider();
    const {vehicleList} = useSelector((state: RootState) => state);

    const [
        assignedVehicles: AssignedVehicle[],
        setAssignedVehicles: Function<AssignedVehicle[]>,
    ] = useState(null);

    useEffect(() => {
        if (!currentDriver) return;
        const vehicles = JSON.parse(currentDriver.vehicles);
        if (vehicles && vehicles.length > 0) {
            const _vehicles = vehicles.map((vehicle) => {
                const vehicleName = vehicleList.find(
                    (v) => v.vehicle_id === vehicle.id,
                )?.name;
                return {...vehicle, vehicle_name: vehicleName};
            });
            setAssignedVehicles(_vehicles);
        } else {
            setAssignedVehicles([]);
        }
    }, [currentDriver, vehicleList]);

    const toggleDriver = (e: Event) => {
        e.preventDefault();
        let method = currentDriver.active ? 'disable' : 'enable';
        driversService[method](
            currentDriver.id,
            (result) => {
                console.debug(
                    'ShowDriver::toggleDriver(%s) => result: %O',
                    method,
                    result,
                );
                currentDriver.active = result.active;
                setCurrentDriver(currentDriver);
                toast.success(t('DRIVER_UPDATED'));
            },
            (reason) => {
                console.warn(
                    'ShowDriver::toggleDriver() => reason: %s',
                    reason,
                );
                toast.error(t('DRIVER_UPDATE_ERROR'), {error: t(reason)});
            },
        );
    };

    const inviteDriver = (e: Event) => {
        e.preventDefault();

        if (!currentDriver.active) {
            toast.error(t('INVITE_ERROR', {error: t('err_driver_inactive')}));
            return;
        }

        console.debug(
            'ShowDriver::inviteDriver() => currentDriver: %O',
            currentDriver,
        );
        driversService.invite(
            {driver_id: currentDriver.id},
            (result) => {
                toast.success(t('INVITE_SENT'));
                console.debug(
                    'ShowDriver::inviteDriver() => result: %O',
                    result,
                );
            },
            (reason) => {
                toast.error(t('INVITE_ERROR', {error: t(reason)}));
                console.warn(
                    'ShowDriver::inviteDriver() => reason: %s',
                    reason,
                );
            },
        );
    };

    return (
        <div id="driver-details-container">
            <RightPane
                id="driver-details"
                className="driver-details panel-right-entity-details"
                title={currentDriver.first_name + ' ' + currentDriver.last_name}
                onComponentHidden={onHide}
                buttons={() => {
                    return (
                        <button
                            className="button action icon"
                            data-tip={t('EDIT_DRIVER')}
                            onClick={() => setEditMode(true)}
                        >
                            <IconEdit />
                        </button>
                    );
                }}
                body={() => {
                    return (
                        <div>
                            <div className="group">
                                <dl>
                                    <dt>{t('FIRST_NAME')}</dt>
                                    <dd>{currentDriver.first_name}</dd>
                                    <dt>{t('LAST_NAME')}</dt>
                                    <dd>{currentDriver.last_name}</dd>
                                    <dt>{t('PHONE_NUMBER')}</dt>
                                    <dd>{currentDriver.phone_number}</dd>
                                    <dt>{t('EMAIL')}</dt>
                                    <dd>{currentDriver.email}</dd>
                                    <dt>{t('COMMENT')}</dt>
                                    <dd>{currentDriver.comment}</dd>
                                    <dt>{t('CAN_USE_MOBILE_APP')}</dt>
                                    <dd>
                                        {currentDriver.user_id
                                            ? t('YES')
                                            : t('NO')}
                                    </dd>
                                    <dt>{t('common:VEHICLES')}</dt>
                                    {assignedVehicles &&
                                        assignedVehicles.length === 0 && (
                                            <dd>--</dd>
                                        )}
                                    {assignedVehicles &&
                                        assignedVehicles.length > 0 && (
                                            <dd>
                                                <ul>
                                                    {assignedVehicles.map(
                                                        (assignedVehicle) => (
                                                            <li
                                                                key={
                                                                    assignedVehicle.id
                                                                }
                                                            >
                                                                {
                                                                    assignedVehicle.vehicle_name
                                                                }
                                                            </li>
                                                        ),
                                                    )}
                                                </ul>
                                            </dd>
                                        )}
                                </dl>
                            </div>
                            <div className="group">
                                {!currentDriver.user_active &&
                                    !currentDriver.has_invitation && (
                                        <div className="account-info account-missing">
                                            <h3>{t('ACCOUNT_MISSING')}</h3>
                                            <button
                                                onClick={inviteDriver}
                                                className="button basic"
                                            >
                                                {t('SEND_INVITE')}
                                            </button>
                                        </div>
                                    )}
                                {!currentDriver.user_active &&
                                    currentDriver.has_invitation && (
                                        <div className="account-info account-inactive">
                                            <h3>{t('ACCOUNT_INACTIVE')}</h3>
                                            <button
                                                onClick={inviteDriver}
                                                className="button basic"
                                            >
                                                {t('RESEND_INVITE')}
                                            </button>
                                        </div>
                                    )}
                                {currentDriver.user_active &&
                                    !currentDriver.has_invitation && (
                                        <div className="account-info account-active">
                                            <h3>{t('ACCOUNT_ACTIVE')}</h3>
                                        </div>
                                    )}
                            </div>
                            {!currentDriver.user_active && (
                                <div className="group hint">
                                    <img
                                        src={
                                            require('../../../graphics/iko_info_round.png')
                                                .default
                                        }
                                        alt=""
                                        className="icon"
                                    />
                                    <span className="text">
                                        {t('NO_ACCOUNT_HINT')}
                                    </span>
                                </div>
                            )}
                            <div className="group account-info">
                                {currentDriver.active ? (
                                    <>
                                        <h3>{t('DRIVER_ACTIVE')}</h3>
                                        <button
                                            className="button edit"
                                            onClick={toggleDriver}
                                        >
                                            {t('DEACTIVATE')}
                                        </button>
                                    </>
                                ) : (
                                    <>
                                        <h3>{t('DRIVER_INACTIVE')}</h3>
                                        <button
                                            className="button basic"
                                            onClick={toggleDriver}
                                        >
                                            {t('ACTIVATE')}
                                        </button>
                                    </>
                                )}
                            </div>
                        </div>
                    );
                }}
            />
        </div>
    );
};

export default ShowDriver;
