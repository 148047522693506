import type {Offer} from '../../utils/interfaces/offer';

export const SET_OFFER_LIST = 'SET_OFFER_LIST';
export const ADD_OFFER = 'ADD_OFFER';
export const UPDATE_OFFER = 'UPDATE_OFFER';
export const REMOVE_OFFER = 'REMOVE_OFFER';

export function setOfferList(offers: Offer[]) {
    return {
        type: SET_OFFER_LIST,
        offers,
    };
}
export function addOffer(offer: Offer) {
    return {
        type: ADD_OFFER,
        offer,
    };
}

export function updateOffer(offer: Offer) {
    return {
        type: UPDATE_OFFER,
        offer,
    };
}

export function removeOffer(offer: Offer) {
    return {
        type: REMOVE_OFFER,
        offer,
    };
}
