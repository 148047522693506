import React, {useState, useEffect} from 'react';
import {useTranslation} from 'react-i18next';
import {useSelector} from 'react-redux';
import Select from 'react-select';
import {toast} from 'react-toastify';

import useServiceProvider from '../../../utils/service';
import Modal from '../../../components/Modal/Modal';
import {CheckboxField} from '../../../components/Input/Input';
import type {SelectOption} from '../../../utils/interfaces/interfaces';
import type {RootState} from '../../../redux/reducers/rootReducer';
import type {Contract, Offer} from '../../../utils/interfaces/offer';

/**
 *
 * @param onHide {Function}
 * @param selectedRows {Vehicle[]|License[]}
 * @returns {JSX.Element}
 * @constructor
 */
const SelectOrder = ({onHide, selectedRows}) => {
    const {t} = useTranslation(['Vehicles', 'common']);

    const {offersService} = useServiceProvider();
    const offerList = useSelector((state: RootState) => state.offerList);

    const [
        orderOptions: SelectOption[],
        setOrderOptions: Function<SelectOption[]>,
    ] = useState([{value: 'new_order', label: t('NEW_ORDER')}]);
    const [
        selectedOrder: SelectOption,
        setSelectedOrder: Function<SelectOption>,
    ] = useState({value: 'new_order', label: t('NEW_ORDER')});
    const [contracts: Contract[], setContracts: Function<Contract[]>] =
        useState(
            selectedRows
                ? selectedRows.map((row) => ({
                      id: row.contract_id,
                      ca: false,
                      pins: false,
                      etoll: false,
                  }))
                : [],
        );
    const [isLoading: boolean, setIsLoading: Function<boolean>] =
        useState(false);

    useEffect(() => {
        if (!offerList) return;
        const options = offerList
            .filter((o) => o.status === 'new')
            .sort((a, b) => b.created_at - a.created_at)
            .map((offer, index) => ({
                value: offer.id,
                label: t('ORDER_WITH_NUMBER', {number: index + 1}),
            }));
        setOrderOptions((prev) => [...prev, ...options]);
    }, [offerList, t]);

    const extendLicenses = () => {
        setIsLoading(true);
        const onSuccess = (offer: Offer) => {
            console.debug('SelectOrder :: extendLicenses', offer);
            toast.success(t('UPDATE_ORDER_SUCCESS'));
            setIsLoading(false);
            onHide && onHide();
        };
        const onError = (reason) => {
            console.error('SelectOrder :: extendLicenses', reason);
            toast.error(t('UPDATE_ORDER_ERROR'));
            setIsLoading(false);
        };
        if (selectedOrder.value === 'new_order') {
            offersService.createOffer(0, 0, 0, contracts, onSuccess, onError);
        } else {
            const currentOffer = offerList.find(
                (offer) => offer.id === selectedOrder.value,
            );

            const _contracts = currentOffer.contracts;

            contracts.forEach((contract) => {
                const index = _contracts.findIndex((c) => c.id === contract.id);
                if (index !== -1) {
                    _contracts[index] = contract;
                } else {
                    _contracts.push(contract);
                }
            });

            offersService.updateOffer(
                {...currentOffer, contracts: _contracts},
                onSuccess,
                onError,
            );
        }
    };

    const onChangeHandler = (
        value: boolean,
        contractId: number,
        field: string,
    ) => {
        const _contracts = contracts.map((contract) => {
            if (contract.id === contractId) {
                return {...contract, [field]: value};
            }
            return contract;
        });
        setContracts(_contracts);
    };

    return (
        <Modal id="select-order" onHide={onHide} isLoading={isLoading}>
            <header>{t('CHOOSE_ORDER')}</header>
            <main>
                {orderOptions && orderOptions.length > 0 && (
                    <Select
                        id="extend_packages_offer"
                        name="extend_packages_offer"
                        label={t('ORDER')}
                        placeholder={t('ORDER')}
                        value={selectedOrder}
                        options={orderOptions}
                        required={true}
                        onChange={(option) => setSelectedOrder(option)}
                    />
                )}
                {selectedRows && (
                    <ul>
                        <li>
                            <p>{t('VEHICLE')}</p>
                            <p>Car Assistant</p>
                            <p>Pins</p>
                            <p>e-TOLL</p>
                        </li>
                        {selectedRows.map((vehicle) => {
                            return (
                                <li key={vehicle.vehicle_id}>
                                    <p>
                                        {vehicle.name ||
                                            vehicle.vehicle_name ||
                                            ''}
                                    </p>
                                    <CheckboxField
                                        id={`select-order-ca`}
                                        value={
                                            contracts.find(
                                                (c) =>
                                                    c.id ===
                                                    vehicle.contract_id,
                                            ).ca
                                        }
                                        name="ca"
                                        onChange={(value) =>
                                            onChangeHandler(
                                                !value,
                                                vehicle.contract_id,
                                                'ca',
                                            )
                                        }
                                        withoutLabel={true}
                                    />
                                    <CheckboxField
                                        id={`select-order-pins`}
                                        value={
                                            contracts.find(
                                                (c) =>
                                                    c.id ===
                                                    vehicle.contract_id,
                                            ).pins
                                        }
                                        name="pins"
                                        onChange={(value) =>
                                            onChangeHandler(
                                                !value,
                                                vehicle.contract_id,
                                                'pins',
                                            )
                                        }
                                        withoutLabel={true}
                                    />
                                    <CheckboxField
                                        id={`select-order-etoll`}
                                        value={
                                            contracts.find(
                                                (c) =>
                                                    c.id ===
                                                    vehicle.contract_id,
                                            ).etoll
                                        }
                                        name="etoll"
                                        onChange={(value) =>
                                            onChangeHandler(
                                                !value,
                                                vehicle.contract_id,
                                                'etoll',
                                            )
                                        }
                                        withoutLabel={true}
                                    />
                                </li>
                            );
                        })}
                    </ul>
                )}
            </main>
            <footer>
                <button onClick={onHide} className="button">
                    {t('common:CANCEL')}
                </button>
                <button
                    disabled={isLoading}
                    onClick={extendLicenses}
                    className="button save"
                >
                    {t('ADD')}
                </button>
            </footer>
        </Modal>
    );
};

export default SelectOrder;
