import React from 'react';

export const IconRunTime = () => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="15.557"
            height="15.6"
            viewBox="0 0 15.557 15.6"
        >
            <g transform="translate(-481.2 -517.309)">
                <g transform="translate(-216 5.109)" opacity="0.7">
                    <path
                        d="M15.923,9.462a6.462,6.462,0,1,0-6.462,6.462"
                        transform="translate(695 510)"
                        fill="none"
                        stroke="#25354d"
                        strokeLinejoin="round"
                        strokeWidth="1.6"
                    />
                    <path
                        d="M18.437,18.436m-1.436,0A1.436,1.436,0,1,0,18.437,17,1.436,1.436,0,0,0,17,18.436"
                        transform="translate(691.051 506.051)"
                        fill="none"
                        stroke="#25354d"
                        strokeLinecap="square"
                        strokeLinejoin="round"
                        strokeWidth="1.6"
                    />
                    <path
                        d="M19,15.5v1.077"
                        transform="translate(690.487 506.474)"
                        fill="none"
                        stroke="#25354d"
                        strokeLinecap="square"
                        strokeLinejoin="round"
                        strokeWidth="1.6"
                    />
                    <path
                        d="M19,21v1.077"
                        transform="translate(690.487 504.923)"
                        fill="none"
                        stroke="#25354d"
                        strokeLinecap="square"
                        strokeLinejoin="round"
                        strokeWidth="1.6"
                    />
                    <path
                        d="M21.666,17.25l-.933.538"
                        transform="translate(689.998 505.981)"
                        fill="none"
                        stroke="#25354d"
                        strokeLinecap="square"
                        strokeLinejoin="round"
                        strokeWidth="1.6"
                    />
                    <path
                        d="M16.9,20l-.933.538"
                        transform="translate(691.342 505.205)"
                        fill="none"
                        stroke="#25354d"
                        strokeLinecap="square"
                        strokeLinejoin="round"
                        strokeWidth="1.6"
                    />
                    <path
                        d="M15.97,17.25l.933.538"
                        transform="translate(691.342 505.981)"
                        fill="none"
                        stroke="#25354d"
                        strokeLinecap="square"
                        strokeLinejoin="round"
                        strokeWidth="1.6"
                    />
                    <path
                        d="M20.733,20l.933.538"
                        transform="translate(689.998 505.205)"
                        fill="none"
                        stroke="#25354d"
                        strokeLinecap="square"
                        strokeLinejoin="round"
                        strokeWidth="1.6"
                    />
                    <path
                        d="M12,7v3.59l1.436,1.436"
                        transform="translate(692.462 508.872)"
                        fill="none"
                        stroke="#25354d"
                        strokeLinecap="square"
                        strokeLinejoin="round"
                        strokeWidth="1.6"
                    />
                </g>
            </g>
        </svg>
    );
};
