import {
    SET_NEW_POI_DATA,
    SET_NEW_POI_DATA_EXTRA,
    SET_NEW_POI_INITIAL_STATE,
    SET_NEW_POI_LAT_LNG,
    SET_NEW_POI_DATA_FROM_EXISTED,
} from '../actions/newPOIActions';
const initialState = {
    name: '',
    address: '',
    lat: 52.06,
    lng: 19.47,
    phone: '',
    email: '',
    type: null,
    extra: {
        // country: "PL",
        // width: 0,
        // length: 0,
        // cost: "",
        // protected: false,
        // fenced: false,
        // illuminated: false,
        // monitored: false,
        // description: "",
        // rate: 0,
    },
};
export default function newPOIData(state = initialState, action) {
    switch (action.type) {
        case SET_NEW_POI_DATA: {
            return Object.assign({}, state, {[action.field]: action.value});
        }
        case SET_NEW_POI_LAT_LNG: {
            return Object.assign({}, state, {lat: action.lat, lng: action.lng});
        }
        case SET_NEW_POI_DATA_EXTRA: {
            return Object.assign({}, state, {
                extra: {
                    ...state.extra,
                    [action.field]: action.value,
                },
            });
        }
        case SET_NEW_POI_DATA_FROM_EXISTED: {
            return Object.assign({}, state, action.data);
        }
        case SET_NEW_POI_INITIAL_STATE: {
            return initialState;
        }
        default: {
            return state;
        }
    }
}
