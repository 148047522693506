import React, {useCallback, useEffect, useMemo, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {shallowEqual, useSelector} from 'react-redux';

import {sortingDriversOrDevices} from '../../utils/sorting';
import {MainListHeader} from '../../components/MainListsHeader/MainListHeader';
import {Loader} from '../../components/Loader/Loader';
import Table from '../../components/Table';
import AddDevice from './parts/AddDevice';
import EditDevice from './parts/EditDevice';
import ShowDevice from './parts/ShowDevice';
import {IconRoundedCircle} from '../../graphics/icons';
import type {RootState} from '../../redux/reducers/rootReducer';
import type {Device} from '../../utils/interfaces/device';

import './Devices.scss';

const Devices = () => {
    const {t} = useTranslation(['Devices', 'common']);

    const deviceList: Device[] = useSelector(
        (state: RootState) => state.deviceList,
        shallowEqual,
    );
    const licenseList = useSelector((state: RootState) => state.licenseList);
    const userData = useSelector((state: RootState) => state.userData);

    const [tableData: Device[], setTableData: Function<Device[]>] =
        useState(null);
    const [currentRow: Device, setCurrentRow: Function<Device>] =
        useState(null);
    const [addMode: boolean, setAddMode: Function<boolean>] = useState(null);
    const [editMode: boolean, setEditMode: Function<boolean>] = useState(null);
    const [searchValue: string, setSearchValue: Function<string>] =
        useState('');

    useEffect(() => {
        if (deviceList === null) {
            return;
        }
        if (searchValue === '') {
            setTableData(deviceList);
        } else {
            const searchString = searchValue.toLowerCase();
            const _tableData = deviceList.filter((row) => {
                return (
                    row.serial_num?.toLowerCase().includes(searchString) ||
                    row.vehicle_name?.toLowerCase().includes(searchString)
                );
            });
            setTableData(_tableData);
        }
    }, [searchValue, deviceList]);

    const sorting = useCallback(
        (rowA, rowB, columnId, desc) =>
            sortingDriversOrDevices(rowA, rowB, columnId, desc),
        [],
    );

    const columns = useMemo(
        () => [
            {
                id: 'serial_num',
                Header: t('SERIAL_NUMBER'),
                accessor: 'serial_num',
                sortType: sorting,
                width: 180,
            },
            {
                id: 'vehicle_name',
                Header: t('VEHICLE'),
                accessor: 'vehicle_name',
                sortType: sorting,
                width: 180,
            },
            {
                id: 'is_assigned',
                Header: t('ASSIGNED'),
                accessor: 'vehicle_id',
                Cell: ({value}) => {
                    return value ? (
                        <IconRoundedCircle />
                    ) : (
                        <IconRoundedCircle color="grey" withoutCircle />
                    );
                },
                width: 60,
                className: 'cell-content center',
                sortType: sorting,
            },
            {
                id: 'contracts',
                Header: t('LICENSES_IN_SYSTEMS'),
                accessor: (device: Device) => {
                    if (!licenseList) {
                        return null;
                    }
                    const contracts: string[] = [];
                    licenseList.forEach((license) => {
                        if (license.device_id === device.id) {
                            contracts.push(
                                t(`common:${license.license_app_name}`),
                            );
                        }
                    });

                    return contracts.join(', ');
                },
                sortType: sorting,
                width: 180,
            },
        ],
        [sorting, t, licenseList],
    );

    const defaultSortBy = useMemo(() => [{id: 'serial_num', desc: false}], []);

    return (
        <div id="devices" className="devices-list-container">
            {currentRow !== null && !editMode && (
                <ShowDevice
                    device={currentRow}
                    setCurrentDevice={setCurrentRow}
                    setEditMode={setEditMode}
                />
            )}
            {addMode && <AddDevice setAddMode={setAddMode} />}
            {editMode && currentRow && (
                <EditDevice
                    currentDevice={currentRow}
                    onHide={() => setEditMode(false)}
                />
            )}
            <MainListHeader
                headerText={t('DEVICES')}
                searchValue={searchValue}
                handleChangeSearchValue={(e) => {
                    setSearchValue(e.target.value);
                }}
                addText={t('NEW_DEVICE')}
                switchAddMode={
                    userData.type === 'manager'
                        ? () => {
                              setAddMode((prev) => !prev);
                          }
                        : undefined
                }
            />
            {tableData === null ? (
                <Loader />
            ) : (
                <Table
                    data={tableData}
                    columns={columns}
                    defaultSortBy={defaultSortBy}
                    onRowClick={setCurrentRow}
                />
            )}
        </div>
    );
};

export default Devices;
