import React, {MutableRefObject, useRef, useState} from 'react';
import {toast} from 'react-toastify';
import {useTranslation} from 'react-i18next';

import RightPane from '../../../components/RightPane/RightPane';
import {TextField} from '../../../components/Input/Input';
import useServiceProvider from '../../../utils/service';
import {emailValidator, phoneValidator} from '../../../utils/validator';
import type {NewDriver} from '../../../utils/interfaces/driver';

/**
 *
 * @param onHide {Function}
 * @returns {JSX.Element}
 * @constructor
 */
const AddDriver = ({onHide}) => {
    const {t} = useTranslation(['Drivers']);
    const {driversService} = useServiceProvider();

    const paneRef: MutableRefObject<RightPane> = useRef(null);

    const initialData = {
        first_name: '',
        last_name: '',
        phone_number: '',
        email: '',
        comment: '',
    };
    const [data: NewDriver, setData: Function<NewDriver>] =
        useState(initialData);
    const [dataErrors: string[], setDataErrors: Function<string[]>] = useState(
        [],
    );
    const [canUseMobileApp: boolean, setCanUseMobileApp: Function<boolean>] =
        useState(false);
    const [inviteLang: string, setInviteLang: Function<string>] = useState(
        'pl',
    );

    const createDriver = (e: Event) => {
        e.preventDefault();

        const errors = [];

        if (data.first_name.trim().length === 0) {
            errors.push('first_name');
        }
        if (data.last_name.trim().length === 0) {
            errors.push('last_name');
        }
        if (
            data.phone_number.trim().length > 0 &&
            phoneValidator(data.phone_number.trim()) === false
        ) {
            errors.push('phone_number');
        }
        if (
            (data.email.trim().length > 0 || canUseMobileApp) &&
            emailValidator(data.email.trim()) === false
        ) {
            errors.push('email');
        }

        if (errors.length > 0) {
            setDataErrors(errors);
            return;
        }

        const _data = {
            first_name: data.first_name.trim(),
            last_name: data.last_name.trim(),
            phone_number: data.phone_number.trim(),
            email: data.email.trim(),
            comment: data.comment.trim(),
        };

        driversService.create(
            _data,
            async (result) => {
                console.debug(
                    'AddDriver::createDriver() => success: %s',
                    result,
                );
                toast.success(t('DRIVER_CREATED'));
                if (canUseMobileApp && result) {
                    await driversService.invite(
                        {driver_id: result.id, lang: inviteLang},
                        () => {
                            toast.success(t('DRIVER_LINKED'));
                            paneRef.current.hideComponent();
                        },
                        (reason) => {
                            toast.error(
                                t('DRIVER_LINKING_ERROR', {error: t(reason)}),
                            );
                        },
                    );
                } else {
                    paneRef.current.hideComponent();
                }
            },
            (reason) => {
                console.warn('AddDriver::createDriver() => reason: %s', reason);
                toast.error(t('DRIVER_CREATE_ERROR', {error: t(reason)}));
            },
        );
    };

    const handleDriverDataInputChange = (e: Event) => {
        let {name, value} = e.target;
        if (
            ['email', 'phone_number', 'phone_number_mobile'].indexOf(name) > -1
        ) {
            value = value.replace(/[ ]*/g, '').toLocaleLowerCase();
        }
        if (['phone_number', 'phone_number_mobile'].indexOf(name) > -1) {
            value = value.replace(/[^0-9+]/g, '');
        }
        setData({...data, [name]: value});
    };

    const handleInviteSelectChange = (e) => {
        const {value} = e.target;
        setInviteLang(value);
    };

    return (
        <form onSubmit={createDriver}>
            <RightPane
                ref={paneRef}
                id="driver-new"
                className="driver-new panel-right-form panel-right-entity-details"
                title={t('CREATE')}
                onComponentHidden={onHide}
                body={() => (
                    <div>
                        <div className="group">
                            <TextField
                                id={'driver_first_name'}
                                label={t('FIRST_NAME')}
                                value={data.first_name}
                                name={'first_name'}
                                onChange={handleDriverDataInputChange}
                                required={true}
                                tip={t('FIELD_REQUIRED')}
                                tipPlace={'left'}
                                hasError={dataErrors.includes('first_name')}
                            />
                            <TextField
                                id={'driver_last_name'}
                                label={t('LAST_NAME')}
                                value={data.last_name}
                                name={'last_name'}
                                onChange={handleDriverDataInputChange}
                                required={true}
                                tip={t('FIELD_REQUIRED')}
                                tipPlace={'left'}
                                hasError={dataErrors.includes('last_name')}
                            />
                            <TextField
                                id={'driver_phone_number'}
                                label={t('PHONE_NUMBER')}
                                value={data.phone_number}
                                name={'phone_number'}
                                onChange={handleDriverDataInputChange}
                                hasError={dataErrors.includes('phone_number')}
                                hint={'Format: +XXYYYYYYYYY'}
                            />
                            <TextField
                                id={'driver_email'}
                                label={t('EMAIL')}
                                value={data.email}
                                name={'email'}
                                onChange={handleDriverDataInputChange}
                                hasError={dataErrors.includes('email')}
                                required={canUseMobileApp}
                            />
                            <TextField
                                id={'driver_comment'}
                                label={t('COMMENT')}
                                value={data.comment}
                                name={'comment'}
                                onChange={handleDriverDataInputChange}
                            />
                            <div className="field">
                                <label htmlFor="driver_can_use_mobile_app">
                                    {t('CAN_USE_MOBILE_APP')}
                                </label>
                                <select
                                    id="driver_can_use_mobile_app"
                                    name="can_use_mobile_app"
                                    value={canUseMobileApp}
                                    onChange={() =>
                                        setCanUseMobileApp((prev) => !prev)
                                    }
                                >
                                    <option value={false}>{t('NO')}</option>
                                    <option value={true}>{t('YES')}</option>
                                </select>
                            </div>
                            {canUseMobileApp &&
                                <div className="field">
                                    <label htmlFor="counterparty_country">
                                        {t('CHOOSE_LANGUAGE')}
                                    </label>
                                    <select
                                        className={'table-text-input'}
                                        name={'lang'}
                                        placeholder={t('LANGUAGE')}
                                        value={inviteLang}
                                        onChange={handleInviteSelectChange}
                                    >
                                        <option value="pl">{t('PL')}</option>
                                        <option value="en">{t('EN')}</option>
                                        <option value="de">{t('DE')}</option>
                                    </select>
                                </div>
                            }
                        </div>
                        <div className="group hint">
                            <img
                                src={
                                    require('../../../graphics/iko_info_round.png')
                                        .default
                                }
                                alt=""
                                className="icon"
                            />
                            <span className="text">{t('NEW_DRIVER_HINT')}</span>
                        </div>
                    </div>
                )}
                footer={() => {
                    return (
                        <button className="button save">{t('CREATE')}</button>
                    );
                }}
            />
        </form>
    );
};

export default AddDriver;
