import Connection from '../Connection';
import {store} from '../redux/store/store';
import {
    addClient,
    removeClient,
    setClientList,
    updateClient,
} from '../redux/actions/clientListActions';
import {
    addContact,
    setContactList,
    updateContact,
} from '../redux/actions/contactListActions';
import {setNewClientDataFromExisting} from '../redux/actions/clientDataActions';
import {setCounterpartyServiceInitialized} from '../redux/actions/appActions';
import type {Counterparty} from '../utils/interfaces/counterparty';
import type {Person} from '../utils/interfaces/person';

export default class ClientService {
    constructor(connection: Connection) {
        this.connection = connection;
        this.connection.addHandler(
            'counterparties.counterparty_created',
            (message) => {
                console.debug(
                    'ClientService::EVENT(counterparties.counterparty_created) => %o',
                    message,
                );
                let client = message.data;
                client.correspondence = JSON.parse(client.correspondence);
                store.dispatch(addClient(client));
            },
        );
        this.connection.addHandler(
            'counterparties.counterparty_updated',
            (message) => {
                console.debug(
                    'ClientService::EVENT(counterparties.counterparty_updated) => %o',
                    message,
                );
                let client = message.data;
                if (client.correspondence) {
                    client.correspondence = JSON.parse(client.correspondence);
                }
                store.dispatch(updateClient(client));
                store.dispatch(setNewClientDataFromExisting(client));
            },
        );
        this.connection.addHandler(
            'counterparties.person_created',
            (message) => {
                console.debug(
                    'ClientService::EVENT(counterparties.person_created) => %o',
                    message,
                );
                let p: Person = message.data;
                p.counterparty = store
                    .getState()
                    .clientList?.find(
                        (c: Counterparty) =>
                            c.counterparty_id === p.counterparty_id,
                    );
                store.dispatch(addContact(p));
            },
        );
        this.connection.addHandler(
            'counterparties.person_updated',
            (message) => {
                console.debug(
                    'ClientService::EVENT(counterparties.person_updated) => %o',
                    message,
                );
                let p: Person = message.data;
                p.counterparty = store
                    .getState()
                    .clientList?.find(
                        (c: Counterparty) =>
                            c.counterparty_id === p.counterparty_id,
                    );
                store.dispatch(updateContact(p));
            },
        );
    }

    initStore() {
        const {app} = store.getState();
        if (app.counterpartyServiceInitialized === true) {
            console.debug(
                'ClientService::initStore() => store already initialized',
            );
            return;
        }
        console.debug('ClientService::initStore() => initializing store...');
        store.dispatch(setCounterpartyServiceInitialized(true));
        let counterparties = new Promise((resolve) => {
            this.connection.query(
                'counterparties.getCounterparties',
                {filter_active: true},
                (result: Counterparty[]) => {
                    let clients = result.map((client: Counterparty) => {
                        client.correspondence = JSON.parse(
                            client.correspondence,
                        );
                        return client;
                    });
                    store.dispatch(setClientList(clients));
                    resolve(clients);
                },
            );
        });
        let persons = new Promise((resolve) => {
            this.connection.query(
                'counterparties.getPersons',
                {filter_active: true},
                (persons: Person[]) => {
                    resolve(persons);
                },
            );
        });
        Promise.all([counterparties, persons]).then((values) => {
            console.debug(
                'ClientService::initStore() => store initialized',
                values,
            );
            let counterparties: Counterparty[] = values[0];
            let persons: Person[] = values[1];
            persons.forEach(
                (p: Person) =>
                    (p.counterparty = counterparties.find(
                        (c: Counterparty) =>
                            c.counterparty_id === p.counterparty_id,
                    )),
            );
            store.dispatch(setContactList(persons));
        });
    }

    // getCounterparties(callbackSuccess: Function, callbackError: Function) {
    //     let query = new Promise((resolve, reject) => {
    //         this.connection.query(
    //             "counterparties.getCounterparties",
    //             {
    //                 // include_id: true,
    //                 // include_name: true,
    //                 // include_tax_id: true,
    //                 // include_krs: true,
    //                 // include_street: true,
    //                 // include_postcode: true,
    //                 // include_country: true,
    //                 filter_active: true
    //             },
    //             resolve,
    //             reject
    //         );
    //     });
    //     query.then((result: Counterparty[]) => {
    //         console.debug('ClientService::getCounterparties => result: %o', result);
    //         let clients = result.map((client: Counterparty) => { client.correspondence = JSON.parse(client.correspondence); return client; });
    //         store.dispatch(setClientList(clients));
    //         callbackSuccess && callbackSuccess(clients)
    //     }).catch(reason => {
    //         console.warn('ClientService::getCounterparties', reason);
    //         callbackError && callbackError(reason)
    //     })
    // }

    storeClient(
        clientData,
        callbackSuccess: Function,
        callbackError: Function,
    ) {
        console.debug('ClientService::storeClient', clientData);
        let query = new Promise((resolve, reject) => {
            let method = clientData.counterparty_id
                ? 'counterparties.updateCounterparty'
                : 'counterparties.createCounterparty';
            // for (let prop in clientData) {
            //     if (!clientData.hasOwnProperty(prop)) {
            //         continue;
            //     }
            //     // if (!clientData[prop]) {
            //     //     delete clientData[prop];
            //     // }
            // }
            clientData.correspondence = JSON.stringify(
                clientData.correspondence,
            );
            this.connection.query(method, clientData, resolve, reject);
        });
        query
            .then((result) => {
                callbackSuccess && callbackSuccess(result);
            })
            .catch((reason) => {
                callbackError && callbackError(reason);
            });
    }

    removeClient(
        clientData,
        callbackSuccess: Function,
        callbackError: Function,
    ) {
        console.debug('ClientService::removeClient', clientData);
        let query = new Promise((resolve, reject) => {
            this.connection.query(
                'counterparties.disableCounterparty',
                {counterparty_id: clientData.counterparty_id},
                resolve,
                reject,
            );
        });
        query
            .then((result) => {
                store.dispatch(removeClient(clientData));
                callbackSuccess && callbackSuccess(result);
            })
            .catch((reason) => {
                callbackError && callbackError(reason);
            });
    }

    getPerson(
        personId: number,
        callbackSuccess: Function,
        callbackError: Function,
    ) {
        this.connection.query(
            'counterparties.getPersons',
            {filter_person_id: personId},
            (result) => {
                callbackSuccess && callbackSuccess(result[0] || null);
            },
            callbackError,
        );
    }

    // getPersons(counterpartyId: number, callbackSuccess: Function, callbackError: Function) {
    //     store.dispatch(clearContactList());
    //     let query = new Promise((resolve, reject) => {
    //         let args = {
    //             filter_active: true,
    //             filter_counterparty_id: counterpartyId
    //         };
    //         this.connection.query(
    //             "counterparties.getPersons",
    //             args,
    //             resolve,
    //             reject
    //         );
    //     });
    //     query.then(result => {
    //         console.log('ClientService::getPersons => result: %o', result);
    //         result.forEach(contact => { store.dispatch(addContact(contact)); });
    //         callbackSuccess && callbackSuccess(result)
    //     }).catch(reason => {
    //         console.warn('ClientService::getPersons', reason);
    //         callbackError && callbackError(reason)
    //     })
    // }

    // getPersonsForSharing(callbackSuccess: Function, callbackError: Function) {
    //     let resultCounterparties, resultPersons;
    //     let queryCounterparties = new Promise((resolve, reject) => {
    //         this.connection.query(
    //             "counterparties.getCounterparties",
    //             {},
    //             result => { resultCounterparties = result; resolve(); },
    //             reject
    //         );
    //     });
    //     let queryPersons = new Promise((resolve, reject) => {
    //         this.connection.query(
    //             "counterparties.getPersons",
    //             {filter_active: true},
    //             result => { resultPersons = result.filter(person => { return person.has_user; }); resolve(); },
    //             reject
    //         );
    //     });
    //     Promise.all([queryCounterparties, queryPersons])
    //         .then(
    //             () => {
    //                 let counterpartyMap = [];
    //                 resultCounterparties.forEach((counterparty) => {
    //                     counterpartyMap[counterparty.counterparty_id] = counterparty;
    //                 });
    //                 let contacts = resultPersons.map((contact) => {
    //                     return  Object.assign({}, contact, { counterparty: counterpartyMap[contact.counterparty_id] });
    //                 });
    //                 callbackSuccess && callbackSuccess(contacts);
    //             }
    //         )
    //         .catch(
    //             reason => {
    //                 console.warn('ClientService::getPersonsForSharing', reason);
    //                 callbackError && callbackError(reason)
    //             }
    //         );
    // }

    storePerson(
        contactData,
        callbackSuccess: Function,
        callbackError: Function,
    ) {
        console.debug('ClientService::storePerson', contactData);
        let query = new Promise((resolve, reject) => {
            let method = contactData.person_id
                ? 'counterparties.updatePerson'
                : 'counterparties.createPerson';
            this.connection.query(method, contactData, resolve, reject);
        });
        query
            .then((result) => {
                // if (contactData.person_id) {
                //     store.dispatch(updateContact(contactData));
                // } else {
                //     store.dispatch(addContact(contactData));
                // }
                callbackSuccess && callbackSuccess(result);
            })
            .catch((reason) => {
                callbackError && callbackError(reason);
            });
    }

    removePerson(
        contactData,
        callbackSuccess: Function,
        callbackError: Function,
    ) {
        console.debug('ClientService::removePerson', contactData);
        let query = new Promise((resolve, reject) => {
            this.connection.query(
                'counterparties.disablePerson',
                {person_id: contactData.person_id},
                resolve,
                reject,
            );
        });
        query
            .then((result) => {
                // store.dispatch(removeContact(contactData));
                callbackSuccess && callbackSuccess(result);
            })
            .catch((reason) => {
                callbackError && callbackError(reason);
            });
    }

    invitePerson(
        contactData,
        callbackSuccess: Function,
        callbackError: Function,
    ) {
        console.debug('ClientService::invitePerson', contactData);
        let query = new Promise((resolve, reject) => {
            this.connection.query(
                'counterparties.invitePersonEmail',
                {person_id: contactData.person_id},
                resolve,
                reject,
            );
        });
        query
            .then((result) => {
                // store.dispatch(removeContact(contactData));
                callbackSuccess && callbackSuccess(result);
            })
            .catch((reason) => {
                callbackError && callbackError(reason);
            });
    }
}
