import React, {MutableRefObject, useRef, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {toast} from 'react-toastify';
import RightPane from '../../components/RightPane/RightPane';
import {Loader} from '../../components/Loader/Loader';
import {TextField} from '../../components/Input/Input';
import {phoneValidator} from '../../utils/validator';

/**
 *
 * @param userData {Account}
 * @param userService {UserService}
 * @param onHide {Function}
 * @returns {Element}
 * @constructor
 */
const EditAccount = ({userData, userService, onHide}) => {
    const {t} = useTranslation('Account');

    const editRef: MutableRefObject<RightPane> = useRef(null);

    const [data, setData] = useState({
        first_name: userData.first_name || '',
        last_name: userData.last_name || '',
        phone_number: userData.phone_number || '',
    });
    const [isProcessing, setIsProcessing] = useState(false);
    const [dataErrors, setDataErrors] = useState([]);

    const validate = () => {
        const errors = [];

        if (
            data.phone_number.trim().length > 0 &&
            !phoneValidator(data.phone_number.trim())
        ) {
            errors.push('phone_number');
        }

        setDataErrors(errors);

        return errors.length === 0;
    };

    const submitHandler = (e: Event) => {
        e.preventDefault();
        console.debug(
            'EditAccount :: submitHandler() => accountData: %O',
            data,
        );
        if (!validate()) {
            return;
        }
        setIsProcessing(true);
        userService.update(
            data,
            (result) => {
                console.debug(
                    'EditAccount :: submitHandler() => result: %O',
                    result,
                );
                setIsProcessing(false);
                editRef.current.hideComponent();
                toast.success(t('ACCOUNT_UPDATED'));
            },
            (reason) => {
                console.warn(
                    'EditAccount :: submitHandler() => reason: %s',
                    reason,
                );
                toast.error(t('ACCOUNT_UPDATE_ERROR'), {error: t(reason)});
                setIsProcessing(false);
            },
        );
    };

    const onChangeHandler = (e: Event) => {
        const {name, value} = e.target;
        setData((prev) => ({...prev, [name]: value}));
    };

    const Body = () => (
        <>
            {isProcessing && <Loader />}
            {!isProcessing && (
                <form onSubmit={submitHandler}>
                    <TextField
                        id="account_first_name"
                        value={data.first_name}
                        name="first_name"
                        label={t('FIRST_NAME')}
                        autoComplete="given-name"
                        onChange={onChangeHandler}
                    />
                    <TextField
                        id="account_last_name"
                        value={data.last_name}
                        name="last_name"
                        label={t('LAST_NAME')}
                        autoComplete="family-name"
                        onChange={onChangeHandler}
                    />
                    <TextField
                        id="account_phone_number"
                        value={data.phone_number}
                        name="phone_number"
                        label={t('PHONE')}
                        autoComplete="phone"
                        hasError={dataErrors.includes('phone_number')}
                        onChange={onChangeHandler}
                    />
                </form>
            )}
        </>
    );

    const Footer = () => (
        <>
            <button
                type="reset"
                className="button action"
                onClick={(e: Event) => {
                    e.preventDefault();
                    editRef.current.hideComponent();
                }}
            >
                {t('ABORT')}
            </button>
            <button
                type="submit"
                className="button save"
                onClick={submitHandler}
            >
                {t('UPDATE_DATA')}
            </button>
        </>
    );

    return (
        <RightPane
            ref={editRef}
            id="account-pane-edit"
            className="account-pane panel-right-form"
            title={t('CHANGE_DATA')}
            body={Body}
            footer={Footer}
            onComponentHidden={onHide}
        />
    );
};

export default EditAccount;
