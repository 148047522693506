import React, {useEffect, useMemo, useState} from 'react';
import {toast} from 'react-toastify';
import {useTranslation} from 'react-i18next';
import {useSelector} from 'react-redux';

import {store} from '../../../redux/store/store';
import {
    setNewPoiData,
    setNewPoiDataExtra,
} from '../../../redux/actions/newPOIActions';
import {
    CheckboxField,
    NumberField,
    SelectField,
    TextField,
} from '../../../components/Input/Input';
import AutoSuggestItem from '../../../containers/RouteCreator/AutoSuggestItem';
import {countriesEurope} from '../../../utils/countries';
import {poiIcons} from '../../../utils/POITypes';
import type {RootState} from '../../../redux/reducers/rootReducer';
import type {POI} from '../../../utils/interfaces/poi';
import type {
    RoutePoint,
    Suggestion,
} from '../../../utils/interfaces/interfaces';

import '../Poi.scss';

/**
 *
 * @param addOrEditPOI {Function}
 * @param disableAddAndEditMode {Function}
 * @constructor
 */
const PoiCreator = ({addOrEditPOI, disableAddAndEditMode}) => {
    const {t} = useTranslation(['Poi', 'common']);

    const {newPOIData, POITypeList} = useSelector((state: RootState) => state);

    const [dataErrors: string[], setDataErrors: Function<string[]>] = useState(
        [],
    );
    const [address: RoutePoint, setAddress] = useState({
        localization: newPOIData?.address || '',
        lat: null,
        lng: null,
    });
    const [localizationSuggestions: Suggestion[], setLocalizationSuggestions] =
        useState([]);

    useEffect(() => {
        if (newPOIData.id === undefined) {
            store.dispatch(setNewPoiDataExtra('PL', 'country'));
            store.dispatch(setNewPoiDataExtra(1, 'radius'));
            store.dispatch(setNewPoiDataExtra(3, 'rate'));
            store.dispatch(setNewPoiDataExtra('', 'description'));
        }
    }, [newPOIData.id]);

    // update address state if pin on map changed
    useEffect(() => {
        if (typeof newPOIData?.address === 'string') {
            setAddress({
                lat: newPOIData.lat,
                lng: newPOIData.lng,
                localization: newPOIData.address,
            });
        }
    }, [newPOIData?.address, newPOIData.lat, newPOIData.lng]);

    useEffect(() => {
        store.dispatch(setNewPoiData(address.localization, 'address'));
        if (
            typeof address.lat === 'number' &&
            typeof address.lng === 'number'
        ) {
            const _lat = parseFloat(address.lat.toString().replace(',', '.'));
            const _lng = parseFloat(address.lng.toString().replace(',', '.'));
            store.dispatch(setNewPoiData(_lat, 'lat'));
            store.dispatch(setNewPoiData(_lng, 'lng'));
        }
    }, [address]);

    const isParking = useMemo(() => {
        if (!newPOIData.type) {
            return false;
        }
        const selectedType = POITypeList.find(
            (type) => type.id === newPOIData.type,
        );
        return selectedType && selectedType.name === 'parking';
    }, [POITypeList, newPOIData.type]);

    const getRateInput = () => {
        const value = newPOIData.extra?.rate || 3;
        return (
            <div className="rate-container">
                <p>{t('RATE')}</p>
                <div className={'cell-content center'}>
                    {[1, 2, 3, 4, 5].map((i) => (
                        <img
                            key={`poi_star_${i}`}
                            className={'image-cell-content'}
                            alt=""
                            src={
                                value >= i
                                    ? require('../../../graphics/iko_rank_a.png')
                                          .default
                                    : require('../../../graphics/iko_rank_c.png')
                                          .default
                            }
                            onClick={() =>
                                store.dispatch(setNewPoiDataExtra(i, 'rate'))
                            }
                        />
                    ))}
                </div>
            </div>
        );
    };

    const validatePOIData = (newPOIData: POI) => {
        const errors = [];

        if (newPOIData.name.length < 3) {
            toast.error(
                t('THE_NAME_MUST_CONTAIN_AT_LEAST_CHARACTERS', {
                    numberOfCharacters: 3,
                }),
            );
            errors.push('name');
        }
        if (
            newPOIData.extra.radius === '' ||
            newPOIData.extra.radius === 0 ||
            newPOIData.extra.radius === '0'
        ) {
            toast.error(t('POI_RADIUS_CAN_NOT_BE_0_AND_NULL'));
            errors.push('radius');
        }
        if (!newPOIData.type) {
            errors.push('type');
        }
        if (newPOIData.lat === 0 || newPOIData.lat === '') {
            errors.push('lat');
        }
        if (newPOIData.lng === 0 || newPOIData.lng === '') {
            errors.push('lng');
        }

        if (errors.length > 0) {
            setDataErrors(errors);
            return false;
        }
        return true;
    };

    const addOrUpdatePOIHandler = (newPOIData: POI) => {
        if (validatePOIData(newPOIData)) {
            const poiType = POITypeList.find(
                (type) => type.id === newPOIData.type,
            );
            const _data = {...newPOIData, address: address.localization};

            if (poiType) {
                _data.poi_type = {
                    id: poiType.id,
                    icon: poiType.icon,
                    name: poiType.name,
                };
            }
            addOrEditPOI(_data);
        }
    };

    const getTypeInput = () => {
        const options = POITypeList.filter((poiType) =>
            poiIcons.includes(poiType.icon),
        )
            .map((POIType) => {
                return (
                    <option
                        key={'poi_type_option_' + POIType.id}
                        value={POIType.id}
                    >
                        {t(POIType.name)}
                    </option>
                );
            })
            .sort((a, b) => {
                const nameA = a.props.children;
                const nameB = b.props.children;
                if (nameA < nameB) {
                    return -1;
                }
                if (nameA > nameB) {
                    return 1;
                }
                return 0;
            });
        options.push(
            <option key={'poi_type_option_'} value="" disabled hidden></option>,
        );

        return (
            <SelectField
                required={true}
                id="poi_creator_type"
                label={t('TYPE')}
                name="type"
                value={newPOIData.type || ''}
                hasError={dataErrors.includes('type')}
                onChange={(event) => {
                    store.dispatch(
                        setNewPoiData(parseInt(event.target.value), 'type'),
                    );
                }}
                options={() => options}
            />
        );
    };

    return (
        <div id={'POI-creator'}>
            <form
                className="panel-right-form"
                onSubmit={() => addOrUpdatePOIHandler(newPOIData)}
            >
                <TextField
                    id="poi_creator_name"
                    label={t('NAME')}
                    value={newPOIData.name || ''}
                    name="name"
                    hasError={dataErrors.includes('name')}
                    onChange={(event) =>
                        store.dispatch(
                            setNewPoiData(event.target.value, 'name'),
                        )
                    }
                    required={true}
                />
                {POITypeList && getTypeInput()}
                <SelectField
                    options={() => {
                        return countriesEurope.map((country) => {
                            return (
                                <option
                                    key={'poi_country_option_' + country.value}
                                    value={country.value}
                                >
                                    {country.name}
                                </option>
                            );
                        });
                    }}
                    id="poi_creator_country"
                    label={t('COUNTRY')}
                    name="country"
                    value={newPOIData.extra?.country || ''}
                    onChange={(event) =>
                        store.dispatch(
                            setNewPoiDataExtra(event.target.value, 'country'),
                        )
                    }
                />
                <NumberField
                    id="poi_creator_lat"
                    name="lat"
                    label={t('LATITUDE')}
                    value={newPOIData.lat || ''}
                    step={0.00001}
                    onChange={(event) => {
                        store.dispatch(
                            setNewPoiData(
                                parseFloat(
                                    event.target.value
                                        .toString()
                                        .replace(',', '.'),
                                ),
                                'lat',
                            ),
                        );
                    }}
                    required={true}
                    hasError={dataErrors.includes('lat')}
                />
                <NumberField
                    id="poi_creator_lng"
                    name="lng"
                    label={t('LONGITUDE')}
                    value={newPOIData.lng || ''}
                    step={0.00001}
                    onChange={(event) => {
                        store.dispatch(
                            setNewPoiData(
                                parseFloat(
                                    event.target.value
                                        .toString()
                                        .replace(',', '.'),
                                ),
                                'lng',
                            ),
                        );
                    }}
                    required={true}
                    hasError={dataErrors.includes('lng')}
                />
                <div className="field">
                    <label>{t('ADDRESS')}</label>
                    <AutoSuggestItem
                        point={address}
                        setPoint={setAddress}
                        localizationSuggestions={localizationSuggestions}
                        setLocalizationSuggestions={setLocalizationSuggestions}
                    />
                </div>
                <NumberField
                    id="poi_creator_radius"
                    name="radius"
                    label={t('RADIUS')}
                    value={newPOIData.extra?.radius || ''}
                    step={1}
                    min={1}
                    onChange={(event) =>
                        store.dispatch(
                            setNewPoiDataExtra(event.target.value, 'radius'),
                        )
                    }
                    hasError={dataErrors.includes('radius')}
                />
                <TextField
                    id="poi_creator_description"
                    name="description"
                    label={t('COMMENTS')}
                    value={newPOIData.extra?.description || ''}
                    onChange={(event) =>
                        store.dispatch(
                            setNewPoiDataExtra(
                                event.target.value,
                                'description',
                            ),
                        )
                    }
                />
                {getRateInput()}
                {isParking && (
                    <NumberField
                        id="poi_creator_length"
                        name="length"
                        label={t('LENGTH')}
                        min={0}
                        step={0.1}
                        value={newPOIData.extra?.length || 0}
                        onChange={(event) =>
                            store.dispatch(
                                setNewPoiDataExtra(
                                    event.target.value,
                                    'length',
                                ),
                            )
                        }
                    />
                )}
                {isParking && (
                    <NumberField
                        id="poi_creator_width"
                        name="width"
                        label={t('WIDTH')}
                        min={0}
                        step={0.1}
                        value={newPOIData.extra?.width || 0}
                        onChange={(event) =>
                            store.dispatch(
                                setNewPoiDataExtra(event.target.value, 'width'),
                            )
                        }
                    />
                )}
                {isParking && (
                    <TextField
                        id="poi_creator_cost"
                        name="cost"
                        label={t('COST_WITH_CURRENCY')}
                        value={newPOIData.extra?.cost || ''}
                        onChange={(event) =>
                            store.dispatch(
                                setNewPoiDataExtra(event.target.value, 'cost'),
                            )
                        }
                    />
                )}
                {isParking && (
                    <div className="checkboxes-container">
                        <CheckboxField
                            id="poi_creator_protected"
                            name="protected"
                            label={t('protected')}
                            value={newPOIData.extra?.protected || false}
                            onChange={(value) =>
                                store.dispatch(
                                    setNewPoiDataExtra(!value, 'protected'),
                                )
                            }
                        />
                        <CheckboxField
                            id="poi_creator_fenced"
                            label={t('fenced')}
                            value={newPOIData.extra?.fenced || false}
                            name="fenced"
                            onChange={(value) =>
                                store.dispatch(
                                    setNewPoiDataExtra(!value, 'fenced'),
                                )
                            }
                        />
                        <CheckboxField
                            id="poi_creator_illuminated"
                            name="illuminated"
                            label={t('illuminated')}
                            value={newPOIData.extra?.illuminated || false}
                            onChange={(value) =>
                                store.dispatch(
                                    setNewPoiDataExtra(!value, 'illuminated'),
                                )
                            }
                        />
                        <CheckboxField
                            id="poi_creator_monitored"
                            name="monitored"
                            label={t('monitored')}
                            value={newPOIData.extra?.monitored || false}
                            onChange={(value) =>
                                store.dispatch(
                                    setNewPoiDataExtra(!value, 'monitored'),
                                )
                            }
                        />
                    </div>
                )}
            </form>
            <div className={'footer'}>
                <button
                    className={'button cancel'}
                    onClick={() => disableAddAndEditMode()}
                >
                    {t('CANCEL')}
                </button>
                <button
                    className={'button save'}
                    onClick={() => addOrUpdatePOIHandler(newPOIData)}
                >
                    {t('SAVE')}
                </button>
            </div>
        </div>
    );
};

export default PoiCreator;
