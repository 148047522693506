import Connection from '../Connection';
import type {
    CreateOrder,
    Order,
    SendCounterpartyMail,
    UpdateOrder,
    OrderHistory,
} from '../utils/interfaces/order';
import {
    addOrder,
    setOrderList,
    updateOrder,
} from '../redux/actions/orderActions';
import {store} from '../redux/store/store';
import {setOrderServiceInitialized} from '../redux/actions/appActions';

export default class OrderService {
    constructor(connection: Connection) {
        this.connection = connection;
        this.createNotificationsHandler();
    }

    initStore(groupId: number | null) {
        const {app} = store.getState();
        if (app.orderServiceInitialized === true) {
            console.debug(
                'OrderService::initStore() => store already initialized',
            );
            return;
        }
        console.debug('OrderService::initStore() => initializing store...');
        store.dispatch(setOrderServiceInitialized(true));
        this.getOrders(groupId);
    }

    createNotificationsHandler() {
        this.connection.addHandler('orders.order_updated', (result) => {
            store.dispatch(updateOrder(result.data));
        });
        this.connection.addHandler('orders.order_created', () => {
            this.getOrders(store.getState().app.selectedGroupId, (result) => {
                console.debug('OrderService :: getOrders', result);
            });
        });
    }

    getOrders(groupId: number | null, callbackSuccess, callbackError) {
        const args = {};
        let _groupId;
        if (typeof groupId === 'number') {
            _groupId = groupId;
        } else {
            _groupId = store.getState().app.selectedGroupId;
        }

        if (typeof _groupId === 'number') {
            const groupIndex = store
                .getState()
                .groupList?.findIndex((group) => group.id === _groupId);
            if (groupIndex !== -1) {
                args.filter_group_id = _groupId;
            }
        }

        const onSuccess = (result: Order[]) => {
            store.dispatch(setOrderList(result));
            callbackSuccess && callbackSuccess(result);
        };

        this._handleQuery(
            'getOrders',
            'orders.getOrders',
            args,
            onSuccess,
            callbackError,
        );
    }

    getOrderHistory(orderId: number) {
        return new Promise((resolve, reject) =>
            this.connection.query(
                'orders.getOrderHistory',
                {order_id: orderId},
                (result: OrderHistory[]) => {
                    const orderHistory = result.map((o) => ({
                        ...o,
                        new_values: JSON.parse(o.new_values),
                    }));
                    resolve(orderHistory);
                },
                (reason) => {
                    reject(reason);
                },
            ),
        );
    }
    getOrdersCounterparty() {
        return new Promise((resolve, reject) =>
            this.connection.query(
                'orders.getOrdersCounterparty',
                {},
                (result) => {
                    result.forEach((order) => store.dispatch(addOrder(order)));
                    resolve(result);
                },
                (reason) => {
                    reject(reason);
                },
            ),
        );
    }

    createOrder(newOrderData: CreateOrder) {
        return new Promise((resolve, reject) =>
            this.connection.query(
                'orders.createOrder',
                newOrderData,
                (result) => {
                    store.dispatch(addOrder(result));
                    resolve(result);
                },
                (reason) => {
                    reject(reason);
                },
            ),
        );
    }

    updateOrder(updatedOrderData: UpdateOrder) {
        return new Promise((resolve, reject) =>
            this.connection.query(
                'orders.updateOrder',
                updatedOrderData,
                (result) => {
                    store.dispatch(updateOrder(result));
                    resolve(result);
                },
                (reason) => {
                    reject(reason);
                },
            ),
        );
    }
    updateOrderCounterparty(updatedOrderData: UpdateOrder) {
        return new Promise((resolve, reject) =>
            this.connection.query(
                'orders.updateOrderCounterparty',
                updatedOrderData,
                (result) => {
                    store.dispatch(updateOrder(result));
                    resolve(result);
                },
                (reason) => {
                    reject(reason);
                },
            ),
        );
    }

    sendCounterpartyMail(counterpartyMailData: SendCounterpartyMail) {
        return new Promise((resolve, reject) =>
            this.connection.query(
                'orders.sendCounterpartyMail',
                counterpartyMailData,
                (result) => {
                    resolve(result);
                },
                (reason) => {
                    reject(reason);
                },
            ),
        );
    }

    _handleQuery(
        f,
        method,
        args,
        callbackSuccess: Function,
        callbackError: Function,
    ) {
        let query = new Promise((resolve, reject) => {
            this.connection.query(method, args, resolve, reject);
        });
        query
            .then((result) => {
                console.debug('OrderService::%s => result: %o', f, result);
                callbackSuccess && callbackSuccess(result);
            })
            .catch((reason) => {
                console.warn('OrderService::%s => reason: %s', f, reason);
                callbackError && callbackError(reason);
            });
    }
}
