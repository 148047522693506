export const SET_APP_VARIANT = 'SET_APP_VARIANT';
export const SET_SELECTED_GROUP_ID = 'SET_SELECTED_GROUP_ID';
export const SET_VEHICLE_SERVICE_INITIALIZED =
    'SET_VEHICLE_SERVICE_INITIALIZED';
export const SET_DRIVER_SERVICE_INITIALIZED = 'SET_DRIVER_SERVICE_INITIALIZED';
export const SET_POI_SERVICE_INITIALIZED = 'SET_POI_SERVICE_INITIALIZED';
export const SET_ORDER_SERVICE_INITIALIZED = 'SET_ORDER_SERVICE_INITIALIZED';
export const SET_COUNTERPARTY_SERVICE_INITIALIZED =
    'SET_COUNTERPARTY_SERVICE_INITIALIZED';
export const SET_ACCOUNT_PANE_VISIBLE = 'SET_ACCOUNT_PANE_VISIBLE';
export const SET_CHAT_SERVICE_INITIALIZED = 'SET_CHAT_SERVICE_INITIALIZED';
export const SET_DEVICES_SERVICE_INITIALIZED =
    'SET_DEVICES_SERVICE_INITIALIZED';
export const SET_LICENSE_SERVICE_INITIALIZED =
    'SET_LICENSE_SERVICE_INITIALIZED';
export const SET_OFFERS_SERVICE_INITIALIZED = 'SET_OFFERS_SERVICE_INITIALIZED';
export const SET_GROUP_SERVICE_INITIALIZED = 'SET_GROUP_SERVICE_INITIALIZED';

export function setAppVariant(variant: string) {
    return {
        type: SET_APP_VARIANT,
        variant,
    };
}

export function setSelectedGroupId(groupId: number | null) {
    return {
        type: SET_SELECTED_GROUP_ID,
        groupId,
    };
}
export function setChatServiceInitialized(initialized: boolean) {
    return {
        type: SET_CHAT_SERVICE_INITIALIZED,
        initialized,
    };
}
export function setVehicleServiceInitialized(initialized: boolean) {
    return {
        type: SET_VEHICLE_SERVICE_INITIALIZED,
        initialized,
    };
}

export function setDriverServiceInitialized(initialized: boolean) {
    return {
        type: SET_DRIVER_SERVICE_INITIALIZED,
        initialized,
    };
}

export function setPoiServiceInitialized(initialized: boolean) {
    return {
        type: SET_POI_SERVICE_INITIALIZED,
        initialized,
    };
}

export function setOrderServiceInitialized(initialized: boolean) {
    return {
        type: SET_ORDER_SERVICE_INITIALIZED,
        initialized,
    };
}

export function setCounterpartyServiceInitialized(initialized: boolean) {
    return {
        type: SET_COUNTERPARTY_SERVICE_INITIALIZED,
        initialized,
    };
}

export function setDevicesServiceInitialized(initialized: boolean) {
    return {
        type: SET_DEVICES_SERVICE_INITIALIZED,
        initialized,
    };
}

export function setLicenseServiceInitialized(initialized: boolean) {
    return {
        type: SET_LICENSE_SERVICE_INITIALIZED,
        initialized,
    };
}

export function setAccountPaneVisible(visible: boolean) {
    return {
        type: SET_ACCOUNT_PANE_VISIBLE,
        visible,
    };
}

export function setOffersServiceInitialized(initialized: boolean) {
    return {
        type: SET_OFFERS_SERVICE_INITIALIZED,
        initialized,
    };
}

export function setGroupServiceInitialized(initialized: boolean) {
    return {
        type: SET_GROUP_SERVICE_INITIALIZED,
        initialized,
    };
}
